import { AltRoute } from '@mui/icons-material';
import { Link, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  nobbNr: number;
  productName: string;
}

const VariantTooltip: React.FC<Props> = ({ nobbNr, productName }) => {
  const t = useProductsTFunction();

  return (
    <Tooltip
      arrow
      title={
        <Typography>
          <span>{t('products.productList.variants.priceFor')}</span>
          <Link
            component={RouterLink}
            to={`/products/${nobbNr}`}
            sx={{ color: 'white', textDecorationColor: 'white', pl: 1 }}
          >
            {productName}
          </Link>
        </Typography>
      }
    >
      <AltRoute fontSize="small" color="primary" />
    </Tooltip>
  );
};

export default VariantTooltip;
