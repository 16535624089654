import { useController, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { SelectInputField } from '@shared/components';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import {
  GenericProductGroup,
  MgSupplierItemDetailedDTO,
  MgSupplierItemSearchResult,
} from '@retail/products/types';
import { Dispatch, SetStateAction } from 'react';
import { CreateFreightMarkupForProductGroup } from './CreateFreightMarkupForProductGroup';
import { CreateFreightMarkupForMgSupplierItem } from './CreateFreightMarkupForMgSupplierItem';
import { FreightMarkupFormBase, CreateFreightMarkupSchema } from '../FreightMarkupForm';

interface Props {
  productGroups: GenericProductGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  setMgSupplierItemSearch: Dispatch<SetStateAction<string>>;
  getMgSupplierItem: (mgSupplierItemId: number) => Promise<MgSupplierItemDetailedDTO>;
  isLoadingFetchItems: boolean;
}

export function CreateFreightMarkupForm({
  productGroups,
  mgSupplierItems,
  setMgSupplierItemSearch,
  getMgSupplierItem,
  isLoadingFetchItems,
}: Props) {
  const t = useFreightMarkupTFunction();
  const { control } = useFormContext<CreateFreightMarkupSchema>();
  const levelController = useController({ name: 'level', control });
  const mgSupplierItemController = useController({ name: 'mgSupplierItem', control });
  const freightTypeController = useController({ name: 'freightUnit', control });
  const freightFactorController = useController({ name: 'freightFactor', control });
  const freightSurchargeController = useController({ name: 'freightSurcharge', control });

  return (
    <form>
      <Stack gap={3} pb={1}>
        <SelectInputField
          {...levelController.field}
          error={levelController.fieldState.error?.message}
          fullWidth
          required
          label={t('freightMarkup.level.level')}
          placeholder={t('freightMarkup.actions.chooseLevel')}
          menuItems={[
            { value: 'supplier', name: t('freightMarkup.level.supplier') },
            { value: 'productGroup', name: t('freightMarkup.level.productGroup') },
            { value: 'mgSupplierItem', name: t('freightMarkup.level.mgSupplierItem') },
          ]}
          onChange={(value) => {
            freightTypeController.field.onChange('');
            freightFactorController.field.onChange('');
            freightSurchargeController.field.onChange('');
            mgSupplierItemController.field.onChange();
            levelController.field.onChange(value);
          }}
        />

        {levelController.field.value === 'supplier' && <FreightMarkupFormBase />}

        {levelController.field.value === 'productGroup' && (
          <CreateFreightMarkupForProductGroup productGroups={productGroups} />
        )}

        {levelController.field.value === 'mgSupplierItem' && (
          <CreateFreightMarkupForMgSupplierItem
            mgSupplierItems={mgSupplierItems}
            setMgSupplierItemSearch={setMgSupplierItemSearch}
            getMgSupplierItem={getMgSupplierItem}
            isLoadingFetchItems={isLoadingFetchItems}
          />
        )}
      </Stack>
    </form>
  );
}
