import { MgSupplierItemSearchResult } from '@retail/products/types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Checkbox, styled, Typography, useTheme } from '@mui/material';
import { Checkbox as CheckboxWithLabel } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  items: MgSupplierItemSearchResult[];
  onItemClick: (item: MgSupplierItemSearchResult) => void;
  selection?: {
    selectedItemIds: number[];
    setSelectedItems: (ids: number[]) => void;
    selectItem: (id: number) => void;
  };
}

export function MgSupplierItemsTableWithCheckbox({ items, onItemClick, selection }: Props) {
  const { palette } = useTheme();
  const t = useProductsTFunction();
  const allItemsAreSelected = useMemo(
    () => selection?.selectedItemIds.length === items.length,
    [items, selection]
  );

  const checkAllItems = useCallback(
    () =>
      allItemsAreSelected
        ? selection?.setSelectedItems([])
        : selection?.setSelectedItems(items.map((item) => item.mgItemNumber)),
    [allItemsAreSelected, items, selection]
  );

  return (
    <StyledTable>
      <TableHead>
        <TableRow sx={{ borderBottom: `2px solid ${palette.grey.A100}` }}>
          {selection && (
            <TableCell align="center">
              <CheckboxWithLabel
                label={t('products.mgItem.selectAll')}
                labelplacement="top"
                checked={allItemsAreSelected}
                onClick={checkAllItems}
              />
            </TableCell>
          )}
          <TableCell>{t('products.mgItem.supplierItem.primaryText')}</TableCell>
          <TableCell>{t('products.supplier.name')}</TableCell>
          <TableCell>{t('products.mgItem.supplierItem.finfoNumber.label')}</TableCell>
          <TableCell>{t('products.mgItem.number.short')}</TableCell>
          <TableCell>{t('products.mgItem.supplierItem.bk04ProductCategory')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => {
          const { primaryText, finfoNumber, mgParticipant, mgItemNumber, bk04Varegruppe } = item;
          return (
            <TableRow key={finfoNumber} onClick={() => onItemClick(item)}>
              {selection && (
                <TableCell align="center">
                  <Checkbox
                    checked={selection.selectedItemIds.includes(item.mgItemNumber)}
                    onClick={(e) => {
                      e.stopPropagation();
                      selection?.selectItem(item.mgItemNumber);
                    }}
                  />
                </TableCell>
              )}
              <TableCell>
                <Typography variant="body1">{primaryText}</Typography>
              </TableCell>
              <TableCell>{mgParticipant.name}</TableCell>
              <TableCell>{finfoNumber}</TableCell>
              <TableCell>{mgItemNumber}</TableCell>
              <TableCell>{bk04Varegruppe.getLabel()}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableBody-root': {
    '& td p': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    '& .MuiTypography-root': { fontWeight: 'bold' },
    '& .MuiTableRow-root': {
      borderBottom: `1.5px solid ${theme.palette.grey.A100}`,
      padding: '8px',
      '&:hover,:focus': {
        cursor: 'pointer',
        outline: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '10px',
        boxShadow: theme.shadows[4],
      },
      '&: nth-of-type(2n)': {
        '& .MuiTableCell-root': {
          '&: first-of-type': {
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px',
          },
          '&: last-of-type': {
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px',
          },
        },
        backgroundColor: theme.palette.primary.A100,
      },
    },
  },
}));
