import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { OrgUnit } from "@retail/auth/types";
import { useUserOrgUnits } from './useUserOrgUnits';
import { useUserBrands } from './Brands';
import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';

export const selectedContextKey = 'selectedContextKey';
const setInitialContext = (contexts: OrgUnit[]) => {
  const storedSelectedContextKey: string | null = JSON.parse(
    localStorage.getItem(selectedContextKey) || 'null'
  );

  const selectedContextIdx = contexts.findIndex((c) => c.value === storedSelectedContextKey);

  if (storedSelectedContextKey !== null && selectedContextIdx !== -1) {
    return contexts[selectedContextIdx];
  }

  return contexts[0];
};

type ContextsState = {
  userContexts: OrgUnit[];
  brandContexts: OrgUnit[];
  selectedContext: OrgUnit;
  switchContext: (orgUnit: OrgUnit) => void;
};

const context = createContext<ContextsState>({} as ContextsState);

const ContextsProvider = (props: { children: ReactNode }) => {
  const { data: userContexts = [] } = useUserOrgUnits({ suspense: true });
  const { selectedBrand } = useUserBrands();
  const navigate = useNavigate();

  const brandContexts = useMemo(() => {
    return userContexts.filter((context: OrgUnit) => context.theme === selectedBrand.key);
  }, [userContexts, selectedBrand]);

  const [selectedContext, setSelectedContext] = useState<OrgUnit>(() =>
    setInitialContext(userContexts)
  );

  useEffect(() => {
    if (selectedBrand !== undefined && selectedBrand.key !== selectedContext.theme) {
      setSelectedContext(brandContexts[0]);
    }
  }, [selectedBrand, brandContexts, selectedContext, setSelectedContext]);

  useEffect(() => {
    if (selectedContext !== undefined) {
      localStorage.setItem(selectedContextKey, JSON.stringify(selectedContext.value));
    }
  }, [selectedContext]);

  const switchContext = useCallback(
    (orgUnit: OrgUnit) => {
      localStorage.setItem(selectedContextKey, JSON.stringify(orgUnit.value));
      navigate(mgPrisRoutes.marketInsight.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const value = useMemo(
    () => ({
      userContexts,
      brandContexts,
      selectedContext,
      switchContext,
    }),
    [brandContexts, selectedContext, switchContext, userContexts]
  );

  return <context.Provider value={value}>{props.children}</context.Provider>;
};

const useContexts = () => {
  return useContext(context);
};

export { ContextsProvider, useContexts };
