import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { MgSupplierItemsDialog } from '@retail/purchase-agreements/components';
import { useAffectedItems } from '@retail/freight-markup/context';

type Props = {
  open: boolean;
  onClose: () => void;
  mgSupplierId: number;
  markupId: string;
};

function ItemsAffectedDialogContainer({ open, onClose, mgSupplierId, markupId }: Props) {
  const { result, setPage, setPageSize } = useAffectedItems({
    mgSupplierId,
    markupId,
    initPageSize: 25,
    shouldFetch: open,
  });

  return (
    <MgSupplierItemsDialog
      affectedItems={result}
      open={open}
      onClose={onClose}
      updatePage={setPage}
      updatePageSize={setPageSize}
    />
  );
}

export default withDialogSuspenseErrorBoundary(ItemsAffectedDialogContainer);
