import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { MgItemSearchResultDTO, PaginationResultDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';

export const mgItemSearchApiRoute = 'v1/mg-item/search';

interface SearchProps {
  searchQuery?: string;
  mgParticipantIds?: number[];
  bk04OvergruppeIds?: string[];
  bk04HovedgruppeIds?: string[];
  bk04VaregruppeIds?: string[];
  page: number;
  pageSize: number;
}

interface FetchProps extends SearchProps {
  baseUrl: string;
  token: string;
}

export async function searchMgItems({
  baseUrl,
  bk04VaregruppeIds,
  bk04OvergruppeIds,
  bk04HovedgruppeIds,
  mgParticipantIds,
  searchQuery,
  page,
  pageSize,
  token,
}: FetchProps) {
  return fetcher<PaginationResultDTO<MgItemSearchResultDTO[]>>({
    url: buildUrl({
      base: baseUrl,
      route: mgItemSearchApiRoute,
      searchParams: {
        query: searchQuery,
        bk04VaregruppeIds,
        bk04OvergruppeIds,
        bk04HovedgruppeIds,
        mgParticipantIds,
        page,
        pageSize,
      },
    }),
    token,
  });
}

type Props = SearchProps;

export function useFetchSearchMgItems(searchProps: Props, options?: QueryOptions) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () => searchMgItems({ baseUrl: pps.url, token, ...searchProps }),
    [pps.url, token, searchProps]
  );
  return useQueryFetch({
    fetcher,
    queryKey: ['mg-item', 'search', searchProps],
    ...options,
  });
}
