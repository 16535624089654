import { memo, useMemo, useCallback } from 'react';
import { PriceUnitAlias } from '../../../types/PriceUnits';
import {
  Autocomplete,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useAliasesApi } from '../../../api/pps/price-units-alias';
import { usePriceUnitsApi } from '../../../api/pps/price-units';
import { useAppTFunction } from '@retail/app/i18n';

interface OptionType {
  label: string;
  value: string;
}

type RowProps = {
  alias: PriceUnitAlias;
  options: OptionType[];
  onChange: (id: number, value: string) => Promise<void>;
};

function CustomRow({ alias, options, onChange }: RowProps) {
  const selectedUnit: OptionType = useMemo(
    () => ({
      label: alias.unit,
      value: alias.unit,
    }),
    [alias.unit]
  );

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {alias.alias}
      </TableCell>
      <TableCell style={{ minWidth: '150px' }}>
        <Autocomplete
          disablePortal
          renderInput={(params) => <TextField {...params} />}
          options={options}
          value={selectedUnit}
          onChange={(_, val) => val && onChange(alias.id, val.value)}
          getOptionLabel={(option) => option.label}
          renderOption={(props, { label }) => (
            <Box component="li" {...props} key={label}>
              {label}
            </Box>
          )}
          isOptionEqualToValue={(option, value) =>
            option.label === value.label && option.value === value.value
          }
        />
      </TableCell>
    </TableRow>
  );
}

function Aliases() {
  const t = useAppTFunction();
  const { aliases, updatePriceUnitAlias } = useAliasesApi();

  const { data: priceUnits = [] } = usePriceUnitsApi();
  const options: OptionType[] = useMemo(() => {
    return priceUnits.map((p) => ({ value: p, label: p }));
  }, [priceUnits]);

  const sortedAlias = useMemo(() => {
    if (aliases.data) {
      return [...aliases.data].sort((a, b) =>
        a.alias.toLowerCase().localeCompare(b.alias.toLowerCase())
      );
    }
    return [];
  }, [aliases.data]);

  const handleUpdate = useCallback(
    async (id: number, value: string) => {
      await updatePriceUnitAlias.mutateAsync({ aliasId: id, unitValue: value });
    },
    [updatePriceUnitAlias]
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>{t('admin.units.alias')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{t('admin.units.unit')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAlias.map((row, idx) => (
            <CustomRow
              key={`${row.alias}${row.unit}${idx}`}
              alias={row}
              options={options}
              onChange={handleUpdate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(Aliases);
