import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { prisinnsiktConfig } from '@retail/app/config';
import { User } from '@retail/auth/types';
import { useToken } from '../../hooks/useToken';
import { fetcher } from '@shared/fetch-utils';

const url = prisinnsiktConfig.pps.url + '/v1/admin/superusers';

export async function fetchSuperUsers(token: string): Promise<User[]> {
  return fetcher({
    url: `${url}`,
    method: 'GET',
    token,
  });
}

export async function promoteSuperUser(token: string, userId: string): Promise<User> {
  return fetcher({
    url: `${url}/${userId}`,
    method: 'POST',
    token,
  });
}
export async function demoteSuperUser(token: string, userId: string): Promise<User> {
  return fetcher({
    url: `${url}/${userId}`,
    method: 'DELETE',
    token,
  });
}

export function useSuperUsers() {
  const queryClient = useQueryClient();
  const tokenWithErrorHandling = useToken();

  const { data: superUsers } = useQuery(['super-users'], async () => {
    const token = await tokenWithErrorHandling();
    return fetchSuperUsers(token);
  });

  const promoteSuperUserMutation = useMutation(
    async (userId: string) => {
      const token = await tokenWithErrorHandling();
      return promoteSuperUser(token, userId);
    },
    {
      onSuccess: (data) => {
        const updatedIdx = superUsers?.findIndex((u) => u.userId === data.userId);
        if (updatedIdx === -1) {
          const updated = superUsers?.concat(data);
          queryClient.setQueryData(['super-users'], updated);
        }
      },
    }
  );

  const demoteSuperUserMutation = useMutation(
    async (userId: string) => {
      const token = await tokenWithErrorHandling();
      return demoteSuperUser(token, userId);
    },
    {
      onSuccess: (data) => {
        const updated = superUsers?.filter((u) => u.userId !== data.userId);
        queryClient.setQueryData(['super-users'], updated);
      },
    }
  );
  return { superUsers, promoteSuperUserMutation, demoteSuperUserMutation };
}
