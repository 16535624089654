import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { BreadcrumbsProps, Paper } from '@shared/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { useFetchAllMgParticipants } from '@retail/products/data-access';
import { MgParticipant } from '@retail/products/types';
import { SupplierSelect } from '@retail/products/components';
import { usePurchaseAgreementsSearchParams } from '@retail/purchase-agreements/hooks';
import {
  BodyLoader,
  BreadcrumbsToolbar,
  ErrorFallbackPage,
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import {
  ItemAgreements,
  SelectedProductGroupOrSupplier,
  ProductGroupsTreeViewContainer,
  SelectedPurchaseAgreements,
  SummaryDrawerContainer,
} from '@retail/purchase-agreements/containers';

export function PurchaseAgreementsForSupplierPage() {
  const t = useAppTFunction();
  const navigate = useNavigate();

  const { supplierId } = useParams<{ supplierId: string }>();
  const { selectedProductGroupId, selectedMgItemId } = usePurchaseAgreementsSearchParams();

  const { data: participantDtos = [] } = useFetchAllMgParticipants({ suspense: true });
  const suppliers = useMemo(
    () => participantDtos.map(MgParticipant.fromMinimalDto),
    [participantDtos]
  );

  const selectedSupplier = useMemo(() => {
    return suppliers.find((supplier) => supplier.id === Number(supplierId));
  }, [supplierId, suppliers]);

  const breadcrumbItems: BreadcrumbsProps['items'] = useMemo(() => {
    return [
      { label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() },
      {
        label: t('app.routes.purchaseAgreements'),
        href: mgPrisRoutes.purchaseAgreements.getFullLinkPath(),
      },
      { label: selectedSupplier?.name || `${supplierId}` },
    ];
  }, [supplierId, selectedSupplier?.name, t]);

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        paddingY="dense"
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}
      >
        <BreadcrumbsToolbar
          items={breadcrumbItems}
          endContent={
            selectedSupplier && (
              <Stack flexDirection="row" gap={1}>
                <SelectedPurchaseAgreements supplierId={selectedSupplier.id} />
                <SupplierSelect
                  size="small"
                  suppliers={suppliers}
                  getSupplierId={(supplier) => `${supplier.id}`}
                  selectedSupplier={selectedSupplier}
                  selectSupplier={() =>
                    navigate(
                      mgPrisRoutes.purchaseAgreements.supplier.getFullLinkPath(
                        `${selectedSupplier.id}`
                      )
                    )
                  }
                  getSupplierName={(supplier) => supplier.name}
                />
              </Stack>
            )
          }
        />
        {selectedSupplier ? (
          <>
            <Stack direction="row" mt={2} flexGrow={1} overflow="hidden">
              <ProductGroupsTreeViewContainer selectedSupplier={selectedSupplier} />
              {selectedMgItemId ? (
                <SuspenseWithSentryErrorBoundary
                  errorFallback={(props) => <ErrorFallbackPage {...props} />}
                  suspenseFallback={<BodyLoader />}
                >
                  <ItemAgreements
                    supplierId={selectedSupplier.id}
                    mgItemNr={Number(selectedMgItemId)}
                  />
                </SuspenseWithSentryErrorBoundary>
              ) : (
                <SelectedProductGroupOrSupplier
                  supplier={selectedSupplier}
                  productGroupId={selectedProductGroupId}
                />
              )}
            </Stack>
            <SummaryDrawerContainer supplierId={selectedSupplier.id} />
          </>
        ) : (
          <NotFoundError
            title={t('purchaseAgreements.supplier.notFound.title')}
            subtitle={t('purchaseAgreements.supplier.notFound.subtitle', { supplierId })}
            buttonText={t('purchaseAgreements.supplier.notFound.goBack')}
          />
        )}
      </Paper>
    </PageLayout>
  );
}
