import { Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useContexts } from '@retail/app/stores/selected-context';
import { useScrollWithShadow } from '@retail/hooks';
import { SelectedRetailerCompetitorsList } from '@retail/retailer/components';
import { Retailer } from '@retail/retailer/types';
import { Paper } from '@shared/components';

interface Props {
  retailers: Retailer[];
  removeStore: (competitorId: number) => void;
  selectedStores: number;
}

export function ChosenByggematerialCompetitors({ retailers, removeStore, selectedStores }: Props) {
  const t = useAppTFunction();
  const { selectedContext } = useContexts();
  const myCoordinates = selectedContext.coordinates;
  const { retailerLogoBaseUrl } = useMgPrisConfig();

  const { onScrollHandler, boxShadow, overflowRef } = useScrollWithShadow([removeStore]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        border: ({ palette }) => `1px solid ${palette.divider}`,
      }}
    >
      <Typography variant="h6" component="h2" pb={1}>
        {t('products.marketInsight.competitors.chosenCompetitors')}
      </Typography>
      <Stack overflow="auto" ref={overflowRef} onScroll={onScrollHandler} sx={{ boxShadow }}>
        {selectedStores ? (
          retailers.map((retailer) => {
            return (
              <SelectedRetailerCompetitorsList
                key={retailer.id}
                retailer={retailer}
                myCoordinates={myCoordinates}
                removeStoreAsCompetitor={removeStore}
                baseUrl={retailerLogoBaseUrl}
              />
            );
          })
        ) : (
          <Typography variant="body1">
            {t('products.marketInsight.competitors.noCompetitors')}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
}

export default ChosenByggematerialCompetitors;
