import { Cancel, Check } from '@mui/icons-material';
import Box from '@mui/material/Box';
import React from 'react';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  followMasterProduct: boolean;
}

const FollowMasterProductCell: React.FC<Props> = ({ followMasterProduct }) => {
  const t = useAppTFunction();

  return (
    <Box display="flex" alignItems="center">
      {followMasterProduct ? (
        <>
          <Box pr={1}>{t('common.yes')}</Box>
          <Check color="success" />
        </>
      ) : (
        <>
          <Box pr={1}>{t('common.no')}</Box>
          <Cancel color="error" />
        </>
      )}
    </Box>
  );
};

export default FollowMasterProductCell;
