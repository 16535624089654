import { useMutation } from '@tanstack/react-query';
import { fetcher, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { User } from '@retail/auth/types';

export const superUsersRoute = '/v1/admin/superusers';

export function useFetchSuperUsers() {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<User[]>({
    method: 'GET',
    queryKey: ['super-users'],
    token,
    url: `${pps.url}${superUsersRoute}`,
  });
}

export const useSetUserAsSuperUser = () => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutation(async (userId: string) => {
    return fetcher<User>({
      url: `${pps.url}${superUsersRoute}/${userId}`,
      method: 'POST',
      token,
    });
  });
};

export const useRemoveUserAsSuperUser = () => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutation(async (userId: string) => {
    return fetcher<User>({
      url: `${pps.url}${superUsersRoute}/${userId}`,
      method: 'DELETE',
      token,
    });
  });
};
