import { MgSupplierItemDetailed } from './MgSupplierItem';
import { MgItemDetailedDTO } from '../../dto';
import { MgPackage } from './MgPackage';
import { getUniqueStoreIds } from './mgItemUtils';
import { CompetitorWithPrice } from './CompetitorWithPrice';
import { MgItemBase } from './MgItemBase';

export class MgItemDetailed extends MgItemBase<MgSupplierItemDetailed> {
  constructor(
    id: number,
    supplierItems: MgSupplierItemDetailed[],
    mgPackages: MgPackage[],
    competitors: CompetitorWithPrice[]
  ) {
    super({ id, supplierItems, mgPackages, competitors });
  }

  static fromDto = (mgItemDto: MgItemDetailedDTO): MgItemDetailed => {
    const supplierItems: MgSupplierItemDetailed[] = mgItemDto.mgSupplierItems.map((supplierItem) =>
      MgSupplierItemDetailed.fromDto(supplierItem, mgItemDto.mgPackages)
    );
    const mgPackages = mgItemDto.mgPackages.map(MgPackage.fromDto);
    const uniqueStoreIds = getUniqueStoreIds(mgItemDto);

    const competitors: CompetitorWithPrice[] = uniqueStoreIds.map((storeId) => {
      return CompetitorWithPrice.getCompetitorWithPrice(mgItemDto, storeId);
    });

    return new MgItemDetailed(mgItemDto.mgItemNumber, supplierItems, mgPackages, competitors);
  };
}
