import { Typography, useTheme, Stack } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { Chip, Dialog, TagHeading } from '@shared/components';
import { SupplierSelect } from '../Supplier';
import { MgSupplierItemPackagesTable } from './MgSupplierItemPackagesTable';
import { MgItemDetailed, MgSupplierItemDetailed } from '@retail/products/types';
import { useState } from 'react';

interface Props {
  mgItem: MgItemDetailed;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export function SupplierItemsModal({ open, setOpen, mgItem }: Props) {
  const { palette } = useTheme();
  const t = useProductsTFunction();
  const [selectedSupplierItem, setSelectedSupplierItem] = useState<MgSupplierItemDetailed>(
    mgItem.getPrimarySupplierItem()
  );
  return (
    <Dialog
      maxWidth="3xl"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack flexDirection="row" pb={2}>
        <TagHeading color="primary">
          <Typography variant="h4">
            {t('products.mgItem.number.label')}: {mgItem.id}
          </Typography>
        </TagHeading>
      </Stack>
      <Stack alignItems="center" px={3} justifyContent="space-between" flexDirection="row" pb={2}>
        <Stack flexDirection="row" gap={1}>
          <Typography variant="h6">{t('products.supplier.name')}</Typography>
          <Chip
            label={selectedSupplierItem?.mgParticipant.name}
            backgroundColor={palette.info[400]}
          />
        </Stack>
        <SupplierSelect
          size="small"
          suppliers={mgItem.supplierItems}
          selectedSupplier={selectedSupplierItem}
          selectSupplier={setSelectedSupplierItem}
          getSupplierName={(supplier) => supplier.mgParticipant.name}
          getSupplierId={(supplier) => supplier.mgParticipant.id + ''}
        />
      </Stack>
      {selectedSupplierItem && (
        <MgSupplierItemPackagesTable
          packages={selectedSupplierItem.packages}
          finfoPrice={selectedSupplierItem.getCurrentFinfoPrice()}
        />
      )}
    </Dialog>
  );
}
