import { FormControlLabel, Stack, Typography, styled } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Checkbox } from '@retail/components';
import { EmptyCircularFallbackImage, Image } from '@retail/products/components';

interface Props {
  name: string;
  id: number;
  distance: number | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  logoUrl: string;
}

export function SelectStoreCheckbox({ name, id, distance, checked, onChange, logoUrl }: Props) {
  const t = useAppTFunction();

  return (
    <StyledFormControlLabel
      value={id}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          label={
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Image
                width="30px"
                src={logoUrl}
                fallback={() => <EmptyCircularFallbackImage size="30px" />}
              />
              <Typography>{name}</Typography>
            </Stack>
          }
        />
      }
      label={
        distance && (
          <Typography color="text.secondary" variant="body2" fontWeight={600}>
            {t('products.marketInsight.competitors.distanceFromMe', {
              distance: distance.toFixed(1),
            })}
          </Typography>
        )
      }
    />
  );
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 4,
  justifyContent: 'space-between',
  '& .MuiFormControlLabel-root': {
    '& .MuiTypography-root': { ...theme.typography.body1, fontWeight: 500 },
  },
}));
