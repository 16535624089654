import { Box } from '@mui/material';

interface Props {
  size: string;
}

export function EmptyCircularFallbackImage({ size }: Props) {
  return (
    <Box
      height={size}
      width={size}
      border={({ palette }) => `2px solid ${palette.grey[400]}`}
      borderRadius="50%"
    />
  );
}
