import { MgSupplierItemsPageDTO } from '@retail/purchase-agreements/types';

export const mgSupplierItemsTestData: MgSupplierItemsPageDTO = {
  page: 0,
  pageSize: 0,
  totalCount: 4,
  totalPages: 0,
  result: [],
};

export const AffectedItemsWithItemsTestData: MgSupplierItemsPageDTO = {
  page: 0,
  pageSize: 25,
  totalCount: 4,
  totalPages: 1,
  result: [
    {
      primaryText: 'SÄTTLAGER STENFLIS SÄCK',
      secondaryText: '2-6MM',
      finfoNumber: 6851142,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
    {
      primaryText: 'STENKROSS GRAFIT/VIT ',
      secondaryText: '16-32MM',
      finfoNumber: 5143991,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
    {
      primaryText: 'PLANK SÅGAD G4-2 FURU 50X100',
      finfoNumber: 5106288,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
    {
      primaryText: 'GLASBLOCK 1908/W KLAR',
      finfoNumber: 3005811,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
  ],
};

export const AffectedItemsWithItemsAndProductGroupTestData: MgSupplierItemsPageDTO = {
  page: 0,
  pageSize: 25,
  totalCount: 2,
  totalPages: 1,
  result: [
    {
      primaryText: 'SÄTTLAGER STENFLIS SÄCK 2-6MM',
      finfoNumber: 6851142,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
    {
      primaryText: 'STENKROSS GRAFIT/VIT 16-32MM',
      finfoNumber: 5143991,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
  ],
};
