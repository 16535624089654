import {
  ByggmaterialpriserPriceDTO,
  ByggmaterialpriserRetailProductMinimalDTO,
  MgItemDetailedDTO,
  MgItemMinimalDTO,
  VilmaUnit,
} from '../..';
import { CompetitorWithPrice, StorePrice } from './CompetitorWithPrice';
import { MgItemMinimal } from './MgItemMinimal';

export type BmpPriceWithUrl = ByggmaterialpriserPriceDTO & {
  urlProduct?: string;
};

export interface MgItemPrice {
  mgItemNumber: number;
  mostRecentPrice: BmpPriceWithUrl;
}

export const getMostRecentPriceForProducts = (
  bmpProducts: ByggmaterialpriserRetailProductMinimalDTO[],
  storeId: number
): BmpPriceWithUrl | undefined => {
  return bmpProducts
    .flatMap(({ stores, urlProduct }) => stores.map((store) => ({ ...store, urlProduct })))
    .filter(({ id }) => id === storeId)
    .flatMap(({ prices, urlProduct }) => prices.map((price) => ({ ...price, urlProduct })))
    .sort((a, b) => Date.parse(b.lastObservedAt) - Date.parse(a.lastObservedAt))[0];
};

export const getPriceUnit = (price: ByggmaterialpriserPriceDTO): VilmaUnit => {
  return (
    price.convertedMgPackage?.vilmaUnit || price.estimatedMgPackage?.vilmaUnit || price.vilmaUnit
  );
};

const getPriceValue = (price: ByggmaterialpriserPriceDTO): number => {
  return price.convertedPrice || price.scrapedPrice;
};

export const getUniqueStoreIds = (mgItemDto: MgItemMinimalDTO | MgItemDetailedDTO): number[] => {
  const mgItemStores =
    mgItemDto.bmpRetailProducts?.flatMap(({ stores }) => stores).map((store) => store.id) || [];
  const equivalentMgItemStores =
    mgItemDto.equivalents?.equivalentMgItems
      .flatMap(({ bmpRetailProducts }) => bmpRetailProducts || [])
      .flatMap(({ stores }) => stores)
      .map((store) => store.id) || [];
  const equivalentRetailProductStores =
    mgItemDto.equivalents?.equivalentRetailProducts?.flatMap(({ stores }) =>
      stores.flatMap((store) => store.id)
    ) || [];
  return Array.from(
    new Set([...mgItemStores, ...equivalentMgItemStores, ...equivalentRetailProductStores])
  );
};

export const getCompetitorWithPrice = (
  mgItemPrice: MgItemPrice,
  mainMgItem: MgItemMinimalDTO,
  storeId: number
): CompetitorWithPrice => {
  const base: Pick<StorePrice, 'price' | 'unit' | 'url' | 'onDiscount' | 'priceDate'> = {
    price: getPriceValue(mgItemPrice.mostRecentPrice),
    unit: getPriceUnit(mgItemPrice.mostRecentPrice),
    url: mgItemPrice.mostRecentPrice.urlProduct,
    onDiscount: mgItemPrice.mostRecentPrice.onDiscount,
    priceDate: mgItemPrice.mostRecentPrice.lastObservedAt,
  };

  if (mgItemPrice.mgItemNumber === mainMgItem.mgItemNumber) {
    return {
      storeId,
      storePrice: {
        ...base,
        source: 'MG_ITEM',
      },
    };
  }
  const eqMgItem = MgItemMinimal.fromDto(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mainMgItem.equivalents!.equivalentMgItems.find(
      (eq) => eq.mgItemNumber === mgItemPrice.mgItemNumber
    )!
  );

  return {
    storeId,
    storePrice: {
      ...base,
      source: 'EQUIVALENT',
      equivalentMgItemSource: {
        primaryText: eqMgItem.getPrimarySupplierItem()?.primaryText,
        mgItemNumber: eqMgItem.id,
      },
    },
  };
};

export const getCompetitorWithMasterProductPrice = (
  masterProductPrice: BmpPriceWithUrl,
  storeId: number
): CompetitorWithPrice => ({
  storeId,
  storePrice: {
    price: getPriceValue(masterProductPrice),
    source: 'MASTER_PRODUCT',
    unit: getPriceUnit(masterProductPrice),
    onDiscount: masterProductPrice.onDiscount,
    url: masterProductPrice.urlProduct,
    priceDate: masterProductPrice.lastObservedAt,
  },
});
