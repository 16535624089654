import { FC, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { CopyToClipboard } from '../CopyToClipboard';
import { Chip, ContainedButton, Paper, TextButton } from '@shared/components';
import { ChevronLeft } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import { useAppTFunction } from '@retail/app/i18n';
import { opsFace } from '@assets/images';

export interface SentryErrorProps {
  componentStack: string;
  error: Error;
  eventId: string;
  resetError: () => void;
}

export const ErrorFallback: FC<SentryErrorProps> = ({
  componentStack,
  error,
  eventId,
  resetError,
}) => {
  const t = useAppTFunction();
  const { palette } = useTheme();
  const [showComponentStack, setShowComponentStack] = useState(false);

  return (
    <Stack alignSelf="center" alignItems="center" spacing={3}>
      <img src={opsFace} width="100px" height="100px" alt="" />
      <Typography variant="h5">{t('app.error.oops')}</Typography>
      <Typography variant="body1">{t('app.error.generic')}</Typography>
      <Typography
        variant="body1"
        maxWidth="380px"
        textAlign="center"
        color={({ palette }) => palette.text.secondary}
      >
        {t('app.error.description')}
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="body2">{`${t('app.error.errorCode')}:`}</Typography>
        <CopyToClipboard value={eventId}>
          <Chip
            backgroundColor={palette.info[400]}
            label={<Typography variant="body2">{eventId}</Typography>}
          />
        </CopyToClipboard>
      </Stack>
      <ContainedButton onClick={resetError}>{t('app.error.reset')}</ContainedButton>
      <TextButton
        size="small"
        onClick={() => setShowComponentStack((prevState) => !prevState)}
        endIcon={
          <ChevronLeft
            sx={{
              transform: showComponentStack ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        }
      >
        {t('app.error.showMore')}
      </TextButton>
      <Collapse in={showComponentStack}>
        <Paper
          sx={{ py: 3, px: 4, backgroundColor: ({ palette }) => palette.background.secondary }}
        >
          <Typography>{error.message}</Typography>
          {componentStack}
        </Paper>
      </Collapse>
    </Stack>
  );
};
