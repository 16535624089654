import dayjs from 'dayjs';
import { MgItemDetailedDTO, MgItemMinimalDTO } from '../../dto';
import { VilmaUnit } from './VilmaUnit';
import {
  BmpPriceWithUrl,
  MgItemPrice,
  getCompetitorWithMasterProductPrice,
  getCompetitorWithPrice,
  getMostRecentPriceForProducts,
} from './mgItemUtils';

export interface StorePrice {
  price: number;
  unit: VilmaUnit;
  source: 'MG_ITEM' | 'EQUIVALENT' | 'MASTER_PRODUCT';
  url: string | undefined;
  priceDate: string;
  onDiscount: boolean;
  equivalentMgItemSource?: {
    primaryText?: string;
    mgItemNumber: number;
  };
}

interface ConstructorProps {
  storeId: number;
  storePrice?: StorePrice;
}

export class CompetitorWithPrice {
  storeId: number;
  storePrice?: StorePrice;

  constructor({ storeId, storePrice }: ConstructorProps) {
    this.storeId = storeId;
    this.storePrice = storePrice;
  }

  static getCompetitorWithPrice(
    mgItemDto: MgItemMinimalDTO | MgItemDetailedDTO,
    storeId: number
  ): CompetitorWithPrice {
    const mostRecentMgItemPrice = [mgItemDto, ...(mgItemDto.equivalents?.equivalentMgItems || [])]
      .map((equivalentMgItem) => ({
        mgItemNumber: equivalentMgItem.mgItemNumber,
        mostRecentPrice: getMostRecentPriceForProducts(
          equivalentMgItem.bmpRetailProducts || [],
          storeId
        ),
      }))
      .filter(({ mostRecentPrice }) => mostRecentPrice)
      .sort(
        (a, b) =>
          Date.parse((b.mostRecentPrice as BmpPriceWithUrl).lastObservedAt) -
          Date.parse((a.mostRecentPrice as BmpPriceWithUrl).lastObservedAt)
      )[0] as MgItemPrice | undefined;

    const mostRecentMasterProductPrice = getMostRecentPriceForProducts(
      mgItemDto.equivalents?.equivalentRetailProducts || [],
      storeId
    );

    if (mostRecentMasterProductPrice) {
      if (mostRecentMgItemPrice) {
        const mgItemPriceIsAfterMasterProductPrice = dayjs(
          mostRecentMgItemPrice.mostRecentPrice.lastObservedAt
        ).isAfter(mostRecentMasterProductPrice.lastObservedAt);

        if (mgItemPriceIsAfterMasterProductPrice) {
          return getCompetitorWithPrice(mostRecentMgItemPrice, mgItemDto, storeId);
        }
      }
      return getCompetitorWithMasterProductPrice(mostRecentMasterProductPrice, storeId);
    }

    return mostRecentMgItemPrice
      ? getCompetitorWithPrice(mostRecentMgItemPrice, mgItemDto, storeId)
      : { storeId };
  }
}
