import {
  Autocomplete,
  AutocompleteProps,
  Box,
  FormControl,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import React, { ForwardedRef, ReactElement, forwardRef } from 'react';
import { InfoTooltip } from '../Tooltip';
import { FormControlWrapper, FormLabelWrapper, HelperText } from './Components';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Pick<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'disabled'
    | 'fullWidth'
    | 'loading'
    | 'noOptionsText'
    | 'getOptionLabel'
    | 'options'
    | 'filterOptions'
    | 'defaultValue'
    | 'multiple'
    | 'freeSolo'
    | 'limitTags'
    | 'onChange'
    | 'value'
    | 'open'
    | 'renderOption'
    | 'autoSelect'
    | 'getOptionDisabled'
    | 'onFocus'
    | 'isOptionEqualToValue'
    | 'onInputChange'
    | 'clearOnBlur'
    | 'PopperComponent'
    | 'ListboxProps'
  > {
  placeholder?: OutlinedInputProps['placeholder'];
  name: OutlinedInputProps['name'];
  error?: string;
  helperText?: string;
  size?: OutlinedInputProps['size'];
  required?: boolean;
  margin?: 'normal' | 'dense' | 'none';
  label?: string;
  tooltipText?: string;
  startIcon?: React.ReactNode;
  showPopupIcon?: boolean;
  labelDirection?: 'row' | 'column';
  labelWidth?: number;
}
/**
 * @description This component can be uncontrolled and use useController-hook.
 * @param autocompleteProps
 * Works with useController-hook like this:
 *  onChange: (_, newValue, reason) => fieldControl.onChange(newValue),
 *  value: fieldControl.value
 */
function AutocompleteInputInner<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  {
    name,
    label,
    helperText,
    error,
    margin,
    required,
    disabled,
    tooltipText,
    placeholder,
    startIcon,
    showPopupIcon,
    size = 'medium',
    fullWidth,
    labelDirection = 'column',
    labelWidth = 100,
    ...rest
  }: Props<T, Multiple, DisableClearable, FreeSolo>,
  ref?: ForwardedRef<ReactElement>
) {
  const autocompleteId = `${name}-autocomplete-id`;
  const inputId = `${name}-id`;
  const formLabelId = `${name}-label`;
  const helperTextId = `${name}-helperText`;

  return (
    <FormControl
      margin={margin}
      error={!!error}
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      component={FormControlWrapper}
      labelDirection={labelDirection}
    >
      {label && (
        <FormLabelWrapper direction={labelDirection} width={labelWidth} size={size}>
          <FormLabel htmlFor={inputId} id={formLabelId}>
            {label}
          </FormLabel>
          {tooltipText && <InfoTooltip tooltipText={tooltipText} disabled={disabled} />}
        </FormLabelWrapper>
      )}

      <Box {...(fullWidth && { flex: '1 0 0' })}>
        <Autocomplete
          {...rest}
          ref={ref}
          popupIcon={showPopupIcon ? undefined : null}
          id={autocompleteId}
          renderInput={(params) => (
            <OutlinedInput
              id={inputId}
              disabled={params.disabled}
              fullWidth={params.fullWidth}
              size={params.size}
              ref={params.InputProps.ref}
              startAdornment={params.InputProps.startAdornment}
              endAdornment={params.InputProps.endAdornment}
              className={params.InputProps.className}
              inputProps={params.inputProps}
              name={name}
              placeholder={placeholder}
            />
          )}
          selectOnFocus
          handleHomeEndKeys
          disabled={disabled}
          size={size}
        />

        {helperText && (
          <HelperText id={helperTextId} status={'info'}>
            {helperText}
          </HelperText>
        )}

        {error && (
          <HelperText id={helperTextId} status={'error'}>
            {error}
          </HelperText>
        )}
      </Box>
    </FormControl>
  );
}

const AutocompleteInput = forwardRef(AutocompleteInputInner) as <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  props: Props<T, Multiple, DisableClearable, FreeSolo> & {
    ref?: ForwardedRef<ReactElement>;
  }
) => ReturnType<typeof AutocompleteInputInner>;

export default AutocompleteInput;
