import { useMemo, useState } from 'react';
import { MgParticipant } from '@retail/products/types';
import { ItemForProductGroup, OverGroup } from '@retail/purchase-agreements/types';
import { ProductGroupsTreeView } from '@retail/components';
import {
  useFetchFreightMarkupProductGroupsForMgParticipant,
  useFetchMgItemsForProductGroups,
} from '@retail/freight-markup/data-access';
import { useFreightMarkupSearchParams } from '@retail/freight-markup/hooks';

type Props = {
  selectedSupplier: MgParticipant;
};

export const ProductGroupsTreeViewContainer = ({ selectedSupplier }: Props) => {
  const { data: productGroupsDto = [] } = useFetchFreightMarkupProductGroupsForMgParticipant(
    selectedSupplier.id,
    { suspense: true }
  );
  const productGroups = productGroupsDto.map(OverGroup.fromOverGroupDto);

  const [selectedProductGroups, setSelectedProductGroups] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { data } = useFetchMgItemsForProductGroups(
    {
      mgSupplierId: selectedSupplier.id,
      productGroupIds: searchTerm ? undefined : selectedProductGroups,
      numberSearch: Number(searchTerm) || undefined,
      textSearch: !Number(searchTerm) ? searchTerm : undefined,
    },
    { suspense: true }
  );

  const mgItems = useMemo(() => {
    return data!.map(ItemForProductGroup.fromItemDto);
  }, [data]);

  const { selectFreightMarkupLevel } = useFreightMarkupSearchParams();

  return (
    <ProductGroupsTreeView
      supplier={selectedSupplier}
      productGroups={productGroups}
      mgItems={mgItems}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      setSelectedProductGroups={setSelectedProductGroups}
      selectAgreementLevel={selectFreightMarkupLevel}
    />
  );
};
