import { useMemo } from 'react';
import { useAuthContext, useFetchMyOrgUnits } from '@retail/auth/data-access';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { TYPE_BRAND } from "@retail/auth/types";

interface Props {
  suspense: boolean;
}

export const useUserOrgUnits = ({ suspense }: Props = { suspense: false }) => {
  const { country } = useMgPrisConfig();
  const { token } = useAuthContext();
  const loadingStatus = useFetchMyOrgUnits(
    {
      brands: [],
      countryCodes: [country],
      token,
    },
    { enabled: !!token, suspense }
  );

  const sortedOrgUnitsExcludingBrandsForNonAdmins = useMemo(
    () =>
      loadingStatus.data
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(({ isAdmin, type }) => isAdmin || type !== TYPE_BRAND),
    [loadingStatus.data]
  );

  return { ...loadingStatus, data: sortedOrgUnitsExcludingBrandsForNonAdmins };
};
