import React, { memo, PropsWithChildren } from 'react';
import { Container } from '@mui/material';
import BodyLoader from './BodyLoader';

interface Props {
  readonly minHeight?: number | string;
  readonly show: boolean;
}

function ContentLoader({ children, minHeight = 0, show }: PropsWithChildren<Props>) {
  return (
    <Container sx={{ position: 'relative', minHeight }}>
      {children}
      {show && <BodyLoader />}
    </Container>
  );
}

export default memo(ContentLoader);
