import { ErrorFallbackProps, ErrorBoundary } from '../components/ErrorBoundary';
import { Suspense, useMemo } from 'react';
import { AppTFunction, useAppTFunction } from '@retail/app/i18n';

interface WithSuspenseProps<T> {
  Component: React.ComponentType<T>;
  errorFallback: ({ error, t }: { error: ErrorFallbackProps; t: AppTFunction }) => React.ReactNode;
  fallback: ({ t }: { t: AppTFunction }) => React.ReactElement;
}

/**
 * @deprecated Use SuspenseWithErrorBoundary instead
 */
const withSuspense =
  <T extends object>({ Component, errorFallback, fallback }: WithSuspenseProps<T>): React.FC<T> =>
  (props) => {
    const t = useAppTFunction();
    const fallbackNode = useMemo(() => fallback({ t }), [t]);

    return (
      <ErrorBoundary fallback={(error) => errorFallback({ t, error })}>
        <Suspense fallback={fallbackNode}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };

export default withSuspense;
