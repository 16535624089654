import {
  AgreementsForMgItemDTO,
  PriceUnit,
  ItemAgreementDTO,
  PriceType,
} from '@retail/purchase-agreements/types';
import { purchaseAgreements } from './purchaseAgreementTestData';

export const itemAgreementsTestDataV1: ItemAgreementDTO[] = [
  {
    ...purchaseAgreements[0],
    status: 'active',
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[1],
    status: 'inactive',
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[2],
    status: 'inactive',
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[3],
    status: 'inactive',
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
];
export const itemAgreementsTestDataV2: ItemAgreementDTO[] = [
  {
    ...purchaseAgreements[0],
    status: 'inactive',
    priceType: PriceType.GTIN,
    price: 108.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[1],
    status: 'active',
    priceType: PriceType.NET,
    price: 109.17,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[2],
    status: 'inactive',
    priceType: PriceType.NET,
    price: 109.87,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[3],
    status: 'inactive',
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
];

export const itemsForProductGroup = [
  { productGroupId: '01112', mgItemNumber: 200593200, primaryText: 'DEKORSTEN 11-16 MARMOR VIT' },
  { productGroupId: '01112', mgItemNumber: 200520736, primaryText: 'DECORSTEN TUMLAD TOROS' },
  { productGroupId: '01112', mgItemNumber: 200046853, primaryText: 'DEKORSTEN VIT MATT 20-40MM' },
  {
    productGroupId: '01112',
    mgItemNumber: 200623562,
    primaryText: 'SÄTTLAGER STENFLIS SÄCK 2-6MM',
  },
  { productGroupId: '01112', mgItemNumber: 200045274, primaryText: 'STENKROSS GRAFIT/VIT 16-32MM' },
  { productGroupId: '01112', mgItemNumber: 200531264, primaryText: 'DECORSTEN TUMLAD BASALT' },

  { productGroupId: '01199', mgItemNumber: 200711675, primaryText: 'PLANK SÅGAD G4-2 FURU 50X100' },
  { productGroupId: '01109', mgItemNumber: 200302595, primaryText: 'GLASBLOCK MENDINI VIT' },
  { productGroupId: '01109', mgItemNumber: 200211643, primaryText: 'GLASBLOCK 1908/W KLAR' },
];

export const agreementsForMgItem: AgreementsForMgItemDTO[] = [
  {
    mgItemNumber: 200593200,
    primaryText: 'DEKORSTEN 11-16 MARMOR VIT',
    agreements: itemAgreementsTestDataV1,
  },
  {
    mgItemNumber: 200520736,
    primaryText: 'DECORSTEN TUMLAD TOROS',
    agreements: itemAgreementsTestDataV1,
  },
  {
    mgItemNumber: 200046853,
    primaryText: 'DEKORSTEN VIT MATT 20-40MM',
    agreements: itemAgreementsTestDataV2,
  },
  {
    mgItemNumber: 200623562,
    primaryText: 'SÄTTLAGER STENFLIS SÄCK 2-6MM',
    agreements: itemAgreementsTestDataV2,
  },
  {
    mgItemNumber: 200045274,
    primaryText: 'STENKROSS GRAFIT/VIT 16-32MM',
    agreements: itemAgreementsTestDataV2,
  },
  {
    mgItemNumber: 200531264,
    primaryText: 'DECORSTEN TUMLAD BASALT',
    agreements: itemAgreementsTestDataV2,
  },
  {
    mgItemNumber: 200711675,
    primaryText: 'PLANK SÅGAD G4-2 FURU 50X100',
    agreements: itemAgreementsTestDataV2,
  },
  {
    mgItemNumber: 200302595,
    primaryText: 'GLASBLOCK MENDINI VIT',
    agreements: itemAgreementsTestDataV1,
  },
  {
    mgItemNumber: 200211643,
    primaryText: 'GLASBLOCK 1908/W KLAR',
    agreements: itemAgreementsTestDataV2,
  },
];
