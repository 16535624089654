import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { activatedPurchaseAgreementsAtom } from './agreementsToActivateAtom';
import {
  ActiveItemAgreementSummary,
  ActiveSupplierAgreementSummary,
  ActiveProductGroupAgreementSummary,
} from '@retail/purchase-agreements/types';

export const usePurchaseAgreementsToActivate = () => {
  const [activatedPurchaseAgreements, setActivatedPurchaseAgreement] = useRecoilState(
    activatedPurchaseAgreementsAtom
  );

  const addMgSupplierAgreement = useCallback(
    (activeSupplierAgreementSummery: ActiveSupplierAgreementSummary) => {
      setActivatedPurchaseAgreement((prevActive) =>
        prevActive.copy({
          supplierAgreementsToActivate: activeSupplierAgreementSummery,
        })
      );
    },
    [setActivatedPurchaseAgreement]
  );

  const addProductGroupAgreement = useCallback(
    (activeProductGroupAgreementSummary: ActiveProductGroupAgreementSummary) => {
      setActivatedPurchaseAgreement((prevActive) => {
        if (
          prevActive.productGroupsAgreementsToActivate.find(
            ({ productGroupNr }) =>
              productGroupNr === activeProductGroupAgreementSummary.productGroupNr
          )
        ) {
          return prevActive.copy({
            productGroupsAgreementsToActivate: [
              ...prevActive.productGroupsAgreementsToActivate.map((agreement) => {
                if (
                  agreement.productGroupNr === activeProductGroupAgreementSummary.productGroupNr
                ) {
                  return activeProductGroupAgreementSummary;
                }
                return agreement;
              }),
            ],
          });
        }
        return prevActive.copy({
          productGroupsAgreementsToActivate: [
            ...prevActive.productGroupsAgreementsToActivate,
            activeProductGroupAgreementSummary,
          ],
        });
      });
    },
    [setActivatedPurchaseAgreement]
  );

  const addItemAgreement = useCallback(
    (activeItemAgreementSummary: ActiveItemAgreementSummary) => {
      setActivatedPurchaseAgreement((prevActive) => {
        if (
          prevActive.itemAgreementsToActivate.find(
            ({ mgItemNr }) => mgItemNr === activeItemAgreementSummary.mgItemNr
          )
        ) {
          return prevActive.copy({
            itemAgreementsToActivate: [
              ...prevActive.itemAgreementsToActivate.map((agreement) => {
                if (agreement.mgItemNr === activeItemAgreementSummary.mgItemNr) {
                  return activeItemAgreementSummary;
                }
                return agreement;
              }),
            ],
          });
        }
        return prevActive.copy({
          itemAgreementsToActivate: [
            ...prevActive.itemAgreementsToActivate,
            activeItemAgreementSummary,
          ],
        });
      });
    },
    [setActivatedPurchaseAgreement]
  );

  const removeSupplierAgreement = useCallback(() => {
    setActivatedPurchaseAgreement((prevActive) =>
      prevActive.copy({ supplierAgreementsToActivate: null })
    );
  }, [setActivatedPurchaseAgreement]);

  const removeProductGroupAgreement = useCallback(
    (productGroupId: string) => {
      setActivatedPurchaseAgreement((prevActive) =>
        prevActive.copy({
          productGroupsAgreementsToActivate: prevActive.productGroupsAgreementsToActivate.filter(
            (agreement) => agreement.productGroupNr !== productGroupId
          ),
        })
      );
    },
    [setActivatedPurchaseAgreement]
  );

  const removeItemAgreement = useCallback(
    (itemAgreementId: number) => {
      setActivatedPurchaseAgreement((prevActive) =>
        prevActive.copy({
          itemAgreementsToActivate: prevActive.itemAgreementsToActivate.filter(
            (agreement) => agreement.mgItemNr !== itemAgreementId
          ),
        })
      );
    },
    [setActivatedPurchaseAgreement]
  );

  const clearState = useCallback(() => {
    setActivatedPurchaseAgreement((prevActive) =>
      prevActive.copy({
        supplierAgreementsToActivate: null,
        productGroupsAgreementsToActivate: [],
        itemAgreementsToActivate: [],
      })
    );
  }, [setActivatedPurchaseAgreement]);

  return {
    activatedPurchaseAgreements,
    addItemAgreement,
    addProductGroupAgreement,
    addMgSupplierAgreement,
    removeSupplierAgreement,
    removeProductGroupAgreement,
    removeItemAgreement,
    clearState,
  };
};
