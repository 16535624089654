import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, Dialog, OutlinedButton, Stepper } from '@shared/components';

import { ItemCodes } from '@retail/assortment/types';
import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  IdentifiableColumn,
  ImportAssortmentSpreadsheet,
  importStepAtom,
  useImportedAssortmentState,
  useSpreadsheetState,
} from '../ImportAssortment';
import { ValidateSpreadsheet } from '../ValidateSpreadsheet';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmitAddOrReplace: (mgItemNumbers: number[]) => void;
  getInvalidItemCodes?: (itemCodes: ItemCodes) => Promise<ItemCodes>;
  isLoading: boolean;
}

const columnsToRetrieve: Set<IdentifiableColumn> = new Set(['mgItem', 'finfo', 'gtin']);

export function AddOrReplaceMgItemsFromFileDialog({
  isOpen,
  onClose,
  onSubmitAddOrReplace,
  getInvalidItemCodes,
  isLoading,
}: Props) {
  const t = useAppTFunction();
  const [activeStep, setActiveStep] = useRecoilState(importStepAtom);
  const [invalidItemCodes, setInvalidItemCodes] = useState<undefined | ItemCodes>();
  const [, setSpreadsheet] = useSpreadsheetState();

  const importState = useImportedAssortmentState({ columnsToRetrieve });

  const onSubmitForm = () => {
    onSubmitAddOrReplace(importState.mgItemNumbers!);
    setActiveStep(0);
    setSpreadsheet(undefined);
  };

  const doValidation = useCallback(() => {
    importState.status === 'success' &&
      getInvalidItemCodes &&
      getInvalidItemCodes({
        finfoNumbers: importState.finfoNumbers || [],
        gtinCodes: importState.gtinNumbers || [],
        mgItemNumbers: importState.mgItemNumbers || [],
      }).then(setInvalidItemCodes);
  }, [importState, getInvalidItemCodes]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
        setActiveStep(0);
        setSpreadsheet(undefined);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" flexDirection="column" flex="1" alignItems="stretch" gap={3}>
          <Typography variant="h5">{t('assortment.admin.update.import.title')}</Typography>
          <Stepper
            activeStep={activeStep}
            steps={[
              t('assortment.admin.create.fromFile.import.title'),
              t('assortment.admin.create.fromFile.import.validate'),
            ]}
          />
        </Box>
      </DialogTitle>
      {activeStep === 0 && (
        <>
          <DialogContent>
            <ImportAssortmentSpreadsheet columnsToRetrieve={columnsToRetrieve} />
          </DialogContent>
          <DialogActions>
            <ContainedButton
              size="small"
              onClick={() => {
                setActiveStep(1);
                doValidation();
              }}
              disabled={importState.status !== 'success'}
            >
              {t('assortment.admin.create.next')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
      {activeStep === 1 && (
        <>
          <DialogContent>
            <ValidateSpreadsheet importState={importState} invalidItemCodes={invalidItemCodes} />
          </DialogContent>
          <DialogActions>
            <OutlinedButton size="small" onClick={() => setActiveStep(0)}>
              {t('assortment.admin.create.previous')}
            </OutlinedButton>
            <ContainedButton size="small" onClick={() => onSubmitForm()} loading={isLoading}>
              {t('assortment.admin.create.importCTA')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
