import { Breadcrumbs, BreadcrumbsProps, TagHeading } from '@shared/components';

type Props = BreadcrumbsProps;

export function TagBreadcrumbs({ items }: Props) {
  return (
    <TagHeading color="primary">
      <Breadcrumbs fontSize="sm" items={items} />
    </TagHeading>
  );
}
