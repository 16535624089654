import {
  Competitor,
  ContextCompetitorDTO,
  RetailerCompetitor,
  StoreCompetitor,
} from '@retail/retailer/types';

export function competitorDtoToCompetitors(
  competitorDTO: ContextCompetitorDTO,
  retailerLogoBaseUrl: string
): Competitor[] {
  const retailers = competitorDTO.retailers || [];
  const stores = competitorDTO.stores || [];

  const retailerCompetitors = retailers.map(
    ({ id, name, urlName }) => new RetailerCompetitor(id, name, urlName, retailerLogoBaseUrl)
  );
  const storeCompetitors = stores.map(
    ({ id, name, retailer, coordinates }) =>
      new StoreCompetitor(id, name, retailer, retailerLogoBaseUrl, coordinates)
  );

  return [...retailerCompetitors, ...storeCompetitors];
}
