import { List } from '@mui/material';
import { NavItem } from './NavItem';
import { NavItemWithSubRoutes } from './NavItemWithSubRoutes';
import { NavRoute, NavWithSubRoutes } from './types';

interface Props {
  navRoutes: (NavRoute | NavWithSubRoutes)[];
  expandedView: boolean;
  expandView: () => void;
  onClickCallback?: (navRoute: NavRoute | NavWithSubRoutes) => void;
}

export function Navigation({ navRoutes, expandedView, expandView, onClickCallback }: Props) {
  return (
    <List sx={{ padding: '24px' }} component="nav">
      {navRoutes.map((navRoute) => {
        if ('subRoutes' in navRoute) {
          const { title, Icon, to, isActive, subRoutes } = navRoute;

          return (
            <NavItemWithSubRoutes
              title={title}
              Icon={Icon}
              onClick={onClickCallback && (() => onClickCallback(navRoute))}
              to={to}
              isActive={isActive}
              subRoutes={subRoutes}
              expandView={expandView}
              expandedView={expandedView}
              key={title}
            />
          );
        }

        const { title, Icon, to, isActive } = navRoute;

        return (
          <NavItem
            title={title}
            Icon={Icon}
            onClick={onClickCallback && (() => onClickCallback(navRoute))}
            to={to}
            isActive={isActive}
            expandedView={expandedView}
            key={to}
          />
        );
      })}
    </List>
  );
}
