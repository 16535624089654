import { PurchaseAgreementDTO } from '../dto';

export class PurchaseAgreement {
  id: string;
  name: string;
  fromDate: Date;
  toDate: Date;

  constructor(id: string, name: string, fromDate: Date, toDate: Date) {
    this.id = id;
    this.name = name;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  static fromDto = (dto: PurchaseAgreementDTO): PurchaseAgreement =>
    new PurchaseAgreement(dto.id, dto.name, dto.fromDate, dto.toDate);
}
