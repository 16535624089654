import { Coordinates } from '@retail/retailer/types';

export const TYPE_BRAND = 'X-Brand-Id';
export const TYPE_DATAOWNER = 'X-Dataowner-Id';
export const TYPE_LOCATION = 'X-Location-Id';

export type ContextType = typeof TYPE_BRAND | typeof TYPE_DATAOWNER | typeof TYPE_LOCATION;

export const BRAND_BYGGTORGET = 'byggtorget';
export const BRAND_XLBYGG = 'xlbygg';
export const BRAND_BOLIST = 'bolist';
export const BRAND_XLBYGG_SVERIGE = 'xlbyggsverige';

export type PrisinnsiktBrand =
  | typeof BRAND_BYGGTORGET
  | typeof BRAND_XLBYGG
  | typeof BRAND_BOLIST
  | typeof BRAND_XLBYGG_SVERIGE;

export type PrisinnsiktCountry = 'NO' | 'SE';

export interface Context {
  name: string;
  value: string;
  theme: PrisinnsiktBrand;
  isAdmin: boolean;
  type: ContextType;
  id: number;
  countryCode: PrisinnsiktCountry;
  coordinates: Coordinates | undefined;
}
