import { ProductGroupAgreement } from '@retail/purchase-agreements/types';
import { Grid, Link, Stack } from '@mui/material';
import { ActiveTagPaper } from '@retail/components';
import dayjs from 'dayjs';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  activeAgreement: ProductGroupAgreement;
  name: string;
  totalItems: number;
  openModal: () => void;
}

export function ActiveProductGroupOrSupplierAgreement({
  activeAgreement,
  name,
  totalItems,
  openModal,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  return (
    <Stack gap={3}>
      <ActiveTagPaper>
        <Grid container gap={6}>
          <Stack flexDirection="row" gap={2}>
            <Stack fontSize={14} fontWeight={700} spacing={2}>
              <Grid item>{t('purchaseAgreements.agreement.columns.agreementText')}</Grid>
              <Grid item>{t('purchaseAgreements.affectedItems.affectedItems')}</Grid>
            </Stack>
            <Stack fontSize={14} spacing={2}>
              <Grid item>{activeAgreement.name}</Grid>
              <Grid item>
                <Link component="button" onClick={openModal} fontWeight={700}>
                  {totalItems}
                </Link>
              </Grid>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2}>
            <Stack fontSize={14} fontWeight={700} spacing={2}>
              <Grid item>{t('purchaseAgreements.agreement.columns.fromDate')}</Grid>
              <Grid item>{t('purchaseAgreements.agreement.columns.toDate')}</Grid>
            </Stack>
            <Stack fontSize={14} spacing={2}>
              <Grid item>{dayjs(activeAgreement.fromDate).format('ll')}</Grid>
              <Grid item>{dayjs(activeAgreement.toDate).format('ll')}</Grid>
            </Stack>
          </Stack>
        </Grid>
      </ActiveTagPaper>
    </Stack>
  );
}
