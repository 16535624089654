import { Box, FormControlLabel, lighten, Stack, Switch, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useCompetitorPrices } from '../../api';
import {
  CompetitorCellsMap,
  CompetitorPrice,
  CompetitorPriceDTO,
  NobbProductDTO,
  ProductCompetitorInfo,
} from '@retail/products/types';
import {
  CompetitorColDef,
  CompetitorPriceCell,
  getCompetitorColumn,
  muiGridWrapperStyles,
  ProductNameCell,
} from '@retail/products/components';
import { withSuspense } from '../../decorators';
import { useMemo, useState } from 'react';
import ContainerLoadingError from './components/ContainerLoadingError';
import LoadingFallback from './components/LoadingFallback';
import { createCompetitorPriceMap } from './productPageUtils';
import { useAppTFunction } from '@retail/app/i18n';
import { useIncludeVat } from '../../stores';
import { keepOrRemoveVAT, keepOrRemoveVATIfPresent } from '../../utils';
import { useFetchContextCompetitors } from '../../api';
import { toRetailPrice } from '@retail/products/utils';
import { Competitor } from '@retail/retailer/types';
import { useSelectedContext } from '../../stores/userContexts';
import { useSelectedPrisinnsiktCompetitors } from '../../hooks';

interface Props {
  mainProduct: ProductCompetitorInfo;
  variants: ProductCompetitorInfo[];
  competitors: Competitor[];
  autoHeight?: boolean;
}

interface ProductRowBase {
  id: number;
  productName: {
    productName: string;
    nobbNr: number;
  };
  isVariant: boolean;
}

const createProductRow = (
  product: ProductCompetitorInfo,
  competitors: Competitor[],
  isVariant: boolean,
  includeVat: boolean
): ProductRowBase => {
  const retailerCellsMap: CompetitorCellsMap<CompetitorPrice> = competitors.reduce(
    (tempMap, competitor) => {
      const { key, price } = toRetailPrice(competitor, product.retailerPrices);
      const vatAdjustedPrice: CompetitorPriceDTO | undefined = price && {
        ...price,
        primaryPrice: keepOrRemoveVAT(price.primaryPrice, includeVat),
        convertedPrice: keepOrRemoveVATIfPresent(price.convertedPrice, includeVat),
      };
      return { ...tempMap, [key]: { price: vatAdjustedPrice } };
    },
    {}
  );

  return {
    productName: {
      productName: product.productName,
      nobbNr: product.nobbNr,
    },
    ...retailerCellsMap,
    id: product.nobbNr,
    isVariant,
  };
};

const columnWidths = {
  productName: 160,
  nobbNr: 130,
  competitor: 170,
};
const rowHeight = 70;

export const ProductWithVariantsCompetitorPricesContent: React.FC<Props> = ({
  mainProduct,
  variants,
  competitors,
  autoHeight,
}) => {
  const t = useAppTFunction();
  const { includeVat } = useIncludeVat();
  const [showVariants, setShowVariants] = useState<boolean>(true);
  const shownVariants = useMemo(() => (showVariants ? variants : []), [variants, showVariants]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'productName',
        headerName: t('products.productList.productName'),
        minWidth: columnWidths.productName,
        renderCell: ({ value }) => (
          <ProductNameCell {...value} href={''} nobbNr={columnWidths.nobbNr} />
        ),
        sortable: false,
      },
      {
        field: 'id',
        headerName: t('products.productList.nobbNr'),
        minWidth: columnWidths.nobbNr,
        sortable: false,
      },
      ...competitors.map((competitor) => {
        const competitorColumn: CompetitorColDef<CompetitorPrice> = {
          ...getCompetitorColumn(competitor),
          renderCell: ({ row }) => <CompetitorPriceCell {...row[competitor.key()]} />,
          minWidth: columnWidths.competitor,
          sortable: false,
        };
        return competitorColumn;
      }),
    ];
  }, [t, competitors]);

  const rows: GridRowsProp = useMemo(() => {
    const mainProductRow = createProductRow(mainProduct, competitors, false, includeVat);
    const variantRows = shownVariants.map((variant) =>
      createProductRow(variant, competitors, true, includeVat)
    );

    return [mainProductRow, ...variantRows];
  }, [includeVat, mainProduct, competitors, shownVariants]);

  // Adjust the grid width with the number of retailers, but no more than 100%
  const tableWidth = useMemo(
    () =>
      `min(100%, ${
        20 +
        columnWidths.productName +
        columnWidths.nobbNr +
        columnWidths.competitor * competitors.length
      }px)`,
    [competitors]
  );

  // Adjust the grid height with the number of variants, but no more than 600px
  const tableHeight = useMemo(
    () => `min(600px, ${75 + (shownVariants.length + 1) * rowHeight}px)`,
    [shownVariants]
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('products.prices.competitors.title')}</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showVariants}
              onChange={() => setShowVariants((prevShow) => !prevShow)}
              color="primary"
            />
          }
          label={t('products.prices.competitors.variants.showSwitch')}
        />
      </Stack>
      <Box
        height={tableHeight}
        width={tableWidth}
        sx={{
          ...muiGridWrapperStyles,
          '& .productlist-variant': {
            bgcolor: ({ palette }) => lighten(palette.primary.light, 0.9),
          },
        }}
      >
        <DataGridPro
          rowHeight={rowHeight}
          rows={rows}
          columns={columns}
          hideFooter
          disableColumnMenu
          disableSelectionOnClick
          disableColumnResize
          pinnedColumns={{ left: ['productName'] }}
          autoHeight={autoHeight}
          getRowClassName={({ row }) => {
            const { isVariant } = row as ProductRowBase;
            return isVariant ? 'productlist-variant' : '';
          }}
        />
      </Box>
    </Stack>
  );
};

interface ContainerProps {
  product: NobbProductDTO;
}

function ProductWithVariantsCompetitorPricesContainer({ product }: ContainerProps) {
  const selectedOrgUnit = useSelectedContext();
  const { data: competitorsDto } = useFetchContextCompetitors(selectedOrgUnit.id);
  const competitors = useSelectedPrisinnsiktCompetitors(selectedOrgUnit);

  const { data: competitorPriceResponse } = useCompetitorPrices({
    nobbNumbers: Array.from(
      new Set(
        [product.nobbNr].concat(
          product.equivalents?.nobbProducts?.map(({ nobbNr }) => nobbNr) || []
        )
      )
    ),
    suspense: true,
    context: { key: selectedOrgUnit.type, value: selectedOrgUnit.value },
    selectedRetailers: competitorsDto?.retailers.map(({ id }) => id) || [],
    selectedStores: competitorsDto?.stores.map(({ id }) => id) || [],
  });

  const compPriceMap = useMemo(() => {
    return createCompetitorPriceMap(competitorPriceResponse || []);
  }, [competitorPriceResponse]);

  const mainProduct = useMemo(
    () => ({
      nobbNr: product.nobbNr,
      productName: product.varetekst1,
      retailerPrices: compPriceMap[product.nobbNr]?.retailers || [],
    }),
    [product, compPriceMap]
  );

  const variants: ProductCompetitorInfo[] = useMemo(
    () =>
      product.equivalents?.nobbProducts
        .filter(({ nobbNr }) => nobbNr !== product.nobbNr)
        .map((variant) => ({
          nobbNr: variant.nobbNr,
          productName: variant.varetekst1,
          retailerPrices: compPriceMap[variant.nobbNr]?.retailers || [],
        })) || [],
    [product, compPriceMap]
  );

  return (
    <ProductWithVariantsCompetitorPricesContent
      mainProduct={mainProduct}
      variants={variants}
      competitors={competitors}
    />
  );
}

export default withSuspense({
  Component: ProductWithVariantsCompetitorPricesContainer,
  fallback: ({ t }) => <LoadingFallback heading={t('products.prices.competitors.title')} />,
  errorFallback: ({ t }) => (
    <ContainerLoadingError containerHeading={t('products.prices.competitors.title')} />
  ),
});
