import {
  contextPath,
  freightMarkupPath,
  freightMarkupProductGroupsPath,
  freightMarkupsPath,
  itemsPath,
  mgItemsForProductGroupPath,
} from '@retail/freight-markup/data-access';
import { HttpResponse, RequestHandler, delay, http } from 'msw';
import { productGroupsTestData } from './testdata/productGroupsTestData';
import { itemsForProductGroup } from './testdata/itemsForProductGroupsTestData';
import { FreightMarkupLevel } from '@retail/freight-markup/types';
import {
  freightMarkupItems,
  freightMarkupItemsOnlyTotal,
  mainGroupLevelFreightMarkups,
  mgItemLevelFreightMarkups,
  overGroupLevelFreightMarkups,
  supplierItemTestData,
  supplierLevelFreightMarkups,
  testMgParticipants,
  underGroupLevelFreightMarkups,
} from './testdata';
import { favoriteSuppliersRoute, mgSupplierItemApiRoute } from '@retail/products/data-access';

export const freightMarkupHandlers: Record<string, RequestHandler[]> = {
  freightMarkupProductGroups: [
    http.get(`*${contextPath}/:contextId/${freightMarkupProductGroupsPath}`, () =>
      HttpResponse.json(productGroupsTestData)
    ),
  ],
  productGroupItems: [
    http.get(`*${freightMarkupPath}/${mgItemsForProductGroupPath}`, ({ request }) => {
      const url = new URL(request.url);
      const query = url.searchParams.get('productGroupIds');
      const numberSearch = url.searchParams.get('numberSearch');
      const textSearch = url.searchParams.get('textSearch');
      const matchingItems = query
        ? itemsForProductGroup.filter(({ productGroupId }) => query?.includes(productGroupId))
        : null;
      const matchMgItemSearch = numberSearch
        ? itemsForProductGroup.filter(({ mgItemNumber }) =>
            (mgItemNumber + '').includes(numberSearch)
          )
        : null;
      const matchPrimaryTextSearch = itemsForProductGroup.filter(({ primaryText }) =>
        textSearch ? primaryText.toLowerCase().includes(textSearch.toLowerCase()) : null
      );
      if (matchingItems) {
        return HttpResponse.json(matchingItems);
      } else if (matchMgItemSearch) {
        return HttpResponse.json(matchMgItemSearch);
      } else if (matchPrimaryTextSearch) {
        return HttpResponse.json(matchPrimaryTextSearch);
      }

      return HttpResponse.json(itemsForProductGroup);
    }),
  ],
  freightMarkups: [
    http.get(`*${contextPath}/:contextId/${freightMarkupsPath}`, ({ request }) => {
      const url = new URL(request.url);

      const queryProductGroupLevel = url.searchParams.get('level') as FreightMarkupLevel;
      const queryMgitemId = url.searchParams.get('mgItemId');

      if (queryProductGroupLevel) {
        switch (queryProductGroupLevel) {
          case 'overGroup': {
            return HttpResponse.json(overGroupLevelFreightMarkups);
          }
          case 'mainGroup': {
            return HttpResponse.json(mainGroupLevelFreightMarkups);
          }
          default: {
            return HttpResponse.json(underGroupLevelFreightMarkups);
          }
        }
      }

      if (queryMgitemId) {
        return HttpResponse.json(mgItemLevelFreightMarkups);
      }

      return HttpResponse.json(supplierLevelFreightMarkups);
    }),
  ],
  freightMarkupItems: [
    http.get(
      `*${contextPath}/:contextId/${freightMarkupsPath}/:markupId/${itemsPath}`,
      ({ request }) => {
        const url = new URL(request.url);

        const pageSize = Number(url.searchParams.get('pageSize'));

        if (pageSize > 0) {
          return HttpResponse.json(freightMarkupItems);
        }

        return HttpResponse.json(freightMarkupItemsOnlyTotal);
      }
    ),
  ],
  mgSupplierItem: [
    http.get(`*${mgSupplierItemApiRoute}/:mgSupplierItemId`, () => {
      return HttpResponse.json(supplierItemTestData);
    }),
  ],
  createFreightMarkup: [
    http.post(`*${contextPath}/:contextId/${freightMarkupsPath}`, async () => {
      await delay(1000);
      return new HttpResponse(null, { status: 204 });
    }),
  ],
  editFreightMarkup: [
    http.post(`*${contextPath}/:contextId/${freightMarkupsPath}/:id`, async () => {
      await delay(1000);
      return new HttpResponse(null, { status: 204 });
    }),
  ],
  favoriteSuppliers: [
    http.get(`*${contextPath}/:contextId${favoriteSuppliersRoute}`, () =>
      HttpResponse.json(testMgParticipants)
    ),
  ],
  editFavoriteSuppliers: [
    http.post(`*${contextPath}/:contextId${favoriteSuppliersRoute}`, async () => {
      await delay(1000);
      return new HttpResponse(null, { status: 204 });
    }),
  ],
};
