import { useCallback, useMemo } from 'react';
import { useFetchAllPurchaseAgreements } from '@retail/purchase-agreements/data-access';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { useSelectedAgreementsForSupplier } from '@retail/purchase-agreements/stores';
import { SelectPurchaseAgreements } from '@retail/purchase-agreements/components';
import { useMountEffect } from '@shared/hooks';

type Props = {
  supplierId: number;
};

export const SelectedPurchaseAgreements = ({ supplierId }: Props) => {
  const { data: purchaseAgreementsDtos } = useFetchAllPurchaseAgreements(
    { mgParticipantAccountId: supplierId },
    { suspense: true }
  );
  const purchaseAgreements = useMemo(
    () => purchaseAgreementsDtos?.map(PurchaseAgreement.fromDto) || [],
    [purchaseAgreementsDtos]
  );

  const { selectedAgreements, setSelectedAgreements } =
    useSelectedAgreementsForSupplier(supplierId);

  useMountEffect(() => {
    if (!selectedAgreements) {
      setSelectedAgreements(purchaseAgreements.map(({ id }) => id));
    }
  });

  const updateCheckedAgreements = useCallback(
    (agreementIds: string[]) => {
      setSelectedAgreements(agreementIds);
    },
    [setSelectedAgreements]
  );

  return (
    <SelectPurchaseAgreements
      purchaseAgreements={purchaseAgreements}
      checkedAgreements={selectedAgreements}
      updateCheckedAgreements={updateCheckedAgreements}
    />
  );
};
