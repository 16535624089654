import { NobbProductSearchDTO, SearchResponse } from '@retail/products/types';
import { fetcher, useQueryFetch } from '@shared/fetch-utils';
import { prisinnsiktConfig } from '@retail/app/config';
import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAuthContext } from './AuthContext';

const url = prisinnsiktConfig.pps.url + '/v1/products/search';

export async function searchNobbProducts(
  token: string,
  searchQuery: string,
  pageSize: number,
  page: number
): Promise<SearchResponse<NobbProductSearchDTO>> {
  if (searchQuery.length === 0) {
    // Return empty Search?
    return Promise.resolve({
      page,
      query: searchQuery,
      pageSize: 0,
      totalCount: 0,
      totalPages: 0,
      result: [],
    });
  }

  // We can use query-string later if we need to generalise this more
  const encodedQuery = encodeURIComponent(searchQuery);
  const queryString = `?query=${encodedQuery}&pageSize=${pageSize}&page=${page}`;

  return fetcher<SearchResponse<NobbProductSearchDTO>>({
    url: url + queryString,
    token,
  });
}

const defaultPageSize = 25;
export const useNobbProductSearch = (pageSize: number = defaultPageSize) => {
  const [query, _setQuery] = useState('');
  const [page, _setPage] = useState(0);
  const { token } = useAuthContext();
  // wait 500ms before setting bouncedQuery
  const setQuery = useDebouncedCallback((v: string) => {
    _setQuery(v);
    _setPage(0);
  }, 500);

  const fetcher = useCallback(
    () => searchNobbProducts(token, query, pageSize, page),
    [token, query, pageSize, page]
  );

  // Use infinitequery instead?
  const { data, isLoading, fetchStatus, isFetching } = useQueryFetch({
    fetcher,
    queryKey: ['product-search', query, page],
    suspense: false,
    enabled: !!query,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const hasMore = useMemo(() => {
    if (data !== undefined) {
      if (data.totalPages === 0) {
        return false;
      }
      const isLastPage = data.page === data.totalPages - 1;
      return !isLastPage;
    }
    return false;
  }, [data]);

  const hasPrev = useMemo(() => {
    if (data !== undefined) {
      return page !== 0;
    }
    return false;
  }, [data, page]);

  const loadNextPage = useCallback(() => {
    _setPage((prevPage) => prevPage + 1);
  }, []);
  const loadPrevPage = useCallback(() => {
    _setPage((prevPage) => (prevPage === 0 ? prevPage : prevPage - 1));
  }, []);

  const clearQuery = useCallback(() => {
    _setQuery('');
    _setPage(0);
  }, []);

  return {
    query,
    clearQuery,
    setQuery,
    isLoading,
    isFetching,
    fetchStatus,
    products: data?.result,
    hasMore,
    hasPrev,
    loadNextPage,
    loadPrevPage,
  };
};
