import { Fragment } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Person } from '@mui/icons-material';
import { usePopupElement } from '@shared/hooks';
import { LogOutIcon } from '@shared/custom-icons';
import { useSharedTranslation } from '@shared/i18n';
import { Paper } from '../Block';
import { SidebarUserAvatar } from './SidebarUserAvatar';

type Props = {
  userName: string;
  userPicture?: string;
  jobTitle?: string;
  logOut: () => void;
  expandedView: boolean;
};

export function SidebarUserMenu({ expandedView, ...props }: Props) {
  return expandedView ? <ExpandedUserMenu {...props} /> : <CollapsedUserMenu {...props} />;
}

function ExpandedUserMenu({
  userName,
  userPicture,
  jobTitle,
  logOut,
}: Omit<Props, 'expandedView'>) {
  const { t } = useSharedTranslation();

  return (
    <Paper
      sx={{
        width: '100%',
        padding: 1.5,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderRadius: 6, backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <Stack direction="row" alignItems="center">
          <SidebarUserAvatar username={userName} userPicture={userPicture} />

          <Box ml={0.5} display="flex" flexDirection="column">
            <Typography sx={{ fontWeight: 700 }}>{userName}</Typography>
            {jobTitle && (
              <Typography
                variant="body3"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {jobTitle}
              </Typography>
            )}
          </Box>
        </Stack>

        <Tooltip title={t('shared.logOut')} arrow placement="right">
          <IconButton onClick={logOut} sx={{ mr: 1, color: (theme) => theme.palette.grey.A400 }}>
            <LogOutIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Paper>
  );
}

function CollapsedUserMenu({ userName, userPicture, logOut }: Omit<Props, 'expandedView'>) {
  const { t } = useSharedTranslation();
  const { menuElementProps, triggerElementProps } = usePopupElement();

  return (
    <Fragment>
      <IconButton {...triggerElementProps} sx={{ m: 0, p: 0 }}>
        <SidebarUserAvatar username={userName} userPicture={userPicture} />
      </IconButton>

      <Popover
        {...menuElementProps}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        elevation={0}
        sx={{ '& .MuiPaper-root': { padding: 1 } }}
      >
        <List sx={{ padding: 0, color: (theme) => theme.palette.text.secondary }}>
          <ListItem>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={userName}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>

          <ListItemButton onClick={logOut}>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <LogOutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t('shared.logOut')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItemButton>
        </List>
      </Popover>
    </Fragment>
  );
}
