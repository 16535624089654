import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IncludeVatAtom } from '../../../stores/VatStore';
import { addVAT } from '../../../utils/vat';
import { renderPrice } from '@retail/products/utils';
import {
  MgPriceDTO,
  NobbDeltagerDTO,
  NobbPriceDTO,
  NobbProductDTO,
  OmniumPriceDTO,
  PriceProvider,
} from '@retail/products/types';

import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { format, fromUnixTime, getUnixTime, sub } from 'date-fns';
import { nb } from 'date-fns/locale';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { SupplierInfo } from '../ProductViewTypes';
import { SupplierSelect } from '@retail/products/components';
import { useFetchHistoricPriceForProvider } from '../../../api/pps/historicProviderPriceApi';
import { withSuspense } from '../../../decorators';
import LoadingFallback from './LoadingFallback';
import ContainerLoadingError from './ContainerLoadingError';
import { useAppTFunction } from '@retail/app/i18n';

interface ContentProps {
  nobbPrices: NobbPriceDTO[];
  mgPrices: MgPriceDTO[];
  omniumPrices: OmniumPriceDTO[];
  supplierSelect: React.ReactElement;
}

interface Props {
  product: NobbProductDTO;
}

interface DataPoint {
  MGPris?: number;
  NOBBPris?: number;
  date: number;
  unit: string;
}

const PriceDateTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
  const t = useAppTFunction();
  if (active && payload && label) {
    const { name, payload: dataPoint } = payload[0];

    return (
      <div
        style={{
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          borderRadius: '5px',
          padding: '8px',
          backgroundColor: '#FFF',
        }}
      >
        <p>{name}</p>
        <p>{`${dateFormatter(+label)}: ${t('products.prices.pricePerUnit', {
          price: renderPrice(+payload[0].value),
          unit: dataPoint.unit,
        })}`}</p>
      </div>
    );
  }
  return null;
};

function dateFormatter(tickItem: number) {
  return format(fromUnixTime(tickItem), 'PP', { locale: nb });
}

function createGraphData(
  nobbPrices: NobbPriceDTO[],
  mgPrices: MgPriceDTO[],
  omniumPrices: OmniumPriceDTO[],
  includeVat: boolean
) {
  const dataPoints: DataPoint[] = [];

  omniumPrices?.forEach((price) => {
    const dataPoint = {
      OmniumPris: includeVat ? addVAT(price.unitPrice) : price.unitPrice,
      date: getUnixTime(new Date(price.changed)),
      unit: price.unit,
    };
    dataPoints.push(dataPoint);
  });

  mgPrices?.forEach((price) => {
    const dataPoint = {
      MGPris: includeVat ? addVAT(price.salgsPris) : price.salgsPris,
      date: getUnixTime(new Date(price.prisdato)),
      unit: price.salgsEnhet,
    };
    dataPoints.push(dataPoint);
  });

  nobbPrices?.forEach((price) => {
    const dataPoint = {
      NOBBPris: includeVat ? addVAT(price.pris) : price.pris,
      date: getUnixTime(new Date(price.fom)),
      unit: price.prisEnhet,
    };
    dataPoints.push(dataPoint);
  });

  return dataPoints;
}

function HistoricNobbPriceChartContent({
  mgPrices,
  nobbPrices,
  omniumPrices,
  supplierSelect,
}: ContentProps) {
  const t = useAppTFunction();
  const includeVat = useRecoilValue(IncludeVatAtom);

  const [startDate, setStartDate] = useState(getUnixTime(sub(new Date(), { years: 3 })));
  const endDate = getUnixTime(new Date());

  const dataPoints: DataPoint[] = createGraphData(nobbPrices, mgPrices, omniumPrices, includeVat);

  function handleTimeWindowChange(props: { target: { value: string } }) {
    const selectedOption = props.target.value;

    if (selectedOption === 'opt3mnd') {
      setStartDate(getUnixTime(sub(new Date(), { months: 3 })));
    }
    if (selectedOption === 'opt1yr') {
      setStartDate(getUnixTime(sub(new Date(), { years: 1 })));
    }
    if (selectedOption === 'opt3yr') {
      setStartDate(getUnixTime(sub(new Date(), { years: 3 })));
    }
  }

  return (
    <Box>
      <Stack spacing={2} direction="row">
        <FormControl>
          <InputLabel id="periodLabel">{t('products.prices.historic.period')}</InputLabel>
          <Select
            defaultValue="opt3yr"
            onChange={handleTimeWindowChange}
            label={t('products.prices.historic.period')}
            labelId="periodLabel"
          >
            <MenuItem value="opt3mnd">
              {t('products.prices.historic.lastXMonths', { count: 3 })}
            </MenuItem>
            <MenuItem value="opt1yr">{t('products.prices.historic.lastYear')}</MenuItem>
            <MenuItem value="opt3yr">
              {t('products.prices.historic.lastXYears', { count: 3 })}
            </MenuItem>
          </Select>
        </FormControl>
        {supplierSelect}
      </Stack>
      <ResponsiveContainer width={'100%'} height={360}>
        <AreaChart
          data={dataPoints}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 30,
          }}
        >
          <defs>
            <linearGradient id="colorNobb" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorMG" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorOmnium" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ca9d82" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ca9d82" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            type="number"
            tickFormatter={dateFormatter}
            domain={[startDate, endDate]}
            allowDataOverflow={true}
          />
          <YAxis unit={t('products.prices.currency.NOK.short')} domain={[0, 'dataMax + 100']} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<PriceDateTooltip />} />
          <Legend verticalAlign="top" iconType="rect" height={36} />
          <Area
            type="stepBefore"
            dataKey="OmniumPris"
            dot
            stroke="#ca9d82"
            fillOpacity={1}
            fill="url(#colorOmnium)"
            name={t('products.prices.own.webPrice')}
            isAnimationActive={false} //bug i recharts - need to set this false to make dots render.
          />
          <Area
            type="stepBefore"
            dataKey="MGPris"
            dot
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorMG)"
            name={t('products.prices.own.mgPrice')}
            isAnimationActive={false} //bug i recharts - need to set this false to make dots render.
          />
          <Area
            type="stepBefore"
            dataKey="NOBBPris"
            dot
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorNobb)"
            name={t('products.prices.own.nobbPrice')}
            isAnimationActive={false} //bug i recharts - need to set this false to make dots render.
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}

interface HistoricPricesSupplier {
  supplierInfo: SupplierInfo;
  nobbPrices: NobbPriceDTO[];
  mgPrices: MgPriceDTO[];
  omniumPrices: OmniumPriceDTO[];
}

const HistoricNobbPriceChart: React.FC<Props> = ({ product }) => {
  const { data: historicMgPrices } = useFetchHistoricPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.mg,
    from: format(sub(new Date(), { years: 3 }), 'yyyy-MM-dd'),
    suspense: true,
    retry: 0,
  });
  const { data: historicOmniumPrices } = useFetchHistoricPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.omnium,
    from: format(sub(new Date(), { years: 3 }), 'yyyy-MM-dd'),
    suspense: true,
    retry: 0,
  });
  const { data: historicNobbPrices } = useFetchHistoricPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.nobb,
    from: format(sub(new Date(), { years: 3 }), 'yyyy-MM-dd'),
    suspense: true,
    retry: 0,
  });
  const t = useAppTFunction();

  const suppliers: HistoricPricesSupplier[] = useMemo(() => {
    return (
      historicMgPrices?.[0]?.leverandorer
        ?.filter((supplier) => !!supplier.deltaker)
        .map((supplier) => {
          const deltaker = supplier.deltaker as NobbDeltagerDTO;
          const omniumPrices =
            historicOmniumPrices
              ?.flatMap((price) => price.leverandorer)
              .filter((omSup) => omSup?.deltaker?.deltagerNr === deltaker.deltagerNr)
              .flatMap((omSup) => omSup?.ecomPriser || []) || [];
          const nobbPrices =
            historicNobbPrices
              ?.flatMap((price) => price.leverandorer)
              .filter((nobbSup) => nobbSup?.deltaker?.deltagerNr === deltaker.deltagerNr)
              .flatMap((omSup) => omSup?.nobbPriser || []) || [];

          const historicSupplier: HistoricPricesSupplier = {
            supplierInfo: {
              id: deltaker.deltagerNr,
              name: deltaker.markedsnavn,
            },
            nobbPrices,
            mgPrices: supplier.mgPriser || [],
            omniumPrices,
          };
          return historicSupplier;
        }) || []
    );
  }, [historicMgPrices, historicNobbPrices, historicOmniumPrices]);

  const [selectedSupplier, setSelectedSupplier] = useState<HistoricPricesSupplier | undefined>();

  useEffect(() => {
    setSelectedSupplier(suppliers[0]);
  }, [suppliers]);

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{t('products.prices.own.historic.title')}</Typography>
      {selectedSupplier ? (
        <HistoricNobbPriceChartContent
          nobbPrices={selectedSupplier.nobbPrices}
          mgPrices={selectedSupplier.mgPrices}
          omniumPrices={selectedSupplier.omniumPrices}
          supplierSelect={
            <SupplierSelect
              suppliers={suppliers}
              selectSupplier={setSelectedSupplier}
              selectedSupplier={selectedSupplier}
              getSupplierId={(supplier) => supplier.supplierInfo.id}
              getSupplierName={(supplier) => supplier.supplierInfo.name}
            />
          }
        />
      ) : (
        <Typography>{t('products.supplier.noExisting')}</Typography>
      )}
    </Stack>
  );
};

export default withSuspense({
  Component: HistoricNobbPriceChart,
  fallback: ({ t }) => <LoadingFallback heading={t('products.prices.own.historic.title')} />,
  errorFallback: ({ t }) => (
    <ContainerLoadingError containerHeading={t('products.prices.own.historic.title')} />
  ),
});
