import { Stack, Typography, alpha, useTheme } from '@mui/material';
import { Chip } from '@shared/components';
import { MgSupplierItemsTable } from './MgSupplierItemsTable';
import { Dispatch, SetStateAction } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { DialogLayout } from '@retail/components';
import { MgSupplierItemsPageResult } from '@retail/purchase-agreements/types';

interface Props {
  open: boolean;
  onClose: () => void;
  affectedItems: MgSupplierItemsPageResult;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function MgSupplierItemsDialog({
  open,
  onClose,
  affectedItems,
  updatePage,
  updatePageSize,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  const { palette } = useTheme();
  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      header={t('purchaseAgreements.affectedItems.itemOverview')}
    >
      <Stack flexDirection="row" pt={3} pl={3} gap={1}>
        <Typography fontWeight={700}>
          {t('purchaseAgreements.affectedItems.affectedItems')}:
        </Typography>
        <Chip
          size="small"
          backgroundColor={alpha(palette.info.main, 0.6)}
          label={affectedItems.totalItems}
        />
      </Stack>
      <MgSupplierItemsTable
        {...affectedItems}
        updatePage={updatePage}
        updatePageSize={updatePageSize}
      />
    </DialogLayout>
  );
}
