import { SvgIcon, SvgIconProps } from '@mui/material';

export const MGPrisIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="43"
        height="42"
        viewBox="0 0 43 42"
        fill="none"
      >
        <path
          d="M42.9614 5.51581V0.453125H21.4691L12.5244 9.39295L3.57979 0.453125H0V25.502H5.06365V9.09464L12.5244 16.5516L19.9843 9.09464V24.0085L19.9881 25.502H42.9614V10.2937H37.8977V20.4384H25.0518V5.51581H42.9614Z"
          fill="url(#paint0_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3398 29.0625H43.0013V30.724H41.3398V29.0625Z"
          fill="url(#paint1_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8281 30.9375H35.4896V32.6H33.8281V30.9375Z"
          fill="url(#paint2_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8281 32.8203H35.4896V34.4818H33.8281V32.8203Z"
          fill="url(#paint3_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.707 34.6953H37.3695V36.3578H35.707V34.6953Z"
          fill="url(#paint4_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4609 34.6953H41.1234V36.3578H39.4609V34.6953Z"
          fill="url(#paint5_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3398 36.5703H43.0013V38.2318H41.3398V36.5703Z"
          fill="url(#paint6_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3398 38.4453H43.0013V40.1078H41.3398V38.4453Z"
          fill="url(#paint7_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8281 40.3281H35.4896V41.9896H33.8281V40.3281Z"
          fill="url(#paint8_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.707 29.0625H37.3695V30.724H35.707V29.0625Z"
          fill="url(#paint9_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5859 29.0625H39.2474V30.724H37.5859V29.0625Z"
          fill="url(#paint10_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4609 29.0625H41.1234V30.724H39.4609V29.0625Z"
          fill="url(#paint11_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5859 34.6953H39.2474V36.3578H37.5859V34.6953Z"
          fill="url(#paint12_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.707 40.3281H37.3695V41.9896H35.707V40.3281Z"
          fill="url(#paint13_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5859 40.3281H39.2474V41.9896H37.5859V40.3281Z"
          fill="url(#paint14_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4609 40.3281H41.1234V41.9896H39.4609V40.3281Z"
          fill="url(#paint15_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1797 29.0625H14.8412V30.724H13.1797V29.0625Z"
          fill="url(#paint16_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0586 29.0625H16.7211V30.724H15.0586V29.0625Z"
          fill="url(#paint17_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9336 29.0625H18.5951V30.724H16.9336V29.0625Z"
          fill="url(#paint18_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0586 36.5703H16.7211V38.2318H15.0586V36.5703Z"
          fill="url(#paint19_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9336 38.4453H18.5951V40.1078H16.9336V38.4453Z"
          fill="url(#paint20_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 29.0625H12.9632V30.724H11.3008V29.0625Z"
          fill="url(#paint21_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 30.9375H12.9632V32.6H11.3008V30.9375Z"
          fill="url(#paint22_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8125 30.9375H20.474V32.6H18.8125V30.9375Z"
          fill="url(#paint23_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 32.8203H12.9632V34.4818H11.3008V32.8203Z"
          fill="url(#paint24_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8125 32.8203H20.474V34.4818H18.8125V32.8203Z"
          fill="url(#paint25_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 34.6953H12.9632V36.3578H11.3008V34.6953Z"
          fill="url(#paint26_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1797 34.6953H14.8412V36.3578H13.1797V34.6953Z"
          fill="url(#paint27_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0586 34.6953H16.7211V36.3578H15.0586V34.6953Z"
          fill="url(#paint28_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9336 34.6953H18.5951V36.3578H16.9336V34.6953Z"
          fill="url(#paint29_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 36.5703H12.9632V38.2318H11.3008V36.5703Z"
          fill="url(#paint30_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 38.4453H12.9632V40.1078H11.3008V38.4453Z"
          fill="url(#paint31_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 40.3281H12.9632V41.9896H11.3008V40.3281Z"
          fill="url(#paint32_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8125 40.3281H20.474V41.9896H18.8125V40.3281Z"
          fill="url(#paint33_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.54688 30.9375H9.20934V32.6H7.54688V30.9375Z"
          fill="url(#paint34_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.54688 32.8203H9.20934V34.4818H7.54688V32.8203Z"
          fill="url(#paint35_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 29.0625H1.70152V30.724H0.0390625V29.0625Z"
          fill="url(#paint36_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.91797 29.0625H3.57946V30.724H1.91797V29.0625Z"
          fill="url(#paint37_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79297 29.0625H5.45543V30.724H3.79297V29.0625Z"
          fill="url(#paint38_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.67188 29.0625H7.33337V30.724H5.67188V29.0625Z"
          fill="url(#paint39_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 30.9375H1.70152V32.6H0.0390625V30.9375Z"
          fill="url(#paint40_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 32.8203H1.70152V34.4818H0.0390625V32.8203Z"
          fill="url(#paint41_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 34.6953H1.70152V36.3578H0.0390625V34.6953Z"
          fill="url(#paint42_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.91797 34.6953H3.57946V36.3578H1.91797V34.6953Z"
          fill="url(#paint43_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79297 34.6953H5.45543V36.3578H3.79297V34.6953Z"
          fill="url(#paint44_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.67188 34.6953H7.33337V36.3578H5.67188V34.6953Z"
          fill="url(#paint45_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 36.5703H1.70152V38.2318H0.0390625V36.5703Z"
          fill="url(#paint46_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 38.4453H1.70152V40.1078H0.0390625V38.4453Z"
          fill="url(#paint47_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 40.3281H1.70152V41.9896H0.0390625V40.3281Z"
          fill="url(#paint48_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4453 29.0625H26.1068V30.724H24.4453V29.0625Z"
          fill="url(#paint49_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 29.0625H27.9818V30.724H26.3203V29.0625Z"
          fill="url(#paint50_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1992 29.0625H29.8607V30.724H28.1992V29.0625Z"
          fill="url(#paint51_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 30.9375H27.9818V32.6H26.3203V30.9375Z"
          fill="url(#paint52_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 32.8203H27.9818V34.4818H26.3203V32.8203Z"
          fill="url(#paint53_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 34.6953H27.9818V36.3578H26.3203V34.6953Z"
          fill="url(#paint54_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 36.5703H27.9818V38.2318H26.3203V36.5703Z"
          fill="url(#paint55_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 38.4453H27.9818V40.1078H26.3203V38.4453Z"
          fill="url(#paint56_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4453 40.3281H26.1068V41.9896H24.4453V40.3281Z"
          fill="url(#paint57_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 40.3281H27.9818V41.9896H26.3203V40.3281Z"
          fill="url(#paint58_linear_6375_14004)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1992 40.3281H29.8607V41.9896H28.1992V40.3281Z"
          fill="url(#paint59_linear_6375_14004)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6375_14004"
            x1="45.1095"
            y1="17.6742"
            x2="6.81813"
            y2="-8.36466"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6375_14004"
            x1="43.0844"
            y1="30.2048"
            x2="41.2129"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_6375_14004"
            x1="35.5727"
            y1="32.0804"
            x2="33.7009"
            y2="31.3387"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_6375_14004"
            x1="35.5727"
            y1="33.9626"
            x2="33.7012"
            y2="33.2206"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_6375_14004"
            x1="37.4526"
            y1="35.8383"
            x2="35.58"
            y2="35.0958"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_6375_14004"
            x1="41.2065"
            y1="35.8383"
            x2="39.334"
            y2="35.0958"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_6375_14004"
            x1="43.0844"
            y1="37.7126"
            x2="41.2129"
            y2="36.9706"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_6375_14004"
            x1="43.0844"
            y1="39.5883"
            x2="41.2126"
            y2="38.8465"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_6375_14004"
            x1="35.5727"
            y1="41.4704"
            x2="33.7012"
            y2="40.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_6375_14004"
            x1="37.4526"
            y1="30.2048"
            x2="35.5803"
            y2="29.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_6375_14004"
            x1="39.3305"
            y1="30.2048"
            x2="37.459"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_6375_14004"
            x1="41.2065"
            y1="30.2048"
            x2="39.3342"
            y2="29.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_6375_14004"
            x1="39.3305"
            y1="35.8383"
            x2="37.4587"
            y2="35.0965"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_6375_14004"
            x1="37.4526"
            y1="41.4704"
            x2="35.5803"
            y2="40.7276"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_6375_14004"
            x1="39.3305"
            y1="41.4704"
            x2="37.459"
            y2="40.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_6375_14004"
            x1="41.2065"
            y1="41.4704"
            x2="39.3342"
            y2="40.7276"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_6375_14004"
            x1="14.9243"
            y1="30.2048"
            x2="13.0528"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_6375_14004"
            x1="16.8042"
            y1="30.2048"
            x2="14.9319"
            y2="29.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_6375_14004"
            x1="18.6782"
            y1="30.2048"
            x2="16.8067"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_6375_14004"
            x1="16.8042"
            y1="37.7126"
            x2="14.9319"
            y2="36.9698"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_6375_14004"
            x1="18.6782"
            y1="39.5883"
            x2="16.8064"
            y2="38.8465"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_6375_14004"
            x1="13.0464"
            y1="30.2048"
            x2="11.1741"
            y2="29.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_6375_14004"
            x1="13.0464"
            y1="32.0804"
            x2="11.1738"
            y2="31.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_6375_14004"
            x1="20.5571"
            y1="32.0804"
            x2="18.6853"
            y2="31.3387"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_6375_14004"
            x1="13.0464"
            y1="33.9626"
            x2="11.1741"
            y2="33.2198"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_6375_14004"
            x1="20.5571"
            y1="33.9626"
            x2="18.6856"
            y2="33.2206"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_6375_14004"
            x1="13.0464"
            y1="35.8383"
            x2="11.1738"
            y2="35.0958"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_6375_14004"
            x1="14.9243"
            y1="35.8383"
            x2="13.0525"
            y2="35.0965"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_6375_14004"
            x1="16.8042"
            y1="35.8383"
            x2="14.9316"
            y2="35.0958"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_6375_14004"
            x1="18.6782"
            y1="35.8383"
            x2="16.8064"
            y2="35.0965"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_6375_14004"
            x1="13.0464"
            y1="37.7126"
            x2="11.1741"
            y2="36.9698"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_6375_14004"
            x1="13.0464"
            y1="39.5883"
            x2="11.1738"
            y2="38.8458"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_6375_14004"
            x1="13.0464"
            y1="41.4704"
            x2="11.1741"
            y2="40.7276"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_6375_14004"
            x1="20.5571"
            y1="41.4704"
            x2="18.6856"
            y2="40.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_6375_14004"
            x1="9.29246"
            y1="32.0804"
            x2="7.41989"
            y2="31.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_6375_14004"
            x1="9.29246"
            y1="33.9626"
            x2="7.42018"
            y2="33.2198"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_6375_14004"
            x1="1.78465"
            y1="30.2048"
            x2="-0.0876275"
            y2="29.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_6375_14004"
            x1="3.66254"
            y1="30.2048"
            x2="1.79106"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_6375_14004"
            x1="5.53855"
            y1="30.2048"
            x2="3.66628"
            y2="29.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_6375_14004"
            x1="7.41644"
            y1="30.2048"
            x2="5.54496"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_6375_14004"
            x1="1.78465"
            y1="32.0804"
            x2="-0.0879235"
            y2="31.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_6375_14004"
            x1="1.78465"
            y1="33.9626"
            x2="-0.0876284"
            y2="33.2198"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_6375_14004"
            x1="1.78465"
            y1="35.8383"
            x2="-0.0879235"
            y2="35.0958"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_6375_14004"
            x1="3.66254"
            y1="35.8383"
            x2="1.79076"
            y2="35.0965"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_6375_14004"
            x1="5.53855"
            y1="35.8383"
            x2="3.66598"
            y2="35.0958"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_6375_14004"
            x1="7.41644"
            y1="35.8383"
            x2="5.54467"
            y2="35.0965"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_6375_14004"
            x1="1.78465"
            y1="37.7126"
            x2="-0.0876275"
            y2="36.9698"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_6375_14004"
            x1="1.78465"
            y1="39.5883"
            x2="-0.0879235"
            y2="38.8458"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint48_linear_6375_14004"
            x1="1.78465"
            y1="41.4704"
            x2="-0.0876284"
            y2="40.7276"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint49_linear_6375_14004"
            x1="26.1899"
            y1="30.2048"
            x2="24.3184"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint50_linear_6375_14004"
            x1="28.0649"
            y1="30.2048"
            x2="26.1934"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint51_linear_6375_14004"
            x1="29.9438"
            y1="30.2048"
            x2="28.0723"
            y2="29.4628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint52_linear_6375_14004"
            x1="28.0649"
            y1="32.0804"
            x2="26.1931"
            y2="31.3387"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint53_linear_6375_14004"
            x1="28.0649"
            y1="33.9626"
            x2="26.1934"
            y2="33.2206"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint54_linear_6375_14004"
            x1="28.0649"
            y1="35.8383"
            x2="26.1931"
            y2="35.0965"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint55_linear_6375_14004"
            x1="28.0649"
            y1="37.7126"
            x2="26.1934"
            y2="36.9706"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint56_linear_6375_14004"
            x1="28.0649"
            y1="39.5883"
            x2="26.1931"
            y2="38.8465"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint57_linear_6375_14004"
            x1="26.1899"
            y1="41.4704"
            x2="24.3184"
            y2="40.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint58_linear_6375_14004"
            x1="28.0649"
            y1="41.4704"
            x2="26.1934"
            y2="40.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
          <linearGradient
            id="paint59_linear_6375_14004"
            x1="29.9438"
            y1="41.4704"
            x2="28.0723"
            y2="40.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
