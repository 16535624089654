import { Dispatch, SetStateAction, useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid-pro';
import { useAppTranslation } from '@retail/app/i18n';
import { ContextUser } from '@retail/auth/types';
import { CopyToClipboard } from '@retail/components';

interface Props {
  contextUsers: ContextUser[];
  selectedUserIds: string[];
  setSelectedUserIds: Dispatch<SetStateAction<string[]>>;
}

export function UsersContainer({ contextUsers, selectedUserIds, setSelectedUserIds }: Props) {
  const { t } = useAppTranslation();

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'givenName', headerName: t('admin.users.firstName'), width: 200 },
      { field: 'familyName', headerName: t('admin.users.lastName'), width: 200 },
      {
        field: 'email',
        headerName: t('admin.users.email'),
        width: 300,
        renderCell: (params) => (
          <CopyToClipboard value={params.value}>{params.value}</CopyToClipboard>
        ),
      },
      { field: 'isAdmin', headerName: t('admin.users.admin'), width: 100 },
      {
        field: 'id',
        headerName: t('admin.users.id'),
        width: 500,
        renderCell: (params) => (
          <CopyToClipboard value={params.value}>{params.value}</CopyToClipboard>
        ),
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      contextUsers?.map((user) => ({
        givenName: user.givenName,
        familyName: user.familyName,
        email: user.email,
        isAdmin: user.isAdmin ? t('common.yes') : t('common.no'),
        id: user.userId,
      })),
    [contextUsers, t]
  );

  const onSelectionModelChange = (selection: GridSelectionModel) => {
    setSelectedUserIds(selection.map((id: GridRowId) => id.toString()));
  };

  return (
    <Box height="700px" width="100%">
      <DataGridPro
        hideFooterRowCount
        columns={columns}
        rows={rows}
        pageSize={30}
        rowsPerPageOptions={[10, 30, 50, 100]}
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectedUserIds}
      />
    </Box>
  );
}
