import { useMemo } from 'react';
import { NavigationTreeView, TreeNode } from '@shared/components';
import { MgParticipant } from '@retail/products/types';
import {
  ItemForProductGroup,
  MainGroup,
  OverGroup,
  SelectedAgreementLevel,
  UnderGroup,
} from '@retail/purchase-agreements/types';
import { CheckIcon } from '@shared/custom-icons';
import { Stack } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';

type Props = {
  supplier: MgParticipant;
  overGroups: OverGroup[];
  numberOfItems: number;
  selectAgreementLevel: (agreementLevel: SelectedAgreementLevel) => void;
  updateSelectedProductGroups: (productGroupNumber: string) => void;
  getItemsForProductGroup: (productGroupNumber: string) => ItemForProductGroup[];
};

export const ProductGroupsTree = ({
  supplier,
  overGroups,
  numberOfItems,
  selectAgreementLevel,
  updateSelectedProductGroups,
  getItemsForProductGroup,
}: Props) => {
  const t = useProductsTFunction();
  const getLabel = (
    groupNumber: string,
    name: string | undefined,
    numberOfItems: number,
    hasActiveAgreement: boolean
  ) => {
    return (
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {`${groupNumber} ${name ? name : ''} (${numberOfItems})`}
        {hasActiveAgreement && <CheckIcon fontSize="small" color="#83BF6E" />}
      </Stack>
    );
  };

  const treeNodes: TreeNode[] = useMemo(() => {
    const overGroupsNodes = overGroups.map((overGroup: OverGroup) => ({
      label: getLabel(
        overGroup.groupNumber,
        overGroup.name,
        overGroup.numberOfItems,
        overGroup.hasActiveAgreement
      ),
      id: overGroup.groupNumber,
      onSelect: () => selectAgreementLevel({ id: overGroup.id, level: 'overGroup' }),

      childNodes: overGroup.mainGroups.map((mainGroup: MainGroup) => ({
        label: getLabel(
          mainGroup.groupNumber,
          mainGroup.name,
          mainGroup.numberOfItems,
          mainGroup.hasActiveAgreement
        ),
        id: mainGroup.groupNumber,
        onSelect: () =>
          selectAgreementLevel({
            id: mainGroup.id,
            level: 'mainGroup',
          }),

        childNodes: mainGroup.underGroups.map((underGroup: UnderGroup) => {
          const filteredUnderGroups = getItemsForProductGroup(underGroup.groupNumber);
          const childNodes: TreeNode[] = filteredUnderGroups.length
            ? filteredUnderGroups.map((item: ItemForProductGroup) => ({
                label: item.primaryText,
                id: item.mgItemNumber + '',
                onSelect: () => {
                  selectAgreementLevel({
                    id: item.mgItemNumber + '',
                    level: 'mgItem',
                  });
                },
              }))
            : [
                {
                  label: t('products.loadingLabel'),
                  id: '_',
                  onSelect: () => {},
                },
              ];

          return {
            label: getLabel(
              underGroup.groupNumber,
              underGroup.name,
              underGroup.numberOfItems,
              underGroup.hasActiveAgreement
            ),
            id: underGroup.groupNumber,
            childrenHeight: 'compact',
            onClick: () => updateSelectedProductGroups(underGroup.groupNumber),
            onSelect: () => {
              selectAgreementLevel({
                id: underGroup.id,
                level: 'underGroup',
              });
            },
            childNodes,
          } as TreeNode;
        }),
      })),
    }));

    const supplierRootNode: TreeNode = {
      label: (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          {`${supplier.name || String(supplier.id)} (${numberOfItems})`}
          <CheckIcon fontSize="small" color="#83BF6E" />
        </Stack>
      ),
      id: String(supplier.id),
      onSelect: () => selectAgreementLevel({ id: String(supplier.id), level: 'supplier' }),
      childNodes: overGroupsNodes,
      defaultExpanded: true,
      defaultSelected: true,
    };
    return [supplierRootNode];
  }, [
    t,
    overGroups,
    selectAgreementLevel,
    updateSelectedProductGroups,
    getItemsForProductGroup,
    numberOfItems,
    supplier,
  ]);

  return <NavigationTreeView nodes={treeNodes} />;
};
