import { useUserContexts } from './useUserContexts';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Context } from '@retail/auth/types';

const selectedContextKeyName = 'selectedContextKey';

const initialLoad = (contexts: Context[]): Context => {
  const storedSelectedContextKey: string | null = JSON.parse(
    localStorage.getItem(selectedContextKeyName) || 'null'
  );

  const selectedContextIdx = contexts.findIndex((c) => c.value === storedSelectedContextKey);

  // Load from Localstorage
  // if nothing from localstorage =>  Select first.
  if (storedSelectedContextKey !== null && selectedContextIdx !== -1) {
    // we want to have fresh state from backend and not stored.
    return contexts[selectedContextIdx];
  } else if (contexts.some((c) => c.value === 'xlbygg')) {
    // do this until we use localstorage or something to preselect
    return contexts.find((c) => c.value === 'xlbygg') as Context;
  } else {
    return contexts[0];
  }
};

export type SelectedContextState = {
  selectedContext: Context;
  setSelectedContext: React.Dispatch<React.SetStateAction<Context>>;
};

export const SelectedContextContext = React.createContext<SelectedContextState | null>(null);

const SelectedContextProvider = (props: { children: ReactNode }) => {
  const { data: userContexts = [] } = useUserContexts({ suspense: true });

  const [selectedContext, setSelectedContext] = useState<Context>(() => {
    return initialLoad(userContexts);
  });

  useEffect(() => {
    if (selectedContext !== undefined) {
      localStorage.setItem(selectedContextKeyName, JSON.stringify(selectedContext.value));
    }
  }, [selectedContext]);

  const value = useMemo(() => ({ selectedContext, setSelectedContext }), [selectedContext]);

  return (
    <SelectedContextContext.Provider value={value}>
      {props.children}
    </SelectedContextContext.Provider>
  );
};

const useSelectedContextState = (): SelectedContextState => {
  const c = useContext(SelectedContextContext);
  if (!c) {
    throw Error('useSelectedContextState must be used within a SelectedContextProvider');
  }
  return c;
};

const useSelectedContext = () => {
  const s = useSelectedContextState();
  return s.selectedContext;
};

export { SelectedContextProvider, useSelectedContext, useSelectedContextState };
