import { Search } from '@mui/icons-material';
import { Stack, TextField } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

type Props = {
  filterSuppliers: (search: string) => void;
};

export function SupplierSearchField({ filterSuppliers }: Props) {
  const t = useAppTFunction();
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <Stack pr={1}>
            <Search />
          </Stack>
        ),
      }}
      fullWidth
      sx={{
        outline: ({ palette }) => `1px solid ${palette.divider}`,

        '&.MuiFormControl-root': {
          ':hover, :focus, :focus-within': {
            outline: ({ palette }) => `1px solid ${palette.primary[400]}`,
            borderWidth: 0,
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderRadius: 0,
            borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
          },
        },
      }}
      placeholder={t('common.searchSupplier')}
      onChange={(e) => filterSuppliers(e.target.value)}
    />
  );
}
