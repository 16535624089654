import { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { Paper } from '@shared/components';

interface Props {
  isOpen: boolean;
  collapsedHeight?: number;
  expandedHeight?: number;
}

export function BottomDrawer({
  isOpen,
  collapsedHeight = 50,
  expandedHeight = 500,
  children,
}: PropsWithChildren<Props>) {
  const height = isOpen ? expandedHeight : collapsedHeight;

  return (
    <Stack
      component={Paper}
      sx={{
        height,
        position: 'absolute',
        bottom: 0,
        left: ({ spacing }) => spacing(4),
        right: ({ spacing }) => spacing(4),
        zIndex: 100,
        transition: (theme) =>
          theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        p: 0,
        boxShadow: (theme) => theme.shadows[1],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: ({ palette }) => `1px solid ${palette.primary.main}`,
        borderBottom: 'none',

        backgroundColor: ({ palette }) => palette.background.secondary,
      }}
    >
      {children}
    </Stack>
  );
}
