import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SummaryDrawerTableRow } from './SummaryDrawerTableRow';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import {
  ActiveItemAgreementSummary,
  ActiveSupplierAgreementSummary,
  ActiveProductGroupAgreementSummary,
} from '@retail/purchase-agreements/types';
import { SummaryDrawerTableItemRow } from './SummaryDrawerTableItemRow';

interface Props {
  supplierAgreement: ActiveSupplierAgreementSummary | null;
  productGroupAgreement: ActiveProductGroupAgreementSummary[];
  mgItemAgreement: ActiveItemAgreementSummary[];
  viewAffectedItems: (productGroupId?: string) => void;
  removeSupplierAgreement: () => void;
  removeProductGroupAgreement: (productGroupId: string) => void;
  removeItemAgreement: (itemAgreementId: number) => void;
}

export function SummaryDrawerTable({
  supplierAgreement,
  productGroupAgreement,
  mgItemAgreement,
  viewAffectedItems,
  removeSupplierAgreement,
  removeProductGroupAgreement,
  removeItemAgreement,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  return (
    <Table sx={{ '& .MuiTableCell-root': { paddingY: 1 } }}>
      <TableHead
        sx={{
          position: 'sticky',
          top: 60,
          zIndex: 1,
          bgcolor: ({ palette }) => palette.common.white,
        }}
      >
        <TableRow>
          <TableCell>{t('purchaseAgreements.agreement.columns.levelOrItemName')}</TableCell>
          <TableCell align="center">
            {t('purchaseAgreements.affectedItems.affectedItems')}
          </TableCell>
          <TableCell>{t('purchaseAgreements.agreement.columns.agreementText')}</TableCell>
          <TableCell>{t('purchaseAgreements.agreement.columns.fromDate')}</TableCell>
          <TableCell>{t('purchaseAgreements.agreement.columns.toDate')}</TableCell>
          <TableCell>{t('purchaseAgreements.agreement.columns.priceType')}</TableCell>
          <TableCell>{t('purchaseAgreements.agreement.columns.price')}</TableCell>
          <TableCell>{t('purchaseAgreements.agreement.columns.basePriceUnit')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {supplierAgreement && (
          <SummaryDrawerTableRow
            id={supplierAgreement.supplierId}
            name={supplierAgreement.supplierName}
            nrOfAffectedItems={supplierAgreement.nrOfAffectedItems}
            viewAffectedItems={viewAffectedItems}
            prevActiveAgreement={supplierAgreement.prevActiveAgreement}
            newActiveAgreement={supplierAgreement.newActiveAgreement}
            removeAgreement={removeSupplierAgreement}
          />
        )}
        {productGroupAgreement.map((agreement) => {
          return (
            <SummaryDrawerTableRow
              key={agreement.productGroupNr}
              id={agreement.productGroupNr}
              viewAffectedItems={() => viewAffectedItems(agreement.productGroupNr)}
              name={agreement.productGroupName}
              nrOfAffectedItems={agreement.nrOfAffectedItems}
              prevActiveAgreement={agreement.prevActiveAgreement}
              newActiveAgreement={agreement.newActiveAgreement}
              removeAgreement={removeProductGroupAgreement}
            />
          );
        })}
        {mgItemAgreement.map((agreement) => {
          return (
            <SummaryDrawerTableItemRow
              key={agreement.mgItemNr}
              {...agreement}
              removeAgreement={removeItemAgreement}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}
