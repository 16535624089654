export const MGNamespace = 'https://mestergruppen.no/authorization';

export interface MGAuthorization {
  apps: string[];
  dataowners: string[];
  locations?: string[];
  mg?: boolean;
}

export interface Auth0Token {
  aud: string[];
  azp: string;
  exp: number;
  [MGNamespace]: MGAuthorization;
  iat: number;
  iss: string;
  scope: string;
  sub: string;
}

export interface UserToken {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}
