import { Bk04Varegruppe, MgSupplierItemMinimal } from '@retail/products/types';
import { FreightMarkupDTO } from '../dto';

export type FreightMarkupLevel =
  | 'supplier'
  | 'overGroup'
  | 'mainGroup'
  | 'underGroup'
  | 'mgSupplierItem';
interface FreightMarkupProps {
  id: string;
  level: FreightMarkupLevel;
  fromDate: Date;
  toDate: Date;
  supplierFreight?: {
    supplierId: number;
    freightFactor: number;
  };
  productGroupFreight?: {
    productGroup: Bk04Varegruppe;
    freightFactor: number;
  };
  mgSupplierItemFreight?: {
    mgSupplierItem: MgSupplierItemMinimal;
    freightFactor?: number;
    freightSurcharge?: {
      unit: string;
      value: number;
    };
  };
}

export class FreightMarkup {
  id: string;
  level: FreightMarkupLevel;
  fromDate: Date;
  toDate: Date;
  supplierFreight?: {
    supplierId: number;
    freightFactor: number;
  };
  productGroupFreight?: {
    productGroup: Bk04Varegruppe;
    freightFactor: number;
  };
  mgSupplierItemFreight?: {
    mgSupplierItem: MgSupplierItemMinimal;
    freightFactor?: number;
    freightSurcharge?: {
      unit: string;
      value: number;
    };
  };

  constructor({
    id,
    level,
    fromDate,
    toDate,
    supplierFreight,
    productGroupFreight,
    mgSupplierItemFreight,
  }: FreightMarkupProps) {
    this.id = id;
    this.level = level;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.supplierFreight = supplierFreight;
    this.productGroupFreight = productGroupFreight;
    this.mgSupplierItemFreight = mgSupplierItemFreight;
  }

  static fromDto = (dto: FreightMarkupDTO): FreightMarkup =>
    new FreightMarkup({
      id: dto.id,
      level: dto.level as FreightMarkupLevel,
      fromDate: dto.fromDate,
      toDate: dto.toDate,
      supplierFreight: dto.supplierFreight,
      productGroupFreight: dto.productGroupFreight
        ? {
            freightFactor: dto.productGroupFreight.freightFactor,
            productGroup: Bk04Varegruppe.fromDto(dto.productGroupFreight.productGroup),
          }
        : undefined,
      mgSupplierItemFreight: dto.mgSupplierItemFreight
        ? {
            freightFactor: dto.mgSupplierItemFreight.freightFactor,
            freightSurcharge: dto.mgSupplierItemFreight.freightSurcharge,
            mgSupplierItem: MgSupplierItemMinimal.fromDto(dto.mgSupplierItemFreight.mgSupplierItem),
          }
        : undefined,
    });
}
