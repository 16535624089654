import { useMemo } from 'react';
import { alpha } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { ActivateAgreementButton } from '../ActivateAgreementButton';
import dayjs from 'dayjs';
import { ColumnHeaderWithHelpText } from '@retail/components';
import { dataGridBoldClass } from '@shared/styles';

type Props = {
  agreements: ItemAgreement[];
  agreementIdAwaitingConfirmation: string;
  variant?: 'default' | 'secondary';
  submitForConfirmation: (agreement: ItemAgreement) => void;
};

export const ItemAgreementsTable = ({
  agreements,
  agreementIdAwaitingConfirmation,
  variant = 'default',
  submitForConfirmation,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        headerAlign: 'left',
        align: 'left',
        cellClassName: () => dataGridBoldClass,
        renderCell: (params) => params.row.agreementText,
      },
      {
        ...baseColumnProps,
        field: 'fromDate',
        width: 140,
        headerName: t('purchaseAgreements.agreement.columns.fromDate'),
        renderCell: (params) => dayjs(params.row.fromDate).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'toDate',
        width: 140,
        headerName: t('purchaseAgreements.agreement.columns.toDate'),
        renderCell: (params) => dayjs(params.row.toDate).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'priceType',
        width: 100,
        headerName: t('purchaseAgreements.agreement.columns.priceType'),
      },
      {
        ...baseColumnProps,
        field: 'price',
        headerName: t('purchaseAgreements.agreement.columns.price'),
        renderCell: ({ value, row: { priceUnit } }) =>
          t('purchaseAgreements.pricePerUnit', {
            price: value,
            unit: priceUnit,
          }),
      },
      {
        ...baseColumnProps,
        field: 'basePriceUnit',
        renderHeader: () => (
          <ColumnHeaderWithHelpText
            header={t('purchaseAgreements.agreement.columns.basePriceUnit')}
            tooltipContent={t('purchaseAgreements.agreement.columns.basePriceUnitDesc')}
          />
        ),
        width: 120,
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        renderCell: ({ row }) => (
          <ActivateAgreementButton
            status={row.status}
            isAwaitingConfirmation={agreementIdAwaitingConfirmation === row.id}
            submitForConfirmation={() => submitForConfirmation(row)}
          />
        ),
      },
    ],
    [t, baseColumnProps, agreementIdAwaitingConfirmation, submitForConfirmation]
  );

  return (
    <DataGridPro
      autoHeight
      getRowId={(row) => row.id}
      columns={columns}
      rows={agreements}
      rowHeight={65}
      disableSelectionOnClick
      disableColumnMenu
      disableColumnResize
      disableColumnReorder
      hideFooter
      sx={
        variant === 'secondary'
          ? {
              '& .MuiDataGrid-row': {
                ':last-of-type': { borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
                ':nth-of-type(odd)': {
                  backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
                  ':hover': {
                    backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
                  },
                },
              },
            }
          : undefined
      }
    />
  );
};
