import { atom, selector } from 'recoil';
import { ProductFilter } from '../../types';
import {
  productHasMgPrice,
  productHasOmniumPrice,
  producthasPriceForRetailer,
} from '@retail/products/utils';
import { NobbProductRow } from '@retail/products/types';

export const HasPriceFiltersAtom = atom<string[]>({
  key: 'has-price-filters-atom',
  default: [],
});

export const mgPriceFilterKey = 'mgPrice';
export const omniumPriceFilterKey = 'omniumPrice';

const productHasPriceForSelection = (product: NobbProductRow, checkedKeys: string[]) =>
  checkedKeys.some((checkedKey) => producthasPriceForRetailer(product, checkedKey));

export const hasPriceFilterSelector = selector<ProductFilter>({
  key: 'hasPriceFilterSelector',
  get: ({ get }) => {
    const checked = get(HasPriceFiltersAtom);
    return {
      active: () => checked.length > 0,
      filter: (products) => {
        const shouldFilterMgPrices = checked.includes(mgPriceFilterKey);
        const shouldFilterOmniumPrices = checked.includes(omniumPriceFilterKey);
        const shouldFilterCompetitionPrices =
          checked.filter((id) => id !== mgPriceFilterKey && id !== omniumPriceFilterKey).length > 0;

        return products.filter(
          (product) =>
            (shouldFilterMgPrices && productHasMgPrice(product)) ||
            (shouldFilterOmniumPrices && productHasOmniumPrice(product)) ||
            (shouldFilterCompetitionPrices && productHasPriceForSelection(product, checked))
        );
      },
    };
  },
});
