import appNo from './no/app.json';
import commonNo from './no/common.json';
import adminNo from './no/admin.json';
import appSe from './se/app.json';
import commonSe from './se/common.json';
import adminSe from './se/admin.json';
import { validationLanguageResourcesResources } from '@retail/form/i18n';
import { productsI18nResources } from '@retail/products/i18n';
import { purchaseAgreementsI18nResources } from '@retail/purchase-agreements/i18n';
import { assortmentI18nResources } from '@retail/assortment/i18n';
import { freightMarkupI18nResources } from '@retail/freight-markup/i18n';
import { markupI18nResources } from '@retail/markup/i18n';

const defaultResources = {
  app: appNo,
  common: commonNo,
  admin: adminNo,
  ...productsI18nResources.no,
  ...assortmentI18nResources.no,
  ...validationLanguageResourcesResources.no,
  ...purchaseAgreementsI18nResources.no,
  ...freightMarkupI18nResources.no,
  ...markupI18nResources.no,
};

export type AppResources = typeof defaultResources;

export type AppLanguageResources = {
  no: AppResources;
  se: AppResources;
};

export const appLanguageResources: AppLanguageResources = {
  no: defaultResources,
  se: {
    products: productsI18nResources.se.products,
    assortment: assortmentI18nResources.se.assortment,
    app: appSe,
    common: commonSe,
    admin: adminSe,
    validation: validationLanguageResourcesResources.se.validation,
    purchaseAgreements: purchaseAgreementsI18nResources.se.purchaseAgreements,
    freightMarkup: freightMarkupI18nResources.se.freightMarkup,
    markup: markupI18nResources.se.markup,
  },
};
