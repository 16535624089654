import { ListItemText, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Checkbox } from '@retail/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';

interface Props {
  purchaseAgreements: PurchaseAgreement[];
  checkedAgreements: string[];
  updateCheckedAgreements: (agreements: string[]) => void;
}

export function SelectPurchaseAgreements({
  purchaseAgreements,
  checkedAgreements,
  updateCheckedAgreements,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  return (
    <Stack justifyContent="flex-end" flexDirection="row" gap={1} alignItems="center">
      <Typography component="label" htmlFor="agreementSelect" variant="body1" fontWeight="bold">
        {t('purchaseAgreements.agreements')}
      </Typography>

      <Select
        size="small"
        sx={{ width: 120 }}
        multiple
        onChange={(event) => {
          const agreementIds = event.target.value;
          updateCheckedAgreements(
            typeof agreementIds === 'string'
              ? agreementIds.split(',').map((id) => id)
              : agreementIds
          );
        }}
        value={checkedAgreements || []}
        renderValue={(selected) =>
          t('purchaseAgreements.agreementsShowing', {
            count: selected.length,
            totalCount: `${purchaseAgreements.length}`,
          })
        }
      >
        {purchaseAgreements?.map((agreement) => {
          return (
            <MenuItem key={agreement.id} value={agreement.id}>
              <ListItemText>{agreement.name}</ListItemText>
              <Checkbox checked={checkedAgreements?.includes(agreement.id)} />
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
}
