import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';

type CustomDimension = 'mg_brand';

export const setCustomDimensions = (
  gaMeasurementId: string,
  { gtag }: GA4ReactResolveInterface,
  dimensions: Partial<Record<CustomDimension, string>> = {}
) => {
  // using 'config' with all custom dimensions because gtag('set') does not seem to work
  gtag('config', gaMeasurementId, {
    custom_map: {
      dimension1: 'mg_app',
      dimension2: 'mg_brand',
    },
    mg_app: 'prisinnsikt',
    ...dimensions,
  });
};
