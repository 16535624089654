import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  show: boolean;
  id: string;
  ariaLabelledby: string;
}

const TabPanel: React.FC<Props> = ({ children, show, id, ariaLabelledby, ...boxProps }) => {
  return (
    <Box role="tabpanel" hidden={!show} id={id} aria-labelledby={ariaLabelledby} {...boxProps}>
      {show && children}
    </Box>
  );
};

export default TabPanel;
