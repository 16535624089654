import { ReactNode } from 'react';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { QuestionIcon } from '@shared/custom-icons';

interface Props {
  header: string;
  tooltipContent: ReactNode;
}

export function ColumnHeaderWithHelpText({ header, tooltipContent }: Props) {
  return (
    <>
      <Box component="span" mr={0.5}>{header}</Box>
      <Tooltip title={tooltipContent}>
        <QuestionIcon sx={{ width: 20, height: 20 }} />
      </Tooltip>
    </>
  );
}
