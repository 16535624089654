import { NobbDeltagerDTO } from './NobbDeltakerDTO';
import { NobbPriceDTO } from './NobbPriceDTO';

export enum PriceProvider {
  campaign = 'CAMPAIGN',
  mg = 'MG',
  nobb = 'NOBB',
  omnium = 'OMNIUM',
}

export interface MgProductPriceDTO {
  alternativeNobbNrs?: number[];
  leverandorer?: PriceLeverandorDTO[];
  nobbNr: number;
  requested: boolean;
}

export interface PriceLeverandorDTO {
  deltaker?: NobbDeltagerDTO;
  nobbPriser?: NobbPriceDTO[];
  mgPriser?: MgPriceDTO[];
  ecomPriser?: OmniumPriceDTO[];
  campaignPriser?: CampaignPriceDTO[];
}

// Price is without mva
export interface MgPriceDTO {
  id: number;
  innkjopsPris: number;
  kostPris: number;
  prio?: boolean;
  prisdato: string; // start date for price
  salgsEnhet: string;
  salgsPris: number;
  changed?: string;
}

// Price is without mva
export interface OmniumPriceDTO {
  currencyCode?: string;
  cost?: number;
  changed: string;
  unit: string;
  unitPrice: number;
}

export interface CampaignPriceDTO {
  changed?: string;
  id: number;
  innkjopsPris: number;
  kostPris: number;
  prio?: boolean;
  prisdato: string;
  salgsEnhet: string;
  salgsPris: number;
}
