import { MgItemDetailed } from '@retail/products/types';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import Tooltip from '@mui/material/Tooltip';
import { CopyToClipboard } from '@retail/components';
import { useState } from 'react';
import { QuestionIcon } from '@shared/custom-icons';
import { SupplierItemsModal } from './SupplierItemsModal';

export interface Props {
  mgItem: MgItemDetailed;
}

export function MgItemView({ mgItem }: Props) {
  const { id } = mgItem;
  const t = useProductsTFunction();
  const { palette, shape } = useTheme();
  const [supplierItemsModal, showSupplierItemsModal] = useState(false);
  const primarySupplierItem = mgItem.getPrimarySupplierItem();
  const primaryPackage = mgItem.getPrimaryPackage();

  return (
    <Grid
      container
      spacing={2}
      maxWidth={['80%', '80%', '80%', '50%']}
      m={0}
      p="8px"
      bgcolor={palette.background.secondary}
      borderRadius={shape.borderRadius}
    >
      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.number.label')}:
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} display="flex" alignItems="center">
        <CopyToClipboard value={id}>
          <Link
            component="button"
            onClick={() => {
              showSupplierItemsModal(true);
            }}
          >
            {id}
          </Link>
        </CopyToClipboard>
        <Tooltip arrow title={t('products.mgItem.number.description')}>
          <QuestionIcon sx={{ color: palette.text.secondary }} fontSize="small" />
        </Tooltip>
      </Grid>
      <SupplierItemsModal
        open={supplierItemsModal}
        setOpen={showSupplierItemsModal}
        mgItem={mgItem}
      />
      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.finfoNumber.label')}:
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8}>
        {primarySupplierItem.finfoNumber ? (
          <CopyToClipboard value={primarySupplierItem.finfoNumber}>
            <Typography color={palette.text.secondary}>
              {primarySupplierItem.finfoNumber}
            </Typography>
          </CopyToClipboard>
        ) : (
          '-'
        )}
      </Grid>
      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.bk04ProductCategory')}:
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem.bk04Varegruppe &&
          `${primarySupplierItem.bk04Varegruppe.groupNumber}${
            primarySupplierItem.bk04Varegruppe.name
              ? ` ${primarySupplierItem.bk04Varegruppe.name}`
              : ''
          }`}
      </Grid>
      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.secondaryText')}:
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem.secondaryText}
      </Grid>
      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.unit')}:
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primaryPackage?.vilmaUnit?.description || '-'}
      </Grid>
      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.gtin.label')}:
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary} paddingBottom={4}>
        {primaryPackage?.gtinCode ? (
          <CopyToClipboard value={primaryPackage.gtinCode}>
            <Typography color={palette.text.secondary}>{primaryPackage.gtinCode}</Typography>
          </CopyToClipboard>
        ) : (
          '-'
        )}
      </Grid>
    </Grid>
  );
}
