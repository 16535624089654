import { prisinnsiktConfig } from '@retail/app/config';
import { useToken } from '../../hooks/useToken';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Context } from '@retail/auth/types';
import { RetailerDetails, SelectedRetailerDetails } from '../../types';
import { useSelectedContext } from '../../stores/userContexts';
import { sortByString } from '../../utils/sort';
import { fetcher } from '@shared/fetch-utils';

const url = prisinnsiktConfig.pps.url + '/v1/retailers';

export async function fetchRetailers(token: string, context: Context): Promise<RetailerDetails[]> {
  const data = await fetcher<RetailerDetails[]>({
    url,
    token,
    context: { key: context.type, value: context.value },
  });
  const filteredData = data.filter((data) => !!data.name);
  const sorted = filteredData
    ?.map((ret) => {
      ret.stores = ret.stores.slice().sort((a, b) => sortByString(a.name, b.name));
      return ret;
    })
    .slice()
    .sort((a, b) => sortByString(a.name, b.name));
  return Promise.resolve(sorted);
}

export async function storeSelectedRetailers(
  token: string,
  context: Context,
  selectedRetailers: SelectedRetailerDetails[]
): Promise<RetailerDetails[]> {
  return fetcher<RetailerDetails[]>({
    url: `${url}/selected`,
    method: 'POST',
    token,
    body: JSON.stringify(selectedRetailers),
    context: { key: context.type, value: context.value },
  });
}

type Options = {
  refetchOnWindowFocus?: boolean;
};

// React friendly version
export function useFetchRetailers(
  { refetchOnWindowFocus }: Options = { refetchOnWindowFocus: false }
) {
  const getAccessTokenWithErrorHandling = useToken();
  const context = useSelectedContext();

  // TODO create generic fetcher?
  // @ts-ignore
  const fetcher = async () => {
    const t = await getAccessTokenWithErrorHandling();

    try {
      return await fetchRetailers(t, context);
    } catch (e) {
      // if err === 401
      // try one more time with new token.
      // else logout/relog
    }
  };

  return useQuery(['retailers', context], fetcher, {
    refetchOnWindowFocus,
  });
}

export function useSaveSelectedRetailers() {
  const queryClient = useQueryClient();
  const getAccessTokenWithErrorHandling = useToken();
  const context = useSelectedContext();

  return useMutation(
    async (selected: SelectedRetailerDetails[]) => {
      const token = await getAccessTokenWithErrorHandling();
      return storeSelectedRetailers(token, context, selected);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['retailers'] });
      },
    }
  );
}

// TODO: Selected retailer hook here? somewhere else?
