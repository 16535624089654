import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Button,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { RadioInput } from '@retail/components';
import LoadingButton from '../../components/LoadingButton';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import {
  IdentifiableColumn,
  ImportAssortmentSpreadsheet,
  importStepAtom,
  spreadsheetAtom,
  useImportedAssortmentState,
} from '@retail/assortment/components';
import {
  ReplaceOrAdd,
  ReplaceOrAddNobbNrsForm,
  replaceOrAddNobbNrsSchema,
} from './ReplaceOrAddNobbNrsForms';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmitAdd: (nobbNrs: number[]) => void;
  onSubmitReplace: (nobbNrs: number[]) => void;
  isLoading: boolean;
}

const columnsToRetrieve: Set<IdentifiableColumn> = new Set(['nobb']);

const ReplaceOrAddNobbNrsDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmitAdd,
  onSubmitReplace,
  isLoading,
}) => {
  const t = useAppTFunction();
  const [activeStep, setActiveStep] = useRecoilState(importStepAtom);
  const setSpreadsheet = useRecoilState(spreadsheetAtom)[1];
  const { handleSubmit, control, reset } = useForm<ReplaceOrAddNobbNrsForm>({
    resolver: yupResolver(replaceOrAddNobbNrsSchema),
    shouldUnregister: false,
  });
  useEffect(() => {
    if (!isOpen) {
      setSpreadsheet(undefined);
      setActiveStep(0);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  const importState = useImportedAssortmentState({ columnsToRetrieve });

  const onSubmitForm = handleSubmit(({ action }) => {
    if (action === ReplaceOrAdd.add) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onSubmitAdd(importState.nobbNumbers!);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onSubmitReplace(importState.nobbNumbers!);
    }
  });

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle display="flex">
        <Typography sx={{ flex: '1', textAlign: 'center' }}>
          {activeStep === 0
            ? t('assortment.admin.update.import.title')
            : t('assortment.admin.update.import.titleCount', {
                count: importState.nobbNumbers?.length || 0,
              })}
        </Typography>
        <IconButton onClick={onClose} sx={{ mt: -2, mr: -2 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {activeStep === 0 ? (
          <ImportAssortmentSpreadsheet columnsToRetrieve={columnsToRetrieve} />
        ) : (
          <RadioInput
            control={control}
            label={t('assortment.admin.update.import.addOrReplace.label')}
            name="action"
            options={[
              {
                label: t('assortment.admin.update.import.addOrReplace.replace'),
                value: ReplaceOrAdd.replace,
              },
              {
                label: t('assortment.admin.update.import.addOrReplace.add'),
                value: ReplaceOrAdd.add,
              },
            ]}
          />
        )}
      </DialogContent>
      {activeStep === 0 ? (
        <DialogActions>
          <Button onClick={() => setActiveStep(1)} disabled={!importState.nobbNumbers}>
            {t('assortment.admin.create.next')}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={() => setActiveStep(0)} variant="text" disabled={isLoading}>
            {t('assortment.admin.create.previous')}
          </Button>
          <LoadingButton onClick={onSubmitForm} isLoading={isLoading}>
            {t('assortment.admin.create.importCTA')}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ReplaceOrAddNobbNrsDialog;
