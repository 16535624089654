import { useFetchItemAgreements } from '@retail/purchase-agreements/data-access';
import { usePurchaseAgreementsToActivate } from '@retail/purchase-agreements/stores';
import { ItemAgreement, MgItemAgreements } from '@retail/purchase-agreements/types';
import { useCallback, useMemo } from 'react';

interface Props {
  mgItemNr: number;
  supplierId: number;
  selectedAgreements: string[];
}

export const useItemAgreements = ({ mgItemNr, supplierId, selectedAgreements }: Props) => {
  const { addItemAgreement, activatedPurchaseAgreements } = usePurchaseAgreementsToActivate();
  const { data } = useFetchItemAgreements(supplierId, selectedAgreements, mgItemNr, {
    suspense: true,
  });

  const itemAgreementDtos = data!;

  const agreementIdAwaitingConfirmation = useMemo(
    () =>
      itemAgreementDtos.agreements.find(({ id }) =>
        activatedPurchaseAgreements.itemAgreementsToActivate.some(
          (agreement) =>
            agreement.newActiveAgreement.id === id &&
            agreement.mgItemNr === itemAgreementDtos.mgItemNumber
        )
      )?.id,
    [activatedPurchaseAgreements.itemAgreementsToActivate, itemAgreementDtos]
  );

  const itemAgreements =
    MgItemAgreements.fromItemAgreementsDto(itemAgreementDtos, agreementIdAwaitingConfirmation) ||
    undefined;

  const activeAgreement = itemAgreements.agreements.find(
    (agreement) => agreement.status !== 'inactive'
  );
  const addAgreementChanges = useCallback(
    (agreement: ItemAgreement) => {
      return addItemAgreement({
        mgItemNr: itemAgreements.mgItemId,
        itemName: itemAgreements.primaryText,
        prevActiveAgreement: activeAgreement!,
        newActiveAgreement: agreement,
      });
    },
    [activeAgreement, addItemAgreement, itemAgreements]
  );

  return {
    itemAgreements,
    addAgreementChanges,
  };
};
