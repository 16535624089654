import { useMemo } from 'react';
import { useAppConfig } from '@retail/app/stores/app-config';
import { TYPE_BRAND } from '@retail/auth/types';
import { useAuthContext, useFetchMeContexts } from '../../api';

interface Props {
  suspense: boolean;
}

export const useUserContexts = ({ suspense }: Props = { suspense: false }) => {
  const { country } = useAppConfig();
  const { token } = useAuthContext();
  const loadingStatus = useFetchMeContexts(
    {
      brands: [],
      countryCodes: [country],
      token,
    },
    { enabled: !!token, suspense }
  );

  const sortedContextsExcludingBrandContextsForNonAdmins = useMemo(
    () =>
      loadingStatus.data
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(({ isAdmin, type }) => isAdmin || type !== TYPE_BRAND),
    [loadingStatus.data]
  );

  return { ...loadingStatus, data: sortedContextsExcludingBrandContextsForNonAdmins };
};
