import { OverGroupDTO } from '@retail/purchase-agreements/types';

export const productGroupsTestData: OverGroupDTO[] = [
  {
    id: '1',
    groupNumber: '01',
    name: 'Byggmaterial',
    hasActiveAgreement: true,
    numberOfItems: 14,
    mainGroups: [
      {
        id: '100',
        groupNumber: '011',
        name: 'Byggnadsblock och ballast',
        hasActiveAgreement: true,
        numberOfItems: 6,
        underGroups: [
          {
            id: '1000',
            groupNumber: '01112',
            name: 'Krossat bergmaterial',
            hasActiveAgreement: false,
            numberOfItems: 3,
          },
          {
            id: '1001',
            groupNumber: '01199',
            name: 'Byggnadsblock och ballast övrigt',
            hasActiveAgreement: true,
            numberOfItems: 1,
          },
          {
            id: '1002',
            groupNumber: '01109',
            name: 'Glasblock',
            hasActiveAgreement: false,
            numberOfItems: 2,
          },
          {
            id: '130400553',
            groupNumber: '01104',
            name: 'Lättklinker block',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400560',
            groupNumber: '01111',
            name: 'Fyllnadsjord',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400554',
            groupNumber: '01105',
            name: 'Lättklinker balk',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400563',
            groupNumber: '01114',
            name: 'Kantelement',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400550',
            groupNumber: '01101',
            name: 'Betongblock',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400562',
            groupNumber: '01113',
            name: 'Grusmaterial',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400559',
            groupNumber: '01110',
            name: 'Sand',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400557',
            groupNumber: '01108',
            name: 'Betongrör, tegelrör och gjutformar',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '101',
        groupNumber: '014',
        name: 'Tätskiktssystem, tejp och tätningslist',
        hasActiveAgreement: false,
        numberOfItems: 3,
        underGroups: [
          {
            id: '130400601',
            groupNumber: '01404',
            name: 'Gummiduk',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
        ],
      },
      {
        id: '102',
        groupNumber: '010',
        name: 'Bindemedel och bruk',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400540',
            groupNumber: '01002',
            name: 'Bruksbindemedel',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '103',
        groupNumber: '013',
        name: 'Isoleringsmaterial',
        hasActiveAgreement: false,
        numberOfItems: 2,
        underGroups: [
          {
            id: '130400591',
            groupNumber: '01303',
            name: 'Cellplast extruderad',
            hasActiveAgreement: false,
            numberOfItems: 2,
          },
        ],
      },
      {
        id: '104',
        groupNumber: '016',
        name: 'Tak- och väggbeklädnad',
        hasActiveAgreement: false,
        numberOfItems: 3,
        underGroups: [
          {
            id: '130400625',
            groupNumber: '01603',
            name: 'Tak uterum',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '105',
        groupNumber: '015',
        name: 'Armering, stål och metallvaror',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400613',
            groupNumber: '01505',
            name: 'Plåt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
    ],
  },
  {
    id: '2',
    groupNumber: '02',
    name: 'Träprodukter',
    hasActiveAgreement: false,
    numberOfItems: 12,
    mainGroups: [
      {
        id: '130400658',
        groupNumber: '020',
        name: 'Trävaror',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400666',
            groupNumber: '02008',
            name: 'Formvirke',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '107',
        groupNumber: '023',
        name: 'Tryckimpregnerat virke',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400690',
            groupNumber: '02305',
            name: 'Brandimpregnerat',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400681',
        groupNumber: '022',
        name: 'Utvändigt beklädnadsvirke',
        hasActiveAgreement: true,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400683',
            groupNumber: '02202',
            name: 'Ytterpanelbräder - Behandlad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400703',
        groupNumber: '026',
        name: 'Biprodukter - trä',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400705',
            groupNumber: '02699',
            name: 'Biprodukter - trä, övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
    ],
  },
];
