import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { MgItemDetailedDTO } from '@retail/products/types';
import { buildUrl } from '@shared/utils';

export const mgItemsApiRoute = '/v1/mg-item';

interface FetchProps {
  baseUrl: string;
  mgItemNumber: number;
  token: string;
  includeByggmaterialpriserPrices?: boolean;
  competitorStoreIds?: number[];
}

export async function fetchMgItem({
  baseUrl,
  mgItemNumber,
  token,
  includeByggmaterialpriserPrices,
  competitorStoreIds = [],
}: FetchProps) {
  return fetcher<MgItemDetailedDTO>({
    url: buildUrl({
      base: baseUrl,
      route: `${mgItemsApiRoute}/${mgItemNumber}`,
      searchParams: {
        storeIds: competitorStoreIds,
        includeByggmaterialpriserPrices: includeByggmaterialpriserPrices,
      },
    }),
    token,
  });
}

interface Props {
  mgItemNumber: number;
  includeByggmaterialpriserPrices: boolean;
  competitorStoreIds?: number[];
}

export function useFetchMgItem(
  { mgItemNumber, includeByggmaterialpriserPrices, competitorStoreIds }: Props,
  options?: QueryOptions
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () =>
      fetchMgItem({
        baseUrl: pps.url,
        mgItemNumber,
        token,
        competitorStoreIds,
        includeByggmaterialpriserPrices,
      }),
    [competitorStoreIds, mgItemNumber, includeByggmaterialpriserPrices, pps.url, token]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['mg-item', mgItemNumber, competitorStoreIds],
    ...options,
  });
}
