import { VilmaUnit } from './VilmaUnit';
import { MgPackageDTO } from '../../dto';

export type MgPackageType =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'TERTIARY'
  | 'PRICE_COMPARISON'
  | 'BASE_PRICE';

interface ConstructorProps {
  calculatedCount: number;
  gtinCode?: string;
  mgPackageId: number;
  packageNumber: number;
  packageType?: MgPackageType;
  vilmaUnit?: VilmaUnit;
}

export class MgPackage {
  calculatedCount: number;
  gtinCode?: string;
  mgPackageId: number;
  packageNumber: number;
  packageType?: MgPackageType;
  vilmaUnit?: VilmaUnit;

  constructor({
    calculatedCount,
    gtinCode,
    mgPackageId,
    packageNumber,
    packageType,
    vilmaUnit,
  }: ConstructorProps) {
    this.calculatedCount = calculatedCount;
    this.gtinCode = gtinCode;
    this.mgPackageId = mgPackageId;
    this.packageNumber = packageNumber;
    this.packageType = packageType;
    this.vilmaUnit = vilmaUnit;
  }

  static fromDto = (dto: MgPackageDTO): MgPackage => {
    return new MgPackage({
      calculatedCount: dto.calculatedCount,
      gtinCode: dto.gtinCode,
      mgPackageId: dto.mgPackageId,
      packageNumber: dto.packageNumber,
      vilmaUnit: {
        description: dto.vilmaUnit.description,
        unit: dto.vilmaUnit.unit,
      },
    });
  };
}
