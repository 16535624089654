import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { DataGridPagination } from '@retail/components';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { alpha } from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { MgSupplierItemSearchResultMinimal } from '@retail/products/types';

interface Props {
  items: MgSupplierItemSearchResultMinimal[];
  page: number;
  pageSize: number;
  totalItems: number;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function MgSupplierItemsTable({
  items,
  page,
  pageSize,
  totalItems,
  updatePage,
  updatePageSize,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  const columns: GridColDef[] = useMemo(() => {
    const primaryTextColDef: GridColDef<MgSupplierItemSearchResultMinimal> = {
      field: 'primaryText',
      width: 220,
      headerName: t('purchaseAgreements.agreement.columns.primaryText'),
      renderCell: ({ row }) => row.primaryText,
    };
    const secondaryTextColDef: GridColDef<MgSupplierItemSearchResultMinimal> = {
      field: 'secondaryText',
      width: 160,
      headerName: t('purchaseAgreements.agreement.columns.secondaryText'),
      renderCell: ({ row }) => row.secondaryText || '-',
    };
    const finfoNumber: GridColDef<MgSupplierItemSearchResultMinimal> = {
      field: 'finfoNumber',
      width: 160,
      headerName: t('purchaseAgreements.agreement.columns.finfoNr'),
      renderCell: ({ row }) => row.finfoNumber,
    };
    return [primaryTextColDef, secondaryTextColDef, finfoNumber];
  }, [t]);

  return (
    <DataGridPro
      autoHeight
      disableSelectionOnClick
      rows={items}
      getRowId={(row) => row.mgItemNumber + row.finfoNumber}
      columns={columns}
      pagination={true}
      paginationMode="server"
      components={{
        Pagination: DataGridPagination,
      }}
      rowCount={totalItems}
      page={page}
      pageSize={pageSize}
      onPageChange={updatePage}
      onPageSizeChange={updatePageSize}
      sx={{
        '& .MuiDataGrid-row': {
          ':last-of-type': { borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
          ':nth-of-type(odd)': {
            backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
            ':hover': {
              backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
            },
          },
        },
      }}
    />
  );
}
