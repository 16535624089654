import React, { ChangeEvent, memo, MouseEvent } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from '@mui/material';

interface Props {
  readonly className?: string;
  readonly label: string;
  readonly clickable?: boolean;
  readonly onClick?: (e: MouseEvent) => void;
  readonly onDelete?: (e: ChangeEvent) => void;
  readonly deleteIcon?: any;
}

function AssortmentChip({
  className,
  label,
  clickable = false,
  onClick,
  onDelete = undefined,
  deleteIcon = <DeleteIcon />,
}: Props) {
  return (
    <Chip
      className={className}
      label={label}
      clickable={clickable}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
    />
  );
}

export default memo(AssortmentChip);
