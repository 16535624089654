import React from 'react';

export interface ErrorFallbackProps<TError = any> {
  error: TError;
}

export type ErrorFallback<TError = any> = (props: ErrorFallbackProps<TError>) => React.ReactNode;

interface Props<TError = any> {
  fallback: ErrorFallback<TError>;
  children: React.ReactNode;
}

interface State<TError = any> {
  hasError: boolean;
  error: TError | null;
}

export class ErrorBoundary<TError = any> extends React.Component<Props, State<TError>> {
  override state: State<TError> = { hasError: false, error: null };
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback({ error: this.state.error });
    }
    return this.props.children;
  }
}
