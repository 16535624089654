import { toast, ToastContent, ToastOptions } from 'react-toastify';

export interface ToastAlert {
  success: (content: ToastContent) => string | number;
  error: (content: ToastContent) => string | number;
  warning: (content: ToastContent) => string | number;
  dark: (content: ToastContent) => string | number;
  info: (content: ToastContent) => string | number;
}

export type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'dark';

export function useToast(): ToastAlert {
  const props: ToastOptions = {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const alert =
    (type: TypeOptions) =>
    (content: ToastContent): string | number => {
      return toast[type](content, props);
    };

  return {
    success: alert('success'),
    error: alert('error'),
    warning: alert('warning'),
    dark: alert('dark'),
    info: alert('info'),
  };
}
