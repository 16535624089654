import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShoppingCartIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2.10526 14.7368V2.10526H0V0H4.21053V12.6316H20V14.7368H2.10526ZM4.21053 20C3.63158 20 3.13579 19.7937 2.72316 19.3811C2.31053 18.9684 2.10456 18.473 2.10526 17.8947C2.10526 17.3158 2.31158 16.82 2.72421 16.4074C3.13684 15.9947 3.63228 15.7888 4.21053 15.7895C4.78947 15.7895 5.28526 15.9958 5.69789 16.4084C6.11053 16.8211 6.31649 17.3165 6.31579 17.8947C6.31579 18.4737 6.10947 18.9695 5.69684 19.3821C5.28421 19.7947 4.78877 20.0007 4.21053 20ZM5.26316 11.5789V5.26316H11.5789V11.5789H5.26316ZM12.6316 11.5789V5.26316H18.9474V11.5789H12.6316ZM17.8947 20C17.3158 20 16.82 19.7937 16.4074 19.3811C15.9947 18.9684 15.7888 18.473 15.7895 17.8947C15.7895 17.3158 15.9958 16.82 16.4084 16.4074C16.8211 15.9947 17.3165 15.7888 17.8947 15.7895C18.4737 15.7895 18.9695 15.9958 19.3821 16.4084C19.7947 16.8211 20.0007 17.3165 20 17.8947C20 18.4737 19.7937 18.9695 19.3811 19.3821C18.9684 19.7947 18.473 20.0007 17.8947 20Z" />
      </svg>
    </SvgIcon>
  );
};
