import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Layout } from '@retail/app/prisinnsikt-app';
import { BodyLoader, ErrorFallbackPage, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Header } from './Header';
import { AppSidebar } from './AppSidebar';
import { mgprisHeaderHeight } from '@shared/styles';

export function AppLayout({ children }: PropsWithChildren) {
  return (
    <Box display="flex" flexGrow="1">
      <Header />
      <AppSidebar />

      <Layout flexGrow="1" marginTop={mgprisHeaderHeight}>
        <SuspenseWithSentryErrorBoundary
          errorFallback={(props) => <ErrorFallbackPage {...props} />}
          suspenseFallback={<BodyLoader />}
        >
          {children}
        </SuspenseWithSentryErrorBoundary>
      </Layout>
    </Box>
  );
}
