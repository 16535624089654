import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { Bk04OvergruppeDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { buildUrl } from '@shared/utils';

export const bk04ProductGroupsRoute = '/v1/bk04-product-groups/all';

interface SearchProps {
  supplierId?: number;
}

interface FetchBk04Props extends SearchProps {
  base: string;
  route: string;
  token: string;
}

export async function fetchBk04ProductGroups({ base, route, token, supplierId }: FetchBk04Props) {
  return fetcher<Bk04OvergruppeDTO[]>({
    url: buildUrl({ base: base, route: route, searchParams: { supplierId } }),
    token,
    method: 'GET',
  });
}

export function useFetchBk04ProductGroups(
  queryOptions: QueryOptions = {},
  searchProps?: SearchProps
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () =>
      fetchBk04ProductGroups({
        base: pps.url,
        route: bk04ProductGroupsRoute,
        token,
        ...searchProps,
      }),
    [pps.url, token, searchProps]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['bk04'],
    ...queryOptions,
  });
}
