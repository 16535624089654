import React, { memo } from 'react';
import { Container, Typography } from '@mui/material';
import { BodyLoader } from '@retail/components';
import Aliases from './components/Aliases';
import guard, { isSuperAdmin } from '../../decorators/guard';
import { useAppTFunction } from '@retail/app/i18n';

function AdminUnitsPage() {
  const t = useAppTFunction();

  return (
    <React.Suspense fallback={<BodyLoader />}>
      <Container>
        <Typography variant="h1">{t('admin.units.title')}</Typography>
        <Aliases />
      </Container>
    </React.Suspense>
  );
}

// TODO: We have an error in console index.js:1 enableBodyScroll unsuccessful
//  - targetElement must be provided when calling enableBodyScroll on IOS devices.
//  Here could be the solution https://github.com/willmcpo/body-scroll-lock

export default memo(guard({ guards: [isSuperAdmin] })(AdminUnitsPage));
