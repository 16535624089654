import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useContexts } from '@retail/app/stores/selected-context';
import { useAuthContext } from '@retail/auth/data-access';
import { OverGroupDTO } from '@retail/purchase-agreements/types';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';

export const contextPath = '/v1/context';
export const freightMarkupProductGroupsPath = 'freight-markup-product-groups';

export function useFetchFreightMarkupProductGroupsForMgParticipant(
  mgParticipantAccountId: number,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['freight-markup-product-groups', selectedContext],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${freightMarkupProductGroupsPath}`,
      searchParams: {
        mgParticipantAccountId,
      },
    }),
    ...queryOptions,
  });
}
