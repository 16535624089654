import { ErrorOutlineRounded, InfoOutlined } from '@mui/icons-material';
import { FormHelperText, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useController, useFormContext } from 'react-hook-form';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { CreateFreightMarkupSchema, EditFreightMarkupSchema } from './freightMarkupSchema';

export function FromToDateForm() {
  const t = useFreightMarkupTFunction();
  const { control } = useFormContext<CreateFreightMarkupSchema, EditFreightMarkupSchema>();
  const fromDateController = useController({ name: 'fromDate', control });
  const toDateController = useController({ name: 'toDate', control });
  const toDateIsToday = toDateController.field.value?.toDateString() === new Date().toDateString();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack flexDirection="row" width="100%" gap={1}>
        <Stack gap={1} width="100%">
          <Stack flexDirection="row">
            <Typography fontWeight={600}>{t('freightMarkup.form.fromDate')}</Typography>
            <Typography color={({ palette }) => palette.error.light}>*</Typography>
          </Stack>

          <DatePicker {...fromDateController.field} maxDate={new Date()} format="dd/MM/yyyy" />
          {!!fromDateController.fieldState.error && (
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <ErrorOutlineRounded color="warning" fontSize="small" />
              <FormHelperText sx={{ color: ({ palette }) => palette.warning.main }}>
                {fromDateController.fieldState.error.message}
              </FormHelperText>
            </Stack>
          )}
        </Stack>

        <Stack gap={1} width="100%">
          <Typography fontWeight={600}>{t('freightMarkup.form.toDate')}</Typography>
          <DatePicker
            {...toDateController.field}
            minDate={new Date()}
            format="dd/MM/yyyy"
            slotProps={{ field: { clearable: true } }}
          />
          {toDateIsToday && (
            <Stack flexDirection="row" alignItems="flex-start" gap={0.5}>
              <InfoOutlined fontSize="small" color="primary" />
              <Typography variant="body3">{t('freightMarkup.form.endFreightMarkup')}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}
