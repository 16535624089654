import { MgItemMinimalDTO } from '../../dto';
import { MgPackage } from './MgPackage';
import { getUniqueStoreIds } from './mgItemUtils';
import { CompetitorWithPrice } from './CompetitorWithPrice';
import { MgSupplierItemBase } from './MgSupplierItem';
import { MgItemBase } from './MgItemBase';

interface ConstructorProps {
  id: number;
  supplierItems: MgSupplierItemBase[];
  mgPackages: MgPackage[];
  competitors: CompetitorWithPrice[];
}

export class MgItemMinimal extends MgItemBase {
  constructor({ id, supplierItems, mgPackages, competitors }: ConstructorProps) {
    super({ id, supplierItems, mgPackages, competitors });
  }

  static fromDto(mgItemDto: MgItemMinimalDTO): MgItemMinimal {
    const supplierItems: MgSupplierItemBase[] = mgItemDto.mgSupplierItems.map(
      ({ finfoNumber, primaryText, mainSupplier }) => ({
        finfoNumber,
        primaryText,
        isMainSupplier: !!mainSupplier,
      })
    );
    const mgPackages = mgItemDto.mgPackages.map(MgPackage.fromDto);
    const uniqueStoreIds = getUniqueStoreIds(mgItemDto);

    const competitors: CompetitorWithPrice[] = uniqueStoreIds.map((storeId) => {
      return CompetitorWithPrice.getCompetitorWithPrice(mgItemDto, storeId);
    });

    return new MgItemMinimal({
      id: mgItemDto.mgItemNumber,
      supplierItems,
      mgPackages,
      competitors,
    });
  }
}
