import { PropsWithChildren, useMemo } from 'react';
import { Box, ClickAwayListener, Drawer, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  isOpen: boolean;
  toggleOpen: () => void;
  appBarHeight?: number;
  overlapOrPushContent?: 'overlap' | 'push';
  collapsedWidth?: number;
  expandedWidth?: number;
};

export function Sidebar({
  isOpen,
  toggleOpen,
  appBarHeight = 66,
  overlapOrPushContent = 'overlap',
  collapsedWidth = 96,
  expandedWidth = 300,
  children,
}: PropsWithChildren<Props>) {
  const openedStyles = useMemo(
    () => getOpenedStyles(expandedWidth, overlapOrPushContent),
    [expandedWidth, overlapOrPushContent]
  );
  const closedStyles = useMemo(() => getClosedStyles(collapsedWidth), [collapsedWidth]);

  const wrapperStyles = useMemo(() => getWrapperStyles(isOpen), [isOpen]);
  const drawerStyles = useMemo(
    () => getDrawerStyles(isOpen ? openedStyles : closedStyles, appBarHeight),
    [isOpen, openedStyles, closedStyles, appBarHeight]
  );
  const stackStyles = useMemo(
    () => getStackStyles(isOpen ? openedStyles : closedStyles),
    [isOpen, openedStyles, closedStyles]
  );

  return overlapOrPushContent === 'overlap' ? (
    <Box marginRight={`${collapsedWidth}px`}>
      <ClickAwayListener onClickAway={isOpen ? toggleOpen : () => null}>
        <Box sx={wrapperStyles}>
          <Drawer anchor="left" sx={drawerStyles} variant="permanent">
            <Stack sx={stackStyles}>{children}</Stack>
          </Drawer>

          <ToggleSidebarButton
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            appBarHeight={appBarHeight}
          />
        </Box>
      </ClickAwayListener>
    </Box>
  ) : (
    <Drawer anchor="left" sx={drawerStyles} variant="permanent">
      <ClickAwayListener onClickAway={isOpen ? toggleOpen : () => null}>
        <Box sx={wrapperStyles}>
          <Stack sx={stackStyles}>{children}</Stack>

          <ToggleSidebarButton
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            appBarHeight={appBarHeight}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}

const ToggleSidebarButton = ({
  isOpen,
  toggleOpen,
  appBarHeight = 80,
}: Pick<Props, 'isOpen' | 'toggleOpen' | 'appBarHeight'>) => {
  return (
    <Box position="absolute" left="100%" zIndex={(theme) => theme.zIndex.drawer + 1}>
      <Box position="fixed" top={`${appBarHeight + 50}px`}>
        <IconButton
          color="primary"
          onClick={toggleOpen}
          sx={{
            width: '22px',
            height: '22px',
            left: '-11px',
            padding: 0,
            borderRadius: '50%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            bgcolor: (theme) => theme.palette.common.white,
            '&:hover': {
              bgcolor: (theme) => theme.palette.common.white,
            },
          }}
        >
          {isOpen ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
        </IconButton>
      </Box>
    </Box>
  );
};

const getWrapperStyles = (isOpen: boolean): SxProps<Theme> => ({
  height: '100%',
  position: 'absolute',
  left: 0,
  boxShadow: (theme) => (isOpen ? theme.shadows[5] : null),
});

const getStackStyles = (openOrClosedStyles: SystemStyleObject<Theme>): SxProps<Theme> => ({
  height: '100%',
  ...openOrClosedStyles,
});

const getDrawerStyles = (
  openOrClosedStyles: SystemStyleObject<Theme>,
  appBarHeight: number
): SxProps<Theme> => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...openOrClosedStyles,

  '& .MuiDrawer-paper': {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    border: 0,
    height: `calc(100% - ${appBarHeight}px)`,
    top: appBarHeight,
    ...openOrClosedStyles,
  },
});

const getOpenedStyles = (
  width: number,
  overlapOrPushContent: string
): SystemStyleObject<Theme> => ({
  width,
  transition: (theme) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  overflowX: 'hidden',
  boxShadow: overlapOrPushContent === 'overlap' ? (theme) => theme.shadows[11] : null,
});

const getClosedStyles = (width: number): SystemStyleObject<Theme> => ({
  width,
  transition: (theme) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  overflowX: 'hidden',
});
