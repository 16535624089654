import { DialogLayout, withDialogSuspenseErrorBoundary } from '@retail/components';
import { ItemAgreementsTable } from '@retail/purchase-agreements/components';
import { useItemAgreements } from '@retail/purchase-agreements/context';

interface Props {
  open: boolean;
  onClose: () => void;
  mgItemNr: number;
  supplierId: number;
  selectedAgreements: string[];
}

function ChangeSelectedItemAgreementDialog({
  onClose,
  mgItemNr,
  supplierId,
  selectedAgreements,
}: Props) {
  const { itemAgreements, addAgreementChanges } = useItemAgreements({
    mgItemNr: mgItemNr,
    supplierId,
    selectedAgreements,
  });

  return (
    <DialogLayout open onClose={onClose} header={itemAgreements.primaryText} width="lg">
      <ItemAgreementsTable
        variant="secondary"
        agreements={itemAgreements.agreements}
        agreementIdAwaitingConfirmation={itemAgreements.awaitingConfirmationAgreementId}
        submitForConfirmation={addAgreementChanges}
      />
    </DialogLayout>
  );
}

export default withDialogSuspenseErrorBoundary(ChangeSelectedItemAgreementDialog);
