import { useFetchContextCompetitors } from '../../api';
import { Competitor } from '@retail/retailer/types';
import { useMemo } from 'react';
import { competitorDtoToCompetitors } from '@retail/retailer/utils';
import { useAppConfig } from '@retail/app/stores/app-config';
import { Context } from '@retail/auth/types';

export const useSelectedPrisinnsiktCompetitors = (selectedContext: Context): Competitor[] => {
  const { data: competitorsDto } = useFetchContextCompetitors(selectedContext.id);
  const { retailerLogoBaseUrl } = useAppConfig();

  return useMemo(
    () => (competitorsDto ? competitorDtoToCompetitors(competitorsDto, retailerLogoBaseUrl) : []),
    [competitorsDto, retailerLogoBaseUrl]
  );
};
