import { OverGroupDTO } from '../dto';

export interface ProductGroup {
  id: string;
  name: string;
  groupNumber: string;
  hasActiveAgreement: boolean;
  numberOfItems: number;
}

export interface UnderGroup extends ProductGroup {}

export interface MainGroup extends ProductGroup {
  underGroups: UnderGroup[];
}

export class OverGroup implements ProductGroup {
  id: string;
  name: string;
  groupNumber: string;
  hasActiveAgreement: boolean;
  numberOfItems: number;
  mainGroups: MainGroup[];

  constructor({ id, name, groupNumber, hasActiveAgreement, numberOfItems, mainGroups }: OverGroup) {
    this.id = id;
    this.name = name;
    this.groupNumber = groupNumber;
    this.hasActiveAgreement = hasActiveAgreement;
    this.numberOfItems = numberOfItems;
    this.mainGroups = mainGroups;
  }

  static fromOverGroupDto = (dto: OverGroupDTO): OverGroup =>
    new OverGroup({
      id: dto.id,
      name: dto.name,
      groupNumber: dto.groupNumber,
      hasActiveAgreement: dto.hasActiveAgreement,
      numberOfItems: dto.numberOfItems,
      mainGroups: dto.mainGroups,
    });
}
