import { BrandConfig } from '../brandConfig';
import {
  byggTorgetWhiteSmallUri,
  byggtorgetIconImgUri,
  xlByggImgUri,
  xlByggIconImgUri,
  byggtorgetCircularLogoUri,
  xlbyggCircularLogoUri,
} from '@assets/images';

export const byggtorgetConfig: BrandConfig = {
  colors: {
    primary: '#00482E',
    contrast: '#FFFFFF',
  },
  logo: byggTorgetWhiteSmallUri,
  icon: byggtorgetIconImgUri,
  circularLogo: byggtorgetCircularLogoUri,
  key: 'byggtorget',
  name: 'Byggtorget',
};

export const xlbyggConfig: BrandConfig = {
  colors: {
    primary: '#161616',
    contrast: '#FFFFFF',
  },
  logo: xlByggImgUri,
  icon: xlByggIconImgUri,
  circularLogo: xlbyggCircularLogoUri,
  key: 'xlbygg',
  name: 'XL-BYGG',
};
