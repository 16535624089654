import validationNo from '../resources/no/validation.json';
import validationSe from '../resources/se/validation.json';
import { MessagePaths } from '@shared/i18n';

const defaultResources = { validation: validationNo };

export type ValidationResouces = typeof defaultResources;

export type ValidationLanguageResources = {
  no: ValidationResouces;
  se: ValidationResouces;
}

export const validationLanguageResourcesResources: ValidationLanguageResources = {
  no: defaultResources,
  se: { validation: validationSe },
};

export type ValidationMessageKeys = MessagePaths<typeof defaultResources>;
