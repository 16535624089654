import { Pagination, Stack } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { useCallback } from 'react';
import { SelectPageSize } from '../Select';

export function DataGridPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const rootProps = useGridRootProps();
  const rowsPerPageOptions = rootProps.rowsPerPageOptions;

  const updatePageSize = useCallback(
    (newPageSize: number) => {
      apiRef.current.setPageSize(Number(newPageSize));
    },
    [apiRef]
  );

  return (
    <Stack flexDirection="row" alignItems="center">
      <SelectPageSize
        currentPageSize={pageSize}
        updatePageSize={updatePageSize}
        pageSizeOptions={rowsPerPageOptions}
      />
      <Pagination
        page={page + 1}
        count={pageCount}
        onChange={(_, newPage) => {
          apiRef.current.setPage(newPage - 1);
        }}
      />
    </Stack>
  );
}
