import { useMemo } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useFreightMarkupTranslation } from '@retail/freight-markup/i18n';
import { FreightMarkup, FreightMarkupLevel } from '@retail/freight-markup/types';
import { dataGridBoldClass } from '@shared/styles';
import { TextButton } from '@shared/components';
import { Edit } from '@mui/icons-material';
import { MgParticipant } from '@retail/products/types';
import { ItemsAffected } from './ItemsAffected';

interface Props {
  supplier: MgParticipant;
  freightMarkups: FreightMarkup[];
  setFreightMarkupToEdit: (freightMarkup: FreightMarkup) => void;
  openItemsAffectedModal: (markupId: string) => void;
}

export const FreightMarkupsTable = ({
  supplier,
  freightMarkups,
  setFreightMarkupToEdit,
  openItemsAffectedModal,
}: Props) => {
  const { t } = useFreightMarkupTranslation();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 150,
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('freightMarkup.columns.level'),
        headerAlign: 'left',
        align: 'left',
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ value }) => t(`freightMarkup.level.${value as FreightMarkupLevel}`),
      },
      {
        ...baseColumnProps,
        field: 'primaryText',
        headerName: t('freightMarkup.columns.primaryText'),
        align: 'left',
        renderCell: ({ row }) =>
          row.level === 'supplier'
            ? supplier.name
            : row.level === 'mgSupplierItem'
            ? row.mgSupplierItemFreight?.mgSupplierItem?.primaryText
            : row.productGroupFreight?.productGroup?.name,
      },
      {
        ...baseColumnProps,
        field: 'fromDate',
        headerName: t('freightMarkup.columns.fromDate'),
        renderCell: ({ value }) => dayjs(value).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'toDate',
        headerName: t('freightMarkup.columns.toDate'),
        renderCell: ({ value }) => dayjs(value).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'freightFactor',
        headerName: t('freightMarkup.columns.freightSurcharge'),
        width: 100,
        renderCell: ({ row }) =>
          row.mgSupplierItemFreight?.freightSurcharge
            ? `${row.mgSupplierItemFreight?.freightSurcharge?.value} per ${row.mgSupplierItemFreight?.freightSurcharge?.unit} `
            : `${
                row.supplierFreight?.freightFactor ||
                row.productGroupFreight?.freightFactor ||
                row.mgSupplierItemFreight?.freightFactor
              } %`,
      },
      {
        ...baseColumnProps,
        field: 'itemsAffected',
        headerName: t('freightMarkup.columns.itemsAffected'),
        width: 180,
        renderCell: ({ row }) => (
          <ItemsAffected
            mgSupplierId={supplier.id}
            markupId={row.id}
            openItemsAffectedModal={openItemsAffectedModal}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: t('freightMarkup.columns.actions'),
        renderCell: ({ row }) => (
          <TextButton
            startIcon={<Edit />}
            size="small"
            onClick={() => setFreightMarkupToEdit(row as FreightMarkup)}
          >
            {t('freightMarkup.actions.edit')}
          </TextButton>
        ),
      },
    ],
    [baseColumnProps, t, supplier, setFreightMarkupToEdit, openItemsAffectedModal]
  );

  return (
    <DataGridPro
      autoHeight
      getRowId={(row) => row.id}
      columns={columns}
      rows={freightMarkups}
      rowHeight={65}
      disableSelectionOnClick
      disableColumnMenu
      disableColumnResize
      disableColumnReorder
      hideFooter
    />
  );
};
