import { syncLocalStorageEffect } from '@retail/hooks';
import { atom, useRecoilState } from 'recoil';

export const IncludeVatAtom = atom<boolean>({
  key: 'includeVat',
  default: true,
  effects: [syncLocalStorageEffect<boolean>('includeVAT')],
});

export const useIncludeVat = () => {
  const [includeVat, setIncludeVat] = useRecoilState(IncludeVatAtom);
  const toggleIncludeVat = () => setIncludeVat((value) => !value);

  return { includeVat, toggleIncludeVat };
};
