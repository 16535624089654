import { useAuthContext, useFetchMe } from '@retail/auth/data-access';
import { PublicErrorPage } from '@retail/app/prisinnsikt-app';
import { BodyLoader } from '@retail/components';
import { PropsWithChildren, useMemo } from 'react';
import { Auth0Token, Context, MGNamespace, User } from '@retail/auth/types';
import { useUserOrgUnits } from '@retail/app/stores/selected-context';
import { LandingPage } from '../Landing';
import { NotAuthorizedPage } from '../NotAuthorized';
import { jwtDecode } from 'jwt-decode';

export enum AuthStatus {
  Loading = 'LOADING',
  NotAuthenticated = 'NOT_AUTHENTICATED',
  Authorized = 'Authorized',
  NotAuthorized = 'NOT_AUTHORIZED',
  Error = 'ERROR',
}

// Temporary use Prisinnsikt as access app due to PPS restrictions
const APP_KEY = 'prisinnsikt';

const hasAppAccess = (accessToken: string, user: User, userContexts: Context[]) => {
  const decoded = jwtDecode<Auth0Token>(accessToken);
  const tokenMeta = decoded[MGNamespace];

  return tokenMeta.apps.includes(APP_KEY) && (user.isSuperUser || userContexts.length > 0);
};

export function AuthContainerPage({ children }: PropsWithChildren) {
  const { isAuthenticated, isLoading: isAuth0Loading, token } = useAuthContext();

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useFetchMe({ suspense: false, enabled: !!token });
  const { data: userContexts, error: userContextError } = useUserOrgUnits({
    suspense: false,
  });

  const authStatus: AuthStatus = useMemo(() => {
    if (token && user && userContexts) {
      return hasAppAccess(token, user, userContexts)
        ? AuthStatus.Authorized
        : AuthStatus.NotAuthorized;
    }

    if (!isAuthenticated && !isAuth0Loading) {
      return AuthStatus.NotAuthenticated;
    }

    if (!user && !isUserLoading) {
      return AuthStatus.NotAuthorized;
    }

    if (userContextError || userError) {
      return AuthStatus.Error;
    }

    return AuthStatus.Loading;
  }, [
    token,
    user,
    userContexts,
    isAuthenticated,
    isAuth0Loading,
    isUserLoading,
    userContextError,
    userError,
  ]);

  switch (authStatus) {
    case AuthStatus.Error:
      return <PublicErrorPage />;
    case AuthStatus.Loading:
      return <BodyLoader />;
    case AuthStatus.NotAuthorized:
      return <NotAuthorizedPage />;
    case AuthStatus.NotAuthenticated:
      return <LandingPage />;
  }

  return children;
}
