import { Breakpoint, Container, Stack, StackProps } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { mgprisPageHeight } from '@shared/styles';
import { ErrorFallbackPage } from '../ErrorFallback';

interface Props extends StackProps {
  maxWidth: Breakpoint | 'full';
  fixedHeight?: boolean;
}

export function PageLayout({ maxWidth, fixedHeight, children, sx, ...stackProps }: Props) {
  if (maxWidth === 'full') {
    return (
      <Stack
        component="main"
        sx={{
          height: fixedHeight ? mgprisPageHeight : undefined,
          flexGrow: 1,
          py: ({ spacing }) => spacing(2),
          px: ({ spacing }) => spacing(2.5),
          bgcolor: ({ palette }) => palette.background.default,
          ...sx,
        }}
        {...stackProps}
      >
        {children}
      </Stack>
    );
  }

  return (
    // @ts-ignore
    <Container maxWidth={maxWidth} component="main" {...stackProps}>
      {children}
    </Container>
  );
}

export default withErrorBoundary(PageLayout, {
  fallback: (props) => <ErrorFallbackPage {...props} />,
});
