import { useAuthContext } from './AuthContext';
import { fetcher, QueryOptions, useMutateFetch, useQueryFetch } from '@shared/fetch-utils';
import { ContextCompetitorDTO, PostContextCompetitorDTO } from '@retail/retailer/types';
import { useCallback } from 'react';
import { useAppConfig } from "@retail/app/stores/app-config";

export const contextsRoute = '/v1/contexts';
export const getCompetitorsRoute = (contextId: number) =>
  `${contextsRoute}/${contextId}/competitors`;

async function fetchContextCompetitors({
  baseUrl,
  token,
  contextId,
}: {
  baseUrl: string;
  token: string;
  contextId: number;
}) {
  return fetcher<ContextCompetitorDTO>({
    url: `${baseUrl}${getCompetitorsRoute(contextId)}`,
    method: 'GET',
    token,
  });
}

export function useFetchContextCompetitors(contextId: number, queryOptions: QueryOptions = {}) {
  const { pps } = useAppConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () =>
      fetchContextCompetitors({
        baseUrl: pps.url,
        token,
        contextId,
      }),
    [pps.url, token, contextId]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['contextCompetitors', contextId],
    ...queryOptions,
  });
}

export function usePostContextCompetitors(contextId: number, queryOptions: QueryOptions = {}) {
  const { pps } = useAppConfig();
  const { token } = useAuthContext();

  return useMutateFetch<ContextCompetitorDTO, PostContextCompetitorDTO>({
    token,
    method: 'POST',
    url: `${pps.url}${getCompetitorsRoute(contextId)}`,
    ...queryOptions,
  });
}
