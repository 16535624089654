import { FinfoPrice, MgSupplierItemPackage } from '@retail/products/types';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItemPackagesTableRow } from './MgSupplierItemPackagesTableRow';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, Typography, styled, useTheme } from '@mui/material';
import { Paper, TagHeading } from '@shared/components';

interface Props {
  packages: MgSupplierItemPackage[];
  finfoPrice?: FinfoPrice;
}

export function MgSupplierItemPackagesTable({ packages, finfoPrice }: Props) {
  const t = useProductsTFunction();
  const { palette } = useTheme();
  const basePackage = packages.find(({ basePriceUnit, deleted }) => basePriceUnit && !deleted);
  const basePackageUnit = basePackage?.mgPackage?.vilmaUnit || basePackage?.vilmaUnit;

  return (
    <Paper sx={{ borderRadius: 3, m: 2, outline: `16px solid ${palette.background.secondary}` }}>
      <TableContainer>
        <TagHeading color="primary">
          <Typography variant="body1" fontWeight="bold">
            {t('products.mgItem.supplierItem.packages')}
          </Typography>
        </TagHeading>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('products.mgItem.supplierItem.gtin.label')}</TableCell>
              <TableCell>{t('products.mgItem.supplierItem.supplierUnit')}</TableCell>
              <TableCell>
                <Stack direction="row" gap="4px" alignItems="start">
                  {t('products.mgItem.supplierItem.mgUnit.label')}
                  <Tooltip arrow title={t('products.mgItem.supplierItem.mgUnit.description')}>
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </Stack>
              </TableCell>
              <TableCell>{t('products.mgItem.supplierItem.consistsOf')}</TableCell>
              <TableCell>{t('products.mgItem.supplierItem.packageType.label')}</TableCell>
              <TableCell align="right">
                {t('products.mgItem.supplierItem.finfoBasePrice')}
              </TableCell>
              <TableCell align="right">
                {t('products.mgItem.supplierItem.finfoRecommendedPrice')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& td p': {
                fontSize: 'inherit',
                lineHeight: 'inherit',
              },
            }}
          >
            {packages
              .sort((a, b) => a.calculatedCount - b.calculatedCount)
              .map((supplierItemPackage) => (
                <MgSupplierItemPackagesTableRow
                  {...supplierItemPackage}
                  finfoPrice={finfoPrice}
                  basePackageUnit={basePackageUnit}
                  key={supplierItemPackage.mgSupplierItemPackageId}
                />
              ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>
  );
}

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': { ...theme.typography.body2, fontWeight: 'bold' },
  },
}));

