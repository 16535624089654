import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { ItemForProductGroupDTO } from '@retail/purchase-agreements/types';
import { buildUrl } from '@shared/utils';

export const freightMarkupPath = '/freight-markup';
export const mgItemsForProductGroupPath = '/items';

interface SearchProps {
  mgSupplierId: number;
  productGroupIds?: string[];
  agreementIds?: number[];
  numberSearch?: number;
  textSearch?: string;
}

export function useFetchMgItemsForProductGroups(
  { mgSupplierId, productGroupIds, agreementIds, numberSearch, textSearch }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<ItemForProductGroupDTO[]>({
    method: 'GET',
    queryKey: [
      'productGroupItems',
      mgSupplierId,
      productGroupIds,
      agreementIds,
      numberSearch,
      textSearch,
    ],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${freightMarkupPath}/${mgItemsForProductGroupPath}`,
      searchParams: {
        mgSupplierId,
        productGroupIds,
        agreementIds,
        numberSearch,
        textSearch,
      },
    }),
    ...queryOptions,
  });
}
