import { MgSupplierItemSearchResultDTO } from '../../dto';
import { Bk04Varegruppe, MgParticipant } from '../finfo';

interface ConstructorProps {
  finfoNumber: number;
  mgSupplierItemId: number;
  mgParticipant: MgParticipant;
  mgItemNumber: number;
  bk04Varegruppe: Bk04Varegruppe;
  primaryText: string;
  finfoSupplierId?: number;
  secondaryText?: string;
}

export class MgSupplierItemSearchResult {
  finfoNumber: number;
  mgSupplierItemId: number;
  mgParticipant: MgParticipant;
  mgItemNumber: number;
  bk04Varegruppe: Bk04Varegruppe;
  primaryText: string;
  finfoSupplierId?: number;
  secondaryText?: string;

  constructor({
    finfoNumber,
    mgSupplierItemId,
    mgParticipant,
    mgItemNumber,
    bk04Varegruppe,
    primaryText,
    finfoSupplierId,
    secondaryText,
  }: ConstructorProps) {
    this.finfoNumber = finfoNumber;
    this.mgSupplierItemId = mgSupplierItemId;
    this.mgParticipant = mgParticipant;
    this.mgItemNumber = mgItemNumber;
    this.bk04Varegruppe = bk04Varegruppe;
    this.primaryText = primaryText;
    this.finfoSupplierId = finfoSupplierId;
    this.secondaryText = secondaryText;
  }

  static fromSearchResultDTOs = (
    searchResultDtos: MgSupplierItemSearchResultDTO[],
    mgParticipant: MgParticipant[],
    bk04Varegrupper: Bk04Varegruppe[]
  ): MgSupplierItemSearchResult[] =>
    searchResultDtos.map(
      (dto) =>
        new MgSupplierItemSearchResult({
          finfoNumber: dto.finfoNumber,
          mgSupplierItemId: dto.mgSupplierItemId,
          mgParticipant:
            mgParticipant.find(({ id }) => id === dto.mgParticipantAccountId) ||
            new MgParticipant(dto.mgParticipantAccountId, 'NO_NAME'),
          mgItemNumber: dto.mgItemNumber,
          bk04Varegruppe:
            bk04Varegrupper.find(({ groupNumber }) => dto.bk04ProductGroup === groupNumber) ||
            new Bk04Varegruppe(-1, dto.bk04ProductGroup, undefined),
          primaryText: dto.primaryText,
          finfoSupplierId: dto.finfoSupplierId,
          secondaryText: dto.secondaryText,
        })
    );
}

interface MinimalConstructorProps {
  finfoNumber: number;
  mgItemNumber: number;
  bk04VaregruppeId: string;
  mgSupplierItemId: number;
  primaryText: string;
  finfoSupplierId?: number;
  secondaryText?: string;
}

export class MgSupplierItemSearchResultMinimal {
  finfoNumber: number;
  mgItemNumber: number;
  bk04VaregruppeId: string;
  mgSupplierItemId: number;
  primaryText: string;
  finfoSupplierId?: number;
  secondaryText?: string;

  constructor({
    finfoNumber,
    mgItemNumber,
    bk04VaregruppeId,
    mgSupplierItemId,
    primaryText,
    finfoSupplierId,
    secondaryText,
  }: MinimalConstructorProps) {
    this.finfoNumber = finfoNumber;
    this.mgItemNumber = mgItemNumber;
    this.bk04VaregruppeId = bk04VaregruppeId;
    this.mgSupplierItemId = mgSupplierItemId;
    this.primaryText = primaryText;
    this.finfoSupplierId = finfoSupplierId;
    this.secondaryText = secondaryText;
  }

  static fromDTO = (
    searchResultDtos: MgSupplierItemSearchResultDTO[]
  ): MgSupplierItemSearchResultMinimal[] =>
    searchResultDtos.map(
      (dto) =>
        new MgSupplierItemSearchResultMinimal({
          finfoNumber: dto.finfoNumber,
          mgItemNumber: dto.mgItemNumber,
          bk04VaregruppeId: dto.bk04ProductGroup,
          mgSupplierItemId: dto.mgSupplierItemId,
          primaryText: dto.primaryText,
          finfoSupplierId: dto.finfoSupplierId,
          secondaryText: dto.secondaryText,
        })
    );
}
