import { atom, useRecoilState } from 'recoil';
import { AssortmentBase } from '@retail/assortment/types';
import { useAsideDisclosure } from '../../../stores';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

export const SelectedAssortmentAtom = atom<AssortmentBase | null>({
  key: 'selectedAssortment',
  default: null,
});

export const ASSORTMENT_SEARCH_PARAMS_KEY = 'assortment';

interface Props {
  assortments: AssortmentBase[];
  onChange?: (assortment: AssortmentBase | null) => void;
}

export const useSelectedAssortment = ({ assortments, onChange }: Props) => {
  const [selectedAssortment, setSelectedAssortment] = useRecoilState(SelectedAssortmentAtom);
  const { onOpen, onClose } = useAsideDisclosure();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSelectAssortment = useCallback(
    (assortment: AssortmentBase | null) => {
      const searchParams = new URLSearchParams(location.search);
      if (assortment === null) {
        searchParams.delete(ASSORTMENT_SEARCH_PARAMS_KEY);
        onClose();
      } else {
        searchParams.set(ASSORTMENT_SEARCH_PARAMS_KEY, `${assortment.id}`);
        onOpen();
      }
      setSelectedAssortment(assortment);
      onChange && onChange(assortment);

      if (location.search !== searchParams.get(ASSORTMENT_SEARCH_PARAMS_KEY)) {
        navigate({
          search: `?${searchParams.toString()}`,
        });
      }
    },
    [location.search, setSelectedAssortment, onChange, onClose, onOpen, navigate]
  );

  useEffect(() => {
    const s = new URLSearchParams(location.search);
    const assortmentId = s.get('assortment');
    if (assortmentId !== null) {
      const asid = +assortmentId;
      if (!selectedAssortment || (!!selectedAssortment && asid !== selectedAssortment.id)) {
        const hasAssortment = assortments.filter((a) => a.id === asid);
        if (hasAssortment.length > 0) {
          setSelectedAssortment(hasAssortment[0]);
          onOpen();
        }
      } else {
        // User has selected assortment already
        onOpen();
      }
    } else {
      // if user clicks back etc.
      if (selectedAssortment !== null) {
        setSelectedAssortment(null);
        onClose();
      }
    }
  }, [location, assortments, setSelectedAssortment, selectedAssortment, onClose, onOpen]);

  return { selectedAssortment, selectAssortment: handleSelectAssortment };
};
