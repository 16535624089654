import { LoadingErrorProps } from './LoadingErrorComponent';
import { useAppTFunction } from '@retail/app/i18n';
import { IconButton, Stack, Typography } from '@mui/material';
import { Cached } from '@mui/icons-material';

export function DefaultPpsErrorFallback({ reload, error }: LoadingErrorProps) {
  const t = useAppTFunction();

  return (
    <Stack alignItems="center" spacing={2}>
      <Typography variant="h1" component="p" sx={{ color: ({ palette }) => palette.warning.main }}>
        {t('common.error.fetch.oops')}
      </Typography>
      <Typography variant="body1">{t('common.error.fetch.content')}</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body1">{t('common.error.fetch.message')}:</Typography>
        <code>{error.error}</code>
      </Stack>
      {reload && (
        <>
          <IconButton onClick={reload}>
            <Cached sx={{ fontSize: '62px', transform: 'scaleX(-1) rotate(45deg)' }} />
          </IconButton>
          <Typography variant="body1">{t('common.error.fetch.tryReloading')}</Typography>
        </>
      )}
    </Stack>
  );
}
