import { AtomEffect } from 'recoil';

export const syncLocalStorageEffect =
  <T>(storageKey: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(storageKey);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(storageKey)
        : localStorage.setItem(storageKey, JSON.stringify(newValue));
    });
  };
