import { MgParticipantsMinimalDTO } from '@retail/products/types';
import { MgSupplierItemsPageDTO } from '@retail/purchase-agreements/types';

export const freightMarkupItemsOnlyTotal: MgSupplierItemsPageDTO = {
  page: 0,
  pageSize: 0,
  totalCount: 2,
  totalPages: 0,
  result: [],
};

export const freightMarkupItems: MgSupplierItemsPageDTO = {
  page: 0,
  pageSize: 25,
  totalCount: 2,
  totalPages: 1,
  result: [
    {
      primaryText: 'SÄTTLAGER STENFLIS SÄCK 2-6MM',
      finfoNumber: 6851142,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
    {
      primaryText: 'STENKROSS GRAFIT/VIT 16-32MM',
      finfoNumber: 5143991,
      bk04ProductGroup: '',
      mgItemNumber: 1,
      mgSupplierItemId: 1,
      mgParticipantAccountId: 1,
    },
  ],
};

export const testMgParticipants: MgParticipantsMinimalDTO[] = [
  {
    mgParticipantAccountId: 300258,
    participantAccountName: 'Perssons Träteknik AB',
  },
  {
    mgParticipantAccountId: 300179,
    participantAccountName: 'Kami AB',
  },
  {
    mgParticipantAccountId: 300337,
    participantAccountName: 'Techtronic Industries Nordic Aps',
  },
  {
    mgParticipantAccountId: 300061,
    participantAccountName: 'Componenta Wood AB',
  },
];
