import { Divider, List, Stack, Typography } from "@mui/material";
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { MgAssortmentDTO } from '@retail/assortment/types';

import { AssortmentListItem } from './AssortmentListItem';

interface Props {
  assortments: MgAssortmentDTO[];
  linkTo: (assortmentId: number) => string;
  contextId: number;
  brandLogoUri: string;
}

export const AssortmentsList: React.FC<Props> = ({
  assortments,
  linkTo,
  contextId,
  brandLogoUri,
}) => {
  const t = useAssortmentTFunction();
  const noAssormentsCreated = !assortments || assortments.length === 0;
  if (noAssormentsCreated) {
    return <Typography>{t('assortment.assortmentsOverview.noAssortments')}</Typography>;
  }

  return (
    <Stack component={List} pt={2} divider={<Divider sx={{ mx: 1 }} />}>
      {assortments.map((assortment) => {
        return (
          <AssortmentListItem
            key={assortment.id}
            assortment={assortment}
            linkTo={linkTo}
            contextId={contextId}
            brandLogoUri={brandLogoUri}
          />
        );
      })}
    </Stack>
  );
};
