import {
  CompetitorPriceResponseDTO,
  LeverandorDTO,
  MgProductPriceDTO,
  PakningDTO,
  PriceLeverandorDTO,
} from '@retail/products/types';
import { getCurrentPrice } from '@retail/products/utils';
import { SupplierView } from './ProductViewTypes';

type ProductListLookUp = {
  [key: number]: CompetitorPriceResponseDTO;
};

export function createCompetitorPriceMap(prices: CompetitorPriceResponseDTO[]): ProductListLookUp {
  return prices.reduce((prev, curr) => {
    prev[curr.nobbNr] = curr;
    return prev;
  }, {} as ProductListLookUp);
}

interface MapProps {
  nobbProductPrice: MgProductPriceDTO | undefined;
  mgProductPrice: MgProductPriceDTO | undefined;
  omniumProductPrice: MgProductPriceDTO | undefined;
  campaignProductPrice: MgProductPriceDTO | undefined;
  productSuppliers: LeverandorDTO[];
}

export const mapToSupplierView = ({
  nobbProductPrice,
  mgProductPrice,
  omniumProductPrice,
  campaignProductPrice,
  productSuppliers,
}: MapProps): SupplierView[] => {
  const suppliersWithDuplicates = [
    ...(nobbProductPrice?.leverandorer || []),
    ...(mgProductPrice?.leverandorer || []),
    ...(omniumProductPrice?.leverandorer || []),
    ...(campaignProductPrice?.leverandorer || []),
  ];
  const deltakernumre = Array.from(
    new Set(
      suppliersWithDuplicates
        .filter(({ deltaker }) => !!deltaker)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(({ deltaker }) => deltaker!.deltagerNr)
    )
  );

  return deltakernumre.map((deltakernummer) => {
    const supplier = suppliersWithDuplicates.find(
      (supplier) => supplier.deltaker?.deltagerNr === deltakernummer
    ) as PriceLeverandorDTO;
    const productSupplier = productSuppliers.find(
      (productSupplier) => productSupplier.deltaker.deltagerNr === supplier.deltaker?.deltagerNr
    );
    const packagesWithoutDuplicates = filterOutPackageDuplicates(productSupplier?.pakninger || []);

    const currentMgPrice = getCurrentPrice(
      mgProductPrice?.leverandorer?.find((lev) => lev.deltaker?.deltagerNr === deltakernummer)
        ?.mgPriser || []
    );
    const currentOmniumPrice = getCurrentPrice(
      omniumProductPrice?.leverandorer?.find((lev) => lev.deltaker?.deltagerNr === deltakernummer)
        ?.ecomPriser || []
    );
    const currentCampaignPrice = getCurrentPrice(
      campaignProductPrice?.leverandorer?.find((lev) => lev.deltaker?.deltagerNr === deltakernummer)
        ?.campaignPriser || []
    );
    const supplierView: SupplierView = {
      supplierInfo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: supplier.deltaker!.deltagerNr,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        name: supplier.deltaker!.markedsnavn,
      },
      nobbPrices:
        nobbProductPrice?.leverandorer?.find((lev) => lev.deltaker?.deltagerNr === deltakernummer)
          ?.nobbPriser || [],
      mgPrice: currentMgPrice,
      omniumPrice: currentOmniumPrice,
      campaignPrice: currentCampaignPrice,
      packages: packagesWithoutDuplicates,
    };
    return supplierView;
  });
};

export const filterOutPackageDuplicates = (packages: PakningDTO[]): PakningDTO[] =>
  packages.reduce((tempPackages, pack) => {
    const duplicate = tempPackages.find((tempP) => tempP.pakningsEnhet === pack.pakningsEnhet);

    if (duplicate) {
      if (new Date(duplicate.updated) > new Date(pack.updated)) {
        return tempPackages;
      }
      return tempPackages
        .filter((tempP) => tempP.pakningsEnhet !== pack.pakningsEnhet)
        .concat(pack);
    }
    return tempPackages.concat(pack);
  }, [] as PakningDTO[]);
