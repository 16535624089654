import { useFetchMgAssortments } from '@retail/assortment/data-access';
import { useCallback, useMemo } from 'react';
import { AssortmentBase, MgAssortmentBase } from '@retail/assortment/types';
import { MgAssortmentContainer } from '@retail/assortment/containers';
import {
  BodyLoader,
  ErrorFallbackPage,
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
  TagBreadcrumbs,
} from '@retail/components';
import { useContexts, useUserBrands } from '@retail/app/stores/selected-context';
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { Chip, OutlinedButton, Paper } from '@shared/components';
import { AssortmentPicker, CentralAssortmentLogo } from '@retail/assortment/components';
import { Box, Stack, useTheme } from '@mui/material';

export function MgAssortmentPage() {
  const { selectedBrand } = useUserBrands();
  const { palette } = useTheme();
  const { assortmentId } = useParams<{
    assortmentId: string;
  }>();
  const { selectedContext } = useContexts();
  const t = useAppTFunction();
  const { data: mgAssortmentDtos = [] } = useFetchMgAssortments();
  const selectedAssortment = mgAssortmentDtos.find(
    (assortment) => assortment.id === Number(assortmentId)
  );

  const otherAssortments = useMemo(
    () =>
      mgAssortmentDtos
        .filter((assortment) => assortment.id !== Number(assortmentId))
        .map(MgAssortmentBase.fromDto),
    [mgAssortmentDtos, assortmentId]
  );
  const navigate = useNavigate();

  const selectAssortment = useCallback(
    (assortment: AssortmentBase | null) => {
      if (assortment) {
        navigate(
          mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(assortment.id + '')
        );
      }
    },
    [navigate]
  );

  const competitorsPageMatch = useMatch(mgPrisRoutes.marketInsight.competitors.fullRoutePath);

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Paper paddingY="dense" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', pb: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" gap={1}>
            <TagBreadcrumbs
              items={[
                {
                  label: t('assortment.marketInsight'),
                  href: mgPrisRoutes.marketInsight.getFullLinkPath(),
                },
                {
                  label: selectedAssortment?.title || assortmentId + '',
                },
              ]}
            />
            <Stack marginLeft={-2}>
              {selectedContext.id !== selectedAssortment?.contextId && selectedAssortment && (
                <CentralAssortmentLogo selectedBrandLogo={selectedBrand.circularLogo} />
              )}
            </Stack>
            <Chip
              label={t('assortment.items', { count: selectedAssortment?.size })}
              backgroundColor={palette.secondary[400]}
              sx={{ cursor: 'inherit' }}
            />
          </Stack>
          <Stack gap={1.5} flexDirection="row" alignItems="center">
            {!!otherAssortments.length && selectedAssortment && (
              <AssortmentPicker
                assortments={otherAssortments}
                selectedAssortment={null}
                selectAssortment={selectAssortment}
                width={260}
                groupByType={false}
                alternativeLabel={t('assortment.changeAssortment')}
              />
            )}
            <OutlinedButton
              component={Link}
              to={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
              disabled={!!competitorsPageMatch}
              sx={{ whiteSpace: 'nowrap', height: '40px' }}
              size="small"
            >
              {t('products.marketInsight.competitors.heading')}
            </OutlinedButton>
          </Stack>
        </Box>
        <SuspenseWithSentryErrorBoundary
          errorFallback={(props) => <ErrorFallbackPage {...props} />}
          suspenseFallback={<BodyLoader />}
        >
          {selectedAssortment ? (
            <MgAssortmentContainer selectedAssortmentId={Number(assortmentId)} />
          ) : (
            <NotFoundError
              title={t('assortment.mgAssortment.notFound.title')}
              subtitle={t('assortment.mgAssortment.notFound.subtitle', { assortmentId })}
              buttonText={t('assortment.mgAssortment.notFound.goBack')}
              navigateTo={mgPrisRoutes.marketInsight.fullRoutePath}
            />
          )}
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
