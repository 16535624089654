import { IconButton, Tooltip } from '@mui/material';
import { usePurchaseAgreementsSearchParams } from '@retail/purchase-agreements/hooks';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { AgreementStatus, InheritedLevel } from '@retail/purchase-agreements/types';
import { CheckIcon, InheritedCheckIcon, RedCrossIcon } from '@shared/custom-icons';

type Props = {
  status: AgreementStatus;
  inheritedLevel?: InheritedLevel;
};

export const AgreementStatusIcon = ({ status, inheritedLevel }: Props) => {
  const { t } = usePurchaseAgreementsTranslation();
  const { selectAgreementLevel } = usePurchaseAgreementsSearchParams();

  if (status === 'inactive') {
    return <RedCrossIcon fontSize="small" />;
  }

  if (status === 'active') {
    return <CheckIcon fontSize="small" color="#83BF6E" />;
  }

  const navigateToLevel = () => {
    if (!inheritedLevel) return;

    selectAgreementLevel({
      id: inheritedLevel.id,
      level: inheritedLevel.level,
    });
  };

  return (
    <Tooltip title={`${t('purchaseAgreements.agreement.inheritedFrom')} ${inheritedLevel?.name}`}>
      <IconButton onClick={navigateToLevel}>
        <InheritedCheckIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
