import { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useAppTranslation } from '@retail/app/i18n';
import { User } from '@retail/auth/types';
import { CopyToClipboard } from '@retail/components';

interface Props {
  superUsers: User[];
}

export function SuperUsersTable({ superUsers }: Props) {
  const { t } = useAppTranslation();

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'givenName', headerName: t('admin.superusers.firstName'), width: 200 },
      { field: 'familyName', headerName: t('admin.superusers.lastName'), width: 200 },
      { field: 'email', headerName: t('admin.superusers.email'), width: 300 },
      {
        field: 'id',
        headerName: t('admin.superusers.id'),
        width: 430,
        renderCell: (params) => (
          <CopyToClipboard value={params.value}>{params.value}</CopyToClipboard>
        ),
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      superUsers?.map((user) => ({
        givenName: user.givenName,
        familyName: user.familyName,
        email: user.email,
        id: user.userId,
      })),
    [superUsers]
  );

  return (
    <Box width="100%">
      <DataGridPro
        autoHeight
        hideFooterRowCount
        columns={columns}
        rows={rows}
        pageSize={10}
        isRowSelectable={() => false}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </Box>
  );
}
