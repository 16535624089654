import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const DisconnectOmnuimIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="35" height="23" viewBox="0 0 35 23">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2436 0.0769717H7.9534C5.51418 0.0769717 0.712125 2.62285 0.407229 7.92804C0.02611 14.5595 4.52331 18.3707 7.9534 18.7518C11.3835 19.133 14.966 18.6756 16.9478 12.8826C17.4675 11.3636 17.8142 9.92851 18.1254 8.64045C19.001 5.01612 19.5953 2.55611 22.9695 2.66859C26.2486 2.77789 28.2738 4.45062 29.1574 6.65573C30.3383 6.92983 31.4286 7.44016 32.3758 8.13424C32.1162 2.05661 26.1308 0.219588 22.9695 0.0769717C20.8098 -0.0754756 17.0174 1.04622 15.8807 6.25111C14.4324 12.8826 13.5177 16.1602 9.78277 16.1602C5.51418 16.1602 3.07507 12.6127 3.07507 9.45252C3.07507 6.23663 5.13311 2.66859 9.78277 2.66859H15.8807C16.1856 2.10961 17.085 0.808718 18.2436 0.0769717ZM19.062 18.898C18.6856 18.0579 18.4364 17.1484 18.3387 16.194C17.6414 16.1932 17.0324 16.1843 16.5667 16.1602C16.0077 16.8971 14.7983 18.4469 14.4324 18.7518C14.2182 18.9304 16.4672 18.9434 19.062 18.898Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2155 15.2816C34.2155 19.1853 31.051 22.3499 27.1473 22.3499C23.2436 22.3499 20.0791 19.1853 20.0791 15.2816C20.0791 11.378 23.2436 8.21343 27.1473 8.21343C31.051 8.21343 34.2155 11.378 34.2155 15.2816ZM23.6677 14.2817C23.1154 14.2817 22.6677 14.7294 22.6677 15.2817C22.6677 15.8339 23.1154 16.2817 23.6677 16.2817H30.6269C31.1792 16.2817 31.6269 15.8339 31.6269 15.2817C31.6269 14.7294 31.1792 14.2817 30.6269 14.2817H23.6677Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DisconnectOmnuimIcon;
