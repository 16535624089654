type Primitive = string | number | boolean;

interface Props {
  base: string;
  route?: string;
  searchParams?: { [key: string]: Primitive | Primitive[] | undefined };
}

export const buildUrl = ({ base, route = '', searchParams = {} }: Props) => {
  const url = new URL(route, base);

  Object.entries(searchParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        url.searchParams.append(key, value.join(','));
      }
    } else {
      if (value !== undefined && value !== null) {
        url.searchParams.append(key, value + '');
      }
    }
  });

  return url.toString();
};
