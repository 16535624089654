import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { MgParticipant } from '@retail/products/types';

interface Props {
  supplier: MgParticipant;
  isAdmin: boolean;
  isAFavoriteSupplier: (supplierId: number) => boolean;
  selectSupplier: (supplier: MgParticipant | null) => void;
  editFavoriteSuppliers: (supplier: MgParticipant) => void;
}

export function Supplier({
  supplier,
  isAdmin,
  isAFavoriteSupplier,
  selectSupplier,
  editFavoriteSuppliers,
}: Props) {
  const { palette } = useTheme();
  const t = useAppTFunction();
  const favorite = isAFavoriteSupplier(supplier.id);

  return (
    <Stack
      component={Button}
      onClick={() => selectSupplier(supplier)}
      borderRadius={0}
      border={0}
      px={3}
      py={2}
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        outline: `1px solid ${palette.divider}`,
        '&:hover': {
          border: 0,
          zIndex: 1,
          outline: `1px solid ${palette.primary.light}`,
          bgcolor: 'inherit',
        },
      }}
    >
      <Typography color="text.primary" variant="h6">
        {supplier.name}
      </Typography>
      {isAdmin ? (
        <IconButton
          sx={{
            p: 0,
            '&.MuiButtonBase-root': {
              '.MuiSvgIcon-root': {
                ':hover': {
                  background: palette.common.white,
                  color: favorite ? palette.warning[400] : 'red',
                },
              },
            },
          }}
          onClick={(e) => {
            editFavoriteSuppliers(supplier);
            e.stopPropagation();
          }}
        >
          {favorite ? (
            <Tooltip arrow placement="right" title={t('common.supplierSelect.remove')}>
              <Favorite color="warning" />
            </Tooltip>
          ) : (
            <Tooltip arrow placement="right" title={t('common.supplierSelect.add')}>
              <FavoriteBorder color="warning" />
            </Tooltip>
          )}
        </IconButton>
      ) : (
        favorite && <Favorite color="warning" />
      )}
    </Stack>
  );
}
