import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { MgSupplierItemsDialog } from '@retail/purchase-agreements/components';
import { useAffectedItems } from '@retail/purchase-agreements/context';

interface Props {
  open: boolean;
  onClose: () => void;
  supplierId: number;
  productGroupId?: string;
}

function MgSupplierItemsDialogContainer({ open, onClose, supplierId, productGroupId }: Props) {
  const { result, setPage, setPageSize } = useAffectedItems({
    mgSupplierId: supplierId,
    productGroupId,
    initPageSize: 25,
  });

  return (
    <MgSupplierItemsDialog
      affectedItems={result}
      open={open}
      onClose={onClose}
      updatePage={setPage}
      updatePageSize={setPageSize}
    />
  );
}

export default withDialogSuspenseErrorBoundary(MgSupplierItemsDialogContainer);
