import { Stack, Typography } from '@mui/material';
import {
  ActiveItemAgreement,
  AgreementStatusIcon,
  ItemAgreementsTable,
} from '@retail/purchase-agreements/components';
import { useItemAgreements } from '@retail/purchase-agreements/context';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { useSelectedAgreementsForSupplier } from '@retail/purchase-agreements/stores';
import { Paper } from '@shared/components';

import { useMemo } from 'react';

type Props = {
  supplierId: number;
  mgItemNr: number;
};

export const ItemAgreements = ({ supplierId, mgItemNr }: Props) => {
  const t = usePurchaseAgreementsTFunction();
  const { selectedAgreements } = useSelectedAgreementsForSupplier(supplierId);
  const { itemAgreements, addAgreementChanges } = useItemAgreements({
    mgItemNr,
    supplierId: supplierId,
    selectedAgreements,
  });

  const activeAgreement = useMemo(
    () => itemAgreements.agreements.find((agreement) => agreement.status !== 'inactive'),
    [itemAgreements.agreements]
  );
  const agreements = useMemo(
    () => itemAgreements.agreements.filter((agreement) => agreement.status === 'inactive'),
    [itemAgreements.agreements]
  );

  return (
    <Stack width="100%" flexGrow={1} gap={3}>
      {activeAgreement && (
        <Stack gap={1}>
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography variant="h5">
              {t('purchaseAgreements.agreementsFor')} {itemAgreements.primaryText}
            </Typography>
            <AgreementStatusIcon
              status={activeAgreement.status}
              inheritedLevel={activeAgreement.inheritedLevel}
            />
          </Stack>

          <ActiveItemAgreement
            activeAgreement={activeAgreement}
            mgItemNr={itemAgreements.mgItemId}
          />
        </Stack>
      )}

      <Paper paddingY="dense" variant="outlined" sx={{ overflow: 'auto', p: 0 }}>
        <ItemAgreementsTable
          agreements={agreements}
          agreementIdAwaitingConfirmation={itemAgreements.awaitingConfirmationAgreementId}
          submitForConfirmation={addAgreementChanges}
        />
      </Paper>
    </Stack>
  );
};
