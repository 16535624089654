import { Flex } from '../Flex';
import { PropsWithChildren } from 'react';

function PublicHeader({ children }: PropsWithChildren) {
  return (
    <Flex
      bgcolor="white"
      height="80px"
      justifyContent="center"
      alignItems="center"
      component="header"
    >
      {children}
    </Flex>
  );
}

export default PublicHeader;
