import React, { useState } from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  src: string;
  alt?: string;
  fallback?: () => JSX.Element | null;
}

export const Image = React.forwardRef(({ fallback, ...props }: Props, ref) => {
  const [hasError, setHasError] = useState(false);
  if (hasError && fallback) {
    return fallback();
  }

  return (
    <Box
      component="img"
      onError={() => {
        setHasError(true);
      }}
      ref={ref}
      {...props}
    />
  );
});
