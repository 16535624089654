import { createFreightMarkupSchema } from '@retail/freight-markup/components';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { UniversalFormProvider } from '@shared/components';
import CreateFreightMarkupDialog from './CreateFreightMarkupDialog';

interface Props {
  supplierId: number;
  closeCreateDialog: () => void;
}

export function CreateFreightMarkupContainer({ supplierId, closeCreateDialog }: Props) {
  const t = useFreightMarkupTFunction();

  return (
    <UniversalFormProvider
      schema={createFreightMarkupSchema(t)}
      defaultValues={createFreightMarkupSchema(t).getDefault()}
    >
      <CreateFreightMarkupDialog supplierId={supplierId} onClose={closeCreateDialog} open={true} />
    </UniversalFormProvider>
  );
}
