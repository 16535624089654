// Sorts an object array alphabetically based on object key of choice, provided as a string prop
export function alphabeticSort<T>(prop: keyof T) {
  return function (a: T, b: T) {
    if (a[prop] < b[prop]) {
      return -1;
    }
    if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  };
}

export function alphabeticDescSort<T>(prop: keyof T) {
  return function (a: T, b: T) {
    if (a[prop] < b[prop]) {
      return 1;
    }
    if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

export function sortByHigherPropValue<T>(prop: keyof T) {
  return function (a: T, b: T) {
    return b[prop] > a[prop];
  };
}

export function sortByLowerPropValue<T>(prop: keyof T) {
  return function (a: T, b: T) {
    if (a[prop] || b[prop]) {
      if (a[prop] && b[prop] && a[prop] > b[prop]) {
        return 1;
      }
      return a[prop] ? -1 : 1;
    }
    return 0;
  };
}


