import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { NobbProductDTO } from '@retail/products/types';
import { useAppTFunction } from '@retail/app/i18n';
import { CopyToClipboard } from '@retail/components';

const ProductInfo = ({ p }: { p: NobbProductDTO }) => {
  const lev = p.leverandorer.find((l) => l.medier.length > 0);
  const media = lev && lev.medier.find((m) => m.type === 'PB');
  const imgUrl = (media && media.url) || 'https://via.placeholder.com/100';
  const t = useAppTFunction();

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box>
        <img src={imgUrl} height={'250px'} alt={p.langVaretekst} />
      </Box>
      <Stack>
        <Typography fontSize={28} fontWeight={'bold'}>
          {p.varetekst1}
        </Typography>
        <CopyToClipboard value={p.nobbNr} copiedText={t('products.nobb.copied')}>
          <Typography fontSize={20} fontWeight={'bold'} color={({ palette }) => palette.grey[700]}>
            {` ${t('products.nobb.number')}: ${p.nobbNr}`}
          </Typography>
        </CopyToClipboard>
      </Stack>
    </Stack>
  );
};

export default React.memo(ProductInfo);
