import { useEffect, ComponentType, PropsWithChildren, FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context, User } from '@retail/auth/types';
import { useSelectedContext } from '../stores/userContexts';
import { useFetchMe } from '../api';
import { routes } from '../config';

type GuardFunc = (me: User, selectedContext: Context) => boolean;

export interface GuardProps {
  readonly redirectUrl?: string;
  readonly guards?: GuardFunc[];
}

export const isSuperAdmin: GuardFunc = (me: User) => {
  return !!me.isSuperUser;
};
export const isContextAdmin: GuardFunc = (me: User, selectedContext: Context): boolean => {
  return isSuperAdmin(me, selectedContext) || selectedContext.isAdmin;
};

const guard =
  ({ redirectUrl = routes.admin.path, guards = [] }: GuardProps) =>
  (WrappedComponent: ComponentType) => {
    const Guard: FC<PropsWithChildren<any>> = (props) => {
      const selectedContext = useSelectedContext();
      const { data: me } = useFetchMe({ refetchOnWindowFocus: false });
      const navigate = useNavigate();

      const allowed = useMemo(() => {
        if (me !== undefined && selectedContext !== undefined) {
          for (const g of guards) {
            if (g(me, selectedContext) === false) {
              return false;
            }
          }
          return true;
        }
        return false;
      }, [me, selectedContext]);

      useEffect(() => {
        if (!allowed) {
          navigate(redirectUrl, { replace: true });
        }
      }, [allowed, navigate]);

      return allowed ? <WrappedComponent {...props} /> : null;
    };

    return Guard;
  };
export default guard;
