import { useState, useMemo } from 'react';
import { Stack, Box, Container, Tabs, Tab } from '@mui/material';
import ProductInfo from './ProductInfo';
import { useParams } from 'react-router-dom';
import NobbPriceTable from './NobbPriceTable';
import { ErrorProduct } from './components/ErrorProduct';
import { LoadingProduct } from './components/LoadingProduct';
import HistoricNobbPriceChart from './components/HistoricNobbPriceChart';
import ProductWithVariantsCompetitorPrices from './ProductWithVariantsCompetitorPrices';
import TabPanel from './TabPanel';
import ProductPagePaper from './components/ProductPagePaper';
import HistoricCompetitorPricesContainer from './HistoricCompetitorPricesContainer';
import { useProductApi } from '../../api';
import { NobbProductDTO } from '@retail/products/types';
import { withSuspense } from '../../decorators';

enum TabOption {
  prices,
  historicPrices,
}

const getTabId = (tabOption: TabOption) => `prices-tab-${tabOption}`;
const getTabPanelId = (tabOption: TabOption) => `prices-tabpanel-${tabOption}`;

function ProductPage() {
  const { productId } = useParams<{ productId: string }>();
  const { data } = useProductApi({ id: Number(productId) });
  const product = data as NobbProductDTO;
  const [selectedTab, setSelectedTab] = useState<TabOption>(TabOption.prices);
  const pricesTabIds = useMemo(
    () => ({
      tabId: getTabId(TabOption.prices),
      tabPanelId: getTabPanelId(TabOption.prices),
    }),
    []
  );
  const historicPricesTabIds = useMemo(
    () => ({
      tabId: getTabId(TabOption.historicPrices),
      tabPanelId: getTabPanelId(TabOption.historicPrices),
    }),
    []
  );

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Container maxWidth="xl">
        <Stack height={'100%'} width={'100%'}>
          <Box mb={2}>
            <ProductInfo p={product} />
          </Box>
          <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab label="Priser" id={pricesTabIds.tabId} aria-controls={pricesTabIds.tabPanelId} />
            <Tab
              label="Historiske priser"
              id={historicPricesTabIds.tabId}
              aria-controls={historicPricesTabIds.tabPanelId}
            />
          </Tabs>
        </Stack>
      </Container>
      <Box bgcolor="#f5f5f5" flexGrow="1" pb={2}>
        <Container maxWidth="3xl" sx={{ pt: 1 }}>
          <TabPanel
            id={pricesTabIds.tabPanelId}
            show={selectedTab === TabOption.prices}
            ariaLabelledby={pricesTabIds.tabId}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
          >
            <ProductPagePaper sx={{ flex: '1 1 500px' }}>
              <NobbPriceTable product={product} />
            </ProductPagePaper>
            <ProductPagePaper sx={{ flex: '10 1 700px' }}>
              <ProductWithVariantsCompetitorPrices product={product} />
            </ProductPagePaper>
          </TabPanel>
          <TabPanel
            id={historicPricesTabIds.tabPanelId}
            show={selectedTab === TabOption.historicPrices}
            ariaLabelledby={historicPricesTabIds.tabId}
          >
            <ProductPagePaper>
              <HistoricNobbPriceChart product={product} />
            </ProductPagePaper>
            <HistoricCompetitorPricesContainer product={product} />
          </TabPanel>
        </Container>
      </Box>
    </Box>
  );
}

export default withSuspense({
  Component: ProductPage,
  errorFallback: () => <ErrorProduct />,
  fallback: () => <LoadingProduct />,
});
