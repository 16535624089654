import { Context, User } from '@retail/auth/types';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useAuthContext } from './AuthContext';
import { useAppConfig } from '@retail/app/stores/app-config';
import { useMemo } from 'react';
import { buildUrl } from '@shared/utils';

export const meRoute = '/v1/users/me';
export const meContextsRoute = '/v1/users/me/contexts';

export function useFetchMe(queryOptions: QueryOptions = {}) {
  const { pps } = useAppConfig();
  const { token } = useAuthContext();

  return useFetch<User>({
    method: 'GET',
    queryKey: ['me'],
    url: pps.url + meRoute,
    token,
    ...queryOptions,
  });
}

interface FetchMeContextProps {
  token: string;
  brands?: string[];
  countryCodes?: string[];
}

export function useFetchMeContexts(
  { brands = [], countryCodes = [], token }: FetchMeContextProps,
  queryOptions: QueryOptions = {}
) {
  const { pps } = useAppConfig();
  const url = useMemo(
    () =>
      buildUrl({ base: pps.url, route: meContextsRoute, searchParams: { brands, countryCodes } }),
    [brands, pps.url, countryCodes]
  );

  return useFetch<Context[]>({
    method: 'GET',
    queryKey: ['meContexts', token],
    url,
    token,
    ...queryOptions,
  });
}

export const useIsSuperUser = () => {
  const { data: me } = useFetchMe({ refetchOnWindowFocus: false });

  return useMemo(() => {
    return !!me?.isSuperUser;
  }, [me]);
};
