import ContentLoader from 'react-content-loader';
import { Box, Link, useTheme } from '@mui/material';
import { useFetchFreightMarkupItems } from '@retail/freight-markup/data-access';
import { useFreightMarkupTranslation } from '@retail/freight-markup/i18n';

type Props = {
  mgSupplierId: number;
  markupId: string;
  openItemsAffectedModal: (markupId: string) => void;
};

export const ItemsAffected = ({ mgSupplierId, markupId, openItemsAffectedModal }: Props) => {
  const { t } = useFreightMarkupTranslation();
  const { palette } = useTheme();

  const { data } = useFetchFreightMarkupItems(
    markupId,
    {
      mgSupplierId,
      page: 0,
      pageSize: 0,
    },
    {
      suspense: true,
    }
  );

  const total = data?.totalCount;

  return (
    <Box>
      {total ? (
        <Link component="button" onClick={() => openItemsAffectedModal(markupId)} fontWeight={700}>
          {total}
        </Link>
      ) : (
        <ContentLoader
          title={t('freightMarkup.itemsAffected.loading')}
          speed={1.5}
          width="100%"
          height="12px"
          backgroundColor={palette.grey[50]}
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      )}
    </Box>
  );
};
