import { AgreementStatusDTO, InheritedLevelDTO } from './ProductGroupAgreementDTO';

export enum PriceType {
  GTIN = 'GTIN',
  NET = 'NET',
  REBATE = 'REBATE',
}

export enum PriceUnit {
  STK = 'STK',
  BNT = 'BNT',
}

export interface ItemAgreementDTO {
  id: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  status: AgreementStatusDTO;
  inheritedLevel?: InheritedLevelDTO;
  priceType: PriceType;
  price: number;
  priceUnit: PriceUnit;
  basePriceUnit: PriceUnit;
}

export type AgreementsForMgItemDTO = {
  mgItemNumber: number;
  primaryText: string;
  agreements: ItemAgreementDTO[];
};
