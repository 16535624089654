import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const NoMansLandFlag = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 10">
      <path fill="#A5A5A5" d="M0,0H16V10H0Z" />
      <path fill="#EDEDED" d="M0,4H5V0H7V4H16V6H7V10H5V6H0Z" />
    </SvgIcon>
  );
};

export default NoMansLandFlag;
