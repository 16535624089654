import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppBar, Divider, Stack, Toolbar, useTheme } from '@mui/material';
import { useLanguageSelector } from '@retail/app/hooks';
import { MgItemSearchAutocomplete } from '@retail/products/containers';
import { LanguageSwitch } from '@retail/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { ContextSelect } from './ContextSelect';
import { BrandSelect, BrandSelectOption } from '@shared/components';
import { MGPrisLogo } from './MGPrisLogo';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { mgprisHeaderHeight } from '@shared/styles';

export function Header() {
  const { palette } = useTheme();
  const {
    i18n: { language },
  } = useTranslation();
  const { switchLanguage } = useLanguageSelector();
  const { availableBrands, selectedBrand, switchBrand } = useUserBrands();

  const brandOptions: BrandSelectOption[] = useMemo(
    () =>
      availableBrands.map(({ name, key, icon }) => ({
        name,
        key,
        logoSrc: icon,
      })),
    [availableBrands]
  );

  const changeBrand = useCallback(
    (brandKey: string) => {
      const newBrand = availableBrands.find(({ key }) => key === brandKey);
      if (newBrand) {
        switchBrand(newBrand);
      }
    },
    [availableBrands, switchBrand]
  );

  const navigate = useNavigate();
  const onMgItemSearchResultClick = useCallback(
    (mgItemId: number) => navigate(mgPrisRoutes.items.item.getFullLinkPath(`${mgItemId}`)),
    [navigate]
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        height: mgprisHeaderHeight,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: 0,
        background: palette.gradient.primary,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignSelf="stretch"
          paddingY={2}
        >
          <BrandSelect
            brands={brandOptions}
            selectedBrandKey={selectedBrand.key}
            onBrandSelect={changeBrand}
          />

          <Divider
            orientation="vertical"
            sx={{
              borderColor: palette.background.default,
              marginX: '1.6rem',
              borderRightWidth: '1px',
            }}
          />

          <MgItemSearchAutocomplete
            onItemClick={onMgItemSearchResultClick}
            sx={{ width: '300px' }}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginLeft={2}
          height="100%"
        >
          <ContextSelect />

          <MGPrisLogo />

          <LanguageSwitch
            selectedLanguage={language}
            switchLanguage={switchLanguage}
            languages={['no', 'se']}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
