import { fetcher, QueryOptions, useMutateFetch, useQueryFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { MgSupplierItemDetailedDTO } from '@retail/products/types';

export const mgSupplierItemApiRoute = '/v1/mg-supplier-items';

interface FetchProps {
  baseUrl: string;
  mgSupplierItemId: number;
  token: string;
}

export async function fetchMgSupplierItem({ baseUrl, mgSupplierItemId, token }: FetchProps) {
  return fetcher<MgSupplierItemDetailedDTO>({
    url: `${baseUrl}${mgSupplierItemApiRoute}/${mgSupplierItemId}`,
    token,
  });
}

interface Props {
  mgSupplierItemId: number;
}

export function useFetchMgSupplierItem({ mgSupplierItemId }: Props, options?: QueryOptions) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () =>
      fetchMgSupplierItem({
        baseUrl: pps.url,
        mgSupplierItemId,
        token,
      }),
    [mgSupplierItemId, pps.url, token]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['mg-item', mgSupplierItemId],
    ...options,
  });
}

export function useTriggerFetchMgSupplierItem() {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateFetch<MgSupplierItemDetailedDTO>({
    url: (mgSupplierItemId: number) => `${pps.url}${mgSupplierItemApiRoute}/${mgSupplierItemId}`,
    method: 'GET',
    token,
  });
}
