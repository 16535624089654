import { Stack, Switch, Typography, useTheme } from '@mui/material';

interface SwitchProps {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  text: string;
}

export const ToggleSwitch = ({ toggle, setToggle, text }: SwitchProps) => {
  const { palette } = useTheme();
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography color={palette.grey.A400} paddingX="12px" variant="body2">
        {text}
      </Typography>
      <Switch value={toggle} onClick={() => setToggle(!toggle)}></Switch>
    </Stack>
  );
};
