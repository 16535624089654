import { SvgIcon, SvgIconProps } from '@mui/material';

export const MarketIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_3361_285870)">
          <path d="M11.0562 1.79755L10.3188 0.210441C10.1969 -0.0651446 9.81562 -0.0751055 9.68125 0.210441L8.94375 1.79755L7.30937 2.04657C7.01875 2.09306 6.89375 2.47489 7.10938 2.704L8.29688 3.93251L8.01562 5.66239C7.97188 5.97118 8.27187 6.21025 8.54062 6.06747L10.0063 5.24403L11.4625 6.05419C11.7312 6.19696 12.0344 5.9579 11.9875 5.64911L11.7063 3.91923L12.8938 2.704C13.1062 2.47821 12.9844 2.09638 12.6938 2.04657L11.0594 1.79755H11.0562ZM8 8.50126C7.44688 8.50126 7 8.97607 7 9.56376V15.9388C7 16.5265 7.44688 17.0013 8 17.0013H12C12.5531 17.0013 13 16.5265 13 15.9388V9.56376C13 8.97607 12.5531 8.50126 12 8.50126H8ZM1 10.6263C0.446875 10.6263 0 11.1011 0 11.6888V15.9388C0 16.5265 0.446875 17.0013 1 17.0013H5C5.55312 17.0013 6 16.5265 6 15.9388V11.6888C6 11.1011 5.55312 10.6263 5 10.6263H1ZM14 13.8138V15.9388C14 16.5265 14.4469 17.0013 15 17.0013H19C19.5531 17.0013 20 16.5265 20 15.9388V13.8138C20 13.2261 19.5531 12.7513 19 12.7513H15C14.4469 12.7513 14 13.2261 14 13.8138Z" />
        </g>
        <defs>
          <clipPath id="clip0_3361_285870">
            <rect width="20" height="17" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
