import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { NavRoute } from './types';
import {
  getListItemButtonStyles,
  getListItemIconStyles,
  getListItemStyles,
  getListItemTextStyles,
} from './styles';

interface Props extends NavRoute {
  expandedView: boolean;
  onClick?: () => void;
}

function NavItemBase({ to, isActive, title, Icon, expandedView, onClick }: Props) {
  const listItemStyles = useMemo(() => getListItemStyles(expandedView), [expandedView]);
  const listItemButtonStyles = useMemo(() => getListItemButtonStyles(isActive), [isActive]);
  const listItemIconStyles = useMemo(() => getListItemIconStyles(isActive), [isActive]);
  const listItemTextStyles = useMemo(() => getListItemTextStyles(expandedView), [expandedView]);

  return (
    <ListItem disablePadding sx={listItemStyles}>
      <Tooltip title={expandedView ? '' : title} placement="right" arrow>
        <ListItemButton
          to={to}
          component={Link}
          onClick={onClick}
          selected={isActive}
          sx={listItemButtonStyles}
        >
          <ListItemIcon sx={listItemIconStyles}>
            <Icon fontSize="small" />
          </ListItemIcon>

          <ListItemText
            disableTypography
            primary={<Typography sx={{ fontWeight: 600 }}>{title}</Typography>}
            sx={listItemTextStyles}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}

export const NavItem = memo(NavItemBase);
