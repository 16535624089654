import { Divider, Menu, MenuItem, useTheme } from '@mui/material';
import { usePopupElement } from '@shared/hooks';
import { ContainedButton } from '@shared/components';
import { TrashIcon } from '@sanity/icons';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { AddIcon, GridIcon, PenIcon } from '@shared/custom-icons';

interface Props {
  onEditNameClick: () => void;
  onReplaceClick: () => void;
  onAddFromFileClick: () => void;
  onDeleteClick: () => void;
}

export const EditAssortmentMenu: React.FC<Props> = ({
  onReplaceClick,
  onEditNameClick,
  onAddFromFileClick,
  onDeleteClick,
}) => {
  const { palette } = useTheme();
  const t = useAssortmentTFunction();
  const { menuElementProps, triggerElementProps } = usePopupElement();
  return (
    <>
      <ContainedButton
        color="secondary"
        size="small"
        {...triggerElementProps}
        sx={{ whiteSpace: 'nowrap' }}
      >
        {t('assortment.editAssortment')}
      </ContainedButton>
      {
        <Menu {...menuElementProps} transitionDuration={400}>
          <MenuItem onClick={onAddFromFileClick}>
            {t('assortment.admin.edit.options.addFromFile')}
            <AddIcon stroke={palette.primary.main} />
          </MenuItem>
          <MenuItem onClick={onReplaceClick}>
            {t('assortment.admin.edit.options.replaceFromFile')}
            <GridIcon fontSize="small" />
          </MenuItem>
          <MenuItem onClick={onEditNameClick}>
            {t('assortment.admin.edit.options.editTitle')}
            <PenIcon fontSize="small" />
          </MenuItem>
          <Divider />
          <MenuItem color="warning" onClick={onDeleteClick}>
            {t('assortment.admin.edit.options.delete')}
            <TrashIcon fontSize="25px" color={palette.common.black} />
          </MenuItem>
        </Menu>
      }
    </>
  );
};
