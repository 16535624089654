import { fetcher, QueryOptions, useFetch, useMutateFetch, useQueryFetch } from '@shared/fetch-utils';
import { MgParticipant, MgParticipantsMinimalDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { useContexts } from '@retail/app/stores/selected-context';
import { Context } from '@retail/auth/types';

export const mgParticipantApiPath = '/v1/mg/mg-participant/all';
export const favoriteSuppliersRoute = '/favorite-suppliers';
export const contextPath = '/v1/context';

interface FetchMgParticipantsProps {
  url: string;
  token: string;
}

export async function fetchAllMgParticipants({ url, token }: FetchMgParticipantsProps) {
  return fetcher<MgParticipantsMinimalDTO[]>({ url, token, method: 'GET' });
}

export function useFetchAllMgParticipants(queryOptions: QueryOptions = {}) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () => fetchAllMgParticipants({ url: `${pps.url}${mgParticipantApiPath}`, token }),
    [pps.url, token]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['mgParticipant'],
    ...queryOptions,
  });
}

export function useFetchFavoriteMgParticipants(queryOptions: QueryOptions = {}) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const { selectedContext } = useContexts();

  return useFetch<MgParticipantsMinimalDTO[]>({
    method: 'GET',
    queryKey: ['favorite-suppliers', selectedContext],
    token,
    url: `${pps.url}${contextPath}/${selectedContext.id}${favoriteSuppliersRoute}`,
    context: { key: selectedContext.type, value: selectedContext.value },
    ...queryOptions,
  });
}

export function useMutateEditFavoriteMgParticipants(context: Context) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateFetch<MgParticipantsMinimalDTO[], MgParticipant[]>({
    url: `${pps.url}${contextPath}/${context.id}${favoriteSuppliersRoute}`,
    method: 'POST',
    context: { key: context.type, value: context.value },
    token,
  });
}
