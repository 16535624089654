import { Stack, Typography } from '@mui/material';
import { SelectInputField, TextInputField } from '@shared/components';
import { Percent } from '@mui/icons-material';
import { useController, useFormContext } from 'react-hook-form';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { useMemo } from 'react';
import { FromToDateForm } from './FromToDateForm';
import { FreightSurchargeOptionsSelect } from '../CreateFreigthMarkup/CreateFreightMarkupForMgSupplierItem';
import { CreateFreightMarkupSchema, percentFreightUnit } from './freightMarkupSchema';

interface Props {
  freightSurchargeOptions: FreightSurchargeOptionsSelect[];
}

export function FreightMarkupFormMgSupplierItemBase({ freightSurchargeOptions }: Props) {
  const t = useFreightMarkupTFunction();
  const { control } = useFormContext<CreateFreightMarkupSchema>();
  const freightTypeController = useController({ name: 'freightUnit', control });
  const freightFactorController = useController({ name: 'freightFactor', control });
  const freightSurchargeController = useController({ name: 'freightSurcharge', control });

  const noOptions = useMemo(() => freightSurchargeOptions.length === 0, [freightSurchargeOptions]);

  return (
    <Stack gap={3}>
      <Stack flexDirection="row" alignItems="flex-start" gap={1}>
        <SelectInputField
          {...freightTypeController.field}
          error={freightTypeController.fieldState.error?.message}
          margin="none"
          required
          fullWidth
          label={t('freightMarkup.form.surchargeType')}
          placeholder={t('freightMarkup.actions.chooseSurchargeType')}
          disabled={noOptions}
          menuItems={freightSurchargeOptions}
        />
        {freightTypeController.field.value === percentFreightUnit && (
          <TextInputField
            {...freightFactorController.field}
            error={freightFactorController.fieldState.error?.message}
            required
            disabled={noOptions}
            margin="none"
            label={t('freightMarkup.columns.freightSurcharge')}
            size="medium"
            fullWidth
            placeholder={t('freightMarkup.actions.writeFreightSurcharge')}
            endAdornment={<Percent />}
          />
        )}
        {freightTypeController.field.value !== percentFreightUnit &&
          freightTypeController.field.value !== '' && (
            <TextInputField
              {...freightSurchargeController.field}
              error={freightSurchargeController.fieldState.error?.message}
              required
              disabled={noOptions}
              margin="none"
              label={t('freightMarkup.columns.freightSurcharge')}
              size="medium"
              fullWidth
              placeholder={t('freightMarkup.actions.writeFreightSurcharge')}
              endAdornment={
                <Typography variant="h6" fontWeight={500}>
                  SEK
                </Typography>
              }
            />
          )}
      </Stack>
      <FromToDateForm />
    </Stack>
  );
}
