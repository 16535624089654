import {
  DialogTitle,
  List,
  ListItemButton as MuiListItemButton,
  ListItemText,
  Stack,
  Typography,
  styled,
  Box,
  useTheme,
  ListItemIcon,
  IconButton,
  Grid,
  DialogContent,
} from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Dialog, Paper, StrictModeDroppable, TagHeading } from '@shared/components';
import { MgAssortmentBase } from '@retail/assortment/types';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { ClickIcon, DeleteIcon } from '@shared/custom-icons';
import { Add } from '@mui/icons-material';

const DROP_ZONE_ID = 'monitor-item';

type Props = {
  open: boolean;
  onClose: () => void;
  assortmentsItemExistsIn: MgAssortmentBase[];
  availableAssortments: MgAssortmentBase[];
  addItemToAssortment: (assortment: MgAssortmentBase) => void;
  deleteItemFromAssortment: (assortment: MgAssortmentBase) => void;
};

export const AddItemToAssortmentDialog = ({
  open,
  onClose,
  assortmentsItemExistsIn,
  availableAssortments,
  addItemToAssortment,
  deleteItemFromAssortment,
}: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const handleClose = () => {
    onClose();
  };

  const handleDelete = (idToDelete: number) => {
    const assortment = assortmentsItemExistsIn.find(({ id }) => id === idToDelete);
    if (!assortment) return;

    deleteItemFromAssortment(assortment);
  };

  const handleDragEnd = ({ draggableId, destination }: DropResult) => {
    if (destination?.droppableId === DROP_ZONE_ID) {
      const assortment = availableAssortments.find(({ id }) => id === Number(draggableId));
      if (!assortment) return;

      addItemToAssortment(assortment);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle display="flex" justifyContent="space-between">
        <TagHeading color="primary">
          <Typography variant="h4" component="span">
            {t('products.mgItem.supplierItem.monitor')}
          </Typography>
        </TagHeading>
      </DialogTitle>

      <DialogContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId={DROP_ZONE_ID}>
            {(provided) => (
              <Grid container spacing={3} minWidth="650px">
                <Grid item xs={6}>
                  <Stack
                    component={Paper}
                    bgcolor={({ palette }) => palette.background.secondary}
                    p={3}
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography>
                        {t('products.mgItem.addItemsToAssortment.existsInAssortments')}
                      </Typography>

                      <List dense>
                        {assortmentsItemExistsIn.map(({ id, title }) => (
                          <StyledListItem key={id}>
                            <ListItemText primary={title} />
                            <ListItemIcon
                              onClick={() => handleDelete(id)}
                              sx={{ minWidth: 0, cursor: 'pointer' }}
                            >
                              <DeleteIcon />
                            </ListItemIcon>
                          </StyledListItem>
                        ))}
                      </List>
                    </Box>

                    <DroppableZone ref={provided.innerRef}>
                      <IconButton
                        sx={{
                          backgroundColor: palette.primary.main,
                          color: palette.common.white,
                          padding: '0.1rem',
                          marginRight: '0.5rem',
                        }}
                      >
                        <Add />
                      </IconButton>
                      <Typography variant="body2" sx={{ color: palette.grey.A400 }}>
                        {t('products.mgItem.addItemsToAssortment.dragInNewAssortment')}
                      </Typography>
                    </DroppableZone>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    component={Paper}
                    bgcolor={({ palette }) => palette.background.secondary}
                    p={3}
                    height="100%"
                  >
                    <Typography>
                      {t('products.mgItem.addItemsToAssortment.addToAssortment')}
                    </Typography>

                    <List dense>
                      {availableAssortments.map(({ id, title }, index) => (
                        <Draggable key={id} draggableId={String(id)} index={index}>
                          {(provided) => (
                            <StyledListItemButton
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemText primary={title} />
                              <ListItemIcon sx={{ minWidth: 0 }}>
                                <ClickIcon />
                              </ListItemIcon>
                            </StyledListItemButton>
                          )}
                        </Draggable>
                      ))}
                    </List>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </DialogContent>
    </Dialog>
  );
};

const StyledListItem = styled(MuiListItemButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.grey.A100}`,
  borderRadius: theme.spacing(1.5),
  justifyContent: 'space-between',
  '&:hover, &.Mui-focusVisible': {
    background: theme.palette.background.paper,
    cursor: 'default',
  },
}));

const StyledListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.grey.A100}`,
  borderRadius: theme.spacing(1.5),
  justifyContent: 'space-between',

  '&:hover, &.Mui-focusVisible': {
    background: `${theme.palette.background.paper}`,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
  },
}));

const DroppableZone = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.grey.A400}`,
  borderRadius: theme.spacing(1.5),
  height: '98px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
}));
