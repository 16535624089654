import { PriceUnit, ActiveItemAgreementDTO, PriceType } from '@retail/purchase-agreements/types';
import { purchaseAgreements } from './purchaseAgreementTestData';

export const itemAgreementsForProductGroupTestData: ActiveItemAgreementDTO[] = [
  {
    ...purchaseAgreements[0],
    primaryText: 'DEKORSTEN 11-16 MARMOR VIT',
    mgItemId: 200593200,
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
    status: 'active',
  },
  {
    ...purchaseAgreements[0],
    primaryText: 'PLANK SÅGAD G4-2 FURU 50X100',
    mgItemId: 200711675,
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'DECORSTEN TUMLAD TOROS',
    mgItemId: 200520736,
    status: 'active',
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'GLASBLOCK MENDINI VIT',
    mgItemId: 200302595,
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'DEKORSTEN VIT MATT 20-40MM',
    mgItemId: 200046853,
    status: 'active',
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'GLASBLOCK 1908/W KLAR',
    mgItemId: 200546624,
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'SÄTTLAGER STENFLIS SÄCK 2-6MM',
    mgItemId: 200623562,
    status: 'active',
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'PLANK SÅGAD G4-2 FURU 38X75',
    mgItemId: 200711756,
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'STENKROSS GRAFIT/VIT 16-32MM',
    mgItemId: 200045274,
    status: 'active',
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'SÅGAD GRAN G4-2 47X175MM',
    mgItemId: 200562702,
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
  {
    ...purchaseAgreements[1],
    primaryText: 'DECORSTEN TUMLAD BASALT',
    mgItemId: 200531264,
    status: 'active',
    priceType: PriceType.GTIN,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.BNT,
  },
  {
    ...purchaseAgreements[2],
    primaryText: 'GLESPANEL G4-3 GRAN 28X120',
    mgItemId: 200711770,
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    priceType: PriceType.NET,
    price: 109.67,
    priceUnit: PriceUnit.STK,
    basePriceUnit: PriceUnit.STK,
  },
];
