import { OverGroupDTO } from '@retail/purchase-agreements/types';

export const productGroupsTestData: OverGroupDTO[] = [
  {
    id: '1',
    groupNumber: '01',
    name: 'Byggmaterial',
    hasActiveAgreement: true,
    numberOfItems: 17,

    mainGroups: [
      {
        id: '100',
        groupNumber: '011',
        name: 'Byggnadsblock och ballast',
        hasActiveAgreement: true,
        numberOfItems: 9,

        underGroups: [
          {
            id: '1000',
            groupNumber: '01112',
            name: 'Krossat bergmaterial',
            hasActiveAgreement: false,
            numberOfItems: 6,
          },
          {
            id: '1001',
            groupNumber: '01199',
            name: 'Byggnadsblock och ballast övrigt',
            hasActiveAgreement: true,
            numberOfItems: 1,
          },
          {
            id: '1002',
            groupNumber: '01109',
            name: 'Glasblock',
            hasActiveAgreement: false,
            numberOfItems: 2,
          },
          {
            id: '130400553',
            groupNumber: '01104',
            name: 'Lättklinker block',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400560',
            groupNumber: '01111',
            name: 'Fyllnadsjord',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400554',
            groupNumber: '01105',
            name: 'Lättklinker balk',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400563',
            groupNumber: '01114',
            name: 'Kantelement',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400550',
            groupNumber: '01101',
            name: 'Betongblock',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400562',
            groupNumber: '01113',
            name: 'Grusmaterial',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400559',
            groupNumber: '01110',
            name: 'Sand',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400557',
            groupNumber: '01108',
            name: 'Betongrör, tegelrör och gjutformar',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400552',
            groupNumber: '01103',
            name: 'Lättbetong',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400556',
            groupNumber: '01107',
            name: 'Natursten',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400555',
            groupNumber: '01106',
            name: 'Lättklinker lös',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400551',
            groupNumber: '01102',
            name: 'Tegel',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '101',
        groupNumber: '014',
        name: 'Tätskiktssystem, tejp och tätningslist',
        hasActiveAgreement: false,
        numberOfItems: 3,
        underGroups: [
          {
            id: '130400601',
            groupNumber: '01404',
            name: 'Gummiduk',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
          {
            id: '130400606',
            groupNumber: '01409',
            name: 'Tätskiktsystem',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400607',
            groupNumber: '01499',
            name: 'Tätskiktssystem, tejp och tätningslist övrigt',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
          {
            id: '130400603',
            groupNumber: '01406',
            name: 'Plastfolie',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400599',
            groupNumber: '01402',
            name: 'Ytpapp',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
          {
            id: '130400605',
            groupNumber: '01408',
            name: 'Tätningslist',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400604',
            groupNumber: '01407',
            name: 'Tejp',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400602',
            groupNumber: '01405',
            name: 'Fogband',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400600',
            groupNumber: '01403',
            name: 'Papprodukter övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400598',
            groupNumber: '01401',
            name: 'Underlagspapp',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '102',
        groupNumber: '010',
        name: 'Bindemedel och bruk',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400540',
            groupNumber: '01002',
            name: 'Bruksbindemedel',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400545',
            groupNumber: '01007',
            name: 'Eld- och syrafast murmassa',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400546',
            groupNumber: '01008',
            name: 'Avjämningsmassa',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400542',
            groupNumber: '01004',
            name: 'Torrbruk fasadputs/putsa utvändigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400548',
            groupNumber: '01099',
            name: 'Bindemedel och bruk övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400539',
            groupNumber: '01001',
            name: 'Cement',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400544',
            groupNumber: '01006',
            name: 'Våtbruk',
            hasActiveAgreement: true,
            numberOfItems: 0,
          },
          {
            id: '130400541',
            groupNumber: '01003',
            name: 'Kalkvaror',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400543',
            groupNumber: '01005',
            name: 'Torrbruk mura/putsa',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400547',
            groupNumber: '01009',
            name: 'Torrbruk gjuta',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '103',
        groupNumber: '013',
        name: 'Isoleringsmaterial',
        hasActiveAgreement: false,
        numberOfItems: 2,
        underGroups: [
          {
            id: '130400591',
            groupNumber: '01303',
            name: 'Cellplast extruderad',
            hasActiveAgreement: false,
            numberOfItems: 2,
          },
          {
            id: '130400594',
            groupNumber: '01306',
            name: 'Kantelement/Isolering',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400593',
            groupNumber: '01305',
            name: 'Skumplast',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400596',
            groupNumber: '01399',
            name: 'Isoleringsmaterial övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400589',
            groupNumber: '01301',
            name: 'Mineralull',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400590',
            groupNumber: '01302',
            name: 'Cellplast',
            hasActiveAgreement: true,
            numberOfItems: 0,
          },
          {
            id: '130400595',
            groupNumber: '01307',
            name: 'Cellulosaisolering',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400592',
            groupNumber: '01304',
            name: 'Träull',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '104',
        groupNumber: '016',
        name: 'Tak- och väggbeklädnad',
        hasActiveAgreement: false,
        numberOfItems: 3,
        underGroups: [
          {
            id: '130400625',
            groupNumber: '01603',
            name: 'Tak uterum',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400628',
            groupNumber: '01606',
            name: 'Tak och väggsäkerhet',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
          {
            id: '130400629',
            groupNumber: '01607',
            name: 'Takpannor lertegel',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400624',
            groupNumber: '01602',
            name: 'Takplåt',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
          {
            id: '130400626',
            groupNumber: '01604',
            name: 'Takplattor asfaltsimpregnerade',
            hasActiveAgreement: false,
            numberOfItems: 1,
          },
          {
            id: '130400630',
            groupNumber: '01608',
            name: 'Takpannor lertegel tillbehör',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400627',
            groupNumber: '01605',
            name: 'Takplåtsdetaljer',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400631',
            groupNumber: '01609',
            name: 'Takpannor betong tillbehör',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400623',
            groupNumber: '01601',
            name: 'Takpannor, betong',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400632',
            groupNumber: '01699',
            name: 'Tak- och väggbeklädnad övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '105',
        groupNumber: '015',
        name: 'Armering, stål och metallvaror',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400613',
            groupNumber: '01505',
            name: 'Plåt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400617',
            groupNumber: '01509',
            name: 'Rostfritt, syrafast, -stål och -plåt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400618',
            groupNumber: '01510',
            name: 'Metaller',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400611',
            groupNumber: '01503',
            name: 'Stångstål',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400614',
            groupNumber: '01506',
            name: 'Hålprofiler och industrirör',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400619',
            groupNumber: '01511',
            name: 'Tunnplåtsprofiler',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400621',
            groupNumber: '01599',
            name: 'Armering, stål och metallvaror övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400609',
            groupNumber: '01501',
            name: 'Armeringsstål',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400612',
            groupNumber: '01504',
            name: 'Balk',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400620',
            groupNumber: '01512',
            name: 'Armeringsnät',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400610',
            groupNumber: '01502',
            name: 'Formbyggnadsmaterial',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400616',
            groupNumber: '01508',
            name: 'Förbehandlat stål och plåt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400615',
            groupNumber: '01507',
            name: 'Perforerad plåt och sträckmetall',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
    ],
  },
  {
    id: '2',
    groupNumber: '02',
    name: 'Träprodukter',
    hasActiveAgreement: false,
    numberOfItems: 12,
    mainGroups: [
      {
        id: '106',
        groupNumber: '027',
        name: 'Ädelträ',
        hasActiveAgreement: false,
        numberOfItems: 12,
        underGroups: [
          {
            id: '130400707',
            groupNumber: '02701',
            name: 'Ädelträ - Ytterpaneler',
            hasActiveAgreement: false,
            numberOfItems: 12,
          },
          {
            id: '130400708',
            groupNumber: '02702',
            name: 'Ädelträ - Tralläkt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400709',
            groupNumber: '02799',
            name: 'Ädelträ övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '107',
        groupNumber: '023',
        name: 'Tryckimpregnerat virke',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400690',
            groupNumber: '02305',
            name: 'Brandimpregnerat',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400686',
            groupNumber: '02301',
            name: 'Impregnerat träskyddsklass NTR/M',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400687',
            groupNumber: '02302',
            name: 'Impregnerat träskyddsklass NTR/A',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400689',
            groupNumber: '02304',
            name: 'Impregnerat träskyddsklass NTR/B',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400691',
            groupNumber: '02399',
            name: 'Impregnerat virke - Övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400688',
            groupNumber: '02303',
            name: 'Impregnerat träskyddsklass NTR/AB',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400658',
        groupNumber: '020',
        name: 'Trävaror',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400666',
            groupNumber: '02008',
            name: 'Formvirke',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400660',
            groupNumber: '02002',
            name: 'Sparrar',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400662',
            groupNumber: '02004',
            name: 'Underlagsspont',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400663',
            groupNumber: '02005',
            name: 'Underlagsspont ändspontat',
            hasActiveAgreement: true,
            numberOfItems: 0,
          },
          {
            id: '130400659',
            groupNumber: '02001',
            name: 'Sågat virke',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400665',
            groupNumber: '02007',
            name: 'Läkt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400664',
            groupNumber: '02006',
            name: 'Slätspont',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400661',
            groupNumber: '02003',
            name: 'Dimensionshyvlat virke',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400667',
            groupNumber: '02099',
            name: 'Byggvirke - Övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400681',
        groupNumber: '022',
        name: 'Utvändigt beklädnadsvirke',
        hasActiveAgreement: true,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400683',
            groupNumber: '02202',
            name: 'Ytterpanelbräder - Behandlad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400682',
            groupNumber: '02201',
            name: 'Ytterpanelbräder',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400684',
            groupNumber: '02299',
            name: 'Utvändigt beklädnadsvirke - Övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400703',
        groupNumber: '026',
        name: 'Biprodukter - trä',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400705',
            groupNumber: '02699',
            name: 'Biprodukter - trä, övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400704',
            groupNumber: '02601',
            name: 'Spån och kutterspån, förpackad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400668',
        groupNumber: '021',
        name: 'Hållfasthetssorterat virke',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400680',
            groupNumber: '02199',
            name: 'Hållfasthetssorterat virke - Övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400670',
            groupNumber: '02102',
            name: 'Hållfasthetsklass C35 - Fingerskarvad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400675',
            groupNumber: '02107',
            name: 'Hållfasthetsklass C18',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400677',
            groupNumber: '02109',
            name: 'Hållfasthetsklass C14',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400672',
            groupNumber: '02104',
            name: 'Hållfasthetsklass C30 - Fingerskarvad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400674',
            groupNumber: '02106',
            name: 'Hållfasthetsklass C24 - Fingerskarvad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400678',
            groupNumber: '02110',
            name: 'Hållfasthetsklass C14 - Fingerskarvad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400679',
            groupNumber: '02111',
            name: 'Ställningsvirke',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400669',
            groupNumber: '02101',
            name: 'Hållfasthetsklass C35',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400671',
            groupNumber: '02103',
            name: 'Hållfasthetsklass C30',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400676',
            groupNumber: '02108',
            name: 'Hållfasthetsklass C18 - Fingerskarvad',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400673',
            groupNumber: '02105',
            name: 'Hållfasthetsklass C24',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400696',
        groupNumber: '025',
        name: 'Träelement',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400697',
            groupNumber: '02501',
            name: 'Limträbalk',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400699',
            groupNumber: '02503',
            name: 'Lättreglar',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400702',
            groupNumber: '02599',
            name: 'Träelement - Övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400700',
            groupNumber: '02504',
            name: 'Lättbalkar',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400698',
            groupNumber: '02502',
            name: 'Limträpelare',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400701',
            groupNumber: '02505',
            name: 'Fanerträ',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
        ],
      },
      {
        id: '130400692',
        groupNumber: '024',
        name: 'Värmebehandlat virke',
        hasActiveAgreement: false,
        numberOfItems: 0,
        underGroups: [
          {
            id: '130400693',
            groupNumber: '02401',
            name: 'Värmebehandlat virke - Sågat',
            hasActiveAgreement: true,
            numberOfItems: 0,
          },
          {
            id: '130400695',
            groupNumber: '02499',
            name: 'Värmebehandlat virke - Övrigt',
            hasActiveAgreement: false,
            numberOfItems: 0,
          },
          {
            id: '130400694',
            groupNumber: '02402',
            name: 'Värmebehandlat virke - Hyvlat',
            hasActiveAgreement: true,
            numberOfItems: 0,
          },
        ],
      },
    ],
  },
];
