import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { ActiveTagPaper } from '@retail/components';
import { Chip } from '@shared/components';
import dayjs from 'dayjs';

interface Props {
  activeAgreement: ItemAgreement;
  mgItemNr: number;
}

export function ActiveItemAgreement({ activeAgreement, mgItemNr }: Props) {
  const t = usePurchaseAgreementsTFunction();
  const { palette } = useTheme();

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Typography variant="body2" fontWeight={600}>
            {t('purchaseAgreements.agreement.columns.mgItemNr')}
          </Typography>
          <Chip size="small" backgroundColor={palette.primary[100]} label={mgItemNr} />
        </Stack>
      </Stack>
      <ActiveTagPaper>
        <Grid container gap={6}>
          <Stack gap={3} flexDirection="row">
            <Stack fontSize={14} fontWeight={700} spacing={2}>
              <Grid item>{t('purchaseAgreements.agreement.columns.agreementText')}</Grid>
              <Grid item>{t('purchaseAgreements.agreement.columns.fromDate')}</Grid>
              <Grid item>{t('purchaseAgreements.agreement.columns.toDate')}</Grid>
            </Stack>
            <Stack fontSize={14} spacing={2}>
              <Grid item>{activeAgreement.name}</Grid>
              <Grid item>{dayjs(activeAgreement.fromDate).format('ll')}</Grid>
              <Grid item>{dayjs(activeAgreement.toDate).format('ll')}</Grid>
            </Stack>
          </Stack>
          <Stack gap={3} flexDirection="row">
            <Stack fontSize={14} fontWeight={700} spacing={2}>
              <Grid item>{t('purchaseAgreements.agreement.columns.priceType')}</Grid>
              <Grid item>{t('purchaseAgreements.agreement.columns.price')}</Grid>
              <Grid item>{t('purchaseAgreements.agreement.columns.basePriceUnit')}</Grid>
            </Stack>
            <Stack fontSize={14} spacing={2}>
              <Grid item>{activeAgreement.priceType}</Grid>
              <Grid item>{activeAgreement.price}</Grid>
              <Grid item>{activeAgreement.basePriceUnit}</Grid>
            </Stack>
          </Stack>
        </Grid>
      </ActiveTagPaper>
    </Stack>
  );
}
