import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  BodyLoader,
  ErrorFallback,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { ContainedButton, Paper } from '@shared/components';
import { marketInsight1, marketInsight2 } from '@assets/images';
import { AllMgAssortmentsContainer } from '@retail/assortment/containers';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useProductsTFunction } from '@retail/products/i18n';

export function MarketInsightPage() {
  const t = useProductsTFunction();

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Stack flexDirection="row" gap={2}>
        <Stack component={Paper} flexDirection="row" justifyContent="space-between" width="60%">
          <Box mr={2}>
            <Typography component="h1" variant="h6" marginBottom={1.5}>
              {t('products.marketInsight.infoCards.welcome')}
            </Typography>
            <Typography variant="body1" fontWeight="medium">
              {t('products.marketInsight.infoCards.welcomeDesc')}
            </Typography>
          </Box>
          <img src={marketInsight1} width="170px" height="150px" alt="marketInsight1" />
        </Stack>
        <Stack component={Paper} flexDirection="row" justifyContent="space-between" width="40%">
          <Box mr={2}>
            <Typography component="h1" variant="h6" marginBottom={1.5}>
              {t('products.marketInsight.infoCards.competitors')}
            </Typography>
            <Typography variant="body1" fontWeight="medium" marginBottom={3}>
              {t('products.marketInsight.infoCards.competitorsDesc')}
            </Typography>
            <ContainedButton
              component={Link}
              to={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
              size="small"
            >
              {t('products.marketInsight.competitors.heading')}
            </ContainedButton>
          </Box>
          <img src={marketInsight2} width="170px" height="150px" alt="marketInsight2" />
        </Stack>
      </Stack>

      <Paper paddingY="dense" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <SuspenseWithSentryErrorBoundary
          errorFallback={(props) => <ErrorFallback {...props} />}
          suspenseFallback={<BodyLoader />}
        >
          <AllMgAssortmentsContainer />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
