import { SvgIcon, SvgIconProps } from '@mui/material';

export const UsersIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="currentColor"
      >
        <path d="M3 4C3 2.93913 3.42143 1.92172 4.17157 1.17157C4.92172 0.421427 5.93913 0 7 0C8.06087 0 9.07828 0.421427 9.82843 1.17157C10.5786 1.92172 11 2.93913 11 4C11 5.06087 10.5786 6.07828 9.82843 6.82843C9.07828 7.57857 8.06087 8 7 8C5.93913 8 4.92172 7.57857 4.17157 6.82843C3.42143 6.07828 3 5.06087 3 4ZM0 15.0719C0 11.9937 2.49375 9.5 5.57188 9.5H8.42813C11.5063 9.5 14 11.9937 14 15.0719C14 15.5844 13.5844 16 13.0719 16H0.928125C0.415625 16 0 15.5844 0 15.0719ZM19.0406 16H14.7312C14.9 15.7063 15 15.3656 15 15V14.75C15 12.8531 14.1531 11.15 12.8188 10.0063C12.8938 10.0031 12.9656 10 13.0406 10H14.9594C17.7437 10 20 12.2562 20 15.0406C20 15.5719 19.5688 16 19.0406 16ZM13.5 8C12.5312 8 11.6562 7.60625 11.0219 6.97188C11.6375 6.14062 12 5.1125 12 4C12 3.1625 11.7938 2.37188 11.4281 1.67813C12.0094 1.25312 12.725 1 13.5 1C15.4344 1 17 2.56562 17 4.5C17 6.43437 15.4344 8 13.5 8Z" />
      </svg>
    </SvgIcon>
  );
};
