import { SvgIcon, SvgIconProps } from '@mui/material';

export const PenIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M17.71 4.0425C17.37 4.3825 17.04 4.7125 17.03 5.0425C17 5.3625 17.34 5.6925 17.66 6.0025C18.14 6.5025 18.61 6.9525 18.59 7.4425C18.57 7.9325 18.06 8.4425 17.55 8.9425L13.42 13.0825L12 11.6625L16.25 7.4225L15.29 6.4625L13.87 7.8725L10.12 4.1225L13.96 0.2925C14.35 -0.0975 15 -0.0975 15.37 0.2925L17.71 2.6325C18.1 3.0025 18.1 3.6525 17.71 4.0425ZM0 14.2525L9.56 4.6825L13.31 8.4325L3.75 18.0025H0V14.2525Z" />
      </svg>
    </SvgIcon>
  );
};
