import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { format, fromUnixTime, getUnixTime, sub } from 'date-fns';
import { nb } from 'date-fns/locale';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { CompetitorPriceDTO, ProductCompetitorInfo, StorePriceDTO } from '@retail/products/types';
import { IncludeVatAtom } from '../../../stores/VatStore';
import { removeVAT } from '../../../utils/vat';
import { renderPrice } from '@retail/products/utils';
import { MenuItem, Select } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { useSelectedContext } from '../../../stores';
import { useSelectedPrisinnsiktCompetitors } from '../../../hooks';

interface HistoricPricesChartProps {
  productData: ProductCompetitorInfo;
  heading?: React.ReactElement;
}

type PricePoint = {
  [retailerId: string]: number | string | boolean | undefined;
  date: number;
  primaryUnit: string;
  onDiscount: boolean;
  type: string;
};

interface RetailerView {
  retailerId: number;
  stores: StorePriceDTO[];
  color: string;
}

interface StorePriceInfo {
  retailerId: number;
  storePrices: CompetitorPriceDTO[];
}

function generatePricePoints(productData: ProductCompetitorInfo, includeVat: boolean) {
  const dataPoints: PricePoint[] = [];

  function pushPricePoints(store: StorePriceInfo, type: string) {
    store.storePrices.forEach((price) => {
      const dataPoint = {
        [store.retailerId.toString()]: includeVat
          ? price.primaryPrice
          : removeVAT(price.primaryPrice),
        date: getUnixTime(new Date(price.firstObservedAt)),
        primaryUnit: price.primaryUnit,
        onDiscount: price.onDiscount,
        type: type,
      };

      dataPoints.push(dataPoint);
    });
  }

  productData.retailerPrices.forEach((retailer) => {
    //push national prices
    pushPricePoints(
      {
        retailerId: retailer.retailerId,
        storePrices: retailer.nationalPrices,
      },
      'NATIONAL'
    );

    //push local prices
    retailer.stores.forEach((store) => {
      pushPricePoints(
        {
          retailerId: retailer.retailerId,
          storePrices: store.localPrices,
        },
        'LOCAL'
      );
    });
  });

  return dataPoints;
}

const Colors = [
  '#E7DECD', //warm gray
  '#FFB800', //orange
  '#0F8B8D', //blue
  '#9D8420', //beige
  '#90A583', //green
  '#94B9AF', //green-blue
  '#BDCFB5', //green-gray
  '#161925', //dark blue
  '#593837', //brown
  '#942911', //red
];

function HistoricProductCompetitorPricesChart({ productData, heading }: HistoricPricesChartProps) {
  const t = useAppTFunction();
  const selectedContext = useSelectedContext();
  const selectedCompetitors = useSelectedPrisinnsiktCompetitors(selectedContext);
  const includeVat = useRecoilValue(IncludeVatAtom);
  const [startDate, setStartDate] = useState(getUnixTime(sub(new Date(), { years: 1 })));
  const endDate = getUnixTime(new Date());

  const priceData: PricePoint[] = useMemo(
    () => generatePricePoints(productData, includeVat),
    [productData, includeVat]
  );

  const retailers: RetailerView[] = useMemo(
    () =>
      productData.retailerPrices.map((retailer, index) => {
        return {
          retailerId: retailer.retailerId,
          stores: retailer.stores,
          color: Colors[index % 10],
        };
      }),
    [productData]
  );

  function legendFormatter(retailerId: number) {
    return selectedCompetitors.find((comp) => comp.id === retailerId)?.fullName() || '';
  }

  function handleTimeWindowChange(props: { target: { value: string } }) {
    const selectedOption = props.target.value;

    if (selectedOption === 'opt3mnd') {
      setStartDate(getUnixTime(sub(new Date(), { months: 3 })));
    }
    if (selectedOption === 'opt1yr') {
      setStartDate(getUnixTime(sub(new Date(), { years: 1 })));
    }
    if (selectedOption === 'opt3yr') {
      setStartDate(getUnixTime(sub(new Date(), { years: 3 })));
    }
  }

  return (
    <React.Fragment>
      {heading}
      <Select defaultValue="opt1yr" onChange={handleTimeWindowChange}>
        <MenuItem value="opt3mnd">
          {t('products.prices.historic.lastXMonths', { count: 3 })}
        </MenuItem>
        <MenuItem value="opt1yr">{t('products.prices.historic.lastYear')}</MenuItem>
        <MenuItem value="opt3yr">{t('products.prices.historic.lastXYears', { count: 3 })}</MenuItem>
      </Select>
      <ResponsiveContainer width={'100%'} height={350}>
        <AreaChart
          data={priceData}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 30,
          }}
        >
          <defs>
            {retailers?.map((store) => {
              return (
                <linearGradient
                  id={store.retailerId.toString()}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                  key={'LG-' + store.retailerId}
                >
                  <stop offset="5%" stopColor={store.color} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={store.color} stopOpacity={0} />
                </linearGradient>
              );
            })}
          </defs>
          <XAxis
            dataKey="date"
            type="number"
            tickFormatter={dateFormatter}
            domain={[startDate, endDate]}
            allowDataOverflow={true}
          />
          <YAxis unit={t('products.prices.currency.NOK.short')} domain={[0, 'dataMax + 100']} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<PriceDateTooltip retailers={retailers} />} />
          <Legend verticalAlign="top" iconType="rect" height={36} />

          {retailers?.map(({ retailerId, color }) => {
            return (
              <Area
                type="stepBefore"
                dataKey={retailerId}
                dot
                // label={priceData[index].onDiscount ? <CampaignLabel x={?} y={?} />} TODO: what is x and y?
                stroke={color}
                fillOpacity={1}
                fill={'url(#' + retailerId + ')'}
                name={legendFormatter(retailerId)}
                key={retailerId}
                isAnimationActive={false} //bug i recharts - need to set this false to make dots render.
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

function dateFormatter(tickItem: number) {
  return format(fromUnixTime(tickItem), 'PP', { locale: nb });
}

interface CampaignLabelProps {
  x: number;
  y: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CampaignLabel: React.FC<CampaignLabelProps> = ({ x, y }) => {
  return (
    <svg
      x={x - 10}
      y={y - 10}
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#3F5CF1" />
      <path
        d="M6.59382 12.0166L10.7766 9.6017L12.5397 3.99113C12.6141 3.68879 13.0258 3.62122 13.1835 3.89436L15.3714 7.68383C16.0732 7.52187 16.8044 7.83065 17.1638 8.45314C17.5234 9.07606 17.4252 9.86369 16.934 10.3904L19.1216 14.1794C19.2679 14.4397 19.0369 14.76 18.7377 14.6934L12.9749 13.4104L11.6846 14.1553L13.8506 17.9069C13.9461 18.0724 13.8876 18.2853 13.7201 18.3821L12.1458 19.291C11.9782 19.3877 11.7646 19.3319 11.669 19.1665L9.50305 15.4149L8.79308 15.8248C7.73229 16.4372 6.37073 16.0816 5.76601 15.0342C5.161 13.9863 5.53384 12.6294 6.59462 12.0169L6.59382 12.0166ZM8.37281 12.1228L9.25967 11.6108L9.68934 12.355L8.80248 12.867L8.37281 12.1228ZM15.7472 8.33479L16.5578 9.73883C16.7233 9.44667 16.7234 9.0928 16.5564 8.80366C16.3892 8.51403 16.0835 8.33737 15.7473 8.33484L15.7472 8.33479ZM11.0767 14.5061L10.1103 15.0641L12.1032 18.5159L13.0696 17.9579L11.0767 14.5061ZM9.9996 10.851L11.5051 13.4587L12.5703 12.8437L11.0648 10.236L9.9996 10.851ZM10.8973 13.8096L9.39182 11.2019L6.94008 12.6175C6.21439 13.0364 5.95857 13.9675 6.37199 14.6835C6.78569 15.4001 7.7199 15.6441 8.44559 15.2251L10.8973 13.8096ZM11.5018 9.59045L13.3474 12.787L18.1169 13.844L12.9716 4.9319L11.5018 9.59045Z"
        fill="white"
      />
    </svg>
  );
};

interface ExtendedTooltipProps extends TooltipProps<any, any> {
  retailers: RetailerView[];
}

const PriceDateTooltip: React.FC<ExtendedTooltipProps> = ({
  active,
  payload,
  label,
  retailers,
}) => {
  const t = useAppTFunction();
  if (active && payload && label) {
    const { name, dataKey, payload: dataPoint } = payload[0];
    const store = retailers.find((s: { retailerId: number }) => s.retailerId === dataKey);

    return (
      <div
        style={{
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          borderRadius: '5px',
          padding: '8px',
          backgroundColor: store ? store.color : '#FFF',
        }}
      >
        <p>{name}</p>
        <p>{`${dateFormatter(+label)}: ${t('products.prices.pricePerUnit', {
          price: renderPrice(+payload[0].value),
          unit: dataPoint.primaryUnit,
        })}`}</p>
      </div>
    );
  }
  return null;
};

export default HistoricProductCompetitorPricesChart;
