import { useState } from 'react';
import { Box } from '@mui/material';
import { AddIcon, TrashIcon } from '@sanity/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  BodyLoader,
  BreadcrumbsToolbar,
  ErrorFallbackPage,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { ContainedButton, Paper } from '@shared/components';
import { UsersContainer } from '@retail/users/containers';
import { mgPrisRoutes } from '@retail/mgpris/config';
import {
  useFetchContextUsers,
  useRemoveUserAsAdmin,
  useSetUserAsAdmin,
} from '@retail/users/data-access';
import { useAppTranslation } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';

export function UsersPage() {
  const { t } = useAppTranslation();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: contextUsers = [] } = useFetchContextUsers();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const { mutateAsync: setUserAsAdmin, isLoading: isSetAdminLoading } = useSetUserAsAdmin();
  const { mutateAsync: removeUserAsAdmin, isLoading: isRemoveAdminLoading } =
    useRemoveUserAsAdmin();

  const setAsAdmin = async () => {
    return await Promise.all(selectedUserIds.map((id: string) => setUserAsAdmin(id)))
      .then(() => queryClient.invalidateQueries({ queryKey: ['context-users'] }))
      .catch(() => toast.error(t('common.error.fetch.generic')))
      .finally(() => setSelectedUserIds([]));
  };

  const removeAsAdmin = async () => {
    return await Promise.all(selectedUserIds.map((id: string) => removeUserAsAdmin(id)))
      .then(() => queryClient.invalidateQueries({ queryKey: ['context-users'] }))
      .catch(() => toast.error(t('common.error.fetch.generic')))
      .finally(() => setSelectedUserIds([]));
  };

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Paper paddingY="dense">
        <BreadcrumbsToolbar
          items={[
            { label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() },
            { label: t('app.routes.users') },
          ]}
          endContent={
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <ContainedButton
                onClick={setAsAdmin}
                disabled={!selectedUserIds.length}
                sx={{ whiteSpace: 'nowrap', marginRight: '0.5rem' }}
                size="small"
                startIcon={<AddIcon />}
                loading={isSetAdminLoading}
              >
                {t('admin.users.actions.promoteToAdmin')}
              </ContainedButton>
              <ContainedButton
                onClick={removeAsAdmin}
                disabled={!selectedUserIds.length}
                sx={{ whiteSpace: 'nowrap' }}
                size="small"
                color="warning"
                startIcon={<TrashIcon />}
                loading={isRemoveAdminLoading}
              >
                {t('admin.users.actions.removeAdmin')}
              </ContainedButton>
            </Box>
          }
        />
        <SuspenseWithSentryErrorBoundary
          errorFallback={(props) => <ErrorFallbackPage {...props} />}
          suspenseFallback={<BodyLoader />}
        >
          <UsersContainer
            contextUsers={contextUsers}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
          />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
