import { useMemo } from 'react';
import { MgSupplierItemSearchResultMinimal } from '@retail/products/types';
import { useFetchFreightMarkupItems } from '@retail/freight-markup/data-access';
import { MgSupplierItemsPageResult } from '@retail/purchase-agreements/types';
import { usePagination } from '@shared/hooks';

type Props = {
  mgSupplierId: number;
  markupId: string;
  initPageSize: number;
  shouldFetch?: boolean;
};

export const useAffectedItems = ({
  mgSupplierId,
  markupId,
  initPageSize,
  shouldFetch = true,
}: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: initPageSize,
    initPage: 0,
  });

  const { data } = useFetchFreightMarkupItems(
    markupId,
    {
      mgSupplierId,
      page,
      pageSize,
    },
    {
      suspense: true,
      enabled: shouldFetch,
    }
  );

  const items = useMemo(
    () => (data ? MgSupplierItemSearchResultMinimal.fromDTO(data.result) : []),
    [data]
  );

  return {
    result: {
      totalItems: data?.totalCount,
      items,
      page,
      pageSize,
    } as MgSupplierItemsPageResult,
    setPage,
    setPageSize,
  };
};