export const routeParamStart = '/:';

type RouteParamPath = `${typeof routeParamStart}${string}`;
type RoutePath = `/${string}`;

type GetStaticPathFunction = () => RoutePath;
type GetDynamicPathFunction = (routeParam: string) => RoutePath;

export interface RouteDef {
  path: RouteParamPath | RoutePath;
  sub?: RoutesDef;
}

export interface RoutesDef {
  [routePath: string]: RouteDef;
}

export type OutputRouteBase<TPathFunction extends GetStaticPathFunction | GetDynamicPathFunction> =
  {
    relativeRoutePath: string;
    fullRoutePath: string;
    getRelativeLinkPath: TPathFunction;
    /**
     * Does not work for dynamic parent path segments
     */
    getFullLinkPath: TPathFunction;
  };

export type Routes<TInputRoutes extends RoutesDef> = {
  [RouteName in keyof TInputRoutes]-?: OutputRouteBase<
    TInputRoutes[RouteName]['path'] extends RouteParamPath
      ? GetDynamicPathFunction
      : GetStaticPathFunction
  > &
    (TInputRoutes[RouteName]['sub'] extends object
      ? Routes<TInputRoutes[RouteName]['sub']>
      : // eslint-disable-next-line @typescript-eslint/ban-types
        {});
};
