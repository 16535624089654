import { useMemo } from 'react';
import { useCompetitorPrices, useFetchPriceForProvider } from '../../../api';
import { PriceProvider } from '@retail/products/types';
import { getProductRows } from './transformations';
import { AssortmentProductsState } from './AssortmentProductsState';
import { RequestContext } from '@shared/fetch-utils';
import { useIncludeVat } from '../../../stores/VatStore';
import { Competitor } from '@retail/retailer/types';
import { useFetchAssortment } from '../../../api';

interface Props {
  assortmentId: number | undefined;
  context: RequestContext;
  selectedCompetitors: Competitor[];
}

export const useGetAssortmentProducts = ({
  assortmentId,
  context,
  selectedCompetitors,
}: Props): AssortmentProductsState => {
  const { includeVat } = useIncludeVat();
  const selectedStoreIds = useMemo(
    () =>
      selectedCompetitors.filter((competitor) => competitor.type() === 'store').map(({ id }) => id),
    [selectedCompetitors]
  );
  const selectedRetailerIds = useMemo(
    () =>
      selectedCompetitors
        .filter((competitor) => competitor.type() === 'retailer')
        .map(({ id }) => id),
    [selectedCompetitors]
  );
  const {
    assortment,
    isLoading: isLoadingAssortment,
    error: errorLoadingAssortment,
  } = useFetchAssortment({ assortmentId });

  const nobbNumbers = useMemo(() => {
    if (assortment !== undefined) {
      return assortment.nobbProducts.map((n) => n.nobbNr);
    }
    return [];
  }, [assortment]);

  const priceSearchEnabled = useMemo(() => !!nobbNumbers.length, [nobbNumbers]);

  const {
    data: mgProductPrices,
    isLoading: isLoadingMg,
    error: errorLoadingMg,
  } = useFetchPriceForProvider({
    nobbNumbers,
    priceProvider: PriceProvider.mg,
    suspense: false,
    enabled: priceSearchEnabled,
    context,
  });

  const {
    data: omniumProductPrices,
    isLoading: isLoadingOmnium,
    error: errorLoadingOmnium,
  } = useFetchPriceForProvider({
    nobbNumbers,
    priceProvider: PriceProvider.omnium,
    suspense: false,
    enabled: priceSearchEnabled,
    context,
  });

  const {
    data: campaignProductPrices,
    isLoading: isLoadingCampaign,
    error: errorLoadingCampaign,
  } = useFetchPriceForProvider({
    nobbNumbers,
    priceProvider: PriceProvider.campaign,
    suspense: false,
    enabled: priceSearchEnabled,
    context,
  });

  const {
    data: competitorPrices,
    isLoading: isLoadingCompetitorPrices,
    error: errorLoadingCompetitorPrices,
  } = useCompetitorPrices({
    enabled: priceSearchEnabled,
    nobbNumbers: nobbNumbers,
    suspense: false,
    context,
    selectedStores: selectedStoreIds,
    selectedRetailers: selectedRetailerIds,
  });

  const loaders = useMemo(
    () => ({
      isLoadingAssortment,
      isLoadingMg,
      isLoadingOmnium,
      isLoadingCampaign,
      isLoadingCompetitorPrices,
    }),
    [
      isLoadingAssortment,
      isLoadingCampaign,
      isLoadingCompetitorPrices,
      isLoadingMg,
      isLoadingOmnium,
    ]
  );

  const productsWithPrices = useMemo(
    () =>
      assortment &&
      getProductRows({
        assortment,
        mgProductPrices,
        omniumProductPrices,
        campaignProductPrices,
        competitorPrices,
        includeVat,
        loaders,
        selectedCompetitors,
      }),
    [
      assortment,
      campaignProductPrices,
      competitorPrices,
      includeVat,
      loaders,
      mgProductPrices,
      omniumProductPrices,
      selectedCompetitors,
    ]
  );

  return {
    assortment,
    products: productsWithPrices,
    loaders,
    errors: {
      errorLoadingAssortment: errorLoadingAssortment?.payload,
      errorLoadingCompetitorPrices: errorLoadingCompetitorPrices?.payload,
      errorLoadingMg: errorLoadingMg?.payload,
      errorLoadingCampaign: errorLoadingCampaign?.payload,
      errorLoadingOmnium: errorLoadingOmnium?.payload,
    },
  };
};
