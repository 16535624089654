import { useState } from 'react';

interface Props {
  initPageSize: number;
  initPage: number;
}

export function usePagination({ initPageSize, initPage }: Props) {
  const [pageSize, setPageSize] = useState(initPageSize);
  const [page, setPage] = useState(initPage);
  return {
    pageSize,
    page,
    setPageSize,
    setPage,
  };
}
