import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import { ProductNameCellType } from '@retail/products/types';
import { Box, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props extends ProductNameCellType {
  href: string;
}

const ProductNameCell: React.FC<Props> = ({ productName, variants = [], href }) => {
  const t = useProductsTFunction();

  return (
    <Box display="flex" flexDirection="column">
      <ProductLink to={href}>
        {productName || t('products.productList.noProductName')}
      </ProductLink>
      {variants?.length > 0 && (
        <Typography
          sx={{
            fontSize: '12px',
            fontStyle: 'italic',
          }}
        >
          {t(
            `products.productList.variants.count.${variants.length === 1 ? 'singular' : 'plural'}`,
            { count: variants.length }
          )}
        </Typography>
      )}
    </Box>
  );
};

export const ProductLink = styled(RouterLink)`
  color: inherit;
  text-decoration: underline;
`;

export default ProductNameCell;
