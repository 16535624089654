import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import guard, { isSuperAdmin } from '../../decorators/guard';
import { Box, Button, ButtonGroup, IconButton, InputBase, Paper, Stack } from '@mui/material';
import { useSuperUsers } from '../../api/pps/super-users';
import { Clear } from '@mui/icons-material';
import { PageHeader } from '../../components';
import { useToast } from '@retail/hooks';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useAppTFunction } from '@retail/app/i18n';
import { PageLayoutOld } from '@retail/components';

function AdminSuperUserPage() {
  const t = useAppTFunction();
  const { superUsers, promoteSuperUserMutation, demoteSuperUserMutation } = useSuperUsers();

  const [userId, setUserId] = useState<string>('');
  const toast = useToast();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setUserId(v);
  };
  const clear = () => {
    setUserId('');
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'givenName', headerName: t('admin.superusers.firstName'), width: 200 },
      { field: 'familyName', headerName: t('admin.superusers.lastName'), width: 200 },
      { field: 'email', headerName: t('admin.superusers.email'), width: 300 },
      { field: 'id', headerName: t('admin.superusers.id'), width: 420 },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      superUsers?.map((user) => ({
        givenName: user.givenName,
        familyName: user.familyName,
        email: user.email,
        id: user.userId,
      })),
    [superUsers]
  );

  const addSuperUser = useCallback(async () => {
    promoteSuperUserMutation
      .mutateAsync(userId)
      .then(({ email }) => {
        setUserId('');
        toast.success(t('admin.superusers.actions.add.success', { email }));
      })
      .catch((err) => {
        toast.error(t('admin.superusers.actions.add.error'));
        console.error(err);
      });
  }, [promoteSuperUserMutation, userId, toast, t]);

  const removeSuperUser = useCallback(async () => {
    demoteSuperUserMutation
      .mutateAsync(userId)
      .then(({ email }) => {
        setUserId('');
        toast.success(t('admin.superusers.actions.remove.success', { email }));
      })
      .catch((err) => {
        toast.error(t('admin.superusers.actions.remove.error'));
        console.error(err);
      });
  }, [demoteSuperUserMutation, userId, toast, t]);

  return (
    <PageLayoutOld>
      <PageHeader>{t('admin.superusers.title')}</PageHeader>
      <Stack spacing={2} sx={{ paddingTop: '2rem' }}>
        <Stack spacing={2}>
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 0.5rem',
            }}
          >
            <InputBase
              placeholder={t('admin.superusers.actions.user.label')}
              onChange={onChange}
              value={userId}
              fullWidth
            />
            <IconButton onClick={clear}>
              <Clear />
            </IconButton>
          </Paper>

          <ButtonGroup>
            <Button
              onClick={addSuperUser}
              disabled={promoteSuperUserMutation.isLoading || demoteSuperUserMutation.isLoading}
            >
              {t('admin.superusers.actions.add.submit')}
            </Button>
            <Button
              onClick={removeSuperUser}
              disabled={promoteSuperUserMutation.isLoading || demoteSuperUserMutation.isLoading}
            >
              {t('admin.superusers.actions.remove.submit')}
            </Button>
          </ButtonGroup>
        </Stack>
        <Box
          sx={{
            height: '650px',
            width: '100%',
            display: 'flex',
          }}
        >
          {rows !== undefined && (
            <DataGridPro
              columns={columns}
              rows={rows}
              pageSize={10}
              isRowSelectable={() => false}
              rowsPerPageOptions={[10, 20, 30]}
            />
          )}
        </Box>
      </Stack>
    </PageLayoutOld>
  );
}

export default memo(guard({ guards: [isSuperAdmin] })(AdminSuperUserPage));
