import { Clear } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { SearchIcon } from '@shared/custom-icons';
import { KeyboardEventHandler, useCallback, useState } from 'react';

interface Props {
  isDisabled: boolean;
  onSubmitSearch: (search: string) => void;
}

export function MgSupplierItemFreeSearchField({ isDisabled, onSubmitSearch }: Props) {
  const [searchFieldInput, setSearchFieldInput] = useState('');
  const t = useProductsTFunction();
  const submitSearch = useCallback(() => {
    onSubmitSearch(searchFieldInput);
    setSearchFieldInput('');
  }, [searchFieldInput, onSubmitSearch]);

  const onKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        submitSearch();
      }
    },
    [submitSearch]
  );

  return (
    <TextField
      id="freeSearch"
      sx={{ width: 320 }}
      disabled={isDisabled}
      placeholder={t('products.search.openSearch.openSearchDetail')}
      value={searchFieldInput}
      onKeyDown={onKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: searchFieldInput ? (
          <Stack flexDirection="row">
            <Button onClick={submitSearch}>{t('products.search.openSearch.search')}</Button>
            <IconButton size="small" onClick={() => setSearchFieldInput('')}>
              <Clear />
            </IconButton>
          </Stack>
        ) : undefined,
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFieldInput(event.target.value);
      }}
    />
  );
}
