import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './app/App';
import { setupi18n } from './i18n/i18nConfig';
import './index.css';
import { mgPrisConfig } from '@retail/mgpris/config';
import * as Sentry from '@sentry/react';
import GA4React from 'ga-4-react';
import { setCustomDimensions } from '@retail/app/prisinnsikt-app';
import { MgPrisContextProvider } from '@retail/app/stores/app-config';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import Hotjar from '@hotjar/browser';
import { purchaseAgreementHandlers } from '@retail/purchase-agreements/mocks';
import { freightMarkupHandlers } from '@retail/freight-markup/mocks';
import { markupHandlers } from '@retail/markup/mocks';

LicenseInfo.setLicenseKey(mgPrisConfig.muiLicenseKey);
dayjs.extend(isLeapYear);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale('nb', {
  weekdaysShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
});
dayjs.locale('sv');
setupi18n();

if (mgPrisConfig.sentryDsn && mgPrisConfig.env !== 'local') {
  Sentry.init({
    dsn: mgPrisConfig.sentryDsn,
    environment: mgPrisConfig.env,
    release: mgPrisConfig.version,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (mgPrisConfig.hotjar && mgPrisConfig.env !== 'local') {
  Hotjar.init(mgPrisConfig.hotjar.id, mgPrisConfig.hotjar.version);
}

async function possiblyEnableMocking() {
  if (mgPrisConfig.env === 'prod') {
    return; // Never use mocks in prod
  }
  const purchaseAgreementsFeatureConfig = mgPrisConfig.features?.purchaseAgreements;
  const useMockApiForPurchaseAgreements =
    purchaseAgreementsFeatureConfig?.toggle === 'on' &&
    purchaseAgreementsFeatureConfig.config?.useMockApi;

  const freightMarkupFeatureConfig = mgPrisConfig.features?.freightMarkup;
  const useMockApiForFreightMarkup =
    freightMarkupFeatureConfig?.toggle === 'on' && freightMarkupFeatureConfig.config?.useMockApi;

  const markupFeatureConfig = mgPrisConfig.features?.markup;
  const useMockApiForMarkup =
    markupFeatureConfig?.toggle === 'on' && markupFeatureConfig.config?.useMockApi;

  if (!useMockApiForPurchaseAgreements && !useMockApiForFreightMarkup && !useMockApiForMarkup) {
    return;
  }

  const activeHandlers = [
    ...Object.values(useMockApiForPurchaseAgreements ? purchaseAgreementHandlers : []).flat(),
    ...Object.values(useMockApiForFreightMarkup ? freightMarkupHandlers : []).flat(),
    ...Object.values(useMockApiForMarkup ? markupHandlers : []).flat(),
  ];

  const { getWorker } = await import('./mocks/browser');
  const worker = getWorker(activeHandlers);

  return worker.start({ onUnhandledRequest: 'bypass' });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const renderDOM = () =>
  possiblyEnableMocking().then(() => {
    root.render(
      <StrictMode>
        <MgPrisContextProvider config={mgPrisConfig}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MgPrisContextProvider>
      </StrictMode>
    );
  });

if (mgPrisConfig.env !== 'local' && mgPrisConfig.gaMeasurementId) {
  const ga4React = new GA4React(mgPrisConfig.gaMeasurementId);

  ga4React
    .initialize()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .then((ga) => setCustomDimensions(mgPrisConfig.gaMeasurementId!, ga))
    .catch((err) => {
      console.error(
        'gtag script failed to load. Most likely due to an ad/tracker blocker. Full error: ',
        err
      );
    })
    .finally(() => {
      renderDOM();
    });
} else {
  renderDOM();
}
