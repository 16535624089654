import React, { useId, useMemo } from 'react';

export const usePopupElement = () => {
  const triggerElementId = useId();
  const popupElementId = useId();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  return useMemo(
    () => ({
      menuElementProps: {
        id: popupElementId,
        anchorEl,
        open: isOpen,
        onClose,
      },
      triggerElementProps: {
        id: triggerElementId,
        'aria-controls': isOpen ? popupElementId : undefined,
        'aria-haspopup': true,
        'aria-expanded': isOpen ? true : undefined,
        onClick: handleClick,
      },
    }),
    [anchorEl, isOpen, popupElementId, triggerElementId]
  );
};
