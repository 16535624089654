import { CircularProgress, Stack, Typography } from '@mui/material';

interface Props {
  heading: string;
}

const LoadingFallback: React.FC<Props> = ({ heading }) => (
  <Stack spacing={2}>
    <Typography variant="h2">{heading}</Typography>
    <Stack justifyContent="center" alignItems="center">
      <CircularProgress />
    </Stack>
  </Stack>
);

export default LoadingFallback;
