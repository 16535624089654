import { Grid, Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Paper } from '@shared/components';
import { ChosenByggematerialCompetitors } from './ChosenByggmaterialCompetitors';
import EditByggmaterialCompetitors from './EditByggmaterialCompetitors';
import { useContexts } from '@retail/app/stores/selected-context';
import {
  useFetchByggmaterialRetailers,
  useFetchContextCompetitors,
  usePostContextCompetitors,
} from '@retail/retailer/data-access';
import { PostContextCompetitorDTO, Retailer } from '@retail/retailer/types';
import { useToast } from '@retail/hooks';
import { useCallback, useMemo, useState } from 'react';
import { DisabledEditState } from '@retail/retailer/components';
import { PageLayout, TagBreadcrumbs } from '@retail/components';
import { mgPrisRoutes } from '@retail/mgpris/config';

export function ByggmaterialCompetitorsPage() {
  const t = useAppTFunction();
  const toast = useToast();
  const { selectedContext } = useContexts();
  const { mutateAsync: postCompetitorsAsync, isLoading } = usePostContextCompetitors(
    selectedContext.id
  );
  const { data: competitorsDto, refetch } = useFetchContextCompetitors(selectedContext.id);
  const { data: allRetailers } = useFetchByggmaterialRetailers();

  const retailers = allRetailers!.map(Retailer.fromByggmaterialpriserRetailerDto(competitorsDto!));

  const prevSelectedStoreIds = useMemo(
    () =>
      retailers
        .flatMap(({ stores }) => stores)
        .filter((store) => store.isSelected)
        .map((store) => store.id),
    [retailers]
  );
  const [selectedStores, setSelectedStores] = useState<number[]>([]);

  const submitCompetitors = useCallback(
    (storeIds: number[]) => {
      const postDto: PostContextCompetitorDTO = {
        stores: storeIds,
      };

      postCompetitorsAsync({ body: postDto })
        .then(() => {
          toast.success(t('admin.retailers.saveSuccess'));
          setSelectedStores([]);
          refetch();
        })
        .catch(() => toast.error(t('admin.retailers.saveError')));
    },
    [postCompetitorsAsync, refetch, t, toast]
  );

  const updateSelectedStores = useCallback((storeId: number) => {
    setSelectedStores((prev) => {
      if (prev.includes(storeId)) {
        return prev.filter((prevStoreIds) => prevStoreIds !== storeId);
      }
      return prev.concat(storeId);
    });
  }, []);

  const addStoresAsCompetitors = useCallback(
    (competitorIds: number[]) => {
      submitCompetitors([...prevSelectedStoreIds, ...competitorIds]);
    },
    [prevSelectedStoreIds, submitCompetitors]
  );

  const removeStoreAsCompetitor = useCallback(
    (competitorId: number) => {
      submitCompetitors(prevSelectedStoreIds.filter((storeId) => storeId !== competitorId));
    },
    [prevSelectedStoreIds, submitCompetitors]
  );

  return (
    <PageLayout maxWidth="full" spacing={2} fixedHeight>
      <Paper
        paddingY="dense"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          gap: 3,
        }}
      >
        <TagBreadcrumbs
          items={[
            {
              label: t('app.routes.marketInsight'),
              href: mgPrisRoutes.marketInsight.getFullLinkPath(),
            },
            { label: t('products.marketInsight.competitors.heading') },
          ]}
        />
        <Stack component={Grid} flexGrow={1} flexDirection="row" overflow="hidden" container>
          <Stack component={Grid} item xs={6} height="100%">
            <ChosenByggematerialCompetitors
              removeStore={removeStoreAsCompetitor}
              retailers={retailers}
              selectedStores={prevSelectedStoreIds.length}
            />
          </Stack>

          <Stack component={Grid} paddingLeft={3} item xs={6} height="100%">
            {selectedContext.isAdmin ? (
              <EditByggmaterialCompetitors
                addStores={addStoresAsCompetitors}
                retailers={retailers}
                setSelectedStores={setSelectedStores}
                loading={isLoading}
                updateSelectedStores={updateSelectedStores}
                selectedStores={selectedStores}
              />
            ) : (
              <DisabledEditState />
            )}
          </Stack>
        </Stack>
      </Paper>
    </PageLayout>
  );
}
