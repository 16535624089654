import { EquivalentInfoDTO } from '@retail/products/types';

export const sortEquivalentInfo = (
  a1: EquivalentInfoDTO,
  a2: EquivalentInfoDTO
): number => {
  if (a1.nobbNr) {
    if (a2.nobbNr) {
      if (a1.nobbNr === a2.nobbNr) {
        return a1.retailProductId - a2.retailProductId;
      }
      return a1.nobbNr - a2.nobbNr;
    }
    return -1;
  }
  if (a2.nobbNr) {
    return 1;
  }
  return a1.retailProductId - a2.retailProductId;
};
