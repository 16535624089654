import React, { useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, styled } from '@mui/material';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { Margin, Size } from '../TextInput';
import { useGetErrorMessage } from '@retail/form/validation';

interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label: string;
  menuItems: { value: any; name: string }[];
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  required?: boolean;
  disabled?: boolean;
  margin?: Margin;
  size?: Size;
  fullWidth?: boolean;
  control: Control<TFieldValues>;
}

export const SelectInput = <TFieldValues extends FieldValues>({
  name,
  label,
  menuItems,
  defaultValue = '' as PathValue<TFieldValues, Path<TFieldValues>>,
  required = false,
  disabled,
  margin = 'none',
  size = 'medium',
  fullWidth,
  control,
}: Props<TFieldValues>) => {
  const labelId = useMemo(() => `label-${name}`, [name]);
  const inputId = useMemo(() => `input-${name}`, [name]);
  const getErrorMessage = useGetErrorMessage();

  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      margin={margin}
      disabled={disabled}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <>
            <InputLabel error={!!error} id={labelId}>
              {label}
            </InputLabel>
            <Select
              {...field}
              displayEmpty
              id={inputId}
              labelId={labelId}
              label={label}
              error={!!error}
            >
              {menuItems.map(({ value, name }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <StyledFormHelperText error>{getErrorMessage(error['message'])}</StyledFormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 1rem;
`;
