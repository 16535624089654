import Tooltip from '@mui/material/Tooltip';
import { Typography, TypographyProps } from '@mui/material';

interface Props {
  unit: string;
  description?: string;
  typographyProps?: TypographyProps;
}

export function UnitTooltip({ unit, description, typographyProps = {} }: Props) {
  return description ? (
    <Tooltip title={description}>
      <Typography {...typographyProps}>{unit}</Typography>
    </Tooltip>
  ) : (
    <Typography {...typographyProps}>{unit}</Typography>
  );
}
