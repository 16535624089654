import { Add } from '@mui/icons-material';
import { Button, FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { MgAssortmentBase } from '@retail/assortment/types';

interface Props {
  mgAssortments: MgAssortmentBase[];
  selectedAssortment: MgAssortmentBase | null;
  setSelectedAssortment: (assortment: MgAssortmentBase | null) => void;
  openCreateManuallyModal: () => void;
}

export function AddOrSelectAssortmentDropdown({
  selectedAssortment,
  mgAssortments,
  setSelectedAssortment,
  openCreateManuallyModal,
}: Props) {
  const t = useAssortmentTFunction();
  return (
    <FormControl sx={{ zIndex: 1 }}>
      <InputLabel>{t('assortment.chooseAssortment')}</InputLabel>
      <Select
        value={selectedAssortment?.id || ''}
        onChange={(event) => {
          const chosenAssortment = mgAssortments.find(
            (assortment) => assortment.id === event.target.value
          );
          setSelectedAssortment(chosenAssortment || null);
        }}
        label={t('assortment.chooseAssortment')}
      >
        <ListSubheader>{t('assortment.admin.update.import.addOrReplace.add')}</ListSubheader>
        <MenuItem LinkComponent={Button} onClick={openCreateManuallyModal}>
          {t('assortment.addToNewAssortment')}
          <Add />
        </MenuItem>
        {mgAssortments.length > 0 && (
          <ListSubheader>{t('assortment.chooseExistingAssortment')}</ListSubheader>
        )}
        {mgAssortments.map((assortment) => {
          return (
            <MenuItem key={assortment.id} value={assortment.id}>
              {assortment.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
