import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { ItemCodes } from '@retail/assortment/types';
import { ExpandCircleDown } from '@mui/icons-material';
import { Chip } from '@shared/components';
import { useMemo } from 'react';
import { AssortmentImportState } from './ImportAssortment';

interface Props {
  importState: AssortmentImportState;
  invalidItemCodes?: ItemCodes;
}

export function ValidateSpreadsheet({ importState, invalidItemCodes }: Props) {
  const t = useAssortmentTFunction();

  const validFinfoNumbers = importState.finfoNumbers?.filter(
    (item) => !invalidItemCodes?.finfoNumbers?.includes(item)
  );

  const validGtinNumbers = importState.gtinNumbers?.filter(
    (item) => !invalidItemCodes?.gtinCodes?.includes(item)
  );
  const validMgItemNumbers = importState.mgItemNumbers?.filter(
    (item) => !invalidItemCodes?.mgItemNumbers?.includes(item)
  );
  const hasInvalidNumbers = useMemo(
    () =>
      !!invalidItemCodes?.finfoNumbers?.length ||
      !!invalidItemCodes?.gtinCodes?.length ||
      !!invalidItemCodes?.finfoNumbers?.length,
    [invalidItemCodes]
  );

  return (
    <Stack gap={1}>
      <Alert severity="success">
        {!!importState.nobbNumbers?.length && (
          <Typography>
            {t('assortment.admin.create.fromFile.import.success.description', {
              count: importState.nobbNumbers.length,
              numbers: t('assortment.admin.create.fromFile.import.nobb'),
            })}
          </Typography>
        )}
        {!!importState.finfoNumbers?.length && (
          <Typography>
            {t('assortment.admin.create.fromFile.import.success.description', {
              count: validFinfoNumbers?.length,
              numbers: t('assortment.admin.create.fromFile.import.finfo'),
            })}
          </Typography>
        )}
        {!!importState.gtinNumbers?.length && (
          <Typography>
            {t('assortment.admin.create.fromFile.import.success.description', {
              count: validGtinNumbers?.length,
              numbers: t('assortment.admin.create.fromFile.import.gtin'),
            })}
          </Typography>
        )}
        {!!importState.mgItemNumbers?.length && (
          <Typography>
            {t('assortment.admin.create.fromFile.import.success.description', {
              count: validMgItemNumbers?.length,
              numbers: t('assortment.admin.create.fromFile.import.mgItem'),
            })}
          </Typography>
        )}
      </Alert>
      {invalidItemCodes && hasInvalidNumbers && (
        <Alert severity="error">
          <Stack gap={1}>
            <Typography>{t('assortment.admin.create.fromFile.import.notValid')}</Typography>
            {!!invalidItemCodes.finfoNumbers?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="finfo">
                  {t('assortment.admin.create.fromFile.import.notValidItem', {
                    count: invalidItemCodes.finfoNumbers.length,
                    numbers: t('assortment.admin.create.fromFile.import.finfo'),
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {invalidItemCodes.finfoNumbers.map((nr) => (
                    <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            )}
            {!!invalidItemCodes.gtinCodes?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="gtin">
                  {t('assortment.admin.create.fromFile.import.notValidItem', {
                    count: invalidItemCodes.gtinCodes.length,
                    numbers: t('assortment.admin.create.fromFile.import.gtin'),
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {invalidItemCodes.gtinCodes.map((nr) => (
                    <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            )}
            {!!invalidItemCodes.mgItemNumbers?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="mgItem">
                  {t('assortment.admin.create.fromFile.import.notValidItem', {
                    count: invalidItemCodes.mgItemNumbers.length,
                    numbers: t('assortment.admin.create.fromFile.import.mgItem'),
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {invalidItemCodes.mgItemNumbers.map((nr) => (
                    <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            )}
          </Stack>
        </Alert>
      )}
    </Stack>
  );
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'inherit',
  border: `1px solid ${theme.palette.error.main}`,
}));
