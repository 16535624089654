import { prisinnsiktConfig } from '@retail/app/config';
import { useToken } from '../../hooks/useToken';
import { useQuery } from '@tanstack/react-query';
import { PriceUnit } from '../../types/PriceUnits';
import { fetcher } from '@shared/fetch-utils';

const url = `${prisinnsiktConfig.pps.url}/v1/priceunits`;

export async function fetchPriceUnits(token: string): Promise<PriceUnit[]> {
  return fetcher<PriceUnit[]>({
    url,
    token,
  });
}

// TODO move to common folder
type Options = {
  refetchOnWindowFocus?: boolean;
};

export function usePriceUnitsApi({ refetchOnWindowFocus = false }: Options = {}) {
  const getAccessTokenWithErrorHandling = useToken();
  // @ts-ignore
  const fetcher = async () => {
    const t = await getAccessTokenWithErrorHandling();

    try {
      // id is not undefined here. TODO find something more beauty?
      return await fetchPriceUnits(t);
    } catch (e) {
      // if err === 401
      // try one more time with new token.
      // else logout/relog
    }
  };

  return useQuery(['price-units'], fetcher, {
    refetchOnWindowFocus,
    refetchOnMount: false,
    refetchOnReconnect: false,
    cacheTime: 1000 * 60 * 10,
  });
}
