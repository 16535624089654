import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';
import { AssortmentType } from '@retail/assortment/types';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { NewAssortmentForm, newAssortmentSchema } from '../newAssortmentForm';
import NewAssortmentFields from '../NewAssortmentFields';

interface NewAssortment {
  title: string;
  assortmentType: AssortmentType;
}

interface Props {
  onClose: () => void;
  onSubmit: (createAssortmentProps: NewAssortment) => void;
  isLoading?: boolean;
  hideAssortmentType?: boolean;
  hideAddItemsLater?: boolean;
}

export const CreateAssortmentManuallyDialog: React.FC<Props> = ({
  onClose,
  onSubmit,
  isLoading,
  hideAssortmentType = false,
  hideAddItemsLater,
}) => {
  const t = useAssortmentTFunction();
  const { handleSubmit, control } = useForm<NewAssortmentForm>({
    resolver: yupResolver(newAssortmentSchema),
    shouldUnregister: false,
    defaultValues: hideAssortmentType ? { assortmentType: AssortmentType.monitoring } : undefined,
  });
  const onSubmitForm = handleSubmit(({ title, assortmentType }) => {
    onSubmit({
      title,
      assortmentType,
    });
  });
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('assortment.admin.create.title')}</DialogTitle>
      <DialogContent>
        <NewAssortmentFields hideAssortmentType={hideAssortmentType} control={control} />
        {!hideAddItemsLater && (
          <Typography>{t('assortment.admin.create.empty.description')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <OutlinedButton size="small" onClick={onClose} disabled={isLoading}>
          {t('assortment.common.cancel')}
        </OutlinedButton>
        <ContainedButton size="small" onClick={onSubmitForm} loading={!!isLoading}>
          {!hideAddItemsLater
            ? t('assortment.admin.create.empty.save')
            : t('assortment.admin.create.assortmentWithItems')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
