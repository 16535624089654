export const routes = {
  root: {
    path: '/',
  },
  products: {
    path: '/products',
    sub: {
      product: {
        path: ':productId',
      },
    },
  },
  admin: {
    path: '/admin',
    sub: {
      users: {
        path: 'users',
      },
      superusers: {
        path: 'superusers',
      },
      assortments: {
        path: 'assortment',
        sub: {
          assortment: {
            path: ':assortmentId',
          },
        },
      },
      retailers: {
        path: 'retailers',
      },
      competitors: {
        path: 'competitors',
      },
      equivalents: {
        path: 'equivalents',
      },
      units: {
        path: 'units',
      },
    },
  },
  firstLogin: {
    path: '/login/retailers',
  },
  notAuthorized: {
    path: '/not-authorized',
  },
};
