import styled from '@emotion/styled';
import { Box, Button, Divider, Typography } from '@mui/material';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { HeaderText } from '../Header';
import { ContentLoader } from '@retail/components';
import { SearchInput } from '@retail/components';
import { ProductCard } from '../ProductCard';
import { TopBarContainer } from '../TopBarContainer';
import { dropZoneSearch } from '../../config/constants';
import { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { BaseNobbProductType } from '@retail/products/types';
import { useAppTFunction } from '@retail/app/i18n';
import { StrictModeDroppable } from '@shared/components';

const DragIcon = styled(OpenWithIcon)({
  width: '30px',
  height: '30px',
  '&:hover': {
    cursor: 'grab',
  },
});

type ProductSearchAndResultProps = {
  setQuery: (v: string) => void;
  isLoading: boolean;
  isIdle: boolean;
  products: BaseNobbProductType[] | undefined;
  hasMore: boolean;
  hasPrev: boolean;
  loadNextPage: () => void;
  loadPrevPage: () => void;
};

export function ProductSearchAndResult({
  setQuery,
  hasMore,
  hasPrev,
  products,
  isIdle,
  isLoading,
  loadNextPage,
  loadPrevPage,
}: ProductSearchAndResultProps) {
  const t = useAppTFunction();

  return (
    <Fragment>
      <HeaderText>{t('assortment.admin.search.title')}</HeaderText>
      <Box
        display="flex"
        flexDirection="column"
        height={'100%'}
        bgcolor="white"
        borderRadius={'10px'}
      >
        <TopBarContainer>
          <Box width={'100%'}>
            <SearchInput onChange={setQuery} />
            <Typography align="right">{t('assortment.admin.edit.howTo')}</Typography>
          </Box>
        </TopBarContainer>
        <Divider />
        <Box bgcolor="white" height={'100%'} flexGrow="1">
          {isLoading && <ContentLoader show={true} />}
          {isIdle && <Box textAlign="center">{t('assortment.admin.search.description')}</Box>}
          {products?.length === 0 && (
            <Box textAlign="center">{t('assortment.admin.search.noResults')}</Box>
          )}
          <StrictModeDroppable droppableId={dropZoneSearch} isDropDisabled>
            {(provided, _) => (
              <div ref={provided.innerRef}>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                    gridGap: '5px 5px',
                  }}
                >
                  {products?.map((p, idx) => {
                    return (
                      <Draggable key={p.nobbNr} draggableId={`${p.nobbNr}`} index={idx}>
                        {(provided, _) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <ProductCard key={p.nobbNr} product={p} icon={<DragIcon />} />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </Box>
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
          <Box display="flex">
            {!isIdle && !isLoading && (
              <Button
                disabled={!hasPrev}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  loadPrevPage();
                }}
              >
                {t('assortment.admin.search.lastPage')}
              </Button>
            )}
            {hasMore && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  loadNextPage();
                }}
              >
                {t('assortment.admin.search.nextPage')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
