import { MgProductPriceDTO, PriceProvider } from '@retail/products/types';
import { prisinnsiktConfig } from '@retail/app/config';
import { useSelectedContext } from '../../stores/userContexts';
import { NobbNr } from '@retail/products/types';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useAuthContext } from '..';

const url = prisinnsiktConfig.pps.url + '/v1/price/{provider}/PriceByDate?from={from}';

interface Options extends QueryOptions {
  nobbNumbers: NobbNr[];
  priceProvider: PriceProvider;
  from: string;
}

export const useFetchHistoricPriceForProvider = ({
  nobbNumbers,
  priceProvider,
  from,
  ...queryOptions
}: Options) => {
  const context = useSelectedContext();
  const { token } = useAuthContext();

  return useFetch<MgProductPriceDTO[]>({
    queryKey: [priceProvider, context, nobbNumbers],
    method: 'POST',
    body: nobbNumbers,
    url: url.split('{provider}').join(priceProvider).split('{from}').join(`${from}`),
    context: { key: context.type, value: context.value },
    token,
    ...queryOptions,
  });
};
