import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  containerHeading: string;
}

const ContainerLoadingError: React.FC<Props> = ({ containerHeading }) => {
  const t = useAppTFunction();

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{containerHeading}</Typography>
      <Typography>{t('common.error.fetch.resource', { resource: containerHeading })}</Typography>
    </Stack>
  );
};

export default ContainerLoadingError;
