import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { MgParticipant } from '@retail/products/types';
import { Supplier } from './Supplier';
import { SupplierSearchField } from './SupplierSearchField';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  suppliers: MgParticipant[];
  isAdmin: boolean;
  isAFavoriteSupplier: (supplierId: number) => boolean;
  selectSupplier: (selectedSupplier: MgParticipant | null) => void;
  editFavoriteSuppliers: (supplier: MgParticipant) => void;
  filterSuppliers: (search: string) => void;
}

export function AllSuppliersLayout({
  suppliers,
  isAdmin,
  isAFavoriteSupplier,
  selectSupplier,
  editFavoriteSuppliers,
  filterSuppliers,
}: PropsWithChildren<Props>) {
  const { palette } = useTheme();
  const t = useAppTFunction();

  return (
    <Stack width={550} bgcolor={palette.common.white} pb={1}>
      <Stack position="sticky" top={0} alignItems="center" zIndex={1}>
        <Box
          height={65}
          display="grid"
          bgcolor={palette.primary[400]}
          alignContent="center"
          width={552}
        >
          <Typography align="center" variant="h5" color={palette.common.white}>
            {t('common.supplierSelect.suppliers')}
          </Typography>
        </Box>
        <SupplierSearchField filterSuppliers={filterSuppliers} />
      </Stack>

      <Stack>
        {suppliers.map((supplier) => {
          return (
            <Supplier
              key={supplier.id}
              supplier={supplier}
              isAdmin={isAdmin}
              isAFavoriteSupplier={isAFavoriteSupplier}
              selectSupplier={selectSupplier}
              editFavoriteSuppliers={editFavoriteSuppliers}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
