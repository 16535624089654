import { PageLayout, TagBreadcrumbs, TanstackQueryLoadingWrapper } from '@retail/components';
import {
  useFetchAllMgParticipants,
  useFetchBk04ProductGroups,
  useMgSupplierItemsSearch,
} from '@retail/products/data-access';
import { useCallback, useMemo, useState } from 'react';
import {
  Bk04Overgruppe,
  Bk04Varegruppe,
  MgParticipant,
  MgSupplierItemSearchResult,
} from '@retail/products/types';
import { Autocomplete, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { useMultipleSelectedSearchParamState, usePagination } from '@shared/hooks';
import { Paper } from '@shared/components';
import { MgSupplierItemSearchResults } from '@retail/products/containers';
import { MgSupplierItemFreeSearchField, SearchTags } from '@retail/products/components';

const mgParticipantQueryKey = 'mgParticipantId';
const productGroupQueryKey = 'productGroupId';

export function MgSupplierItemSearchPage() {
  const t = useProductsTFunction();
  const { data: participantDtos } = useFetchAllMgParticipants({ suspense: true });
  const { data: bk04ProductGroupsDto } = useFetchBk04ProductGroups({ suspense: true });

  const bk04ProductGroups = bk04ProductGroupsDto!.map(Bk04Overgruppe.fromDto);
  const flattenedProductGroups = Bk04Overgruppe.flatten(bk04ProductGroups);

  const allProductGroups: Bk04Varegruppe[] = useMemo(
    () =>
      bk04ProductGroupsDto!.flatMap(({ hovedgrupper }) =>
        hovedgrupper.flatMap(({ varegrupper }) => varegrupper.map(Bk04Varegruppe.fromDto))
      ),
    [bk04ProductGroupsDto]
  );

  const mgParticipants = useMemo(
    () => participantDtos?.map(MgParticipant.fromMinimalDto) || [],
    [participantDtos]
  );
  const [selectedMgParticipants, selectMgParticipants] = useMultipleSelectedSearchParamState({
    options: mgParticipants,
    getOptionId: ({ id }) => `${id}`,
    searchParamKey: mgParticipantQueryKey,
  });

  const [selectedProductGroups, selectProductGroups] = useMultipleSelectedSearchParamState({
    options: flattenedProductGroups,
    getOptionId: ({ commonGroupId }) => `${commonGroupId}`,
    searchParamKey: productGroupQueryKey,
  });
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const hasAddedFreeSearch = searchTerms.length > 0;
  const onSubmitFreeSearchTerm = useCallback(
    (term: string) => {
      setSearchTerms((prev) => {
        if (prev.includes(term)) {
          return prev;
        }
        return [...prev, term];
      });
    },
    [setSearchTerms]
  );

  const productGroupLevel = useMemo(() => {
    if (selectedProductGroups) {
      const overgruppe: string[] = [];
      const hovedgruppe: string[] = [];
      const varegruppe: string[] = [];

      selectedProductGroups.map((selectedProductGroup) => {
        if (selectedProductGroup.commonGroupId.length === 2) {
          overgruppe.push(selectedProductGroup.id + '');
        }
        if (selectedProductGroup.commonGroupId.length === 3) {
          hovedgruppe.push(selectedProductGroup.id + '');
        }
        if (selectedProductGroup.commonGroupId.length === 5) {
          varegruppe.push(selectedProductGroup.id + '');
        }
        return null;
      });
      return { overgruppe, hovedgruppe, varegruppe };
    }
    return null;
  }, [selectedProductGroups]);

  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: 100,
    initPage: 0,
  });
  const loadingState = useMgSupplierItemsSearch(
    {
      pageSize: pageSize,
      page: page,
      textSearch: hasAddedFreeSearch ? searchTerms.join(',') : undefined,
      mgParticipantAccountIds: selectedMgParticipants
        ? selectedMgParticipants?.map(({ id }) => id)
        : undefined,
      bk04OvergruppeIds: productGroupLevel?.overgruppe,
      bk04HovedgruppeIds: productGroupLevel?.hovedgruppe,
      bk04VaregruppeIds: productGroupLevel?.varegruppe,
    },
    {
      enabled: !!selectedMgParticipants || !!productGroupLevel || hasAddedFreeSearch,
      suspense: false,
      retry: 0,
    }
  );
  const { shape, palette, spacing } = useTheme();

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Paper paddingY="dense">
        <TagBreadcrumbs items={[{ label: t('products.search.title') }]} />
        <Stack
          my={2}
          direction="row"
          justifyContent="space-evenly"
          gap={3}
          p={`${spacing(3)} ${spacing(2)}`}
          borderRadius={shape.borderRadius}
          bgcolor={palette.background.secondary}
          flexWrap="wrap"
        >
          <Stack alignItems="center" gap={1.5}>
            <Typography
              component="label"
              htmlFor="supplierSelect"
              variant="h5"
              color={palette.grey.A400}
            >
              {t('products.supplier.name')}
            </Typography>
            <Autocomplete
              id="supplierSelect"
              disabled={loadingState.isFetching}
              multiple
              filterSelectedOptions
              disableClearable
              renderTags={() => null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedMgParticipants || []}
              onChange={(_, newValue) => {
                selectMgParticipants(newValue);
                setPage(0);
              }}
              options={mgParticipants}
              sx={{ width: 320 }}
              getOptionLabel={({ name }) => name}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('products.supplier.searchForSuppliers')} />
              )}
            />
          </Stack>
          <Stack alignItems="center" gap={1.5}>
            <Typography
              component="label"
              htmlFor="productGroups"
              variant="h5"
              color={palette.grey.A400}
            >
              {t('products.search.productGroup.label')}
            </Typography>
            <Autocomplete
              id="productGroups"
              disabled={loadingState.isFetching}
              multiple
              filterSelectedOptions
              disableClearable
              renderTags={() => null}
              value={selectedProductGroups || []}
              onChange={(_, newValue) => {
                selectProductGroups(newValue);
                setPage(0);
              }}
              options={flattenedProductGroups}
              getOptionLabel={({ name, commonGroupId }) =>
                name ? `${commonGroupId} ${name}` : commonGroupId
              }
              renderInput={(params) => (
                <TextField
                  sx={{ width: 320 }}
                  {...params}
                  placeholder={t('products.search.productGroup.searchInProductgroup')}
                />
              )}
            />
          </Stack>
          <Stack alignItems="center" gap={1.5}>
            <Typography
              component="label"
              htmlFor="freeSearch"
              variant="h5"
              color={palette.grey.A400}
            >
              {t('products.search.openSearch.label')}
            </Typography>
            <MgSupplierItemFreeSearchField
              isDisabled={loadingState.isFetching}
              onSubmitSearch={onSubmitFreeSearchTerm}
            />
          </Stack>
        </Stack>

        {!loadingState.isFetching && !loadingState.data && !loadingState.error && (
          <Stack py={17}>
            <Typography variant="h3" color={palette.grey[200]} alignSelf="center">
              {t('products.mgItem.search.infoText')}
            </Typography>
          </Stack>
        )}
        <SearchTags
          selectedMgParticipants={selectedMgParticipants}
          selectMgParticipants={selectMgParticipants}
          selectedProductGroups={selectedProductGroups}
          selectProductGroups={selectProductGroups}
          addedFreeSearch={searchTerms}
          addFreeSearch={setSearchTerms}
          totalMatchesInSearch={loadingState.data?.totalCount}
        />
        <TanstackQueryLoadingWrapper loadingState={loadingState} showReloadButtonOnError>
          {(searchResults) => {
            const supplierItems = MgSupplierItemSearchResult.fromSearchResultDTOs(
              searchResults.result,
              mgParticipants,
              allProductGroups
            );
            return (
              <MgSupplierItemSearchResults
                totalPages={searchResults.totalPages}
                supplierItems={supplierItems}
                currentPage={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            );
          }}
        </TanstackQueryLoadingWrapper>
      </Paper>
    </PageLayout>
  );
}
