import { FreightMarkup } from "@retail/freight-markup/types";
import { MgSupplierItemDetailedDTO } from '@retail/products/types';

const supplier: FreightMarkup[] = [
  {
    id: '1',
    level: 'supplier',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
    supplierFreight: {
      supplierId: 4266714,
      freightFactor: 5,
    },
  },
];

const overGroups: FreightMarkup[] = [
  {
    id: '2',
    level: 'overGroup',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
    productGroupFreight: {
      freightFactor: 23,
      productGroup: {
        id: 23,
        groupNumber: '1',
        name: 'Byggmaterial',
        getLabel: () => '',
      },
    },
  },
  {
    id: '3',
    level: 'overGroup',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
    productGroupFreight: {
      freightFactor: 50,
      productGroup: {
        id: 123,
        groupNumber: '2',
        name: 'Träprodukter',
        getLabel: () => '',
      },
    },
  },
];

const mainGroups: FreightMarkup[] = [
  {
    id: '4',
    level: 'mainGroup',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
    productGroupFreight: {
      freightFactor: 5,
      productGroup: {
        id: 13,
        groupNumber: '100',
        name: 'Byggnadsblock ock ballast',
        getLabel: () => '',
      },
    },
  },
];

const underGroups: FreightMarkup[] = [
  {
    id: '5',
    level: 'underGroup',
    fromDate: new Date('2024-01-13T03:24:00'),
    toDate: new Date('2024-10-10T03:24:00'),
    productGroupFreight: {
      freightFactor: 40,
      productGroup: {
        id: 123,
        groupNumber: '1000',
        name: 'Krossat bergmaterial',
        getLabel: () => '',
      },
    },
  },
];

const mgItems: FreightMarkup[] = [
  {
    id: '6',
    level: 'mgSupplierItem',
    fromDate: new Date('2024-02-10T03:24:00'),
    toDate: new Date('2024-06-10T03:24:00'),
    mgSupplierItemFreight: {
      mgSupplierItem: {
        finfoNumber: 12,
        mgSupplierItemId: 200593200,
        primaryText: 'STENKROSS GRAFIT/VIT ',
      },
      freightSurcharge: {
        unit: 'tonn',
        value: 21,
      },
    },
  },
  {
    id: '7',
    level: 'mgSupplierItem',
    fromDate: new Date('2023-12-13T03:24:00'),
    toDate: new Date('2024-04-13T03:24:00'),
    mgSupplierItemFreight: {
      mgSupplierItem: {
        finfoNumber: 12,
        mgSupplierItemId: 200711675,
        primaryText: 'PLANK SÅGAD G4-2 FURU 50X100',
      },
      freightSurcharge: {
        unit: 'kilo',
        value: 13,
      },
    },
  },
  {
    id: '8',
    level: 'mgSupplierItem',
    fromDate: new Date('2024-01-20T03:24:00'),
    toDate: new Date('2024-04-20T03:24:00'),
    mgSupplierItemFreight: {
      mgSupplierItem: {
        finfoNumber: 12,
        mgSupplierItemId: 200211643,
        primaryText: 'SÄTTLAGER STENFLIS SÄCK 2-6MM',
      },
      freightSurcharge: {
        unit: 'STK',
        value: 30,
      },
    },
  },
  {
    id: '9',
    level: 'mgSupplierItem',
    fromDate: new Date('2024-01-20T03:24:00'),
    toDate: new Date('2024-04-20T03:24:00'),
    mgSupplierItemFreight: {
      mgSupplierItem: {
        finfoNumber: 12,
        mgSupplierItemId: 200211644,
        primaryText: 'STENKROSS GRAFIT/VIT 16-32MM',
      },
      freightFactor: 23,
    },
  },

];

export const supplierLevelFreightMarkups: FreightMarkup[] = [
  ...supplier,
  ...overGroups,
  ...mainGroups,
  ...underGroups,
  ...mgItems,
];

export const overGroupLevelFreightMarkups: FreightMarkup[] = [
  ...overGroups,
  ...mainGroups,
  ...underGroups,
  ...mgItems,
];

export const mainGroupLevelFreightMarkups: FreightMarkup[] = [
  ...mainGroups,
  ...underGroups,
  ...mgItems,
];

export const underGroupLevelFreightMarkups: FreightMarkup[] = [...underGroups, ...mgItems];

export const mgItemLevelFreightMarkups: FreightMarkup[] = [...mgItems];

export const supplierItemTestData: MgSupplierItemDetailedDTO = {
  mgSupplierItemId: 612332,
  createdAt: '',
  updatedAt: '',
  mgParticipantAccount: {
    customerSpecificSupplierId: 23,
    finfoSupplierId: 3,
    mgParticipantAccountId: 3,
    participantAccountName: 'string',
    vilmaLevel: 'string',
  },
  mgSupplierItemPackages: [
    {
      calculatedCount: 2,
      createdAt: '',
      deleted: false,
      deliverable: true,
      mgSupplierItemPackageId: 1,
      type: '',
      updatedAt: '',
      vilmaUnit: { unit: 'STK' },
    },
    {
      calculatedCount: 2,
      createdAt: '',
      deleted: false,
      deliverable: true,
      mgSupplierItemPackageId: 2,
      type: '',
      updatedAt: '',
      vilmaUnit: { unit: 'PKT' },
    },
    {
      calculatedCount: 2,
      createdAt: '',
      deleted: false,
      deliverable: true,
      mgSupplierItemPackageId: 3,
      type: '',
      updatedAt: '',
      vilmaUnit: { unit: 'PALL' },
    },
    {
      calculatedCount: 2,
      createdAt: '',
      deleted: false,
      deliverable: true,
      mgSupplierItemPackageId: 4,
      type: '',
      updatedAt: '',
      vilmaUnit: { unit: 'tonn' },
    },
    {
      calculatedCount: 2,
      createdAt: '',
      deleted: false,
      deliverable: true,
      mgSupplierItemPackageId: 5,
      type: '',
      updatedAt: '',
      vilmaUnit: { unit: 'kilo' },
    },
  ],
  supplierItemId: '2',
  primaryText: '',
};