import { ProductGroupAgreementsTable } from '@retail/purchase-agreements/components';
import { Paper } from '@shared/components';
import {
  ProductGroupAgreement,
  ProductGroupWithAgreements,
} from '@retail/purchase-agreements/types';
import { useCallback } from 'react';
import { usePurchaseAgreementsToActivate } from '@retail/purchase-agreements/stores';
import { MgParticipant } from '@retail/products/types';

type Props = {
  agreements: ProductGroupWithAgreements;
  mgSupplier: MgParticipant;
  productGroupId?: string;
  totalItems: number;
};

export const ProductGroupAgreements = ({
  agreements,
  mgSupplier,
  productGroupId,
  totalItems,
}: Props) => {
  const { addProductGroupAgreement, addMgSupplierAgreement } = usePurchaseAgreementsToActivate();

  const inactiveAgreements = agreements.agreements.filter(
    (agreement) => agreement.status === 'inactive'
  );
  const activeAgreement = agreements.agreements.find(
    (agreement) => agreement.status !== 'inactive'
  );

  const submitForConfirmation = useCallback(
    (agreement: ProductGroupAgreement) => {
      productGroupId
        ? addProductGroupAgreement({
            productGroupNr: agreements.productGroupId!,
            productGroupName: agreements.productGroupName || '',
            nrOfAffectedItems: totalItems,
            prevActiveAgreement: activeAgreement!,
            newActiveAgreement: agreement,
          })
        : addMgSupplierAgreement({
            supplierId: mgSupplier.id + '',
            supplierName: mgSupplier.name,
            nrOfAffectedItems: totalItems,
            prevActiveAgreement: activeAgreement!,
            newActiveAgreement: agreement,
          });
    },
    [
      activeAgreement,
      addProductGroupAgreement,
      addMgSupplierAgreement,
      mgSupplier,
      productGroupId,
      totalItems,
      agreements,
    ]
  );

  return (
    <Paper paddingY="dense" tabPanel variant="outlined" sx={{ overflow: 'auto', p: 0 }}>
      <ProductGroupAgreementsTable
        agreements={inactiveAgreements}
        agreementIdAwaitingConfirmation={agreements.awaitingConfirmationAgreementId}
        submitForConfirmation={submitForConfirmation}
      />
    </Paper>
  );
};
