import { prisinnsiktConfig } from '@retail/app/config';
import { fetcher, useQueryFetch } from '@shared/fetch-utils';
import { NobbNr, NobbProductDTO } from '@retail/products/types';
import { useCallback } from 'react';
import { useAuthContext } from './AuthContext';

const url = prisinnsiktConfig.pps.url + '/v1/nobb/products';

export async function fetchProduct(token: string, nobbNr: NobbNr) {
  return fetcher<NobbProductDTO>({
    url: url + '/' + nobbNr,
    token,
  });
}

type Options = {
  id: NobbNr;
  refetchOnWindowFocus?: boolean;
};

export function useProductApi({ refetchOnWindowFocus = false, id }: Options) {
  const { token } = useAuthContext();
  const fetcher = useCallback(() => fetchProduct(token, id), [token, id]);

  return useQueryFetch({
    fetcher,
    queryKey: ['product', id],
    refetchOnWindowFocus,
    enabled: !!id,
  });
}
