import PriceLoader from './PriceLoader';
import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { CompetitorPrice, CompetitorPriceDTO } from '@retail/products/types';
import { renderCompetitorPrice } from '@retail/products/utils';
import { OpenInNew } from '@mui/icons-material';
import NoPrice from './NoPrice';
import VariantTooltip from './VariantTooltip';
import CampaignPriceTooltip from './CampaignPriceTooltip';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  price: CompetitorPriceDTO;
  variantInfo?: {
    productName: string;
    nobbNr: number;
  };
}

const CompetitorPriceCell: React.FC<Props> = ({ price, variantInfo }) => {
  const actualPrice = useMemo(() => renderCompetitorPrice(price), [price]);
  const t = useProductsTFunction();

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        {price.onDiscount && <CampaignPriceTooltip />}
        {variantInfo && <VariantTooltip {...variantInfo} />}
        <Typography fontSize="inherit">
          {t('products.prices.pricePerUnit', {
            price: actualPrice,
            unit: price.convertedUnit || price.primaryUnit,
          })}
        </Typography>
        <Box component="a" href={price.urlProduct} target="_blank" display="flex">
          <OpenInNew
            fontSize="inherit"
            titleAccess={t('products.productList.competitorPrice.openInNew')}
          />
        </Box>
      </Stack>
      <Typography fontSize="inherit">
        <i>{`${t('products.productList.updated')}: ${new Date(
          price.lastObservedAt
        ).toLocaleDateString()}`}</i>
      </Typography>
    </Box>
  );
};

const CompetitorPriceCellContainer: React.FC<CompetitorPrice> = ({
  price,
  lowestVariantPrice,
  isLoading,
}) => {
  if (isLoading) {
    return <PriceLoader />;
  }
  if (price) {
    return <CompetitorPriceCell price={price} />;
  }

  if (lowestVariantPrice?.price) {
    return (
      <CompetitorPriceCell
        price={lowestVariantPrice.price}
        variantInfo={{
          nobbNr: lowestVariantPrice.nobbNr,
          productName: lowestVariantPrice.productName,
        }}
      />
    );
  }

  return <NoPrice />;
};

export default CompetitorPriceCellContainer;
