import { InfoOutlined } from '@mui/icons-material';
import { Autocomplete, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, Paper } from '@shared/components';

export function DisabledEditState() {
  const t = useAppTFunction();

  return (
    <Paper variant="outlined">
      <Stack flexDirection="row" alignItems="center" mb={3}>
        <Typography color={({ palette }) => palette.grey[200]} variant="h6" component="h2" mr={1}>
          {t('products.marketInsight.competitors.chooseCompetitors')}
        </Typography>
        <Tooltip title={t('products.marketInsight.competitors.onlyAdminAccess')}>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Stack>
      <Autocomplete
        disabled
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('products.marketInsight.competitors.chooseRetailer')}
          />
        )}
      />
      <ContainedButton sx={{ alignSelf: 'flex-end', mt: 2 }} disabled>
        {t('common.save')}
      </ContainedButton>
    </Paper>
  );
}
