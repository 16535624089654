import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  AddOrSelectAssortmentDropdown,
  CreateAssortmentManuallyDialog,
  NewAssortment,
} from '@retail/assortment/components';
import { useToast } from '@retail/hooks';
import {
  MgSupplierItemsTableWithDelete,
  SuccessAddingItemsDialog,
} from '@retail/products/components';
import {
  getMgAssortmentsQueryKey,
  useCreateMgAssortment,
  useFetchMgAssortments,
} from '@retail/assortment/data-access';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton, Dialog, OutlinedButton, TagHeading } from '@shared/components';
import { FetchError } from '@shared/fetch-utils';
import { useDisclosure } from '@shared/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMgAssortmentActions } from '@retail/assortment/context';
import { useContexts } from '@retail/app/stores/selected-context';
import { MgAssortmentBase } from '@retail/assortment/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedItems: MgSupplierItemSearchResult[];
  setCheckedItems: (ids: number[]) => void;
}

function MonitorMgSupplierItemsDialog({ open, selectedItems, onClose, setCheckedItems }: Props) {
  const { palette, shape } = useTheme();
  const t = useAppTFunction();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { selectedContext } = useContexts();
  const { data: mgAssortmentDtos = [] } = useFetchMgAssortments([], {
    enabled: open,
    suspense: true,
  });
  const mgAssortments = useMemo(
    () => mgAssortmentDtos.map(MgAssortmentBase.fromDto),
    [mgAssortmentDtos]
  );
  const [selectedAssortment, setSelectedAssortment] = useState<MgAssortmentBase | null>(null);
  const [successDialog, openSuccessDialog] = useState(false);
  const uniqueMgItemIds = useMemo(
    () => Array.from(new Set(selectedItems.map(({ mgItemNumber }) => mgItemNumber))),
    [selectedItems]
  );
  const removeCheckedItem = useCallback(
    (mgItemId: number) => {
      setCheckedItems(
        selectedItems
          .map((item) => item.mgItemNumber)
          .filter((checkedItems) => checkedItems !== mgItemId)
      );
    },
    [setCheckedItems, selectedItems]
  );
  const { isLoading, handleAddItemsToMgAssortment } = useMgAssortmentActions();

  const {
    isOpen: isCreateManuallyModalOpen,
    onClose: closeCreateManuallyModal,
    onOpen: openCreateManuallyModal,
  } = useDisclosure(false);

  const { mutateAsync: createMgAssortmentAsync, isLoading: isLoadingCreate } =
    useCreateMgAssortment(selectedContext);

  const submitForm = ({ title, assortmentType }: NewAssortment) => {
    createMgAssortmentAsync({
      body: {
        title,
        assortmentType,
        itemCodes: {
          mgItemNumbers: selectedItems.map(({ mgItemNumber }) => mgItemNumber),
        },
      },
    })
      .then((assortment) => {
        queryClient.invalidateQueries(getMgAssortmentsQueryKey(selectedContext));
        setSelectedAssortment(MgAssortmentBase.fromDto(assortment));
        closeCreateManuallyModal();
        onClose();
        openSuccessDialog(true);
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('assortment.admin.create.error.generic'));
      });
  };

  const handleAddItemsToAssortment = () => {
    if (!selectedAssortment) {
      return;
    }
    return handleAddItemsToMgAssortment({
      assortmentId: selectedAssortment.id,
      mgItemNumbers: uniqueMgItemIds,
    })
      .then((updatedAssortment) => {
        closeCreateManuallyModal();
        onClose();
        openSuccessDialog(true);
        toast.success(t('assortment.admin.update.success', { name: updatedAssortment.title }));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
      });
  };

  const navigate = useNavigate();
  const navigateToAssortment = useCallback(
    (assortmentId: number) => {
      navigate(
        mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(assortmentId + '')
      );
    },
    [navigate]
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="3xl">
        <DialogTitle display="flex" justifyContent="space-between">
          <TagHeading color="primary">
            <Typography variant="h4" component="span">
              {t('products.mgItem.add')}
            </Typography>
          </TagHeading>
        </DialogTitle>
        <DialogContent>
          <Box
            maxHeight="calc(100vh - 324px)"
            overflow="scroll"
            mt={2}
            mx={3}
            px={3}
            borderRadius={shape.borderRadius}
            border={`2px solid ${palette.grey.A100}`}
            bgcolor={palette.background.paper}
            sx={{ outline: `16px solid ${palette.primary.A100}` }}
          >
            <Stack
              position="sticky"
              top={0}
              zIndex={1}
              pt={3}
              pb={1}
              bgcolor={palette.common.white}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="h6">
                  {t('products.mgItem.numberChosenItems', { number: selectedItems.length })}
                </Typography>
                <Typography variant="body2">
                  {t('products.search.results.numberOfUniqueItems', {
                    number: uniqueMgItemIds.length,
                  })}
                </Typography>
              </Stack>

              <AddOrSelectAssortmentDropdown
                mgAssortments={mgAssortments}
                selectedAssortment={selectedAssortment}
                setSelectedAssortment={setSelectedAssortment}
                openCreateManuallyModal={openCreateManuallyModal}
              />
            </Stack>
            <MgSupplierItemsTableWithDelete
              items={selectedItems}
              removeCheckedItem={removeCheckedItem}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack flexDirection="row" pt={1} gap={1} justifyContent="right">
            <OutlinedButton onClick={onClose}>{t('common.cancel')}</OutlinedButton>
            <ContainedButton
              loading={isLoading}
              disabled={!selectedAssortment}
              onClick={handleAddItemsToAssortment}
            >
              {t('common.save')}
            </ContainedButton>
          </Stack>
        </DialogActions>
      </Dialog>
      {isCreateManuallyModalOpen && (
        <CreateAssortmentManuallyDialog
          onClose={closeCreateManuallyModal}
          onSubmit={submitForm}
          isLoading={isLoadingCreate}
          hideAddItemsLater
          hideAssortmentType
        />
      )}
      {successDialog && selectedAssortment && (
        <SuccessAddingItemsDialog
          isOpen={successDialog}
          closeDialog={() => {
            setCheckedItems([]);
            openSuccessDialog(false);
          }}
          successText={t('products.mgItem.addItemsToAssortment.addedTo', {
            nrOfItems: uniqueMgItemIds.length,
          })}
          assortmentTitle={selectedAssortment.title}
          assortmentId={selectedAssortment.id}
          navigate={navigateToAssortment}
        />
      )}
    </>
  );
}

export default withDialogSuspenseErrorBoundary(MonitorMgSupplierItemsDialog);
