import React from 'react';
import { Box, Stack } from '@mui/material';
import { BreadcrumbsProps } from '@shared/components';
import { TagBreadcrumbs } from './TagBreadcrumbs';

interface Props extends BreadcrumbsProps {
  endContent?: React.ReactNode;
  marginBottom?: number;
}

export function BreadcrumbsToolbar({ items, endContent, marginBottom }: Props) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <TagBreadcrumbs items={items} />
      <Stack>{endContent}</Stack>
    </Box>
  );
}
