import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import { LoadingErrorComponent } from './LoadingErrorComponent';
import { PPSFetchError } from '@shared/fetch-utils';
import { DefaultPpsErrorFallback } from './DefaultPpsErrorFallback';
import { DefaultLoadingFallback } from './DefaultLoadingFallback';

interface ErrorWrapped {
  status: number;
  text: string;
  payload: PPSFetchError;
}

type UsePPSQueryResult<TData> = UseQueryResult<TData, ErrorWrapped>;

interface Props<TData> {
  loadingState: UsePPSQueryResult<TData>;
  children: (data: TData, status: UsePPSQueryResult<TData>['fetchStatus']) => JSX.Element;
  LoadingComponent?: React.ComponentType;
  ErrorComponent?: LoadingErrorComponent;
  NoDataComponent?: React.ComponentType;
  showReloadButtonOnError?: boolean;
}

export function TanstackQueryLoadingWrapper<TData>({
  loadingState: { fetchStatus, error, data, refetch, isError, isFetching },
  ErrorComponent = DefaultPpsErrorFallback,
  LoadingComponent = DefaultLoadingFallback,
  NoDataComponent = () => null,
  showReloadButtonOnError,
  children,
}: Props<TData>) {
  if (isError && error) {
    return (
      <ErrorComponent
        error={error.payload}
        reload={showReloadButtonOnError ? refetch : undefined}
      />
    );
  }

  if (isFetching) {
    return <LoadingComponent />;
  }

  if (!data) {
    return <NoDataComponent />;
  }

  return children(data, fetchStatus);
}
