import { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { OutlinedButton } from '@shared/components';
import { DataGridPagination } from '@retail/components';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { usePagination } from '@shared/hooks';
import { dataGridBoldClass } from '@shared/styles';
import { ActiveItemAgreementDTO } from '@retail/purchase-agreements/types';
import { ColumnHeaderWithHelpText } from '@retail/components';
import { AgreementStatusIcon } from '../AgreementStatusIcon';

type Props = {
  activeItemAgreements: ActiveItemAgreementDTO[];
  setMgItemToEdit: (itemAgreement: number) => void;
};

export const ItemAgreementsForProductGroupTable = ({
  activeItemAgreements,
  setMgItemToEdit,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();

  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: 10,
    initPage: 0,
  });

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 150,
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'primaryText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('purchaseAgreements.agreement.columns.primaryText'),
        headerAlign: 'left',
        align: 'left',
        width: 200,
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        renderCell: (params) => params.row.agreementText,
        headerAlign: 'left',
        align: 'left',
      },
      {
        ...baseColumnProps,
        field: 'mgItemId',
        headerName: t('purchaseAgreements.agreement.columns.mgItemNr'),
      },
      {
        ...baseColumnProps,
        field: 'status',
        headerName: t('purchaseAgreements.agreement.columns.active'),
        width: 100,
        renderCell: ({ value, row }) => (
          <AgreementStatusIcon status={value} inheritedLevel={row.inheritedLevel} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'fromDate',
        headerName: t('purchaseAgreements.agreement.columns.fromDate'),
        renderCell: (params) => new Date(params.row.fromDate).toLocaleDateString(),
      },
      {
        ...baseColumnProps,
        field: 'toDate',
        headerName: t('purchaseAgreements.agreement.columns.toDate'),
        renderCell: (params) => new Date(params.row.toDate).toLocaleDateString(),
      },
      {
        ...baseColumnProps,
        field: 'priceType',
        headerName: t('purchaseAgreements.agreement.columns.priceType'),
      },
      {
        ...baseColumnProps,
        field: 'price',
        headerName: t('purchaseAgreements.agreement.columns.price'),
        renderCell: ({ value, row: { basePriceUnit } }) =>
          t('purchaseAgreements.pricePerUnit', {
            price: value,
            unit: basePriceUnit,
          }),
      },
      {
        ...baseColumnProps,
        field: 'basePriceUnit',
        renderHeader: () => (
          <ColumnHeaderWithHelpText
            header={t('purchaseAgreements.agreement.columns.basePriceUnit')}
            tooltipContent={t('purchaseAgreements.agreement.columns.basePriceUnitDesc')}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        width: 90,
        renderCell: ({ row }) => (
          <OutlinedButton size="small" onClick={() => setMgItemToEdit(row.mgItemId)}>
            {t('purchaseAgreements.agreement.actions.edit')}
          </OutlinedButton>
        ),
      },
    ],
    [t, baseColumnProps, setMgItemToEdit]
  );

  return (
    <Box height="100%" pb={2}>
      <DataGridPro
        getRowId={(row) => row.mgItemId}
        columns={columns}
        rows={activeItemAgreements}
        rowHeight={65}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        pagination={true}
        components={{
          Pagination: DataGridPagination,
        }}
        rowCount={activeItemAgreements.length}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[10, 15, 20]}
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
      />
    </Box>
  );
};
