import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { AssortmentResources } from '../types/AssortmentResources';

export const useAssortmentTranslation = () => useMessagesTranslation<AssortmentResources>();

export type AssortmentMessageKey = MessagePaths<AssortmentResources>;

export const useAssortmentTFunction = () => useAssortmentTranslation().t;

export type AssortmentTFunction = ReturnType<typeof useAssortmentTFunction>;
