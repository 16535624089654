import React, { useContext } from 'react';
import { MgPrisConfig } from '@retail/mgpris/config-types';

export const MgPrisConfigContext = React.createContext<MgPrisConfig | null>(null);

export const MgPrisContextProvider = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config: MgPrisConfig;
}) => {
  return <MgPrisConfigContext.Provider value={config}>{children}</MgPrisConfigContext.Provider>;
};

export const useMgPrisConfig = () => {
  const mgPrisContext = useContext(MgPrisConfigContext);
  if (!mgPrisContext) {
    throw new Error('useMgPrisContext must be used within a MgPrisContextProvider');
  }
  return mgPrisContext;
};
