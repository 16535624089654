import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

function PageHeader({ children, ...props }: TypographyProps) {
  return (
    <Typography variant="h1" {...props}>
      {children}
    </Typography>
  );
}

export default PageHeader;
