import { SvgIcon, SvgIconProps } from '@mui/material';

export const CrownIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="currentColor"
      >
        <path d="M10.7292 2.72656C11.125 2.48047 11.3889 2.03398 11.3889 1.53125C11.3889 0.754297 10.7674 0.125 10 0.125C9.23264 0.125 8.61111 0.754297 8.61111 1.53125C8.61111 2.0375 8.875 2.48047 9.27083 2.72656L7.28125 6.75547C6.96528 7.39531 6.14583 7.57813 5.59375 7.13164L2.5 4.625C2.67361 4.38945 2.77778 4.09766 2.77778 3.78125C2.77778 3.0043 2.15625 2.375 1.38889 2.375C0.621528 2.375 0 3.0043 0 3.78125C0 4.5582 0.621528 5.1875 1.38889 5.1875C1.39583 5.1875 1.40625 5.1875 1.41319 5.1875L3 14.0258C3.19097 15.0945 4.11111 15.875 5.1875 15.875H14.8125C15.8854 15.875 16.8056 15.098 17 14.0258L18.5868 5.1875C18.5938 5.1875 18.6042 5.1875 18.6111 5.1875C19.3785 5.1875 20 4.5582 20 3.78125C20 3.0043 19.3785 2.375 18.6111 2.375C17.8438 2.375 17.2222 3.0043 17.2222 3.78125C17.2222 4.09766 17.3264 4.38945 17.5 4.625L14.4062 7.13164C13.8542 7.57813 13.0347 7.39531 12.7188 6.75547L10.7292 2.72656Z" />
      </svg>
    </SvgIcon>
  );
};
