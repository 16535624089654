import { delay, http, HttpResponse, RequestHandler } from 'msw';
import {
  affectedItemsPath,
  contextPath,
  itemAgreementsPath,
  mgItemsForProductGroupApiRoute,
  purchaseAgreementApiRoute,
  purchaseAgreementsPath,
  purchaseAgreementsProductGroupsPath,
} from '@retail/purchase-agreements/data-access';
import { purchaseAgreements } from './testdata/purchaseAgreementTestData';
import { productGroupsTestData } from './testdata/productGroupsTestData';
import { agreementsForMgItem, itemsForProductGroup } from './testdata/itemAgreementsTestData';
import { AgreementsForMgItemDTO } from '@retail/purchase-agreements/types';
import {
  productGroupWithAgreementsTestData,
  supplierAgreementsTestData,
} from './testdata/productGroupAgreementsTestData';
import { itemAgreementsForProductGroupTestData } from './testdata/itemAgreementsForProductGroupTestData';
import {
  mgSupplierItemsTestData,
  AffectedItemsWithItemsAndProductGroupTestData,
  AffectedItemsWithItemsTestData,
} from './testdata/mgSupplierItemsTestData';

export const purchaseAgreementHandlers: Record<string, RequestHandler[]> = {
  productGroups: [
    http.get(
      `*${contextPath}/:contextId/${purchaseAgreementsProductGroupsPath}/:mgParticipantAccountId`,
      () => HttpResponse.json(productGroupsTestData)
    ),
  ],
  productGroupItems: [
    http.get(
      `*${purchaseAgreementApiRoute}/:mgParticipantAccountId${mgItemsForProductGroupApiRoute}`,
      ({ request }) => {
        const url = new URL(request.url);
        const query = url.searchParams.get('productGroupIds');
        const numberSearch = url.searchParams.get('numberSearch');
        const textSearch = url.searchParams.get('textSearch');
        const matchingItems = query
          ? itemsForProductGroup.filter(({ productGroupId }) => query?.includes(productGroupId))
          : null;
        const matchMgItemSearch = numberSearch
          ? itemsForProductGroup.filter(({ mgItemNumber }) =>
              (mgItemNumber + '').includes(numberSearch)
            )
          : null;
        const matchPrimaryTextSearch = itemsForProductGroup.filter(({ primaryText }) =>
          textSearch ? primaryText.toLowerCase().includes(textSearch.toLowerCase()) : null
        );
        if (matchingItems) {
          return HttpResponse.json(matchingItems);
        } else if (matchMgItemSearch) {
          return HttpResponse.json(matchMgItemSearch);
        } else if (matchPrimaryTextSearch) {
          return HttpResponse.json(matchPrimaryTextSearch);
        }

        return HttpResponse.json(itemsForProductGroup);
      }
    ),
  ],
  itemAgreements: [
    http.get(
      `*${contextPath}/:contextId/${purchaseAgreementsPath}/:mgParticipantAccountId/${itemAgreementsPath}/:mgItemId`,
      ({ params, request }) => {
        const { mgItemId } = params;
        const url = new URL(request.url);
        const query = url.searchParams.get('agreementIds');
        const matchingItem = agreementsForMgItem.find(
          (item) => item.mgItemNumber === Number(mgItemId)
        );
        if (matchingItem) {
          const selectedAgreements = matchingItem.agreements.filter(({ id }) =>
            query?.includes(id)
          );
          if (selectedAgreements) {
            return HttpResponse.json({
              mgItemNumber: matchingItem.mgItemNumber,
              primaryText: matchingItem.primaryText,
              agreements: selectedAgreements,
            } as AgreementsForMgItemDTO);
          }
          return HttpResponse.json(agreementsForMgItem);
        }
        return new HttpResponse('Item not found', { status: 404 });
      }
    ),
  ],
  productGroupAgreements: [
    http.get(
      `*${contextPath}/:contextId/${purchaseAgreementsPath}/:mgParticipantAccountId`,
      ({ request }) => {
        const url = new URL(request.url);
        const query = url.searchParams.get('productGroupId');

        if (query) {
          const matching = productGroupWithAgreementsTestData.find(
            (productGroup) => productGroup.productGroupId === query
          );
          return HttpResponse.json(matching ? matching : productGroupWithAgreementsTestData[0]);
        }
        return HttpResponse.json(supplierAgreementsTestData);
      }
    ),
  ],
  activeItemAgreementsUnderProductGroup: [
    http.get(
      `*${contextPath}/:contextId/${purchaseAgreementsPath}/:mgParticipantAccountId/${itemAgreementsPath}`,
      ({ request }) => {
        const url = new URL(request.url);
        const query = url.searchParams.get('search');
        const filterAffected = url.searchParams.get('onlyAffected');

        const itemAgreements =
          filterAffected === 'true'
            ? itemAgreementsForProductGroupTestData.slice(0, 5)
            : itemAgreementsForProductGroupTestData;
        const matchingItems =
          query &&
          itemAgreements.filter(
            (item) => item.primaryText.includes(query) || (item.mgItemId + '').includes(query)
          );
        if (query) {
          if (matchingItems) {
            return HttpResponse.json(matchingItems);
          }
          return HttpResponse.json([]);
        }
        return HttpResponse.json(itemAgreements);
      }
    ),
  ],
  affectedItems: [
    http.get(
      `*${purchaseAgreementApiRoute}/:mgParticipantAccountId/${affectedItemsPath}`,
      ({ request }) => {
        const url = new URL(request.url);
        const query = url.searchParams.get('pageSize');
        const hasProductGroupId = url.searchParams.get('productGroupId');
        if (query === '0' && !hasProductGroupId) {
          return HttpResponse.json(mgSupplierItemsTestData);
        }
        if (hasProductGroupId) {
          return HttpResponse.json(AffectedItemsWithItemsAndProductGroupTestData);
        }
        return HttpResponse.json(AffectedItemsWithItemsTestData);
      }
    ),
  ],
  purchaseAgreementSones: [
    http.get(`*${purchaseAgreementApiRoute}/:mgParticipantAccountId`, () =>
      HttpResponse.json(purchaseAgreements)
    ),
  ],
  saveAgreementsForOrgUnit: [
    http.post(`*${contextPath}/:contextId/${purchaseAgreementsPath}/:agreementItemId`, async () => {
      await delay(1000);
      return new HttpResponse(null, { status: 204 });
    }),
  ],
};
