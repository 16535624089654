import { DialogContent, DialogTitle } from '@mui/material';
import { useSelectedCompetitors } from '@retail/app/hooks';
import { useContexts } from '@retail/app/stores/selected-context';
import { useFetchMgItem } from '@retail/products/data-access';
import { MgItemDetailed } from '@retail/products/types';
import { Dialog, Paper, TagHeading } from '@shared/components';
import { MgItemContainer } from './MgItemContainer';
import {
  BodyLoader,
  DefaultPpsErrorFallback,
  ErrorFallback,
  SuspenseWithSentryErrorBoundary,
  TanstackQueryLoadingWrapper,
} from '@retail/components';

export interface Props {
  open: boolean;
  onClose: () => void;
  itemNumber: number;
}

export function MgItemDialog({ onClose, itemNumber }: Props) {
  const { selectedContext } = useContexts();
  const competitors = useSelectedCompetitors(selectedContext.id);
  const mgItemLoadingState = useFetchMgItem(
    {
      mgItemNumber: itemNumber,
      includeByggmaterialpriserPrices: true,
      competitorStoreIds: competitors.map((competitor) => competitor.id),
    },
    { suspense: false }
  );

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <TanstackQueryLoadingWrapper
        loadingState={mgItemLoadingState}
        LoadingComponent={BodyLoader}
        ErrorComponent={DefaultPpsErrorFallback}
      >
        {(mgItemDto) => {
          const mgItem = MgItemDetailed.fromDto(mgItemDto);
          const firstSupplierItem = mgItem.getPrimarySupplierItem();
          return (
            <>
              <DialogTitle pb={0}>
                <TagHeading color="primary">{firstSupplierItem?.primaryText}</TagHeading>
              </DialogTitle>
              <DialogContent>
                <Paper sx={{ height: '100%', p: 0, display: 'flex', flexDirection: 'column' }}>
                  <SuspenseWithSentryErrorBoundary
                    errorFallback={(props) => <ErrorFallback {...props} />}
                    suspenseFallback={<BodyLoader />}
                  >
                    <MgItemContainer mgItem={mgItem} competitors={competitors} />
                  </SuspenseWithSentryErrorBoundary>
                </Paper>
              </DialogContent>
            </>
          );
        }}
      </TanstackQueryLoadingWrapper>
    </Dialog>
  );
}
