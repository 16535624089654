import { Percent } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { TextInputField } from '@shared/components';
import { FromToDateForm } from './FromToDateForm';
import { useController, useFormContext } from 'react-hook-form';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { CreateFreightMarkupSchema, EditFreightMarkupSchema } from './freightMarkupSchema';

export function FreightMarkupFormBase() {
  const t = useFreightMarkupTFunction();
  const { control } = useFormContext<CreateFreightMarkupSchema, EditFreightMarkupSchema>();
  const freightFactorController = useController({ name: 'freightFactor', control });
  return (
    <Stack gap={3}>
      <TextInputField
        {...freightFactorController.field}
        error={freightFactorController.fieldState.error?.message}
        margin="none"
        required
        label={t('freightMarkup.columns.freightSurcharge')}
        size="medium"
        fullWidth
        placeholder={t('freightMarkup.actions.writeFreightSurcharge')}
        endAdornment={<Percent />}
      />
      <FromToDateForm />
    </Stack>
  );
}
