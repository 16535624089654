import { Box, CircularProgress, useTheme } from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useProductsTFunction } from '@retail/products/i18n';
import { ColumnHeaderWithHelpText, CopyToClipboard, DataGridPagination } from '@retail/components';
import { Competitor } from '@retail/retailer/types';
import { getCompetitorColumn } from '../ProductList';
import { BmpCompetitorPriceCell, NoPrice } from '../ProductCell';
import { MgItemMinimal } from '@retail/products/types';
import { dataGridBoldClass } from '@shared/styles';

interface Props {
  items: MgItemMinimal[];
  totalItems: number | undefined;
  pageSize: number | undefined;
  page: number | undefined;
  excludeVat: boolean;
  toggleSimpleView: boolean;
  competitors: Competitor[];
  updatePageSize: (pageSize: number) => void;
  updatePage: (page: number) => void;
  setItemToView: (mgItemNumber: number) => void;
}

export function MgAssortmentItemsView({
  items,
  totalItems,
  pageSize,
  page,
  excludeVat,
  toggleSimpleView,
  competitors,
  updatePageSize,
  updatePage,
  setItemToView,
}: Props) {
  const t = useProductsTFunction();
  const iconColor = useTheme().palette.grey[900];
  const [columnOrder, setColumnOrder] = useState<string[]>();
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = useMemo(() => {
    const productNameColDef: GridColDef<MgItemMinimal> = {
      field: 'name',
      headerName: t('products.mgItem.supplierItem.primaryText'),
      minWidth: 180,
      sortable: false,
      cellClassName: () => dataGridBoldClass,
      renderCell: ({ row }) => {
        const primarySupplierItem = row.getPrimarySupplierItem();
        return <span>{primarySupplierItem?.primaryText || '-'}</span>;
      },
    };
    const idColDef: GridColDef<MgItemMinimal, MgItemMinimal['id']> = {
      field: 'id',
      minWidth: 140,
      sortable: false,
      renderHeader: () => (
        <ColumnHeaderWithHelpText
          header={t('products.mgItem.number.short')}
          tooltipContent={t('products.mgItem.number.description')}
        />
      ),
      renderCell: ({ row }) => (
        <CopyToClipboard CopyIconProps={{ htmlColor: iconColor }} value={row.id}>
          {row.id}
        </CopyToClipboard>
      ),
    };
    const finfoNumberColDef: GridColDef<MgItemMinimal> = {
      field: 'finfoNumber',
      minWidth: 130,
      sortable: false,
      renderHeader: () => (
        <ColumnHeaderWithHelpText
          header={t('products.mgItem.supplierItem.finfoNumber.short')}
          tooltipContent={t('products.mgItem.supplierItem.finfoNumber.primaryDescription')}
        />
      ),
      renderCell: ({ row }) => {
        const primarySupplierItem = row.getPrimarySupplierItem();
        return primarySupplierItem?.finfoNumber ? (
          <CopyToClipboard
            CopyIconProps={{ htmlColor: iconColor }}
            value={primarySupplierItem.finfoNumber}
          >
            {primarySupplierItem.finfoNumber}
          </CopyToClipboard>
        ) : (
          '-'
        );
      },
    };
    const gtinNumberColDef: GridColDef<MgItemMinimal> = {
      field: 'gtin',
      minWidth: 160,
      sortable: false,
      renderHeader: () => (
        <ColumnHeaderWithHelpText
          header={t('products.mgItem.supplierItem.gtin.label')}
          tooltipContent={t('products.mgItem.supplierItem.gtin.primaryDescription')}
        />
      ),
      renderCell: ({ row }) => {
        const primaryPackage = row.getPrimaryPackage();
        return primaryPackage?.gtinCode ? (
          <CopyToClipboard CopyIconProps={{ htmlColor: iconColor }} value={primaryPackage.gtinCode}>
            {primaryPackage.gtinCode}
          </CopyToClipboard>
        ) : (
          '-'
        );
      },
    };
    const competitorColDefs: GridColDef<MgItemMinimal>[] = competitors.map((competitor) => ({
      ...getCompetitorColumn(competitor),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        const store = row.competitors.find(({ storeId }) => storeId === competitor.id);
        return store?.storePrice ? (
          <BmpCompetitorPriceCell
            {...store.storePrice}
            setItemToView={setItemToView}
            excludeVat={excludeVat}
          />
        ) : (
          <NoPrice />
        );
      },
    }));
    if (toggleSimpleView) {
      return [productNameColDef, idColDef, ...competitorColDefs];
    }
    return [productNameColDef, idColDef, finfoNumberColDef, gtinNumberColDef, ...competitorColDefs];
  }, [t, competitors, iconColor, excludeVat, toggleSimpleView, setItemToView]);

  const onRowClick = useCallback(
    (
      params: GridRowParams<MgItemMinimal>,
      event: MuiEvent<React.MouseEvent>,
      __: GridCallbackDetails
    ) => {
      // @ts-ignore
      if (['span', 'div', 'p'].includes((event.target.tagName || '').toLowerCase())) {
        setItemToView(Number(params.id));
      }
    },
    [setItemToView]
  );

  const saveReorderingOfColumns = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('columnOrder', JSON.stringify(currentState.columns!.orderedFields));
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage.getItem('columnOrder');
    setColumnOrder(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : []);

    window.addEventListener('drop', saveReorderingOfColumns);
  }, [saveReorderingOfColumns]);

  const sortedColumns = useMemo(() => {
    if (columnOrder) {
      return columns.sort((a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field));
    }
    return columns;
  }, [columns, columnOrder]);

  if (!columnOrder) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        height: '100%',
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
          ':hover': { backgroundColor: ({ palette }) => `${palette.primary[50]} !important` },
        },
      }}
    >
      <DataGridPro
        rowHeight={60}
        apiRef={apiRef.current ? apiRef : undefined}
        columns={sortedColumns}
        rows={items}
        disableSelectionOnClick
        localeText={{ noRowsLabel: t('products.productList.noProducts') }}
        disableColumnMenu
        pagination={true}
        paginationMode="server"
        components={{
          Pagination: DataGridPagination,
        }}
        rowCount={totalItems}
        page={page}
        pageSize={pageSize}
        onPageChange={updatePage}
        onPageSizeChange={updatePageSize}
        onRowClick={onRowClick}
        initialState={{
          pinnedColumns: { left: ['name', 'id', 'finfoNumber', 'gtin'] },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            marginLeft: 0,
          },
          '& .MuiDataGrid-row': {
            paddingLeft: 0,
          },
        }}
      />
    </Box>
  );
}
