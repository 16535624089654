import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUserOrgUnits } from './useUserOrgUnits';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useNavigate } from 'react-router-dom';
import { selectedContextKey } from './Contexts';
import { BrandConfig } from '@retail/mgpris/config-types';
import { mgPrisRoutes } from '@retail/mgpris/config';

const selectedBrandKey = 'selectedBrandKey';

const setInitialBrand = (brands: BrandConfig[]) => {
  if (!brands.length)
    throw new Error('Expected at least one brand. Should not reach this point without a brand.');
  const storedSelectedBrandKey: string | null = JSON.parse(
    localStorage.getItem(selectedBrandKey) || 'null'
  );

  const selectedBrandIdx = brands.findIndex(({ key }) => key === storedSelectedBrandKey);

  if (storedSelectedBrandKey !== null && selectedBrandIdx !== -1) {
    return brands[selectedBrandIdx];
  }

  return brands[0];
};

type UserBrand = BrandConfig & {
  name: string;
};

type BrandsState = {
  availableBrands: UserBrand[];
  selectedBrand: UserBrand;
  switchBrand: (brand: UserBrand) => void;
};

const context = createContext<BrandsState>({} as BrandsState);

const BrandsProvider = (props: { children: ReactNode }) => {
  const { data: userContexts = [] } = useUserOrgUnits({ suspense: true });
  const { brandConfig } = useMgPrisConfig();
  const navigate = useNavigate();
  const availableBrands = useMemo(
    () =>
      Array.from(new Set(userContexts.map(({ theme }) => theme))).map(
        (brandKey) => brandConfig[brandKey] as UserBrand
      ),
    [brandConfig, userContexts]
  );

  const [selectedBrand] = useState<BrandConfig>(() => setInitialBrand(availableBrands));

  useEffect(() => {
    if (selectedBrand !== undefined) {
      localStorage.setItem(selectedBrandKey, JSON.stringify(selectedBrand.key));
    }
  }, [selectedBrand]);

  const switchBrand = useCallback(
    (brand: UserBrand) => {
      localStorage.setItem(selectedBrandKey, JSON.stringify(brand.key));
      const firstBrandContext = userContexts.find(({ theme }) => theme === brand.key);

      if (firstBrandContext) {
        localStorage.setItem(selectedContextKey, JSON.stringify(firstBrandContext.value));
      }

      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate, userContexts]
  );

  return (
    <context.Provider
      value={{
        availableBrands,
        selectedBrand,
        switchBrand,
      }}
    >
      {props.children}
    </context.Provider>
  );
};

const useUserBrands = () => {
  return useContext(context);
};

export { BrandsProvider, useUserBrands };
