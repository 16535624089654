import { floor } from 'lodash';
import { DependencyList, UIEvent, useEffect, useMemo, useRef, useState } from 'react';

export function useScrollWithShadow(isOverflowEffectDeps?: DependencyList) {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const overflowRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const onScrollHandler = (event: UIEvent<any>) => {
    setScrollTop(event.currentTarget.scrollTop);
    setScrollHeight(event.currentTarget.scrollHeight);
    setOffsetHeight(event.currentTarget.offsetHeight);
  };

  useEffect(() => {
    overflowRef.current
      ? setIsOverflow(overflowRef.current.scrollHeight > overflowRef.current.offsetHeight)
      : setIsOverflow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, isOverflowEffectDeps);

  const boxShadow = useMemo(() => {
    const isTop = scrollTop === 0;
    const isBottom = scrollTop > 0 && offsetHeight === floor(scrollHeight - scrollTop);
    const isBetween = scrollTop > 0 && offsetHeight < floor(scrollHeight - scrollTop);

    const top = 'inset 0 8px 4px -5px rgba(0, 0, 0, 0.09)';
    const bottom = '0px 5px 8px -5px rgba(0, 0, 0, 0.09)';

    if (!isOverflow) {
      return 'none';
    } else if (isTop) {
      return bottom;
    } else if (isBetween) {
      return `${top}, ${bottom}`;
    } else if (isBottom) {
      return top;
    }
    return 'none';
  }, [isOverflow, offsetHeight, scrollHeight, scrollTop]);

  return { boxShadow, onScrollHandler, overflowRef };
}
