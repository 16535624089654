import { MgItemSearchResultDTO } from '@retail/products/types';
import { useProductsTFunction } from '@retail/products/i18n';
import { useMemo } from 'react';
import { lighten, Stack, Typography } from '@mui/material';

interface Props extends MgItemSearchResultDTO {
  liProps: React.HTMLAttributes<HTMLLIElement>;
  searchQuery: string;
}

export function SearchResultItem({
  searchQuery,
  mgItemNumber,
  mgSupplierItems,
  gtinCodes,
  liProps,
}: Props) {
  const t = useProductsTFunction();

  const { topText, bottomText } = useMemo(() => {
    const isNumberSearch = Number.isInteger(Number(searchQuery));
    const matchingFinfoItem = isNumberSearch
      ? mgSupplierItems.find((item) => `${item.finfoNumber}`.includes(searchQuery))
      : undefined;

    if (matchingFinfoItem) {
      return {
        topText: matchingFinfoItem.primaryText,
        bottomText: `${t('products.mgItem.supplierItem.finfoNumber.label')}: ${
          matchingFinfoItem.finfoNumber
        }`,
      };
    }
    const matchingGtin = isNumberSearch
      ? gtinCodes.find((gtin) => gtin.includes(searchQuery))
      : undefined;
    if (matchingGtin) {
      return {
        topText: mgSupplierItems[0].primaryText,
        bottomText: `${t('products.mgItem.supplierItem.gtin.label')}: ${matchingGtin}`,
      };
    }

    const matchingTextSearchItem = mgSupplierItems.find(
      (item) =>
        item.primaryText.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.secondaryText?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (matchingTextSearchItem) {
      return {
        topText: matchingTextSearchItem.primaryText,
        bottomText: `${t('products.mgItem.supplierItem.finfoNumber.label')}: ${
          matchingTextSearchItem.finfoNumber
        }`,
      };
    }

    // Default to mg item head
    return {
      topText: mgSupplierItems[0].primaryText,
      bottomText: `${t('products.mgItem.number.label')}: ${mgItemNumber}`,
    };
  }, [gtinCodes, mgItemNumber, mgSupplierItems, searchQuery, t]);

  return (
    <Stack component="li" {...liProps} alignItems="start !important">
      <Typography variant="body1">{topText}</Typography>
      <Typography variant="body2" color={({ palette }) => lighten(palette.text.primary, 0.2)}>
        {bottomText}
      </Typography>
    </Stack>
  );
}
