import { AssortmentDetailsDTO } from '@retail/assortment/types';
import { prisinnsiktConfig } from '@retail/app/config';
import { RequestContext, useFetch, useMutateFetch } from '@shared/fetch-utils';
import { useAuthContext } from './AuthContext';

const assortmentsBaseUrl = prisinnsiktConfig.pps.url + '/v1/assortments';

interface Props {
  assortmentId: number | undefined;
  suspense?: boolean;
}

export const getAssortmentQueryKey = (assortmentId: number | undefined) => [
  'assortment',
  assortmentId,
];

export function useFetchAssortment({ assortmentId, suspense = false }: Props) {
  const { token } = useAuthContext();
  const {
    data: assortment,
    isLoading,
    error,
  } = useFetch<AssortmentDetailsDTO>({
    queryKey: getAssortmentQueryKey(assortmentId),
    method: 'GET',
    url: assortmentsBaseUrl + `/${assortmentId}`,
    suspense,
    enabled: !!assortmentId,
    token,
  });

  return {
    assortment,
    isLoading,
    error,
  };
}

interface PostProps {
  nobbNrs: number[];
  title: string;
  description?: string;
}

export const useReplaceAssortmentApi = (assortmentId: number, context: RequestContext) => {
  const { token } = useAuthContext();

  return useMutateFetch<AssortmentDetailsDTO, PostProps>({
    url: `${assortmentsBaseUrl}/${assortmentId}`,
    method: 'POST',
    context,
    token,
  });
};

export const useAddProductsToAssortmentApi = (assortmentId: number, context: RequestContext) => {
  const { token } = useAuthContext();

  return useMutateFetch<AssortmentDetailsDTO, number[]>({
    url: `${assortmentsBaseUrl}/${assortmentId}`,
    method: 'PUT',
    context,
    token,
  });
};

export const useDeleteAssortmentApi = (assortmentId: number, context: RequestContext) => {
  const { token } = useAuthContext();

  return useMutateFetch<void>({
    url: `${assortmentsBaseUrl}/${assortmentId}`,
    method: 'DELETE',
    context,
    token,
  });
};
