import { SharedResources } from '../resources';
import { MessagePaths } from '../utils';
import { useMessagesTranslation } from './useMessagesTranslation';

export const useSharedTranslation = () => useMessagesTranslation<SharedResources>();

export type SharedMessageKeys = MessagePaths<SharedResources>;

export const useSharedTFunction = () => useSharedTranslation().t;

export type SharedTFunction = ReturnType<typeof useSharedTFunction>;