import { Box, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridOverlay } from '@mui/x-data-grid-pro';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { MgItemDetailed } from '@retail/products/types';
import { Competitor } from '@retail/retailer/types';
import { useMemo } from 'react';
import { Context } from '@retail/auth/types';
import { Image, EmptyCircularFallbackImage } from '../Image';
import dayjs from 'dayjs';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import { EquivalentItemTooltip } from './EquivalentItemTooltip';
import { ExternalLinkIcon } from '@shared/custom-icons';
import OnDiscountTooltip from './OnDiscountTooltip';
import { useProductsTFunction } from '@retail/products/i18n';
import { Link } from 'react-router-dom';

interface Props {
  mgItem: MgItemDetailed;
  contextCompetitors: Competitor[];
  context: Context;
}

export function CompetitorViewForMgItem({ mgItem, contextCompetitors, context }: Props) {
  const t = useProductsTFunction();
  const { i18n } = useTranslation();
  const columns: GridColDef[] = useMemo(() => {
    const competitorNameColDef: GridColDef<Competitor> = {
      field: 'name',
      headerName: t('products.marketInsight.competitors.competitorName'),
      minWidth: 440,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap="20px"
            whiteSpace="normal"
            sx={{ wordWrap: 'break-word' }}
          >
            <Image
              width="25px"
              height="25px"
              src={row.circularLogoUrl()}
              fallback={() => <EmptyCircularFallbackImage size="25px" />}
            />
            <Typography>
              {row.retailer().name}, {row.name}
            </Typography>
          </Stack>
        );
      },
    };
    const unitColDef: GridColDef<Competitor> = {
      field: 'unit',
      headerName: t('products.mgItem.table.unit'),
      minWidth: 220,
      renderCell: ({ row }) => {
        const currentCompetitor =
          mgItem.competitors.find((competitor) => competitor.storeId === row.id) || null;
        return (
          currentCompetitor?.storePrice?.unit.description ||
          currentCompetitor?.storePrice?.unit.unit ||
          '-'
        );
      },
    };
    const priceColDef: GridColDef<Competitor> = {
      field: 'price',
      headerName: t('products.mgItem.table.price'),
      minWidth: 260,
      renderCell: ({ row }) => {
        const currentCompetitor =
          mgItem.competitors.find((competitor) => competitor.storeId === row.id) || null;
        const equivalentMgItemSource = currentCompetitor?.storePrice?.equivalentMgItemSource;
        const url = currentCompetitor?.storePrice?.url;
        const price = currentCompetitor?.storePrice?.price;
        const onDiscount = currentCompetitor?.storePrice?.onDiscount;
        return (
          (currentCompetitor?.storePrice && price && (
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="body2">
                {t('products.prices.price', {
                  price: formatPrice(price, i18n.language),
                })}
              </Typography>
              {equivalentMgItemSource && (
                <EquivalentItemTooltip
                  linkTo={mgPrisRoutes.items.item.getFullLinkPath(
                    `${equivalentMgItemSource.mgItemNumber}`
                  )}
                  mgItemNumber={equivalentMgItemSource.mgItemNumber}
                />
              )}
              {onDiscount && <OnDiscountTooltip />}
              {url && (
                <Box component="a" href={url} target="_blank" display="flex">
                  <ExternalLinkIcon
                    fontSize="inherit"
                    titleAccess={t('products.productList.competitorPrice.openInNew')}
                  />
                </Box>
              )}
            </Stack>
          )) ||
          '-'
        );
      },
    };
    const dateColDef: GridColDef<Competitor> = {
      field: 'priceDate',
      headerName: t('products.mgItem.table.dateForPrice'),
      minWidth: 260,
      renderCell: ({ row }) => {
        const currentCompetitor =
          mgItem.competitors.find((competitor) => competitor.storeId === row.id) || null;
        const priceDate = currentCompetitor?.storePrice?.priceDate;
        return priceDate ? dayjs(priceDate).format('ll') : '-';
      },
    };

    return [competitorNameColDef, unitColDef, priceColDef, dateColDef];
  }, [mgItem.competitors, t, i18n]);
  return (
    <Box>
      <DataGridPro
        components={{
          NoRowsOverlay: () => (
            <GridOverlay sx={{ backgroundColor: 'inherit' }}>
              <Stack direction="row" mt={6} gap={1} flexWrap="wrap">
                <Typography variant="h3" color={(theme) => theme.palette.grey[200]}>
                  {t('products.marketInsight.competitors.noCompetitors')}
                </Typography>
                <Typography
                  zIndex={1}
                  sx={{ cursor: 'pointer' }}
                  component={Link}
                  to={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
                  variant="h3"
                  color={(theme) => theme.palette.grey[200]}
                >
                  {t('products.marketInsight.competitors.goToCompetitors')}
                </Typography>
              </Stack>
            </GridOverlay>
          ),
        }}
        autoHeight
        hideFooterRowCount
        rowHeight={75}
        columns={columns}
        rows={contextCompetitors}
        disableSelectionOnClick
        disableColumnMenu
      />
    </Box>
  );
}
