import { useCallback, useMemo, useState } from 'react';
import { useSelectedCompetitors } from '@retail/app/hooks';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { alpha, Backdrop, Stack, Tooltip } from '@mui/material';
import {
  AddOrReplaceMgItemsFromFileDialog,
  DeleteAssortmentDialog,
  EditAssortmentMenu,
  EditAssortmentTitleDialog,
} from '@retail/assortment/components';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@shared/hooks';
import { useMgAssortment, useMgAssortmentActions } from '@retail/assortment/context';
import { useToast } from '@retail/hooks';
import { useContexts } from '@retail/app/stores/selected-context';
import { BodyLoader, ToggleSwitch } from '@retail/components';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { MgAssortmentItemsView } from '@retail/products/components';
import { ContainedButton, OutlinedButton } from '@shared/components';
import { TYPE_BRAND } from '@retail/auth/types';
import { MgItemDialog } from '@retail/products/containers';

interface Props {
  selectedAssortmentId: number;
}

export function MgAssortmentContainer({ selectedAssortmentId }: Props) {
  const { userContexts, selectedContext } = useContexts();
  const competitors = useSelectedCompetitors(selectedContext.id);
  const toast = useToast();
  const [itemToView, setItemToView] = useState<number | undefined>(undefined);

  const {
    result: { assortment, pageSize, page, totalItems },
    setPageSize,
    setPage,
    refetch,
    isFetching,
  } = useMgAssortment({ assortmentId: selectedAssortmentId, competitors });

  const assortmentContext = useMemo(
    () => userContexts.find((context) => context.id === assortment.contextId),
    [userContexts, assortment]
  );

  const {
    isLoading,
    handleDeleteMgAssortment,
    handlePatchTitleOrItemsOfMgAssortment,
    handleAddItemsToMgAssortment,
    handleValidateItemsOnImportedAssortment,
    exportAssortmentToExcel,
    isLoadingExcel,
  } = useMgAssortmentActions();

  const t = useAssortmentTFunction();
  const navigate = useNavigate();

  const [toggleVat, setToggleVat] = useState(false);
  const [toggleSimpleView, setToggleSimpleView] = useState(false);

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isEditNameModalOpen,
    onOpen: onOpenEditNameModal,
    onClose: onCloseEditNameModal,
  } = useDisclosure();

  const {
    isOpen: isAddItemsModalOpen,
    onOpen: onOpenAddItemsModal,
    onClose: onCloseAddItemsModal,
  } = useDisclosure();

  const {
    isOpen: isReplaceItemsModalOpen,
    onOpen: onOpenReplaceItemsModal,
    onClose: onCloseReplaceItemsModal,
  } = useDisclosure();

  const onDelete = () => {
    handleDeleteMgAssortment(selectedAssortmentId)
      .then(() => {
        toast.success(t('assortment.admin.delete.success'));
        navigate(`${mgPrisRoutes.marketInsight.getFullLinkPath()}`);
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
        onCloseDeleteModal();
      });
  };

  const onAddItems = (mgItemNumbers: number[]) => {
    handleAddItemsToMgAssortment({
      assortmentId: selectedAssortmentId,
      mgItemNumbers: mgItemNumbers,
    })
      .then(() => {
        toast.success(t('assortment.admin.update.success', { name: assortment.title }));
        refetch();
        onCloseAddItemsModal();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
        onCloseAddItemsModal();
      });
  };

  const onReplaceItems = (mgItemNumbers: number[]) => {
    handlePatchTitleOrItemsOfMgAssortment({
      assortmentId: selectedAssortmentId,
      title: assortment.title,
      itemCodes: {
        mgItemNumbers: mgItemNumbers,
      },
    })
      .then(() => {
        toast.success(t('assortment.admin.update.success', { name: assortment.title }));
        refetch();
        onCloseReplaceItemsModal();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
        onCloseReplaceItemsModal();
      });
  };

  const onUpdateTitle = (title: string) => {
    handlePatchTitleOrItemsOfMgAssortment({
      assortmentId: selectedAssortmentId,
      itemCodes: {
        mgItemNumbers: assortment.items.map(({ id }) => id),
      },
      description: assortment.description,
      title,
    })
      .then(() => {
        onCloseEditNameModal();
        refetch();
        toast.success(t('assortment.admin.update.assortmentSucess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
      });
  };

  const onExportAssortment = useCallback(
    () =>
      exportAssortmentToExcel(assortment.id)
        .then(() => {
          toast.success(t('assortment.export.success'));
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('assortment.export.error'));
        }),
    [assortment.id, exportAssortmentToExcel, t, toast]
  );

  return (
    <Stack spacing={1} flexGrow={1}>
      <Backdrop
        sx={{
          bgcolor: ({ palette }) => alpha(palette.common.white, 0.7),
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
        open={isFetching}
      >
        <BodyLoader />
      </Backdrop>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" pb={1}>
        <Stack direction="row" gap={1}>
          <ToggleSwitch
            toggle={toggleVat}
            setToggle={setToggleVat}
            text={t('assortment.vat.excluded')}
          />
          <ToggleSwitch
            toggle={toggleSimpleView}
            setToggle={setToggleSimpleView}
            text={t('assortment.assortmentTable.simpleView')}
          />
          <OutlinedButton
            size="small"
            onClick={onExportAssortment}
            loading={isLoadingExcel}
          >
            {t('assortment.export.heading_short')}
          </OutlinedButton>
        </Stack>
        {assortmentContext?.isAdmin ? (
          <EditAssortmentMenu
            onReplaceClick={onOpenReplaceItemsModal}
            onAddFromFileClick={onOpenAddItemsModal}
            onEditNameClick={onOpenEditNameModal}
            onDeleteClick={onOpenDeleteModal}
          />
        ) : (
          <Tooltip
            title={
              assortmentContext?.type === TYPE_BRAND
                ? t('assortment.admin.edit.notAuthorized.brand')
                : t('assortment.admin.edit.notAuthorized.location')
            }
            arrow
          >
            <div>
              <ContainedButton size="small" disabled>
                {t('assortment.editAssortment')}
              </ContainedButton>
            </div>
          </Tooltip>
        )}
      </Stack>
      <MgAssortmentItemsView
        updatePageSize={setPageSize}
        updatePage={setPage}
        totalItems={totalItems}
        pageSize={pageSize}
        page={page}
        items={assortment.items}
        excludeVat={toggleVat}
        toggleSimpleView={toggleSimpleView}
        competitors={competitors}
        setItemToView={(mgItemNumber) => setItemToView(mgItemNumber)}
      />

      <DeleteAssortmentDialog
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
        handleConfirmDelete={onDelete}
        isLoading={isLoading}
      />
      <EditAssortmentTitleDialog
        isOpen={isEditNameModalOpen}
        onClose={onCloseEditNameModal}
        existingTitle={assortment.title}
        handleConfirmTitle={onUpdateTitle}
        isLoading={isLoading}
      />
      <AddOrReplaceMgItemsFromFileDialog
        isOpen={isAddItemsModalOpen}
        getInvalidItemCodes={handleValidateItemsOnImportedAssortment}
        onClose={() => {
          onCloseAddItemsModal();
        }}
        isLoading={isLoading}
        onSubmitAddOrReplace={onAddItems}
      />
      <AddOrReplaceMgItemsFromFileDialog
        isOpen={isReplaceItemsModalOpen}
        getInvalidItemCodes={handleValidateItemsOnImportedAssortment}
        onClose={() => {
          onCloseReplaceItemsModal();
        }}
        isLoading={isLoading}
        onSubmitAddOrReplace={onReplaceItems}
      />
      {itemToView && (
        <MgItemDialog open itemNumber={itemToView} onClose={() => setItemToView(undefined)} />
      )}
    </Stack>
  );
}
