import React, { ComponentType, FC, Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Dialog, DialogProps } from '@shared/components';
import { BodyLoader } from '../Loader';
import { ErrorFallback } from '../ErrorFallback';
import { DialogContent } from '@mui/material';

export const withDialogSuspenseErrorBoundary =
  <T extends Pick<DialogProps, 'open' | 'onClose'>>(
    WrappedDialogComponent: ComponentType<T>
  ): FC<T> =>
  (props) => {
    return (
      <ErrorBoundary
        fallback={(fallbackProps) => (
          <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent sx={{ pt: 3 }}>
              <ErrorFallback {...fallbackProps} />
            </DialogContent>
          </Dialog>
        )}
      >
        <Suspense
          fallback={
            <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
              <DialogContent>
                <BodyLoader />
              </DialogContent>
            </Dialog>
          }
        >
          <WrappedDialogComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
