import { memo, useMemo } from 'react';
import { isSuperAdmin, guard } from '../../decorators';
import { Container, Button, Typography, Stack, CircularProgress } from '@mui/material';
import { EquivalentGroupDTO } from '@retail/products/types';
import { useForm } from 'react-hook-form';
import { TextInput } from '@retail/components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { numberString } from '@retail/form/validation';
import {
  EquivalentSearchError,
  useEquivalentGroup,
  useMasterProductGroup,
} from '../../api/pps/equivalentsV2Api';
import EquivalentGroup from './EquivalentGroup';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppMessageKeys, useAppTFunction } from '@retail/app/i18n';

interface SearchForm {
  nobbSearch: string;
}

const searchSchema = yup.object().shape({
  nobbSearch: numberString<AppMessageKeys>({
    key: 'admin.equivalents.nobbSearch.error',
  }),
});

const AdminEquivalentsPage: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const t = useAppTFunction();

  const nobbnrQuery = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('nobbnr');
  }, [search]);

  const { control, handleSubmit } = useForm<SearchForm>({
    resolver: yupResolver(searchSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = ({ nobbSearch }: SearchForm) => {
    navigate({ search: `?nobbnr=${nobbSearch}` });
  };

  const equivalentGroupSearchResult = useEquivalentGroup(nobbnrQuery);
  const masterProductSearchResult = useMasterProductGroup(nobbnrQuery);
  const isLoading = equivalentGroupSearchResult.isLoading || masterProductSearchResult.isLoading;

  return (
    <Stack spacing={2} flexGrow="1">
      <Container maxWidth="xl" component={Stack} spacing={2}>
        <Typography variant="h1">{t('admin.equivalents.title')}</Typography>
        <Stack
          component="form"
          direction="row"
          alignItems="flex-start"
          spacing={2}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            control={control}
            name="nobbSearch"
            label={t('admin.equivalents.nobbSearch.label')}
            size="medium"
          />
          <Button type="submit" sx={{ paddingY: 2 }} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Søk'}
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="3xl" sx={{ bgcolor: '#f5f5f5', flexGrow: '1' }}>
        <Stack p={2} flexDirection="row" sx={{ flexWrap: 'wrap' }}>
          {nobbnrQuery && equivalentGroupSearchResult && (
            <Stack spacing={1} flex="1 1 650px" p={1}>
              <Typography variant="h2">{t('admin.equivalents.result.equivalent.group')}</Typography>
              <SearchResultContent
                equivalentGroup={equivalentGroupSearchResult.equivalentGroup}
                error={equivalentGroupSearchResult.error}
              />
            </Stack>
          )}
          {nobbnrQuery && masterProductSearchResult && (
            <Stack spacing={1} flex="1 1 650px" p={1}>
              <Typography variant="h2">
                {t('admin.equivalents.result.masterProduct.group')}
              </Typography>
              <SearchResultContent
                equivalentGroup={masterProductSearchResult.masterPrdouctGroup}
                error={masterProductSearchResult.error}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

interface ResultProps {
  equivalentGroup: EquivalentGroupDTO[] | undefined;
  error: EquivalentSearchError | undefined;
}

const SearchResultContent: React.FC<ResultProps> = ({ equivalentGroup, error }) => {
  const t = useAppTFunction();
  if (error) {
    if (error.status === 404) {
      return <Typography>{t('admin.equivalents.error.notFound')}</Typography>;
    }

    return <Typography>{t('admin.equivalents.error.other')}</Typography>;
  }

  if (equivalentGroup) {
    const { alternativeProducts, groupTitle, imageUrl, updated, sourceNobbNr } = equivalentGroup[0];

    if (alternativeProducts.length === 0) {
      return (
        <Typography>{t('admin.equivalents.error.noMasterProduct', { sourceNobbNr })}</Typography>
      );
    }

    return (
      <EquivalentGroup
        alternativeProducts={alternativeProducts}
        groupTitle={groupTitle}
        imageUrl={imageUrl}
        updated={updated}
      />
    );
  }

  return null;
};

export default memo(guard({ guards: [isSuperAdmin] })(AdminEquivalentsPage));
