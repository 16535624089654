import { MgSupplierItemSearchResult } from '@retail/products/types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { IconButton, Typography, useTheme } from '@mui/material';
import { TrashIcon } from '@sanity/icons';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  items: MgSupplierItemSearchResult[];
  removeCheckedItem: (id: number) => void;
}

export function MgSupplierItemsTableWithDelete({ items, removeCheckedItem }: Props) {
  const { palette } = useTheme();
  const t = useProductsTFunction();

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ borderBottom: ({ palette }) => `2px solid ${palette.grey.A100}` }}>
          <TableCell>{t('products.mgItem.supplierItem.primaryText')}</TableCell>
          <TableCell>{t('products.supplier.name')}</TableCell>
          <TableCell>{t('products.mgItem.supplierItem.finfoNumber.label')}</TableCell>
          <TableCell>{t('products.mgItem.number.short')}</TableCell>
          <TableCell>{t('products.mgItem.supplierItem.bk04ProductCategory')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody sx={{ '& td p': { fontSize: 'inherit' } }}>
        {items.map((item) => {
          const { primaryText, finfoNumber, mgParticipant, mgItemNumber, bk04Varegruppe } = item;
          return (
            <TableRow key={finfoNumber} sx={{ borderBottom: `1.5px solid ${palette.grey.A100}` }}>
              <TableCell>
                <Typography variant="body1">{primaryText}</Typography>
              </TableCell>
              <TableCell>{mgParticipant.name}</TableCell>
              <TableCell>{finfoNumber}</TableCell>
              <TableCell>{mgItemNumber}</TableCell>
              <TableCell>{bk04Varegruppe.getLabel()}</TableCell>
              <TableCell>
                <IconButton onClick={() => removeCheckedItem(item.mgItemNumber)}>
                  <TrashIcon fontSize="25px" />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
