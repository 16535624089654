import { useCallback } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

export const useRecoilDisclosure = (recoilState: RecoilState<boolean>) => {
  const [isOpen, setIsOpen] = useRecoilState(recoilState);
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onToggle = useCallback(() => setIsOpen((current) => !current), [setIsOpen]);

  return {
    isOpen,
    onToggle,
    onOpen,
    onClose,
  };
};
