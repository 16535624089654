import { Stack, Typography } from '@mui/material';

type Props = {
  expandedView: boolean;
  title: string;
};

export const SidebarHeader = ({ expandedView, ...props }: Props) => {
  return expandedView ? (
    <ExpandedSidebarHeader {...props} />
  ) : (
    <CollapsedSidebarHeader {...props} />
  );
};

export function ExpandedSidebarHeader({ title }: Pick<Props, 'title'>) {
  return (
    <Stack mt={4} mx={3}>
      <Typography variant="body2" fontWeight={500} color={({ palette }) => palette.text.secondary}>
        {title}
      </Typography>
    </Stack>
  );
}

export function CollapsedSidebarHeader({ title }: Pick<Props, 'title'>) {
  return (
    <Stack mt={4} alignItems="center">
      <Typography fontWeight={500} variant="body2" color={({ palette }) => palette.text.secondary}>
        {title}
      </Typography>
    </Stack>
  );
}
