import { prisinnsiktConfig } from '@retail/app/config';
import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { NobbOvergruppeDTO } from '@retail/products/types';
import { useAuthContext } from './AuthContext';
import { useCallback } from 'react';

const url = prisinnsiktConfig.pps.url + '/v1/nobb/overgrupper';

export async function fetchProductGroups(token: string) {
  return fetcher<NobbOvergruppeDTO[]>({
    url,
    token,
  });
}

export function useProductGroupsApi(queryOptions: QueryOptions = {}) {
  const { token } = useAuthContext();
  const fetcher = useCallback(() => fetchProductGroups(token), [token]);

  return useQueryFetch({
    fetcher,
    queryKey: ['product-groups'],
    ...queryOptions,
  });
}
