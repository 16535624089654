import { Box, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Paper } from '@shared/components';
import React from 'react';

export interface Props {
  children: React.ReactNode;
}

export function ActiveTagPaper({ children }: Props) {
  const t = useAppTFunction();
  return (
    <Paper
      sx={{
        border: ({ palette }) => `1px solid ${palette.divider}`,
        width: 'fit-content',
        overflow: 'hidden',
        gap: 3,
      }}
      paddingY="dense"
    >
      <Box position="relative">
        <Typography
          variant="body2"
          position="absolute"
          color={({ palette }) => palette.common.white}
          textAlign="center"
          width={80}
          fontWeight={600}
          top={-14}
          right={-50}
          sx={{
            background: ({ palette }) => palette.gradient.tertiary,
            transform: 'rotate(43deg)',
          }}
        >
          {t('common.actions.active')}
        </Typography>
      </Box>

      {children}
    </Paper>
  );
}
