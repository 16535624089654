import { Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { useContexts } from '@retail/app/stores/selected-context';
import {
  AllSuppliersLayout,
  BodyLoader,
  ErrorFallbackPage,
  SuspenseWithSentryErrorBoundary,
  TagBreadcrumbs,
} from '@retail/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import {
  useFetchFavoriteMgParticipants,
  useMutateEditFavoriteMgParticipants,
} from '@retail/products/data-access';
import { MgParticipant } from '@retail/products/types';
import { Paper } from '@shared/components';
import { alphabeticSort } from '@shared/utils';
import { useCallback, useMemo, useState } from 'react';

interface Props {
  label: string;
  breadcrumb: string;
  mgParticipants: MgParticipant[];
  selectSupplier: (selectedSupplier: MgParticipant | null) => void;
}

export function SelectSupplierContainer({
  label,
  breadcrumb,
  mgParticipants,
  selectSupplier,
}: Props) {
  const t = useAppTFunction();
  const { selectedContext } = useContexts();
  const { data: favortiteSuppliersDtos = [], refetch } = useFetchFavoriteMgParticipants({
    suspense: true,
  });
  const favoriteSuppliers = favortiteSuppliersDtos.map(MgParticipant.fromMinimalDto);
  const favoriteSupplierIds = favoriteSuppliers?.map(({ id }) => id);

  const sortedSuppliers = useMemo(() => {
    return [
      ...favoriteSuppliers.sort(alphabeticSort<MgParticipant>('name')),
      ...mgParticipants
        .filter(({ id }) => !favoriteSupplierIds.includes(id))
        .sort(alphabeticSort<MgParticipant>('name')),
    ];
  }, [favoriteSupplierIds, favoriteSuppliers, mgParticipants]);

  const [filteredSuppliers, setFilteredSuppliers] = useState(sortedSuppliers);

  const filterSuppliers = useCallback(
    (search: string) => {
      setFilteredSuppliers(() =>
        sortedSuppliers.filter((supplier) =>
          supplier.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    },
    [sortedSuppliers]
  );

  const { mutateAsync: mutateEditAsync } = useMutateEditFavoriteMgParticipants(selectedContext);

  const editFavoriteSuppliers = useCallback(
    (supplier: MgParticipant) => {
      const newFavoriteSuppliers = favoriteSupplierIds?.includes(supplier.id)
        ? favoriteSuppliers?.filter((favoriteSupplier) => favoriteSupplier.id !== supplier.id)
        : [...favoriteSuppliers!, supplier];
      mutateEditAsync({ body: newFavoriteSuppliers }).then(() => {
        refetch();
      });
    },

    [favoriteSupplierIds, favoriteSuppliers, mutateEditAsync, refetch]
  );

  const isAFavoriteSupplier = useCallback(
    (supplierId: number) =>
      favoriteSuppliers ? favoriteSuppliers.map(({ id }) => id).includes(supplierId) : false,
    [favoriteSuppliers]
  );

  return (
    <Paper paddingY="dense" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <TagBreadcrumbs
        items={[
          { label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() },
          { label: breadcrumb },
        ]}
      />
      <Stack overflow="auto">
        <SuspenseWithSentryErrorBoundary
          errorFallback={(props) => <ErrorFallbackPage {...props} />}
          suspenseFallback={<BodyLoader />}
        >
          <Stack gap={4} pt={6} alignItems="center" alignSelf="center">
            <Typography align="center" variant="h4">
              {label}
            </Typography>
            <AllSuppliersLayout
              selectSupplier={selectSupplier}
              isAdmin={selectedContext.isAdmin}
              suppliers={filteredSuppliers}
              isAFavoriteSupplier={isAFavoriteSupplier}
              editFavoriteSuppliers={editFavoriteSuppliers}
              filterSuppliers={filterSuppliers}
            />
          </Stack>
        </SuspenseWithSentryErrorBoundary>
      </Stack>
    </Paper>
  );
}
