import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions, useFetch, useMutateFetch } from '@shared/fetch-utils';
import { useContexts } from '@retail/app/stores/selected-context';
import {
  ActiveItemAgreementDTO,
  AgreementsForMgItemDTO,
  OverGroupDTO,
  ProductGroupAgreementsDTO,
  PurchaseAgreementsPostDTO,
} from '@retail/purchase-agreements/types';
import { buildUrl } from '@shared/utils';

export const contextPath = '/v1/context';
export const purchaseAgreementsPath = 'purchase-agreements';
export const purchaseAgreementsProductGroupsPath = 'purchase-agreements-product-groups';
export const itemAgreementsPath = 'item-agreements';

export function useFetchProductGroupsForMgParticipant(
  mgParticipantAccountId: number,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['purchase-agreements-product-groups', selectedContext],
    token,
    url: `${pps.url}${contextPath}/${selectedContext.id}/${purchaseAgreementsProductGroupsPath}/${mgParticipantAccountId}`,
    ...queryOptions,
  });
}

export function useFetchItemAgreements(
  mgParticipantAccountId: number,
  agreementIds: string[],
  mgItemId: number,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<AgreementsForMgItemDTO>({
    method: 'GET',
    queryKey: ['item-agreements', selectedContext, mgParticipantAccountId, agreementIds, mgItemId],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${purchaseAgreementsPath}/${mgParticipantAccountId}/${itemAgreementsPath}/${mgItemId}`,
      searchParams: {
        agreementIds,
      },
    }),
    ...queryOptions,
  });
}

export type QueryParams = {
  status?: string[];
  agreementIds?: string[];
  productGroupId?: string;
  search?: string;
  onlyAffected?: boolean;
};

export function useFetchItemAgreementsForProductGroup(
  mgParticipantAccountId: number,
  { status, agreementIds, productGroupId, search, onlyAffected = false }: QueryParams,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<ActiveItemAgreementDTO[]>({
    method: 'GET',
    queryKey: [
      'item-agreements-for-product-group',
      selectedContext,
      mgParticipantAccountId,
      status,
      agreementIds,
      productGroupId,
      search,
      onlyAffected,
    ],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${purchaseAgreementsPath}/${mgParticipantAccountId}/${itemAgreementsPath}`,
      searchParams: {
        status,
        agreementIds,
        productGroupId,
        search,
        onlyAffected,
      },
    }),
    ...queryOptions,
  });
}

export function useFetchProductGroupAgreements(
  mgParticipantAccountId: number,
  { agreementIds, productGroupId }: QueryParams,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<ProductGroupAgreementsDTO>({
    method: 'GET',
    queryKey: [
      'product-group-agreements',
      selectedContext,
      mgParticipantAccountId,
      agreementIds,
      productGroupId,
    ],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${purchaseAgreementsPath}/${mgParticipantAccountId}`,
      searchParams: {
        agreementIds,
        productGroupId,
      },
    }),
    ...queryOptions,
  });
}

export function useMutatePurchaseAgreements(queryOptions: QueryOptions = {}) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateFetch<void, PurchaseAgreementsPostDTO>({
    method: 'POST',
    url: (mgParticipantAccountId: number) =>
      `${pps.url}${contextPath}/${selectedContext.id}/${purchaseAgreementsPath}/${mgParticipantAccountId}`,
    token,
    ...queryOptions,
  });
}
