import { SvgIcon, SvgIconProps } from '@mui/material';

export const InheritedCheckIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M20.7326 18.068C20.7326 19.3935 19.6581 20.468 18.3326 20.468C17.0071 20.468 15.9326 19.3935 15.9326 18.068C15.9326 16.7425 17.0071 15.668 18.3326 15.668C19.6581 15.668 20.7326 16.7425 20.7326 18.068Z"
          fill="#DCFFCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9993 18.3346C20.9993 19.8074 19.8054 21.0013 18.3327 21.0013C16.8599 21.0013 15.666 19.8074 15.666 18.3346C15.666 16.8619 16.8599 15.668 18.3327 15.668C19.8054 15.668 20.9993 16.8619 20.9993 18.3346ZM20.466 18.3346C20.466 19.5128 19.5109 20.468 18.3327 20.468C17.1545 20.468 16.1993 19.5128 16.1993 18.3346C16.1993 17.1564 17.1545 16.2013 18.3327 16.2013C18.9615 16.2013 19.5268 16.4734 19.9173 16.9063L18.066 18.7575L17.3212 18.0127C17.2171 17.9086 17.0483 17.9086 16.9441 18.0127C16.84 18.1169 16.84 18.2857 16.9441 18.3899L17.6889 19.1346C17.8972 19.3429 18.2349 19.3429 18.4431 19.1346L20.2264 17.3514C20.3795 17.6456 20.466 17.98 20.466 18.3346Z"
          fill="#83BF6E"
        />
        <path
          d="M15.2008 5.2C15.2008 7.5196 13.3204 9.4 11.0008 9.4C8.68119 9.4 6.80078 7.5196 6.80078 5.2C6.80078 2.8804 8.68119 1 11.0008 1C13.3204 1 15.2008 2.8804 15.2008 5.2Z"
          fill="#DCFFCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6673 5.66667C15.6673 8.24399 13.578 10.3333 11.0007 10.3333C8.42332 10.3333 6.33398 8.24399 6.33398 5.66667C6.33398 3.08934 8.42332 1 11.0007 1C13.578 1 15.6673 3.08934 15.6673 5.66667ZM14.734 5.66667C14.734 7.72853 13.0625 9.4 11.0007 9.4C8.93879 9.4 7.26732 7.72853 7.26732 5.66667C7.26732 3.6048 8.93879 1.93333 11.0007 1.93333C12.1011 1.93333 13.0904 2.40948 13.7737 3.167L10.534 6.4067L9.23063 5.10335C9.04839 4.92111 8.75291 4.92111 8.57067 5.10335C8.38842 5.28559 8.38842 5.58107 8.57067 5.76332L9.87402 7.06667C10.2385 7.43116 10.8295 7.43116 11.194 7.06667L14.3147 3.94595C14.5826 4.46087 14.734 5.04609 14.734 5.66667Z"
          fill="#83BF6E"
        />
        <path
          d="M15.5455 16.4548L11 13.7276M11 13.7276L6.45455 16.4548M11 13.7276V10.0914M15.5455 18.2729C15.5455 18.9961 15.8328 19.6898 16.3443 20.2012C16.8557 20.7127 17.5494 21 18.2727 21C18.996 21 19.6897 20.7127 20.2012 20.2012C20.7127 19.6898 21 18.9961 21 18.2729C21 17.5496 20.7127 16.8559 20.2012 16.3445C19.6897 15.833 18.996 15.5457 18.2727 15.5457C17.5494 15.5457 16.8557 15.833 16.3443 16.3445C15.8328 16.8559 15.5455 17.5496 15.5455 18.2729ZM3.72727 15.5457C3.00396 15.5457 2.31026 15.833 1.7988 16.3445C1.28734 16.8559 1 17.5496 1 18.2729C1 18.9961 1.28734 19.6898 1.7988 20.2012C2.31026 20.7127 3.00396 21 3.72727 21C4.45059 21 5.14428 20.7127 5.65575 20.2012C6.16721 19.6898 6.45455 18.9961 6.45455 18.2729C6.45455 17.5496 6.16721 16.8559 5.65575 16.3445C5.14428 15.833 4.45059 15.5457 3.72727 15.5457ZM15.5455 5.54616C15.5453 6.75175 15.0663 7.90792 14.2137 8.76032C13.3611 9.61272 12.2047 10.0915 10.9991 10.0914C9.79344 10.0913 8.63722 9.61225 7.78478 8.75968C6.93234 7.90711 6.45352 6.75084 6.45364 5.54525C6.45376 4.33965 6.93282 3.18348 7.78542 2.33108C8.63803 1.47868 9.79435 0.999879 11 1C12.2057 1.00012 13.3619 1.47916 14.2143 2.33173C15.0667 3.1843 15.5456 4.34056 15.5455 5.54616Z"
          stroke="#83BF6E"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
