import { ChangeEvent, useMemo, useState } from 'react';
import { InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ItemAgreementsForProductGroupTable } from '@retail/purchase-agreements/components';
import { useFetchItemAgreementsForProductGroup } from '@retail/purchase-agreements/data-access';
import { Paper } from '@shared/components';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { useDebounce } from '@retail/hooks';
import ChangeSelectedItemAgreementDialog from './ChangeSelectedItemAgreementDialog';
import { ToggleSwitch } from '@retail/components';

interface Props {
  selectedAgreements: string[];
  supplierId: number;
  productGroupId?: string;
}

export const ItemAgreementsForProductGroup = ({
  selectedAgreements,
  supplierId,
  productGroupId,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();

  const [mgItemToEdit, setMgItemToEdit] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 250);
  const [onlyAffectedItems, showOnlyAffectedItems] = useState(false);

  const queryParams = useMemo(() => {
    return {
      status: ['active', 'activeInherited'],
      agreementIds: selectedAgreements,
      productGroupId,
      search: debouncedSearch,
      onlyAffected: onlyAffectedItems,
    };
  }, [selectedAgreements, productGroupId, onlyAffectedItems, debouncedSearch]);

  const { data: activeItemAgreements = [] } = useFetchItemAgreementsForProductGroup(
    supplierId,
    queryParams,
    { suspense: true }
  );
  return (
    <>
      {mgItemToEdit && (
        <ChangeSelectedItemAgreementDialog
          open={!!mgItemToEdit}
          onClose={() => setMgItemToEdit(undefined)}
          mgItemNr={mgItemToEdit}
          selectedAgreements={selectedAgreements}
          supplierId={supplierId}
        />
      )}
      <Paper paddingY="dense" variant="outlined" tabPanel sx={{ flexGrow: 1, p: 0, pb: 5 }}>
        <Stack flexDirection="row" gap={1} pt={2} pl={2}>
          <OutlinedInput
            size="small"
            placeholder={t('purchaseAgreements.agreement.actions.search')}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />
          <ToggleSwitch
            text={t('purchaseAgreements.affectedItems.onlyAffectedItems')}
            toggle={onlyAffectedItems}
            setToggle={() => showOnlyAffectedItems(!onlyAffectedItems)}
          />
        </Stack>

        <ItemAgreementsForProductGroupTable
          activeItemAgreements={activeItemAgreements}
          setMgItemToEdit={setMgItemToEdit}
        />
      </Paper>
    </>
  );
};
