import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  Navigation,
  NavRoute,
  NavWithSubRoutes,
  Sidebar,
  SidebarHeader,
  SidebarUserMenu,
} from '@shared/components';
import { useSidebarDisclosure } from '@shared/stores';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { useAuthContext, useFetchMe, useIsSuperUser } from '@retail/auth/data-access';
import { useContexts } from '@retail/app/stores/selected-context';
import {
  CalculatorIcon,
  CrownIcon,
  MarketIcon,
  ShoppingCartIcon,
  UsersIcon,
} from '@shared/custom-icons';
import { useSharedTranslation } from '@shared/i18n';
import { useMgPrisConfig } from '@retail/app/stores/app-config';

type Route = NavRoute | NavWithSubRoutes;

export function AppSidebar() {
  const t = useAppTFunction();
  const { t: sharedT } = useSharedTranslation();
  const { features } = useMgPrisConfig();
  const activatePurchaseAgreements = useMemo(
    () => features?.purchaseAgreements.toggle === 'on',
    [features]
  );
  const activateFreightMarkup = useMemo(() => features?.freightMarkup.toggle === 'on', [features]);
  const activateMarkup = useMemo(() => features?.markup.toggle === 'on', [features]);

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleSidebar,
    onOpen: openSidebar,
    onClose: closeSidebar,
  } = useSidebarDisclosure();

  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });
  const userName = useMemo(() => `${user?.givenName} ${user?.familyName}`, [user]);

  const isSuperUser = useIsSuperUser();
  const { selectedContext } = useContexts();

  const { logout } = useAuthContext();
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const productsRouteMatch = useMatch(`${mgPrisRoutes.marketInsight.fullRoutePath}/*`);
  const itemsRouteMatch = useMatch(`${mgPrisRoutes.items.fullRoutePath}/*`);
  const userRouteMatch = useMatch(`${mgPrisRoutes.users.fullRoutePath}/*`);
  const superuserRouteMatch = useMatch(`${mgPrisRoutes.superusers.fullRoutePath}/*`);
  const purchaseAgreementsRouteMatch = useMatch(
    `${mgPrisRoutes.purchaseAgreements.fullRoutePath}/*`
  );
  const freightMarkupRouteMatch = useMatch(`${mgPrisRoutes.freightMarkup.fullRoutePath}/*`);
  const markupRouteMatch = useMatch(`${mgPrisRoutes.markup.fullRoutePath}/*`);

  const activeSubroutes = [
    activatePurchaseAgreements
      ? [
          {
            title: t('app.routes.purchaseAgreements'),
            to: mgPrisRoutes.purchaseAgreements.getFullLinkPath(),
            isActive: !!purchaseAgreementsRouteMatch,
          },
        ]
      : [],
    activateFreightMarkup
      ? [
          {
            title: t('app.routes.freightMarkup'),
            to: mgPrisRoutes.freightMarkup.getFullLinkPath(),
            isActive: !!freightMarkupRouteMatch,
          },
        ]
      : [],
    activateMarkup
      ? [
          {
            title: t('app.routes.markup'),
            to: mgPrisRoutes.markup.getFullLinkPath(),
            isActive: !!markupRouteMatch,
          },
        ]
      : [],
  ].flat();

  const navRoutes: Route[] = useMemo(() => {
    const routes: Route[] = [
      {
        title: t('app.routes.marketInsight'),
        to: mgPrisRoutes.marketInsight.getFullLinkPath(),
        Icon: MarketIcon,
        isActive: !!productsRouteMatch,
      },
      {
        title: t('app.routes.products'),
        to: mgPrisRoutes.items.getFullLinkPath(),
        Icon: ShoppingCartIcon,
        isActive: !!itemsRouteMatch,
      },
    ];
    if (activatePurchaseAgreements || activateFreightMarkup || activateMarkup) {
      routes.push({
        title: t('app.routes.prices.title'),
        Icon: CalculatorIcon,
        isActive: !!purchaseAgreementsRouteMatch || !!freightMarkupRouteMatch || !!markupRouteMatch,
        subRoutes: activeSubroutes,
      });
    }

    if (selectedContext.isAdmin) {
      routes.push({
        title: t('app.routes.users'),
        Icon: UsersIcon,
        to: `${mgPrisRoutes.users.getFullLinkPath()}`,
        isActive: !!userRouteMatch,
      });
    }

    if (isSuperUser) {
      routes.push({
        title: t('app.routes.superuser'),
        to: `${mgPrisRoutes.superusers.getFullLinkPath()}`,
        Icon: CrownIcon,
        isActive: !!superuserRouteMatch,
      });
    }

    return routes;
  }, [
    t,
    productsRouteMatch,
    itemsRouteMatch,
    activatePurchaseAgreements,
    activateFreightMarkup,
    activateMarkup,
    selectedContext.isAdmin,
    isSuperUser,
    purchaseAgreementsRouteMatch,
    freightMarkupRouteMatch,
    markupRouteMatch,
    activeSubroutes,
    userRouteMatch,
    superuserRouteMatch,
  ]);

  return (
    <Sidebar isOpen={isSidebarOpen} toggleOpen={toggleSidebar}>
      <SidebarHeader title={sharedT('shared.navigation')} expandedView={isSidebarOpen} />
      <Navigation
        navRoutes={navRoutes}
        expandedView={isSidebarOpen}
        expandView={openSidebar}
        onClickCallback={closeSidebar}
      />

      <Box marginTop="auto">
        <Box display="flex" justifyContent="center" p={3}>
          <SidebarUserMenu userName={userName} logOut={handleLogout} expandedView={isSidebarOpen} />
        </Box>
      </Box>
    </Sidebar>
  );
}
