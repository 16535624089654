import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { MgParticipant } from '@retail/products/types';
import {
  ItemForProductGroup,
  MainGroup,
  OverGroup,
  SelectedAgreementLevel,
  UnderGroup,
} from '@retail/purchase-agreements/types';
import { OutlinedButton, Paper } from '@shared/components';
import { SearchIcon } from '@shared/custom-icons';
import { useProductsTFunction } from '@retail/products/i18n';
import { Clear } from '@mui/icons-material';
import { ProductGroupsTree } from './ProductGroupsTree';

type Props = {
  supplier: MgParticipant;
  productGroups: OverGroup[];
  mgItems: ItemForProductGroup[];
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setSelectedProductGroups: Dispatch<SetStateAction<string[]>>;
  selectAgreementLevel: (agreementLevel: SelectedAgreementLevel) => void;
};

export const ProductGroupsTreeView = ({
  supplier,
  productGroups,
  mgItems,
  searchTerm,
  setSearchTerm,
  setSelectedProductGroups,
  selectAgreementLevel,
}: Props) => {
  const { palette } = useTheme();
  const t = useProductsTFunction();

  const [searchFieldInput, setSearchFieldInput] = useState<string>('');

  const filteredProductGroups = useMemo(() => {
    return (
      searchTerm &&
      productGroups.reduce((accOverGroups, overGroup) => {
        const filteredMainGroups = overGroup.mainGroups.reduce((accMainGroups, mainGroup) => {
          const filteredUnderGroups = mainGroup.underGroups.reduce((accUnderGroups, underGroup) => {
            const filteredItems = mgItems
              .map((item) => item.productGroupId)
              .filter((itemId) => itemId.includes(underGroup.groupNumber));
            filteredItems.length > 0 &&
              accUnderGroups.push({ ...underGroup, numberOfItems: filteredItems.length });
            return accUnderGroups;
          }, [] as UnderGroup[]);

          filteredUnderGroups.length > 0 &&
            accMainGroups.push({
              ...mainGroup,
              numberOfItems: filteredUnderGroups.reduce(
                (acc, value) => acc + value.numberOfItems,
                0
              ),
              underGroups: filteredUnderGroups,
            });
          return accMainGroups;
        }, [] as MainGroup[]);

        filteredMainGroups.length > 0 &&
          accOverGroups.push({
            ...overGroup,
            numberOfItems: filteredMainGroups.reduce((acc, value) => acc + value.numberOfItems, 0),
            mainGroups: filteredMainGroups,
          });
        return accOverGroups;
      }, [] as OverGroup[])
    );
  }, [productGroups, mgItems, searchTerm]);

  const updateSelectedProductGroups = useCallback(
    (productGroupNumber: string) => {
      setSelectedProductGroups((prev) => {
        if (prev.includes(productGroupNumber)) {
          return prev.filter((prev) => prev !== productGroupNumber);
        }
        return [...prev, productGroupNumber];
      });
    },
    [setSelectedProductGroups]
  );

  const getItemsForProductGroup = useCallback(
    (productGroupId: string) => {
      return mgItems.filter((item) => item.productGroupId === productGroupId);
    },
    [mgItems]
  );

  const totalNumberOfItems = useMemo(() => {
    return searchTerm
      ? mgItems.length
      : productGroups
          .flatMap((overGroup) => overGroup)
          .reduce((total, productGroup) => (total = total + productGroup.numberOfItems), 0);
  }, [productGroups, mgItems, searchTerm]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Stack
        component={Paper}
        overflow="auto"
        px={2.5}
        py={3}
        mr={3}
        gap={1.5}
        width={348}
        border={`1px solid ${palette.divider}`}
        boxShadow={(theme) => theme.shadows[4]}
      >
        <Stack flexDirection="row" gap={1}>
          <Typography fontWeight={700}>{supplier.name}</Typography>
          <Typography fontWeight={700} color={({ palette }) => palette.primary.main}>
            {`(${t('products.search.openSearch.numberOfItems', { number: totalNumberOfItems })})`}
          </Typography>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
          <TextField
            size="small"
            value={searchFieldInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: searchFieldInput ? (
                <Stack flexDirection="row">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchFieldInput('');
                      setSearchTerm('');
                    }}
                  >
                    <Clear />
                  </IconButton>
                </Stack>
              ) : undefined,
            }}
            placeholder={t('products.search.openSearch.searchText')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.target.value === '' && setSearchTerm('');

              setSearchFieldInput(event.target.value);
            }}
          />
          <OutlinedButton
            size="small"
            sx={{ height: '37px' }}
            onClick={() => {
              setSearchTerm(searchFieldInput);
            }}
          >
            {t('products.search.openSearch.search')}
          </OutlinedButton>
        </Stack>
        <ProductGroupsTree
          supplier={supplier}
          overGroups={filteredProductGroups || productGroups}
          numberOfItems={totalNumberOfItems}
          selectAgreementLevel={selectAgreementLevel}
          updateSelectedProductGroups={updateSelectedProductGroups}
          getItemsForProductGroup={getItemsForProductGroup}
        />
      </Stack>
    </Box>
  );
};
