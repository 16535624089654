import { Add } from '@mui/icons-material';
import { Pagination, Stack, Typography } from '@mui/material';
import { MgSupplierItemsTableWithCheckbox } from '@retail/products/components';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton } from '@shared/components';
import { useCallback, useState } from 'react';
import MonitorMgSupplierItemsDialog from './MonitorMgSupplierItemsDialog';
import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useDisclosure } from '@shared/hooks';
import { SelectPageSize } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { mgprisHeaderHeight } from '@shared/styles';
import { useContexts } from '@retail/app/stores/selected-context';

interface Props {
  totalPages: number;
  supplierItems: MgSupplierItemSearchResult[];
  pageSize: number;
  currentPage: number;
  setPage: (pageNr: number) => void;
  setPageSize: (pageSize: number) => void;
}

export function MgSupplierItemSearchResults({
  totalPages,
  supplierItems,
  pageSize,
  currentPage,
  setPage,
  setPageSize,
}: Props) {
  const t = useProductsTFunction();
  const { selectedContext } = useContexts();

  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const {
    isOpen: isMonitorItemsModalOpen,
    onClose: closeMonitorItemsModal,
    onOpen: openMonitorItemsModal,
  } = useDisclosure(false);

  const selectItem = useCallback(
    (mgItemNr: number) => {
      setCheckedItems((prev) => {
        let multipleMgItemNrEntries = supplierItems
          .map((item) => item.mgItemNumber)
          .filter((itemNr) => itemNr === mgItemNr).length;

        if (prev.includes(mgItemNr)) {
          return prev.filter((selectedItems) => selectedItems !== mgItemNr);
        } else if (multipleMgItemNrEntries > 1) {
          const entries = [];
          while (multipleMgItemNrEntries > 0) {
            entries.push(mgItemNr);
            multipleMgItemNrEntries = multipleMgItemNrEntries - 1;
          }
          return prev.concat(entries);
        }
        return prev.concat(mgItemNr);
      });
    },
    [supplierItems]
  );

  const navigate = useNavigate();
  const navigateToItem = useCallback(
    ({ mgItemNumber }: MgSupplierItemSearchResult) =>
      navigate(`${mgPrisRoutes.items.item.getFullLinkPath(`${mgItemNumber}`)}`),
    [navigate]
  );

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        py={2}
        position="sticky"
        top={mgprisHeaderHeight}
        zIndex={1}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        bgcolor={(theme) => theme.palette.common.white}
      >
        {checkedItems.length !== 0 && (
          <Typography pl={22} flex={1} textAlign="center">
            {t('products.mgItem.selectedItems', {
              selected: checkedItems.length,
              total: supplierItems.length,
            })}
          </Typography>
        )}

        <MonitorMgSupplierItemsDialog
          open={isMonitorItemsModalOpen}
          onClose={closeMonitorItemsModal}
          selectedItems={supplierItems.filter((item) => checkedItems.includes(item.mgItemNumber))}
          setCheckedItems={setCheckedItems}
        />
        {selectedContext.isAdmin && (
          <ContainedButton
            disabled={checkedItems.length === 0}
            onClick={() => openMonitorItemsModal()}
            size="small"
            color="secondary"
            startIcon={<Add />}
          >
            {t('products.mgItem.addSelected')}
          </ContainedButton>
        )}
      </Stack>
      <MgSupplierItemsTableWithCheckbox
        items={supplierItems}
        onItemClick={navigateToItem}
        selection={
          selectedContext.isAdmin
            ? {
                selectedItemIds: checkedItems,
                setSelectedItems: setCheckedItems,
                selectItem,
              }
            : undefined
        }
      />
      <Stack flexDirection="row" mt={3} alignItems="center" justifyContent="center">
        <SelectPageSize
          currentPageSize={pageSize}
          updatePageSize={(newPageSize: number) => setPageSize(newPageSize)}
        />
        <Pagination
          count={totalPages}
          page={currentPage + 1}
          onChange={(_, newPage) => setPage(newPage - 1)}
        />
      </Stack>
    </>
  );
}
