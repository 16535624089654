/**
 * VAT utility
 */

const DEFAULT_VAT_PERCENT = 25;

export function keepOrAddVATIfPresent(
  priceWithVAT: number | undefined,
  includeVat: boolean,
  p: number = DEFAULT_VAT_PERCENT
): number | undefined {
  if (!includeVat) {
    return priceWithVAT;
  }
  return addVAT(priceWithVAT, p);
}

export function addVAT(
  priceWithoutVAT?: number,
  p: number = DEFAULT_VAT_PERCENT
): number | undefined {
  if (priceWithoutVAT !== undefined) {
    return priceWithoutVAT * (p / 100) + priceWithoutVAT;
  }
  return priceWithoutVAT;
}

export function keepOrRemoveVAT(
  priceWithVAT: number,
  includeVat: boolean,
  p: number = DEFAULT_VAT_PERCENT
): number {
  if (includeVat) {
    return priceWithVAT;
  }
  return removeVAT(priceWithVAT, p);
}

export function keepOrRemoveVATIfPresent(
  priceWithVAT: number | undefined,
  includeVat: boolean,
  p: number = DEFAULT_VAT_PERCENT
): number | undefined {
  if (!includeVat && priceWithVAT) {
    return removeVAT(priceWithVAT, p);
  }
  return priceWithVAT;
}

export function removeVAT(priceWithVAT: number, p: number = DEFAULT_VAT_PERCENT): number {
  if (priceWithVAT !== undefined) {
    return priceWithVAT / (1 + p / 100);
  }
  return priceWithVAT;
}
