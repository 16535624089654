import { StorePrice } from '@retail/products/types';
import { useProductsTFunction } from '@retail/products/i18n';
import { Box, Stack, Typography } from '@mui/material';
import { ExternalLinkIcon } from '@shared/custom-icons';
import { EquivalentItemTooltip } from '../MgItem/EquivalentItemTooltip';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface Props extends StorePrice {
  setItemToView: (mgItemNumber: number) => void;
  excludeVat?: boolean;
}

export function BmpCompetitorPriceCell({
  price,
  unit,
  equivalentMgItemSource,
  setItemToView,
  excludeVat,
  priceDate,
  url,
}: Props) {
  const { i18n } = useTranslation();
  const t = useProductsTFunction();

  return (
    <Stack alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2" fontWeight="600">
          {t('products.prices.pricePerUnit', {
            price: formatPrice(excludeVat ? price * 0.75 : price, i18n.language),
            unit: unit.unit,
          })}
        </Typography>
        {url && (
          <Box component="a" href={url} target="_blank" display="flex">
            <ExternalLinkIcon
              fontSize="inherit"
              titleAccess={t('products.productList.competitorPrice.openInNew')}
            />
          </Box>
        )}
        {equivalentMgItemSource && (
          <EquivalentItemTooltip
            onClick={() => setItemToView(equivalentMgItemSource.mgItemNumber)}
            mgItemNumber={equivalentMgItemSource.mgItemNumber}
          />
        )}
      </Stack>
      <Typography variant="body2" fontWeight="400">
        {dayjs(priceDate).format('ll')}
      </Typography>
    </Stack>
  );
}
