import {
  ActiveItemAgreementSummary,
  ActiveSupplierAgreementSummary,
  ActiveProductGroupAgreementSummary,
} from '@retail/purchase-agreements/types';
import { atom } from 'recoil';

interface ConstructorProps {
  supplierAgreementsToActivate: ActiveSupplierAgreementSummary | null;
  productGroupsAgreementsToActivate: ActiveProductGroupAgreementSummary[];
  itemAgreementsToActivate: ActiveItemAgreementSummary[];
}

export class ActivatedPurchaseAgreements {
  supplierAgreementsToActivate: ActiveSupplierAgreementSummary | null;
  productGroupsAgreementsToActivate: ActiveProductGroupAgreementSummary[];
  itemAgreementsToActivate: ActiveItemAgreementSummary[];

  constructor({
    supplierAgreementsToActivate,
    productGroupsAgreementsToActivate,
    itemAgreementsToActivate,
  }: ConstructorProps) {
    this.supplierAgreementsToActivate = supplierAgreementsToActivate;
    this.productGroupsAgreementsToActivate = productGroupsAgreementsToActivate;
    this.itemAgreementsToActivate = itemAgreementsToActivate;
  }

  numberOfAgreementsToActivate() {
    return (
      (this.supplierAgreementsToActivate ? 1 : 0) +
      this.productGroupsAgreementsToActivate.length +
      this.itemAgreementsToActivate.length
    );
  }

  copy(fieldsToOverride: Partial<ConstructorProps> = {}) {
    return new ActivatedPurchaseAgreements({ ...this, ...fieldsToOverride });
  }
}

export const activatedPurchaseAgreementsAtom = atom<ActivatedPurchaseAgreements>({
  key: 'activatedPurchaseAgreementsAtom',
  default: new ActivatedPurchaseAgreements({
    supplierAgreementsToActivate: null,
    productGroupsAgreementsToActivate: [],
    itemAgreementsToActivate: [],
  }),
});
