import { atom, selector, useRecoilValue } from 'recoil';
import { useRecoilDisclosure } from '@shared/hooks';

const AsideAtom = atom<boolean>({
  key: 'AsideState',
  default: false,
});

const asideWidthSelector = selector({
  key: 'asideWidthSelector',
  get: ({ get }) => (get(AsideAtom) ? '16rem' : '3rem'),
});

export const useAsideDisclosure = () => useRecoilDisclosure(AsideAtom);

export const useAsideWidth = () => useRecoilValue(asideWidthSelector);
