import { syncLocalStorageEffect } from '@retail/hooks';
import { atom, useRecoilState } from 'recoil';

const atomKey = 'selectedAgreements';

export const selectedAgreementsForSupplierAtom = atom<Record<string, string[]>>({
  key: atomKey,
  default: {},
  effects: [syncLocalStorageEffect<Record<string, string[]>>(atomKey)],
});

export const useSelectedAgreementsForSupplier = (supplierId: number) => {
  const [selectedAgreementsForAllSuppliers, setSelectedAgreementsForAllSuppliers] = useRecoilState(
    selectedAgreementsForSupplierAtom
  );
  return {
    selectedAgreements: selectedAgreementsForAllSuppliers[supplierId],
    setSelectedAgreements: (selectedAgreements: string[]) =>
      setSelectedAgreementsForAllSuppliers((prev) => ({
        ...prev,
        [supplierId]: selectedAgreements,
      })),
  };
};
