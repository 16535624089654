import { fetchFile, QueryOptions, useFetch, useMutateFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import {
  ItemCodes,
  ItemCodesDTO,
  MgAssortmentCreateDTO,
  MgAssortmentDetailsDTO,
  MgAssortmentDTO,
  MgAssortmentsPageDTO,
} from '@retail/assortment/types';
import { Context } from '@retail/auth/types';
import { buildUrl } from '@shared/utils';
import { useContexts } from '@retail/app/stores/selected-context';
import { useMutation } from '@tanstack/react-query';

export const mgAssortmentsRoute = '/v1/mg-assortment';
export const validateItemsRoute = '/v1/mg-item/validate-item-codes';

export const getMgAssortmentsQueryKey = (context: Context, mgItemNumbers: number[] = []) => [
  'mgAssortments',
  context.id,
  mgItemNumbers,
];

export const getMgAssortmentQueryKey = (assortmentId: number) => ['mgAssortments', assortmentId];

export function useFetchMgAssortments(
  mgItemNumbers: number[] = [],
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const { selectedContext } = useContexts();

  return useFetch<MgAssortmentDTO[]>({
    method: 'GET',
    queryKey: getMgAssortmentsQueryKey(selectedContext, mgItemNumbers),
    token,
    url: buildUrl({
      base: `${pps.url}${mgAssortmentsRoute}`,
      searchParams: {
        mgItemNumbers,
        excludedSources: ['SOLVE'], // exclude temporarily
      },
    }),
    context: { key: selectedContext.type, value: selectedContext.value },
    ...queryOptions,
  });
}

interface SearchParams {
  assortmentId: number;
  competitorStoreIds: number[];
  page?: number;
  pageSize?: number;
}

export function useFetchMgAssortment(
  { assortmentId, competitorStoreIds, page, pageSize }: SearchParams,
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useFetch<MgAssortmentsPageDTO>({
    method: 'GET',
    queryKey: getMgAssortmentQueryKey(assortmentId),
    token,
    url: buildUrl({
      base: pps.url,
      route: `${mgAssortmentsRoute}/${assortmentId}`,
      searchParams: {
        storeIds: competitorStoreIds,
        page,
        pageSize,
        historicalPrices: false,
        includeByggmaterialpriserPrices: true,
        includeFinfoAgreementPrices: false,
        includeFinfoPrices: false,
        modelType: 'MINIMIZED',
      },
    }),
    ...queryOptions,
  });
}

export function useValidateImportedItems() {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateFetch<ItemCodesDTO, ItemCodes>({
    method: 'POST',
    token,
    url: `${pps.url}${validateItemsRoute}`,
  });
}

export function useCreateMgAssortment(context: Context) {
  const { token } = useAuthContext();
  const { pps } = useMgPrisConfig();

  return useMutateFetch<MgAssortmentDTO, MgAssortmentCreateDTO>({
    url: `${pps.url}${mgAssortmentsRoute}`,
    method: 'POST',
    context: { key: context.type, value: context.value },
    token,
  });
}

interface PostProps {
  mgItemNumbers: number[];
  finfoNumbers?: number[];
  gtinCodes?: number[];
}

interface PatchProps {
  itemCodes: ItemCodes;
  title?: string;
  description?: string;
}

export const useAddItemsToMgAssortment = (context: Context) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateFetch<MgAssortmentDetailsDTO, PostProps>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}/items`,
    method: 'POST',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const useDeleteItemsFromMgAssortment = (context: Context) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateFetch<void>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}/items`,
    method: 'DELETE',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const usePatchTitleOrItemsOfMgAssortment = (context: Context) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateFetch<MgAssortmentDetailsDTO, PatchProps>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}`,
    method: 'PATCH',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const useDeleteMgAssortment = (context: Context) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateFetch<void>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}`,
    method: 'DELETE',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const useExportAssortmentToExcel = () => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutation((assortmentId: number) =>
    fetchFile({
      url: `${pps.url}${mgAssortmentsRoute}/${assortmentId}/export-excel`,
      token,
      method: 'GET'
    })
  );
};
