import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import AdminAssortmentProvider from './AdminAssortmentContext';
import AdminAssortmentContainer from './AdminAssortmentContainer';
import { SuspenseWithErrorBoundary } from '../../components';
import { useAppTFunction } from '@retail/app/i18n';

function AdminAssortmentPage() {
  const { assortmentId } = useParams<{ assortmentId: string }>();
  const t = useAppTFunction();

  return (
    <SuspenseWithErrorBoundary
      errorFallback={LoadingError}
      fallback={() => (
        <Stack alignItems="center" justifyContent="center" flexGrow="1">
          <Typography>{t('assortment.admin.loadingAssortment')}</Typography>
          <CircularProgress />
        </Stack>
      )}
    >
      <AdminAssortmentProvider assortmentId={Number(assortmentId)}>
        <AdminAssortmentContainer />
      </AdminAssortmentProvider>
    </SuspenseWithErrorBoundary>
  );
}

const LoadingError = (error: any) => {
  const t = useAppTFunction();
  const errorMessage =
    error?.error?.status === 404
      ? t('assortment.admin.error.notFound')
      : t('assortment.admin.error.generic');

  return (
    <Stack alignItems="center" justifyContent="center" flexGrow="1" spacing={4}>
      <Typography variant="h1">{errorMessage}</Typography>
      <Button component={Link} to="/admin/assortment">
        {t('assortment.admin.error.goBack')}
      </Button>
    </Stack>
  );
};

export default AdminAssortmentPage;
