import { useFetchContextCompetitors } from '@retail/retailer/data-access';
import { Competitor } from '@retail/retailer/types';
import { useMemo } from 'react';
import { competitorDtoToCompetitors } from '@retail/retailer/utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';

export const useSelectedCompetitors = (orgUnitId: number): Competitor[] => {
  const { data: competitorsDto } = useFetchContextCompetitors(orgUnitId);
  const { retailerLogoBaseUrl } = useMgPrisConfig();

  return useMemo(
    () => (competitorsDto ? competitorDtoToCompetitors(competitorsDto, retailerLogoBaseUrl) : []),
    [competitorsDto, retailerLogoBaseUrl]
  );
};
