import { PurchaseAgreementDTO } from '@retail/purchase-agreements/types';

export const purchaseAgreements: PurchaseAgreementDTO[] = [
  {
    id: '1',
    name: 'Sone 1: Delade pkt',
    fromDate: new Date('2023-12-23T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
  },
  {
    id: '2',
    name: 'Sone 1: Hel pkt',
    fromDate: new Date('2023-12-17T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
  },
  {
    id: '3',
    name: 'Sone 1: Direkt',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
  },
  {
    id: '4',
    name: 'Sone 2: Delade pkt',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
  },
  {
    id: '5',
    name: 'Sone 3: Hel pkt',
    fromDate: new Date('2024-01-10T03:24:00'),
    toDate: new Date('2024-04-10T03:24:00'),
  },
];
