import { SxProps, Theme } from '@mui/material';
import { treeItemClasses } from '@mui/x-tree-view';

export const childNodeClassName = 'childnode';
export const rootNodeClassName = 'rootnode';

export const compactNodesSx: SxProps<Theme> = () => ({
  '& .MuiCollapse-root': { maxHeight: '144px !important', overflow: 'auto' },
});

export const navTreeSx: SxProps<Theme> = (theme) => ({
  [`& .${treeItemClasses.label}`]: {
    fontSize: '16px',
    my: 0.5,
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  [`&.${childNodeClassName} .${treeItemClasses.label}`]: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 'medium',
  },

  [`.${treeItemClasses.selected}, .${treeItemClasses.focused}`]: {
    backgroundColor: 'inherit !important',
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
    },
  },
  [`.${treeItemClasses.content}`]: {
    padding: 0,
    margin: 0,
  },
  [`.${treeItemClasses.group}`]: {
    padding: 0,
    margin: 0,
    marginLeft: 1,
  },
  [`button.${treeItemClasses.iconContainer}`]: {
    width: 'auto',
  },
});

export const navTreeItemSx: SxProps<Theme> = (theme) => ({
  [`& .${treeItemClasses.group}`]: {
    position: 'relative',
    '&::before': {
      content: '""',
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      position: 'absolute',
      top: '1px',
      left: '1px',
      height: 'calc(100% - 24px)',
    },
  },

  [`& .${treeItemClasses.content}`]: {
    [`& .${treeItemClasses.label}`]: {
      fontSize: '14px',
      lineHeight: '34px',
    },
  },
  [`&.${childNodeClassName} .${treeItemClasses.label}`]: {
    position: 'relative',
    pl: '10px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-7px',
      top: '5px',
      width: '12px',
      height: '12px',
      borderLeft: '2px solid',
      borderBottom: '2px solid',
      borderColor: theme.palette.primary.main,
      borderBottomLeftRadius: '8px',
    },
  },
  [`.${treeItemClasses.selected}, .${treeItemClasses.focused}`]: {
    backgroundColor: 'inherit !important',
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  [`button.${treeItemClasses.iconContainer}`]: {
    width: 'auto',
  },
});
