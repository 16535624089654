import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { BreadcrumbsToolbar, NotFoundError, PageLayout } from '@retail/components';
import { useFetchAllMgParticipants } from '@retail/products/data-access';
import { MgParticipant } from '@retail/products/types';
import { BreadcrumbsProps, Paper } from '@shared/components';
import { SupplierSelect } from '@retail/products/components';

export function MarkupForSupplierPage() {
  const t = useAppTFunction();
  const navigate = useNavigate();

  const { supplierId } = useParams<{ supplierId: string }>();
  const { data: participantDtos = [] } = useFetchAllMgParticipants({ suspense: true });
  const suppliers = useMemo(
    () => participantDtos?.map(MgParticipant.fromMinimalDto),
    [participantDtos]
  );

  const selectedSupplier = useMemo(() => {
    return suppliers.find((supplier) => supplier.id === Number(supplierId));
  }, [supplierId, suppliers]);

  const breadcrumbItems: BreadcrumbsProps['items'] = useMemo(() => {
    return [
      { label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() },
      {
        label: t('app.routes.markup'),
        href: mgPrisRoutes.markup.getFullLinkPath(),
      },
      { label: selectedSupplier?.name || `${supplierId}` },
    ];
  }, [supplierId, selectedSupplier?.name, t]);

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper paddingY="dense" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <BreadcrumbsToolbar
          items={breadcrumbItems}
          endContent={
            selectedSupplier && (
              <Stack direction="row" gap={1}>
                <SupplierSelect
                  size="small"
                  suppliers={suppliers}
                  getSupplierId={(participant) => `${participant.id}`}
                  selectedSupplier={selectedSupplier}
                  selectSupplier={() =>
                    navigate(mgPrisRoutes.markup.supplier.getFullLinkPath(`${selectedSupplier.id}`))
                  }
                  getSupplierName={(participant) => participant.name}
                />
              </Stack>
            )
          }
        />
        {selectedSupplier ? (
          <Stack direction="row" mt={2} flexGrow={1} overflow="hidden"></Stack>
        ) : (
          <NotFoundError
            title={t('markup.supplier.notFound.title')}
            subtitle={t('markup.supplier.notFound.subtitle', {
              supplierId,
            })}
            buttonText={t('markup.supplier.notFound.goBack')}
          />
        )}
      </Paper>
    </PageLayout>
  );
}
