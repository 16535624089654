import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { PurchaseAgreementsResources } from '../types/PurchaseAgreementsResources';

export const usePurchaseAgreementsTranslation = () =>
  useMessagesTranslation<PurchaseAgreementsResources>();

export type PurchaseAgreementsMessageKey = MessagePaths<PurchaseAgreementsResources>;

export const usePurchaseAgreementsTFunction = () => usePurchaseAgreementsTranslation().t;

export type PurchaseAgreementsTFunction = ReturnType<typeof usePurchaseAgreementsTFunction>;
