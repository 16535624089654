import { AutocompleteInput } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { GenericProductGroup } from '@retail/products/types';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { Stack } from '@mui/material';
import { CreateFreightMarkupSchema, FreightMarkupFormBase } from '../FreightMarkupForm';

interface Props {
  productGroups: GenericProductGroup[];
}

export function CreateFreightMarkupForProductGroup({ productGroups }: Props) {
  const t = useFreightMarkupTFunction();
  const { control } = useFormContext<CreateFreightMarkupSchema>();
  const productGroupController = useController({ name: 'productGroup', control });

  return (
    <Stack gap={3}>
      <AutocompleteInput
        {...productGroupController.field}
        error={productGroupController.fieldState.error?.message}
        required
        label={t('freightMarkup.level.productGroup')}
        placeholder={t('freightMarkup.actions.chooseProductGroup')}
        showPopupIcon
        options={productGroups}
        getOptionLabel={({ name, commonGroupId }) =>
          name ? `${commonGroupId} ${name}` : commonGroupId
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, value) => {
          productGroupController.field.onChange(value);
        }}
      />
      <FreightMarkupFormBase />
    </Stack>
  );
}
