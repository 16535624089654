import { atom, selector, selectorFamily, useRecoilState } from 'recoil';
import { AssortmentDetailsDTO } from '@retail/assortment/types';
import { NobbOvergruppeDTO } from '@retail/products/types';
import { Root } from '@retail/products/types';
import { calculateProductGroups, deepestSearch, nobbGroupRegex } from '@retail/products/utils';
import { SelectedAssortmentAtom } from '../../features/products/stores';
import { ProductFilter } from '../../types';

export const SelectedProductGroupsAtom = atom<string[]>({
  key: 'selected-product-groups',
  default: [],
});

export const useProductGroups = () => {
  const [selectedProductGroups, setSelectedProductGroups] =
    useRecoilState(SelectedProductGroupsAtom);

  return { selectedProductGroups, setSelectedProductGroups };
};

type ProductGroupsSelectorArgs = {
  assortment: AssortmentDetailsDTO | undefined;
  nobbGroups: NobbOvergruppeDTO[] | undefined;
};

export const productGroupsSelector = selectorFamily<
  Root,
  // @ts-ignore Args should be Serializable but this works fine
  ProductGroupsSelectorArgs
>({
  key: 'getProductGroups',
  get:
    ({ assortment, nobbGroups }) =>
    ({ get }) => {
      const selectedAssortment = get(SelectedAssortmentAtom);
      if (
        selectedAssortment !== undefined &&
        assortment !== undefined &&
        nobbGroups !== undefined
      ) {
        return calculateProductGroups(assortment.nobbProducts, nobbGroups);
      }
      return {};
    },
});

export const productGroupFilterSelector = selector<ProductFilter>({
  key: 'productGroupFilter',
  get: ({ get }) => {
    const selected = get(SelectedProductGroupsAtom);

    return {
      active: () => selected.length > 0,
      filter: (products) => {
        const groups = deepestSearch(selected);
        return products
          .filter((p) => !!p.nobbModul)
          .filter((p) => {
            if (!p.nobbModul?.nobbVaregruppe) {
              return false;
            }
            const group = nobbGroupRegex.exec(p.nobbModul.nobbVaregruppe.varegruppeNr);
            if (group !== null) {
              const [varegruppe, overgruppe, hovedgruppe] = group;
              return (
                groups.has(varegruppe) ||
                groups.has(`${overgruppe}${hovedgruppe}`) ||
                groups.has(overgruppe)
              );
            } else {
              return true;
            }
          });
      },
    };
  },
});
