import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

export function ErrorProduct() {
  const t = useAppTFunction();

  return (
    <Container>
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography>{t('products.loadingError')}</Typography>
      </Box>
    </Container>
  );
}
