import { ProductGroupAgreementDTO, ProductGroupAgreementsDTO } from '../dto';

export type AgreementStatus = 'active' | 'activeInherited' | 'inactive';

export type AgreementLevel = 'supplier' | 'overGroup' | 'mainGroup' | 'underGroup' | 'mgItem';

export type InheritedLevel = {
  id: string;
  level: AgreementLevel;
  name: string;
}

interface ProductGroupAgreementProps {
  id: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  status: AgreementStatus;
  inheritedLevel?: InheritedLevel;
}

interface ProductGroupAgreementsProps {
  productGroupId?: string;
  productGroupName?: string;
  awaitingConfirmationAgreementId: string;
  agreements: ProductGroupAgreement[];
}

export class ProductGroupAgreement {
  id: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  status: AgreementStatus;
  inheritedLevel?: InheritedLevel;

  constructor({ id, name, fromDate, toDate, status, inheritedLevel }: ProductGroupAgreementProps) {
    this.id = id;
    this.name = name;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.status = status;
    this.inheritedLevel = inheritedLevel;
  }

  static fromAgreementDto = (dto: ProductGroupAgreementDTO): ProductGroupAgreement =>
    new ProductGroupAgreement({
      id: dto.id,
      name: dto.name,
      fromDate: dto.fromDate,
      toDate: dto.toDate,
      status: dto.status as AgreementStatus,
      inheritedLevel: dto.inheritedLevel as InheritedLevel
    });
}

export class ProductGroupWithAgreements {
  productGroupId?: string;
  productGroupName?: string;
  awaitingConfirmationAgreementId: string;
  agreements: ProductGroupAgreement[];

  constructor({
    productGroupId,
    productGroupName,
    awaitingConfirmationAgreementId,
    agreements,
  }: ProductGroupAgreementsProps) {
    this.productGroupId = productGroupId;
    this.productGroupName = productGroupName;
    this.awaitingConfirmationAgreementId = awaitingConfirmationAgreementId;
    this.agreements = agreements;
  }

  static fromProductGroupAgreementsDTO = (
    dto: ProductGroupAgreementsDTO,
    awaitingConfirmationAgreementId?: string
  ): ProductGroupWithAgreements =>
    new ProductGroupWithAgreements({
      productGroupId: dto.productGroupId,
      productGroupName: dto.productGroupName,
      awaitingConfirmationAgreementId: awaitingConfirmationAgreementId!,
      agreements: dto.agreements.map(ProductGroupAgreement.fromAgreementDto),
    });
}