import { Clear, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useDebounce } from '@retail/hooks';
import { useFetchSearchMgItems } from '@retail/products/data-access';
import { useProductsTFunction } from '@retail/products/i18n';
import { useKeypress } from '@shared/hooks';
import { getOS } from '@shared/utils';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SearchResultItem } from './SearchResultItem';

type SearchStatus = 'noSearch' | 'searching' | 'error' | 'noResult' | 'success';

interface Props {
  onItemClick: (itemId: number) => void;
  sx?: SystemStyleObject<Theme>;
}

const currentOs = getOS();

const focusHotkeyText = currentOs === 'mac' ? '⌘ K' : 'ctrl K';

export function MgItemSearchAutocomplete({ onItemClick, sx = {} }: Props) {
  const t = useProductsTFunction();
  const { register, watch, setValue, setFocus } = useForm<{ search: string }>({
    mode: 'onChange',
    defaultValues: { search: '' },
  });
  const inputValue = watch('search');
  const debouncedSearch = useDebounce(inputValue, 250);
  const { data, isLoading, error } = useFetchSearchMgItems(
    { page: 0, pageSize: 10, searchQuery: debouncedSearch },
    { enabled: !!debouncedSearch, suspense: false, retry: 0 }
  );

  const onClick: Props['onItemClick'] = useCallback(
    (itemId) => {
      onItemClick(itemId);
      setValue('search', '');
    },
    [onItemClick, setValue]
  );

  const results = data?.result || [];
  const searchField = register('search');

  const searchStatus: SearchStatus = useMemo(() => {
    if (error) {
      return 'error';
    }
    if (isLoading) {
      return 'searching';
    }
    if (!debouncedSearch) {
      return 'noSearch';
    }
    return results?.length ? 'success' : 'noResult';
  }, [debouncedSearch, error, isLoading, results?.length]);

  const PopperComponent = useMemo(() => {
    switch (searchStatus) {
      case 'searching':
        return results?.length ? Popper : NullComponent;
      case 'error':
      case 'noSearch':
        return NullComponent;
      case 'noResult':
      case 'success':
      default:
        return Popper;
    }
  }, [results?.length, searchStatus]);

  const setInputFocusCallback = useCallback(
    (event: KeyboardEvent) => {
      if (
        ((currentOs === 'mac' && event.metaKey) || (currentOs !== 'mac' && event.ctrlKey)) &&
        event.code === 'KeyK'
      ) {
        setFocus('search');
      }
    },
    [setFocus]
  );

  useKeypress(setInputFocusCallback);

  return (
    <Autocomplete
      inputValue={inputValue}
      sx={{ ...autoCompleteSx, ...sx }}
      renderInput={(params) => {
        // @ts-ignore
        const isFocused = params.inputProps?.ref?.current === document.activeElement;

        return (
          <>
            <TextField
              {...params}
              {...searchField}
              InputProps={{
                ...params.InputProps,
                sx: {
                  paddingRight: '9px !important',
                },
                endAdornment: inputValue ? (
                  <InputAdornment position="end">
                    {isLoading ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <Tooltip title={t('products.mgItem.search.reset')}>
                        <IconButton onClick={() => setValue('search', '')}>
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputAdornment>
                ) : (
                  !isFocused && (
                    <InputAdornment position="end">
                      <Box
                        border={({ palette }) => `1px solid ${palette.divider}`}
                        fontSize="0.85rem"
                        fontWeight="bold"
                        paddingX="6px"
                        borderRadius="7px"
                        lineHeight="28px"
                      >
                        {focusHotkeyText}
                      </Box>
                    </InputAdornment>
                  )
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className="search-icon" />
                  </InputAdornment>
                ),
              }}
              error={!!error}
              placeholder={t('products.mgItem.search.input.label')}
              fullWidth
            />
            {!!error && (
              <FormHelperText error>{t('products.mgItem.search.error.generic')}</FormHelperText>
            )}
          </>
        );
      }}
      options={results}
      renderOption={(props, option) => (
        <SearchResultItem
          key={option.mgItemNumber}
          liProps={{ ...props, onClick: () => onClick(option.mgItemNumber) }}
          searchQuery={debouncedSearch}
          {...option}
        />
      )}
      clearOnBlur={false}
      filterOptions={(option) => option} // must be set, see https://mui.com/material-ui/react-autocomplete/#search-as-you-type
      getOptionLabel={({ mgSupplierItems, mgItemNumber }) =>
        mgSupplierItems.find((item) => item.primaryText)?.primaryText || `${mgItemNumber}`
      }
      PopperComponent={PopperComponent}
      ListboxProps={{
        sx: {
          maxHeight: '25rem',
          '& li': {
            borderTop: ({ palette }) => `1px solid ${palette.divider}`,
            '&:last-child': {
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
            },
          },
        },
      }}
      noOptionsText={t('products.mgItem.search.noResults')}
    />
  );
}

function NullComponent() {
  return null;
}

const autoCompleteSx: SystemStyleObject<Theme> = {
  '&.Mui-focused .search-icon': {
    color: ({ palette }) => palette.primary.main,
  },
  '& .Mui-error .search-icon': {
    color: ({ palette }) => palette.error.main,
  },
  '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
    paddingY: '4px',
  },
};
