import { IconButton, Menu, MenuItem, Stack, styled, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Flag } from '@shared/components';
import { LanguageCapitalLettersCode } from '@shared/types';
import { useState } from 'react';

type Language = 'no' | 'se';

interface Props {
  selectedLanguage: string;
  languages: Language[];
  switchLanguage: (languageCode: Language) => void;
}

export const LanguageSwitch = ({ selectedLanguage, switchLanguage, languages }: Props) => {
  const t = useAppTFunction();
  const { palette, spacing, shadows } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const selectedLanguageAsType = selectedLanguage as Language;
  const handleLanguageButtons = (newLanguage: Language) => {
    switchLanguage(newLanguage);
    setAnchorEl(null);
  };

  const args = {
    '&:hover': {
      outline: `1px solid ${palette.primary[900]}`,
      borderRadius: '50%',
    },
  };

  return (
    <Stack
      border={`1px solid ${palette.grey[50]}`}
      bgcolor={palette.common.white}
      borderRadius={spacing(2)}
      boxShadow={shadows[7]}
    >
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        aria-label={t('common.changeLanguage')}
        sx={{ padding: 0.5 }}
      >
        <Flag country={selectedLanguage.toUpperCase() as LanguageCapitalLettersCode} size="25px" />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          aria-label={t(`common.changeTo.${selectedLanguageAsType}`)}
          key={selectedLanguageAsType}
          value={selectedLanguageAsType}
          onClick={() => handleLanguageButtons(selectedLanguageAsType)}
          sx={{ padding: 0.3, pt: 0.1 }}
        >
          <Flag
            country={selectedLanguageAsType.toUpperCase() as LanguageCapitalLettersCode}
            size="25px"
            sx={args}
          />
        </MenuItem>
        {languages.map(
          (language) =>
            selectedLanguageAsType !== language && (
              <MenuItem
                aria-label={t(`common.changeTo.${language}`)}
                key={language}
                value={language}
                onClick={() => handleLanguageButtons(language)}
                sx={{ padding: 0.3 }}
              >
                <Flag
                  country={language.toUpperCase() as LanguageCapitalLettersCode}
                  size="25px"
                  sx={args}
                />
              </MenuItem>
            )
        )}
      </StyledMenu>
    </Stack>
  );
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  p: 0,
  '& .MuiMenu-paper': {
    padding: 0,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[7],
    border: `1px solid ${theme.palette.grey[50]}`,
  },
  '& .MuiMenu-list': { padding: 0 },
}));

export default LanguageSwitch;
