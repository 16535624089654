import { routeParamStart, Routes, RoutesDef } from './routeTypes';

/**
 *
 * @param input - must conform to RoutesDef, but should be typed 'as const' to correctly infer the output types
 * @param parentPath
 */
export function transformRoutes<TInputRoutes extends RoutesDef>(
  input: TInputRoutes,
  parentPath = ''
): Routes<TInputRoutes> {
  // @ts-ignore
  const output: Routes<TInputRoutes> = {};

  for (const routeKey in input) {
    const route = input[routeKey];

    const outputPath = parentPath + route.path;
    // @ts-ignore
    output[routeKey] = {
      relativeRoutePath: route.path,
      fullRoutePath: outputPath,
      getRelativeLinkPath: route.path.startsWith(routeParamStart)
        ? (routeParam) => `/${routeParam}`
        : () => route.path,
      getFullLinkPath: route.path.startsWith(routeParamStart)
        ? (routeParam) => `${parentPath}/${routeParam}`
        : () => outputPath,
    };

    if (route.sub) {
      output[routeKey] = { ...output[routeKey], ...transformRoutes(route.sub, outputPath) };
    }
  }

  return output;
}
