import { MgSupplierItemBase } from './MgSupplierItem';
import { MgPackage } from './MgPackage';
import { CompetitorWithPrice } from './CompetitorWithPrice';

interface ConstructorProps<TSupplierItem extends MgSupplierItemBase> {
  id: number;
  supplierItems: TSupplierItem[];
  mgPackages: MgPackage[];
  competitors: CompetitorWithPrice[];
}

export abstract class MgItemBase<TSupplierItem extends MgSupplierItemBase = MgSupplierItemBase> {
  id: number;
  supplierItems: TSupplierItem[];
  mgPackages: MgPackage[];
  competitors: CompetitorWithPrice[];

  protected constructor({
    id,
    supplierItems,
    mgPackages,
    competitors,
  }: ConstructorProps<TSupplierItem>) {
    this.id = id;
    this.supplierItems = supplierItems;
    this.mgPackages = mgPackages;
    this.competitors = competitors;
  }

  getPrimarySupplierItem = (): TSupplierItem => {
    const mainSupplier = this.supplierItems.find(({ isMainSupplier }) => isMainSupplier);
    if (mainSupplier) {
      return mainSupplier;
    }
    const supplierItemsWithFinfoNumber = this.supplierItems.filter(
      ({ finfoNumber }) => finfoNumber
    );
    if (supplierItemsWithFinfoNumber.length) {
      return this.supplierItems.sort((a, b) => (b.finfoNumber || 0) - (a.finfoNumber || 0))[0];
    }
    return this.supplierItems[0];
  };

  getPrimaryPackage = (): MgPackage | undefined => {
    return (
      this.mgPackages.find(
        ({ packageType, packageNumber }) => packageNumber === 1 && packageType === 'PRIMARY'
      ) ||
      this.mgPackages
        .filter(({ gtinCode }) => gtinCode)
        .sort((a, b) => (a.packageNumber > b.packageNumber ? 1 : -1))[0] ||
      this.mgPackages.sort((a, b) => (a.packageNumber > b.packageNumber ? 1 : -1))[0]
    );
  };
}
