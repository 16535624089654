import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchMe } from '../api';
import { routes } from '../config';
import { useSelectedContext } from '../stores/userContexts';
import { useSelectedPrisinnsiktCompetitors } from '../hooks';

const firstLoginPath = routes.firstLogin.path;
const safeList = [firstLoginPath, `${routes.admin.path}/${routes.admin.sub.retailers.path}`];

/**
 * If context does not have any selected competitors then show selector.
 * Super admins can skip this.
 */
export const FirstLoginContextContainer = (props: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: me } = useFetchMe();
  const selectedContext = useSelectedContext();
  const selectedCompetitors = useSelectedPrisinnsiktCompetitors(selectedContext);

  useEffect(() => {
    if (me?.isSuperUser) {
      return;
    }
    if (selectedCompetitors.length === 0 && !safeList.includes(location.pathname)) {
      navigate(firstLoginPath);
    } else if (selectedCompetitors.length > 0 && location.pathname === firstLoginPath) {
      navigate('/');
    }
  }, [selectedCompetitors, location, navigate, me]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{props.children}</React.Fragment>;
};
