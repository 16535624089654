import {
  BodyLoader,
  DefaultPpsErrorFallback,
  ErrorFallback,
  LoadingErrorProps,
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
  TagBreadcrumbs,
  TanstackQueryLoadingWrapper,
} from '@retail/components';
import { useParams } from 'react-router-dom';
import { Paper } from '@shared/components';
import { useSelectedCompetitors } from '@retail/app/hooks';
import { useContexts } from '@retail/app/stores/selected-context';
import { useFetchMgItem } from '@retail/products/data-access';
import { MgItemDetailed } from '@retail/products/types';
import { CircularProgress, Stack } from '@mui/material';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgItemContainer } from '@retail/products/containers';

export function MgItemPage() {
  const { itemNumber } = useParams<{ itemNumber: string }>();
  const { selectedContext } = useContexts();
  const competitors = useSelectedCompetitors(selectedContext.id);
  const t = useProductsTFunction();
  const mgItemLoadingState = useFetchMgItem(
    {
      mgItemNumber: Number(itemNumber),
      includeByggmaterialpriserPrices: true,
      competitorStoreIds: competitors.map((competitor) => competitor.id),
    },
    { suspense: false }
  );

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <TanstackQueryLoadingWrapper
        loadingState={mgItemLoadingState}
        ErrorComponent={MgItemErrorPage}
        LoadingComponent={MgItemLoadingState}
      >
        {(mgItemDto) => {
          const mgItem = MgItemDetailed.fromDto(mgItemDto);
          const firstSupplierItem = mgItem.getPrimarySupplierItem();
          return (
            <Paper
              paddingY="dense"
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <TagBreadcrumbs
                items={[
                  {
                    label: t('products.search.title'),
                    href: mgPrisRoutes.items.getFullLinkPath(),
                  },
                  {
                    label: firstSupplierItem.primaryText,
                  },
                ]}
              />
              <SuspenseWithSentryErrorBoundary
                errorFallback={(props) => <ErrorFallback {...props} />}
                suspenseFallback={<BodyLoader />}
              >
                <MgItemContainer mgItem={mgItem} competitors={competitors} />
              </SuspenseWithSentryErrorBoundary>
            </Paper>
          );
        }}
      </TanstackQueryLoadingWrapper>
    </PageLayout>
  );
}

function MgItemErrorPage({ error, reload }: LoadingErrorProps) {
  const t = useProductsTFunction();
  const { itemNumber } = useParams<{ itemNumber: string }>();
  if (error.status === 404) {
    return (
      <>
        <TagBreadcrumbs
          items={[
            {
              label: t('products.search.title'),
              href: mgPrisRoutes.items.getFullLinkPath(),
            },
            {
              label: itemNumber + '',
            },
          ]}
        />
        <NotFoundError
          title={t('products.mgItem.notFound.title')}
          subtitle={t('products.mgItem.notFound.subtitle', { itemId: itemNumber + '' })}
          navigateTo={mgPrisRoutes.items.fullRoutePath}
          buttonText={t('products.mgItem.notFound.goBack')}
        />
      </>
    );
  }
  return <DefaultPpsErrorFallback error={error} reload={reload} />;
}
function MgItemLoadingState() {
  const t = useProductsTFunction();
  return (
    <>
      <TagBreadcrumbs
        items={[
          {
            label: t('products.search.title'),
            href: mgPrisRoutes.items.getFullLinkPath(),
          },
          {
            label: '',
          },
        ]}
      />
      <Stack display="flex" alignItems="center" pt={24}>
        <CircularProgress size={50} />
      </Stack>
    </>
  );
}
