import { MgParticipantDTO, MgParticipantsMinimalDTO } from '../../dto';

export class MgParticipant {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  static fromMinimalDto = (dto: MgParticipantsMinimalDTO): MgParticipant =>
    new MgParticipant(dto.mgParticipantAccountId, dto.participantAccountName);

  static fromDto = (dto: MgParticipantDTO) =>
    new MgParticipant(dto.mgParticipantAccountId, dto.participantAccountName);
}
