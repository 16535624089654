import { useQuery } from '@tanstack/react-query';
import { prisinnsiktConfig } from '@retail/app/config';
import { RetailerDetails } from '../../types';
import { Context } from '@retail/auth/types';
import { fetcher } from '@shared/fetch-utils';
import { useToken } from '../../hooks/useToken';
import { useSelectedContext } from '../../stores/userContexts';

const url = prisinnsiktConfig.pps.url;

export async function fetchRetailersClosestStores(
  token: string,
  context: Context,
  radius: number,
  storeId?: number
): Promise<RetailerDetails[]> {
  return fetcher<RetailerDetails[]>({
    url: `${url}/v1/retailers/closestStores?km=${radius}${storeId ? `&storeId=${storeId}` : ''}`,
    token,
    context: { key: context.type, value: context.value },
  });
}

/**
 * This endpoint is the latest and greatest but got some bugs where context does not have coordinates
 * Using old fetchRetailersClosestStores until we find a better solution
 */
export async function fetchClosestStores(
  token: string,
  context: Context,
  radius: number
): Promise<RetailerDetails[]> {
  return fetcher<RetailerDetails[]>({
    url: `${url}/v1/retailers/getClosestStores?km=${radius}`,
    token,
    context: { key: context.type, value: context.value },
  });
}

export function useClosestRetailersApi(radius: number, storeId: number | undefined) {
  const tokenWithErrorHandling = useToken();
  const selectedContext = useSelectedContext();

  return useQuery(
    ['closest-retailers', selectedContext, radius, storeId],
    async () => {
      const token = await tokenWithErrorHandling();
      return fetchRetailersClosestStores(token, selectedContext, radius, storeId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!storeId,
    }
  );
}
