import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { ByggmaterialpriserRetailerDTO } from '@retail/retailer/types';

export const byggmaterialretailersRoute = '/v1/byggmaterialpriser/retailers';

export function useFetchByggmaterialRetailers(queryOptions: QueryOptions = {}) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<ByggmaterialpriserRetailerDTO[]>({
    method: 'GET',
    queryKey: ['byggmaterialretailers', 'all'],
    token,
    url: `${pps.url}${byggmaterialretailersRoute}/all`,
    ...queryOptions,
  });
}
