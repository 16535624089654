import React, { ReactElement, useCallback, useMemo } from 'react';
import * as XLSX from 'xlsx';
import { Box, useTheme } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useToast } from '@retail/hooks';
import { useAppTFunction } from '@retail/app/i18n';
import { SpreadsheetStructure } from '@retail/assortment/types';

interface Props {
  onUpload: (spreadsheet: SpreadsheetStructure) => void;
  label: string | ReactElement;
}

export const ImportSpreadsheetInput: React.FC<Props> = ({ onUpload, label }) => {
  const t = useAppTFunction();
  const toast = useToast();
  const theme = useTheme();
  const spreadsheetFileToJsonReader = useMemo(() => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const readData = XLSX.read(event.target?.result, { type: 'binary' });
      const wsname = readData.SheetNames[0];
      const ws = readData.Sheets[wsname];

      const parsedData = XLSX.utils.sheet_to_json<(string | number)[]>(ws, {
        header: 1,
      });
      const [headerRow, ...dataRows] = parsedData;
      onUpload({ headerRow, dataRows });
    };

    return reader;
  }, [onUpload]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile) {
        spreadsheetFileToJsonReader.readAsBinaryString(acceptedFile);
      } else if (rejectedFiles.length) {
        toast.error(t('assortment.admin.create.fromFile.import.error.fileType'));
      }
    },
    [spreadsheetFileToJsonReader, t, toast]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': [],
    },
  });

  return (
    <Box
      component="label"
      sx={{
        display: 'flex',
        p: 2,
        borderWidth: '2px',
        borderRadius: '8px',
        borderStyle: 'dashed',
        bgcolor: ({ palette }) => palette.grey[100],
        borderColor: ({ palette }) => (isDragActive ? palette.primary.main : palette.grey[300]),
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer',
          borderColor: ({ palette }) => palette.primary.main,
        },
      }}
      {...getRootProps()}
    >
      <Box display="flex" flexDirection="column" flexGrow="1" pr={1}>
        {label}
      </Box>
      <FileUpload htmlColor={theme.palette.grey[500]} />
      <Box component="input" flexGrow="1" {...getInputProps()} />
    </Box>
  );
};
