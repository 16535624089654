import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AgreementTabs, SelectedAgreementLevel } from '@retail/purchase-agreements/types';

export const usePurchaseAgreementsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedProductGroupId = useMemo(
    () => searchParams.get('productGroupId') || undefined,
    [searchParams]
  );

  const selectedMgItemId = useMemo(() => searchParams.get('mgItemId'), [searchParams]);

  const selectedTab = useMemo(() => searchParams.get('tab'), [searchParams]);

  const selectAgreementLevel = ({ id, level }: SelectedAgreementLevel) =>
    setSearchParams(() => {
      if (level === 'supplier') {
        searchParams.delete('productGroupId');
        searchParams.delete('mgItemId');
      } else if (level === 'mgItem') {
        searchParams.delete('productGroupId');
        searchParams.set('mgItemId', id);
      } else {
        searchParams.set('productGroupId', id);
        searchParams.delete('mgItemId');
      }

      searchParams.set('tab', AgreementTabs.AGREEMENTS);

      return searchParams;
    });

  const selectTab = (tab: AgreementTabs) => {
    setSearchParams(() => {
      searchParams.set('tab', tab);
      return searchParams;
    });
  };

  return {
    selectedProductGroupId,
    selectedMgItemId,
    selectedTab,
    selectAgreementLevel,
    selectTab
  };
};