import * as yup from 'yup';

export enum ReplaceOrAdd {
  replace = 'replace',
  add = 'add',
}

export interface ReplaceOrAddNobbNrsForm {
  action: ReplaceOrAdd;
}

export const replaceOrAddNobbNrsSchema = yup.object().shape({
  action: yup
    .string()
    .oneOf(Object.values(ReplaceOrAdd))
    .required({ key: 'assortment.admin.update.import.addOrReplace.chooseOne' }),
});
