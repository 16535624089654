import { useCallback } from 'react';
import { ValidationError } from './formValidations';
import { useMessagesTranslation } from '@shared/i18n';

type MessageWrappedError = { message: ValidationError<any> };
export const useGetErrorMessage = () => {
  const { t } = useMessagesTranslation();

  return useCallback(
    (error: MessageWrappedError | ValidationError<any> | string | undefined) => {
      if (!error) {
        return undefined;
      } else if (typeof error === 'string') {
        return error;
      } else {
        // @ts-ignore
        if (error.message) {
          // @ts-ignore
          const { key, values } = error.message;
          return t(key, values);
        } else {
          // @ts-ignore
          const { key, values } = error;
          return t(key, values);
        }
      }
    },
    [t]
  );
};
