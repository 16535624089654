import { MgProductPriceDTO, PriceProvider } from '@retail/products/types';
import { prisinnsiktConfig } from '@retail/app/config';
import { NobbNr } from '@retail/products/types';
import { QueryOptions, RequestContext, useFetch } from '@shared/fetch-utils';
import { useAuthContext } from './AuthContext';

const priceBaseUrl = `${prisinnsiktConfig.pps.url}/v1/price`;

interface PriceUrlProps {
  priceProvider: PriceProvider;
  numPrices: number;
}

const getProviderPriceUrl = ({ priceProvider, numPrices }: PriceUrlProps) =>
  `${priceBaseUrl}/${priceProvider}/Price?prices=${numPrices}`;

interface Options extends QueryOptions {
  nobbNumbers: NobbNr[];
  priceProvider: PriceProvider;
  context: RequestContext;
  numPrices?: number;
}

export const useFetchPriceForProvider = ({
  nobbNumbers,
  priceProvider,
  numPrices = 1,
  ...queryOptions
}: Options) => {
  const { token } = useAuthContext();
  return useFetch<MgProductPriceDTO[]>({
    queryKey: [priceProvider, queryOptions.context, nobbNumbers],
    method: 'POST',
    body: nobbNumbers,
    token,
    url: getProviderPriceUrl({ priceProvider, numPrices }),
    ...queryOptions,
  });
};
