import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SelectedAgreementLevel } from '@retail/purchase-agreements/types';
import { FreightMarkupLevel } from '@retail/freight-markup/types';


export const useFreightMarkupSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedLevel = useMemo(
    () => searchParams.get('level') as FreightMarkupLevel || undefined,
    [searchParams]
  );

  const selectedProductGroupId = useMemo(
    () => searchParams.get('productGroupId') || undefined,
    [searchParams]
  );

  const selectedMgItemId = useMemo(() => searchParams.get('mgItemId') || undefined, [searchParams]);

  const selectFreightMarkupLevel = ({ id, level }: SelectedAgreementLevel) =>
    setSearchParams(() => {
      if (level === 'supplier') {
        searchParams.delete('level');
        searchParams.delete('productGroupId');
        searchParams.delete('mgItemId');
      } else if (level === 'mgItem') {
        searchParams.delete('level');
        searchParams.delete('productGroupId');
        searchParams.set('mgItemId', id);
      } else {
        searchParams.set('level', level);
        searchParams.set('productGroupId', id);
        searchParams.delete('mgItemId');
      }
      return searchParams;
    });

  return {
    selectedLevel,
    selectedProductGroupId,
    selectedMgItemId,
    selectFreightMarkupLevel
  };
};