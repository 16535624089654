import { forwardRef, PropsWithChildren } from 'react';
import { Button, ButtonProps } from '@mui/material';

function TextButtonBase(
  { children, ...rest }: PropsWithChildren<ButtonProps>,
  ref: ButtonProps['ref']
) {
  return (
    <Button
      variant="text"
      ref={ref}
      size="small"
      color="grey"
      sx={{
        fontSize: '14px',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

const TextButton = forwardRef(TextButtonBase);

export default TextButton;
