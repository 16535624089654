import { Fragment, useMemo } from 'react';
import { Box, ButtonBase, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { usePopupElement } from '@shared/hooks';

export interface BrandSelectOption {
  name: string;
  key: string;
  logoSrc: string;
}

interface Props {
  brands: BrandSelectOption[];
  selectedBrandKey: string;
  onBrandSelect: (brandKey: string) => void;
}

export function BrandSelect({ brands, selectedBrandKey, onBrandSelect }: Props) {
  const { palette } = useTheme();
  const selectedBrand = useMemo(
    () => brands.find((b) => b.key === selectedBrandKey) as BrandSelectOption,
    [brands, selectedBrandKey]
  );

  const { menuElementProps, triggerElementProps } = usePopupElement();
  const enableMenu = useMemo(() => brands.length > 1, [brands]);

  return (
    <Fragment>
      <Stack
        component={ButtonBase}
        {...triggerElementProps}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={palette.background.default}
        color={palette.text.primary}
        borderRadius={3}
        width="228px"
        px={2}
        py={1}
        sx={{
          cursor: enableMenu ? 'pointer' : 'default',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Box
            component="img"
            src={selectedBrand.logoSrc}
            alt={selectedBrand.name}
            sx={{ width: '30px', height: '30px' }}
          />
          <Typography variant="body1" fontWeight="500">
            {selectedBrand.name}
          </Typography>
        </Stack>
        {enableMenu && (
          <ChevronLeft
            sx={{
              transform: menuElementProps.open ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        )}
      </Stack>
      {enableMenu && (
        <Menu
          {...menuElementProps}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {brands.map((brand) => (
            <MenuItem
              key={brand.key}
              value={brand.key}
              onClick={() => {
                onBrandSelect(brand.key);
              }}
              selected={brand.key === selectedBrand.key}
            >
              <Box
                component="img"
                src={brand.logoSrc}
                alt={brand.name}
                sx={{ width: '20px', height: '20px' }}
                />
              {brand.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
}
