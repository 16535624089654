import { useAuth0 } from '@auth0/auth0-react';

export function useToken() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const getAccessTokenWithErrorHandling = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (e: any) {
      // 1) token has expired or requires relogin
      if (e.error === 'login_required') {
        // relog
        loginWithRedirect();
        throw e;
      } else {
        throw e;
      }
    }
  };

  return getAccessTokenWithErrorHandling;
}
