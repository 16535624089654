import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { useController, useFormContext } from 'react-hook-form';
import {
  CreateFreightMarkupForm,
  CreateFreightMarkupSchema,
  percentFreightUnit,
} from '@retail/freight-markup/components';
import {
  useFetchBk04ProductGroups,
  useMgSupplierItemsSearch,
  useTriggerFetchMgSupplierItem,
} from '@retail/products/data-access';
import { Bk04Overgruppe, MgSupplierItemSearchResult } from '@retail/products/types';
import { useCallback, useState } from 'react';
import { useDebounce, useToast } from '@retail/hooks';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useMutateCreateNewFreightMarkup } from '@retail/freight-markup/data-access';

interface Props {
  onClose: () => void;
  open: boolean;
  supplierId: number;
}

function CreateFreightMarkupDialog({ onClose, supplierId }: Props) {
  const t = useAppTFunction();
  const toast = useToast();

  const { handleSubmit, control } = useFormContext<CreateFreightMarkupSchema>();
  const levelController = useController({ name: 'level', control });

  const { data: productGroupsDto = [] } = useFetchBk04ProductGroups(
    { suspense: true, enabled: !!(levelController.field.value === 'productGroup') },
    {
      supplierId: supplierId,
    }
  );

  const productGroups = productGroupsDto!.map(Bk04Overgruppe.fromDto);
  const flattenedProductGroups = Bk04Overgruppe.flatten(productGroups);

  const [mgSupplierItemSearch, setMgSupplierItemSearch] = useState('');
  const debouncedSearch = useDebounce(mgSupplierItemSearch, 400);
  const { data: supplierItemsDto, isLoading: isLoadingFetchItems } = useMgSupplierItemsSearch(
    {
      pageSize: 20,
      page: 0,
      textSearch: debouncedSearch,
      mgParticipantAccountIds: [supplierId],
    },
    {
      enabled: !!(levelController.field.value === 'mgSupplierItem') && !!debouncedSearch,
      suspense: false,
      retry: 0,
    }
  );

  const items = mgSupplierItemSearch ? supplierItemsDto?.result || [] : undefined;
  const mgSupplierItems = items
    ? MgSupplierItemSearchResult.fromSearchResultDTOs(items, [], [])
    : undefined;

  const { mutateAsync: fetchMgSupplierItem } = useTriggerFetchMgSupplierItem();

  const getMgSupplierItem = useCallback(
    (mgSupplierItemId: number) => {
      return fetchMgSupplierItem({ urlVariables: mgSupplierItemId });
    },
    [fetchMgSupplierItem]
  );

  const { mutateAsync: postFreightMarkup, isLoading } = useMutateCreateNewFreightMarkup();
  const onSubmit = useCallback(
    (data: CreateFreightMarkupSchema) => {
      postFreightMarkup({
        body: {
          mgSupplierId: supplierId,
          mgSupplierFreightFactor:
            data.level === 'supplier' ? Number(data.freightFactor) : undefined,
          productGroupFreight:
            data.level === 'productGroup'
              ? {
                  productGroupId: data.productGroup.id,
                  freightFactor: Number(data.freightFactor),
                }
              : undefined,
          mgSupplierItemFreight:
            data.level === 'mgSupplierItem'
              ? {
                  mgSupplierItemId: data.mgSupplierItem.mgSupplierItemId,
                  freightFactor:
                    data.freightUnit === percentFreightUnit
                      ? Number(data.freightFactor)
                      : undefined,
                  frightSurcharge:
                    data.freightUnit !== percentFreightUnit
                      ? {
                          unit: data.freightUnit,
                          value: Number(data.freightSurcharge),
                        }
                      : undefined,
                }
              : undefined,
          fromDate: `${data.fromDate.getFullYear()}-${data.fromDate.getMonth()}-${data.fromDate.getDay()}`,
          toDate: data.toDate
            ? `${data.toDate.getFullYear()}-${data.toDate.getMonth()}-${data.toDate.getDay()}`
            : undefined,
        },
      })
        .then(() => {
          onClose();
          toast.success(t('freightMarkup.actions.success'));
        })
        .catch(() => toast.error(t('freightMarkup.errors.generic')));
    },
    [postFreightMarkup, supplierId, onClose, toast, t]
  );

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle>{t('freightMarkup.newFreightMarkup')}</DialogTitle>
      <DialogContent>
        <CreateFreightMarkupForm
          productGroups={flattenedProductGroups}
          mgSupplierItems={mgSupplierItems}
          setMgSupplierItemSearch={setMgSupplierItemSearch}
          getMgSupplierItem={getMgSupplierItem}
          isLoadingFetchItems={isLoadingFetchItems}
        />
      </DialogContent>

      <DialogActions>
        <TextButton
          sx={{ textDecoration: 'none', color: ({ palette }) => palette.common.black }}
          size="small"
          onClick={onClose}
        >
          {t('common.close')}
        </TextButton>
        <ContainedButton
          onClick={handleSubmit((data) => onSubmit(data))}
          disabled={isLoading}
          size="small"
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateFreightMarkupDialog);
