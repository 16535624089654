import { EquivalentGroupDTO } from '@retail/products/types';
import { prisinnsiktConfig } from '@retail/app/config';
import { useToken } from '../../hooks/useToken';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetcher } from '@shared/fetch-utils';

export function fetchEquivalentGroup(token: string, nobbNr: string) {
  return fetcher<EquivalentGroupDTO[]>({
    url: `${prisinnsiktConfig.pps.url}/v2/equivalents/alternativeProducts`,
    token,
    method: 'POST',
    body: JSON.stringify([nobbNr]),
  });
}

export function fetchMasterProductEquivalentGroup(token: string, nobbNr: string) {
  return fetcher<EquivalentGroupDTO[]>({
    url: `${prisinnsiktConfig.pps.url}/v2/masterProduct/alternativeProducts`,
    token,
    method: 'POST',
    body: JSON.stringify([nobbNr]),
  });
}

export interface EquivalentSearchError {
  status: number;
  message?: string;
}

export const useEquivalentGroup = (nobbNr: string | null) => {
  const getToken = useToken();

  const queryOptions = useMemo(
    () => ({ enabled: Boolean(nobbNr), suspense: false, retry: false }),
    [nobbNr]
  );

  const result = useQuery(
    ['equivalentGroup', nobbNr],
    async () => {
      const token = await getToken();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return fetchEquivalentGroup(token, nobbNr!);
    },
    queryOptions
  );
  const error = result.error as any;

  return {
    equivalentGroup: result.data,
    isLoading: result.isLoading,
    error: error
      ? {
          status: error.status,
          message: error.payload?.error,
        }
      : undefined,
  };
};

export const useMasterProductGroup = (nobbNr: string | null) => {
  const getToken = useToken();

  const queryOptions = useMemo(
    () => ({ enabled: Boolean(nobbNr), suspense: false, retry: false }),
    [nobbNr]
  );

  const result = useQuery(
    ['masterGroup', nobbNr],
    async () => {
      const token = await getToken();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return fetchMasterProductEquivalentGroup(token, nobbNr!);
    },
    queryOptions
  );

  const error = result.error as any;

  return {
    masterPrdouctGroup: result.data,
    isLoading: result.isLoading,
    error: error
      ? {
          status: error.status,
          message: error.payload?.error,
        }
      : undefined,
  };
};
