import { CopyIcon } from '@shared/custom-icons';
import { IconButton, Stack, StackProps, SvgIconProps, Tooltip } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { useAppTFunction } from '@retail/app/i18n';

interface Props extends StackProps {
  value: string | number;
  copiedText?: string;
  CopyIconProps?: SvgIconProps;
}

export const CopyToClipboard: React.FC<PropsWithChildren<Props>> = ({
  value,
  copiedText,
  children,
  CopyIconProps = {
    fontSize: 'small',
    color: 'action',
  },
  ...stackProps
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const t = useAppTFunction();

  const copy = () => {
    navigator.clipboard.writeText(`${value}`);
    setShowTooltip(true);
  };

  return (
    <Stack direction="row" spacing={0} alignItems="center" {...stackProps}>
      {children}
      <Tooltip
        title={copiedText || t('common.clipboard.copied')}
        open={showTooltip}
        leaveDelay={1000}
        onClose={() => setShowTooltip(false)}
      >
        <IconButton aria-label={t('common.clipboard.copy')} size="small" onClick={copy}>
          <CopyIcon {...CopyIconProps} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
