import { useMutation } from '@tanstack/react-query';
import { fetcher, QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useContexts } from '@retail/app/stores/selected-context';
import { ContextUser, SearchUser } from '@retail/auth/types';

export const contextsUsersRoute = '/v1/contexts/users';

export function useFetchContextUsers(queryOptions: QueryOptions = { refetchOnWindowFocus: true }) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<ContextUser[]>({
    method: 'GET',
    queryKey: ['context-users', selectedContext],
    token,
    url: `${pps.url}${contextsUsersRoute}`,
    context: { key: selectedContext.type, value: selectedContext.value },
    ...queryOptions,
  });
}

export const usersRoute = '/v1/users';
export const adminContextPath = 'adminContext';

export const useSetUserAsAdmin = () => {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutation(async (userId: string) => {
    return fetcher<SearchUser>({
      url: `${pps.url}${usersRoute}/${userId}/${adminContextPath}`,
      method: 'POST',
      token,
      context: { key: selectedContext.type, value: selectedContext.value },
    });
  });
};

export const useRemoveUserAsAdmin = () => {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutation(async (userId: string) => {
    return fetcher<SearchUser>({
      url: `${pps.url}${usersRoute}/${userId}/${adminContextPath}`,
      method: 'DELETE',
      token,
      context: { key: selectedContext.type, value: selectedContext.value },
    });
  });
};
