import { MgSupplierItemSearchResultMinimal } from '@retail/products/types';
import { useFetchAffectedItems } from '@retail/purchase-agreements/data-access';
import { MgSupplierItemsPageResult } from '@retail/purchase-agreements/types';
import { usePagination } from '@shared/hooks';

interface Props {
  mgSupplierId: number;
  productGroupId?: string;
  initPageSize: number;
}

export const useAffectedItems = ({ mgSupplierId, productGroupId, initPageSize }: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: initPageSize,
    initPage: 0,
  });

  const { data } = useFetchAffectedItems(
    {
      mgSupplierId,
      productGroupId,
      page,
      pageSize,
    },
    {
      suspense: true,
    }
  );

  const mgSupplierItemsPageDTO = data!;

  const items = MgSupplierItemSearchResultMinimal.fromDTO(mgSupplierItemsPageDTO.result);

  return {
    result: {
      totalItems: mgSupplierItemsPageDTO.totalCount,
      items,
      page,
      pageSize,
    } as MgSupplierItemsPageResult,
    setPage,
    setPageSize,
  };
};
