import { Search } from '@mui/icons-material';
import { CircularProgress, Popper, Stack, Typography } from '@mui/material';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { MgSupplierItemDetailedDTO, MgSupplierItemSearchResult } from '@retail/products/types';
import { AutocompleteInput } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { InfoOutlineIcon } from '@sanity/icons';
import {
  FreightMarkupFormMgSupplierItemBase,
  CreateFreightMarkupSchema,
  percentFreightUnit,
} from '../FreightMarkupForm';

interface Props {
  mgSupplierItems?: MgSupplierItemSearchResult[];
  setMgSupplierItemSearch: Dispatch<SetStateAction<string>>;
  getMgSupplierItem: (mgSupplierItemId: number) => Promise<MgSupplierItemDetailedDTO>;
  isLoadingFetchItems: boolean;
}

export interface FreightSurchargeOptionsSelect {
  value: string;
  name: string;
}

export function CreateFreightMarkupForMgSupplierItem({
  mgSupplierItems,
  setMgSupplierItemSearch,
  getMgSupplierItem,
  isLoadingFetchItems,
}: Props) {
  const t = useFreightMarkupTFunction();
  const { control } = useFormContext<CreateFreightMarkupSchema>();
  const mgSupplierItemController = useController({ name: 'mgSupplierItem', control });
  const freightTypeController = useController({ name: 'freightUnit', control });
  const freightFactorController = useController({ name: 'freightFactor', control });
  const freightSurchargeController = useController({ name: 'freightSurcharge', control });

  const [freightSurchargeOptions, setfreightSurchargeOptions] = useState<
    FreightSurchargeOptionsSelect[]
  >([]);

  const getPackages = useCallback(
    (mgSupplierItemId: number | undefined) => {
      if (mgSupplierItemId) {
        getMgSupplierItem(mgSupplierItemId).then((item) => {
          const packages = item.mgSupplierItemPackages.map(
            (mgSupplierItemPackage) =>
              ({
                value: mgSupplierItemPackage.vilmaUnit.unit,
                name: `${t('freightMarkup.form.surcharge')} ${
                  mgSupplierItemPackage.vilmaUnit.unit
                }`,
              } as FreightSurchargeOptionsSelect)
          );
          setfreightSurchargeOptions([
            { value: percentFreightUnit, name: t('freightMarkup.form.percent') },
            ...packages,
          ]);
        });
      } else {
        setfreightSurchargeOptions([]);
      }
    },
    [getMgSupplierItem, t]
  );

  return (
    <Stack gap={3}>
      <AutocompleteInput
        {...mgSupplierItemController.field}
        error={mgSupplierItemController.fieldState.error?.message}
        required
        clearOnBlur={false}
        label={t('freightMarkup.level.mgSupplierItem')}
        placeholder={t('freightMarkup.actions.searchForMgSupplierItem')}
        startIcon={<Search />}
        options={mgSupplierItems || []}
        getOptionLabel={({ primaryText, finfoNumber }) => primaryText + finfoNumber}
        noOptionsText={
          isLoadingFetchItems ? (
            <Stack alignItems="center">
              <CircularProgress color="inherit" size="24px" />
            </Stack>
          ) : (
            t('freightMarkup.errors.noResult')
          )
        }
        renderOption={(props, option) => {
          return (
            <Stack component={'li'} {...props}>
              <Typography>{option.primaryText}</Typography>
              <Typography>
                {t('freightMarkup.form.finfoNr')}: {option.finfoNumber}
              </Typography>
            </Stack>
          );
        }}
        PopperComponent={!mgSupplierItems ? NullComponent : Popper}
        onInputChange={(_, value) => setMgSupplierItemSearch(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, value) => {
          if (!value) {
            freightTypeController.field.onChange('');
            freightFactorController.field.onChange('');
            freightSurchargeController.field.onChange('');
          }
          getPackages(value?.mgSupplierItemId);
          mgSupplierItemController.field.onChange(value);
        }}
        ListboxProps={{
          sx: {
            '& li': {
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
              '&:last-child': { borderBottom: '0px' },
            },
          },
        }}
      />
      <Stack>
        {freightSurchargeOptions.length === 0 && (
          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <InfoOutlineIcon fontSize="large" />
            <Typography color={({ palette }) => palette.text.secondary} variant="body2">
              {t('freightMarkup.errors.chooseItemFirst')}
            </Typography>
          </Stack>
        )}
        <FreightMarkupFormMgSupplierItemBase freightSurchargeOptions={freightSurchargeOptions} />
      </Stack>
    </Stack>
  );
}
function NullComponent() {
  return null;
}
