import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { notFoundError } from '@assets/images';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { ContainedButton } from '@shared/components';

interface Props {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  navigateTo?: string;
}

export function NotFoundError({ title, subtitle, buttonText, navigateTo }: Props) {
  return (
    <Stack alignSelf="center" alignItems="center">
      <img src={notFoundError} height="340px" width="340px" alt="404-Not found" />
      <Typography variant="h2" mb={2}>
        {title}
      </Typography>
      <Typography variant="body1" mb={4}>
        {subtitle}
      </Typography>
      <ContainedButton
        component={Link}
        to={navigateTo ? navigateTo : mgPrisRoutes.marketInsight.fullRoutePath}
      >
        {buttonText}
      </ContainedButton>
    </Stack>
  );
}
