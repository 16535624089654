import { forwardRef } from 'react';
import clsx from 'clsx';
import { IconButton, Typography } from '@mui/material';
import { TreeItem, TreeItemContentProps, useTreeItem } from '@mui/x-tree-view';
import { TreeNode } from './NavigationTreeView';
import { childNodeClassName, compactNodesSx, navTreeSx } from './styles';

const TreeItemContent = forwardRef(function (props: TreeItemContentProps, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
    onSelect,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick && onClick(event);
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onSelect && onSelect(event);
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
      {icon && (
        <div onClick={handleExpansionClick}>
          <IconButton className={classes.iconContainer}>{icon}</IconButton>
        </div>
      )}
    </div>
  );
});

interface Props extends TreeNode {
  depth: number;
}

export function NavigationTreeItem({
  label,
  childNodes = [],
  onClick,
  onSelect,
  id,
  depth,
  childrenHeight = 'normal',
}: Props) {
  return (
    <TreeItem
      nodeId={id}
      label={label}
      sx={childrenHeight === 'compact' ? compactNodesSx : navTreeSx}
      className={depth !== 0 ? childNodeClassName : undefined}
      ContentProps={{ onClick, onSelect }}
      ContentComponent={TreeItemContent}
    >
      {childNodes.map((node) => (
        <NavigationTreeItem key={node.id} depth={depth + 1} {...node} />
      ))}
    </TreeItem>
  );
}
