import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Image } from '@retail/products/components';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { TrashIcon } from '@shared/custom-icons';
import { useState } from 'react';

interface SelectedCompetitorProps {
  removeStoreAsCompetitor: () => void;
  name: string;
  url: string;
  distance: number | undefined;
}

export function SelectedCompetitor({
  removeStoreAsCompetitor,
  name,
  url,
  distance,
}: SelectedCompetitorProps) {
  const t = useAppTFunction();
  const [openModal, setOpenModal] = useState(false);

  return (
    <ListItem divider sx={{ justifyContent: 'space-between', py: 2 }}>
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Image
          src={url}
          width="30px"
          fallback={() => (
            <Box
              width="30px"
              border={({ palette }) => `2px solid ${palette.grey[400]}`}
              borderRadius="50%"
            />
          )}
        />
        <Typography variant="body1" fontWeight="medium">
          {name}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        {distance && (
          <Typography variant="body2" color="text.secondary" fontWeight={600}>
            {t('products.marketInsight.competitors.distanceFromMe', {
              distance: distance.toFixed(1),
            })}
          </Typography>
        )}
        <IconButton onClick={() => setOpenModal(true)}>
          <TrashIcon stroke="text.secondary" fontSize="small" />
        </IconButton>
      </Stack>
      <DeleteDialog
        removeStoreAsCompetitor={removeStoreAsCompetitor}
        setOpenModal={setOpenModal}
        openModal={openModal}
        name={name}
      />
    </ListItem>
  );
}

interface DialogProps {
  removeStoreAsCompetitor: () => void;
  setOpenModal: (open: boolean) => void;
  openModal: boolean;
  name: string;
}

const DeleteDialog = ({ removeStoreAsCompetitor, setOpenModal, openModal, name }: DialogProps) => {
  const t = useAppTFunction();

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="sm">
      <DialogTitle sx={{ pb: 2 }}>
        {t('products.marketInsight.competitors.deleteDialogTitle', { storeName: name })}
      </DialogTitle>

      <DialogContent sx={{ pb: 2 }}>
        <Typography>
          {t('products.marketInsight.competitors.deleteDialogDesc', { storeName: name })}
        </Typography>
      </DialogContent>

      <DialogActions>
        <TextButton onClick={() => setOpenModal(false)}>{t('common.cancel')}</TextButton>

        <ContainedButton color="secondary" size="small" onClick={removeStoreAsCompetitor}>
          {t('products.marketInsight.competitors.deleteButtonText')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
