import { Menu, MenuItem } from '@mui/material';
import { useAssortmentTFunction } from '@retail/assortment/i18n';

interface Props {
  openImportModal: () => void;
  openCreateManuallyModal: () => void;
  open: boolean;
  id: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const CreateAssortmentMenu: React.FC<Props> = ({
  openImportModal,
  openCreateManuallyModal,
  open,
  id,
  anchorEl,
  onClose,
}) => {
  const t = useAssortmentTFunction();

  return (
    <Menu open={open} id={id} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={openImportModal}>{t('assortment.admin.create.options.fromFile')}</MenuItem>
      <MenuItem onClick={openCreateManuallyModal}>
        {t('assortment.admin.create.options.manual')}
      </MenuItem>
    </Menu>
  );
};
