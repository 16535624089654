import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

const ExternalLinkIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  const { palette } = useTheme();
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0_1238_167642)">
        <path
          opacity="0.4"
          d="M15.625 12.5H16.875C17.0408 12.5 17.1997 12.5658 17.3169 12.6831C17.4342 12.8003 17.5 12.9592 17.5 13.125V18.125C17.5 18.6223 17.3025 19.0992 16.9508 19.4508C16.5992 19.8025 16.1223 20 15.625 20H1.875C1.37772 20 0.900805 19.8025 0.549175 19.4508C0.197544 19.0992 0 18.6223 0 18.125L0 4.375C0 3.87772 0.197544 3.40081 0.549175 3.04917C0.900805 2.69754 1.37772 2.5 1.875 2.5H8.125C8.29076 2.5 8.44973 2.56585 8.56694 2.68306C8.68415 2.80027 8.75 2.95924 8.75 3.125V4.375C8.75 4.54076 8.68415 4.69973 8.56694 4.81694C8.44973 4.93415 8.29076 5 8.125 5H2.5V17.5H15V13.125C15 12.9592 15.0658 12.8003 15.1831 12.6831C15.3003 12.5658 15.4592 12.5 15.625 12.5Z"
          fill="url(#paint0_linear_1238_167642)"
        />
        <path
          d="M18.9054 8.75H18.207C17.9169 8.75 17.6387 8.63477 17.4336 8.42965C17.2285 8.22453 17.1132 7.94633 17.1132 7.65625V7.62578L17.1867 5L7.53472 14.723C7.35942 14.899 7.12149 14.9981 6.87314 14.9988C6.6248 14.9994 6.38634 14.9015 6.21011 14.7266L6.20777 14.7242L5.27261 13.7926C5.09681 13.6168 4.99805 13.3783 4.99805 13.1297C4.99805 12.8811 5.09681 12.6426 5.27261 12.4668L5.27496 12.4645L14.9992 2.8125L12.3738 2.88672C12.2301 2.89083 12.087 2.86658 11.9527 2.81535C11.8184 2.76413 11.6955 2.68693 11.5911 2.58818C11.4866 2.48943 11.4027 2.37106 11.344 2.23984C11.2853 2.10862 11.2531 1.96712 11.2492 1.82344V1.09375C11.2492 0.803669 11.3644 0.52547 11.5695 0.320352C11.7746 0.115234 12.0528 0 12.3429 0L18.541 0C18.9277 0 19.2986 0.153632 19.5721 0.427098C19.8455 0.700564 19.9992 1.07146 19.9992 1.4582V7.65625C19.9992 7.94633 19.8839 8.22453 19.6788 8.42965C19.4737 8.63477 19.1955 8.75 18.9054 8.75Z"
          fill="url(#paint1_linear_1238_167642)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1238_167642"
          x1="17.6357"
          y1="19.3056"
          x2="-2.49021"
          y2="14.8014"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette.primary['700']} />
          <stop offset="0.327149" stopColor={palette.primary['600']} />
          <stop offset="0.636347" stopColor={palette.primary['500']} />
          <stop offset="1" stopColor={palette.primary['400']} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1238_167642"
          x1="20.1155"
          y1="14.4036"
          x2="2.86368"
          y2="10.5421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette.primary['700']} />
          <stop offset="0.327149" stopColor={palette.primary['600']} />
          <stop offset="0.636347" stopColor={palette.primary['500']} />
          <stop offset="1" stopColor={palette.primary['400']} />
        </linearGradient>
        <clipPath id="clip0_1238_167642">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

export default ExternalLinkIcon;
