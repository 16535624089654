import { ActivatedButton } from '@retail/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { AgreementStatus } from '@retail/purchase-agreements/types';
import { ContainedButton, OutlinedButton } from '@shared/components';

interface Props {
  status: AgreementStatus;
  isAwaitingConfirmation: boolean;
  submitForConfirmation: () => void;
}

export function ActivateAgreementButton({
  status,
  isAwaitingConfirmation,
  submitForConfirmation,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  return status !== 'inactive' ? (
    <ActivatedButton />
  ) : isAwaitingConfirmation ? (
    <ContainedButton size="small" sx={{ whiteSpace: 'nowrap' }} disabled>
      {t('purchaseAgreements.agreement.actions.toApproval')}
    </ContainedButton>
  ) : (
    <OutlinedButton
      size="small"
      onClick={() => {
        submitForConfirmation();
      }}
    >
      {t('purchaseAgreements.agreement.actions.activate')}
    </OutlinedButton>
  );
}
