import { Stack, Typography } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { ConfettiCircle } from '@retail/components';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
  successText: string;
  assortmentTitle: string;
  assortmentId: number;
  navigate: (assortmentId: number) => void;
}

export function SuccessAddingItemsDialog({
  isOpen,
  closeDialog,
  successText,
  assortmentTitle,
  assortmentId,
  navigate,
}: Props) {
  const t = useProductsTFunction();
  return (
    <Dialog open={isOpen} onClose={() => closeDialog()}>
      <Stack p={0.5} gap={2} alignItems="center">
        <ConfettiCircle />

        <Typography variant="h4" color={({ palette }) => palette.grey.A400}>
          {t('products.mgItem.addItemsToAssortment.success')}
        </Typography>

        <Typography variant="h3" align="center">
          {successText}
        </Typography>

        <Typography variant="h5" color={({ palette }) => palette.grey.A400}>
          {t('products.mgItem.addItemsToAssortment.addedIn', { assortment: assortmentTitle })}
        </Typography>

        <Stack pt={2} flexDirection="row" gap={2}>
          <TextButton onClick={() => closeDialog()}>
            {t('products.mgItem.addItemsToAssortment.close')}
          </TextButton>

          <ContainedButton onClick={() => navigate(assortmentId)}>
            {t('products.mgItem.addItemsToAssortment.goToAssortment')}
          </ContainedButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
