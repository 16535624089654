import { useMemo } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { ProductGroupAgreement } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { ActivateAgreementButton } from '../ActivateAgreementButton';
import dayjs from 'dayjs';
import { dataGridBoldClass } from '@shared/styles';

interface Props {
  agreements: ProductGroupAgreement[];
  agreementIdAwaitingConfirmation: string | undefined;
  submitForConfirmation: (agreement: ProductGroupAgreement) => void;
}

export const ProductGroupAgreementsTable = ({
  agreements,
  agreementIdAwaitingConfirmation,
  submitForConfirmation,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 150,
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        width: 180,
        field: 'name',
        cellClassName: () => dataGridBoldClass,
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        headerAlign: 'left',
        align: 'left',
      },
      {
        ...baseColumnProps,
        field: 'fromDate',
        headerName: t('purchaseAgreements.agreement.columns.fromDate'),
        renderCell: ({ value }) => dayjs(value).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'toDate',
        headerName: t('purchaseAgreements.agreement.columns.toDate'),
        renderCell: ({ value }) => dayjs(value).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        renderCell: ({ row }) => (
          <ActivateAgreementButton
            status={row.status}
            isAwaitingConfirmation={agreementIdAwaitingConfirmation === row.id}
            submitForConfirmation={() => submitForConfirmation(row)}
          />
        ),
      },
    ],
    [t, baseColumnProps, submitForConfirmation, agreementIdAwaitingConfirmation]
  );

  return (
    <DataGridPro
      autoHeight
      getRowId={(row) => row.id}
      columns={columns}
      rows={agreements}
      rowHeight={65}
      disableSelectionOnClick
      disableColumnMenu
      disableColumnResize
      disableColumnReorder
      hideFooter
    />
  );
};
