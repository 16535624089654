import { SpreadsheetStructure } from '@retail/assortment/types';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

export const importStepAtom = atom<number>({
  key: 'importAssortmentAtom',
  default: 0,
});

export const spreadsheetAtom = atom<SpreadsheetStructure | undefined>({
  key: 'importedSpreadsheet',
  default: undefined,
});

export const useSpreadsheetState = () => useRecoilState(spreadsheetAtom);

const recognizedNobbNrHeadings = ['nobb-nummer', 'nobb nummer', 'nobb-nr', 'nobb nr', 'nobbnr'];
const recognizedFinfoNrHeadings = [
  'finfo-nummer',
  'finfo nummer',
  'finfo-nr',
  'finfo nr',
  'finfonr',
];
const recognizedMgItemNrHeadings = [
  'mg-item-nummer',
  'mg-item nummer',
  'mgitem-nummer',
  'mgitem nummer',
  'mgitem-nr',
  'mgitem nr',
  'mgitemnr',
  'mgitemNr',
];
const recognizedGtinHeadings = ['gtin', 'gtin-nummer', 'gtin nummer', 'gtin-nr', 'gtin nr'];

const getIsRecognizedHeaderPredicate = (header: string) => (recHeading: string) =>
  header.toLowerCase().includes(recHeading);

export const getColumnHeader = (header: string) =>
  recognizedNobbNrHeadings.find(getIsRecognizedHeaderPredicate(header)) ||
  recognizedFinfoNrHeadings.find(getIsRecognizedHeaderPredicate(header)) ||
  recognizedGtinHeadings.find(getIsRecognizedHeaderPredicate(header)) ||
  recognizedMgItemNrHeadings.find(getIsRecognizedHeaderPredicate(header));

export type ImportColumnsError = 'columnsNotFound' | 'noNumbersFound';
export type ImportStatus = 'none' | 'success' | 'error';

export interface AssortmentImportState {
  status: ImportStatus;
  columnsState?: ImportColumnsError;
  nobbNumbers?: number[];
  finfoNumbers?: number[];
  gtinNumbers?: number[];
  mgItemNumbers?: number[];
}

const getNumbersForColumn = (
  spreadsheet: SpreadsheetStructure,
  recognizedColumnHeadings: string[]
): number[] | undefined => {
  const { headerRow, dataRows } = spreadsheet;
  const columnIndex = headerRow.findIndex((header) =>
    recognizedColumnHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  if (columnIndex < 0) {
    return undefined;
  }
  const numbers = dataRows
    .map((row) => row[columnIndex])
    .map((nr) => Number(nr))
    .filter((nr) => Number.isInteger(nr));
  return numbers;
};

export type IdentifiableColumn = 'nobb' | 'finfo' | 'gtin' | 'mgItem';

interface Props {
  columnsToRetrieve: Set<IdentifiableColumn>;
}

export const useImportedAssortmentState = ({ columnsToRetrieve }: Props): AssortmentImportState => {
  const spreadsheet = useRecoilValue(spreadsheetAtom);

  if (!spreadsheet) {
    return { status: 'none' };
  }

  const nobbNumbers = columnsToRetrieve.has('nobb')
    ? getNumbersForColumn(spreadsheet, recognizedNobbNrHeadings)
    : undefined;
  const finfoNumbers = columnsToRetrieve.has('finfo')
    ? getNumbersForColumn(spreadsheet, recognizedFinfoNrHeadings)
    : undefined;
  const gtinNumbers = columnsToRetrieve.has('gtin')
    ? getNumbersForColumn(spreadsheet, recognizedGtinHeadings)
    : undefined;
  const mgItemNumbers = columnsToRetrieve.has('mgItem')
    ? getNumbersForColumn(spreadsheet, recognizedMgItemNrHeadings)
    : undefined;

  const columnsState: ImportColumnsError | undefined =
    !nobbNumbers && !finfoNumbers && !gtinNumbers && !mgItemNumbers
      ? 'columnsNotFound'
      : (!nobbNumbers || !!nobbNumbers) &&
        (!finfoNumbers || !!finfoNumbers) &&
        (!gtinNumbers || !!gtinNumbers) &&
        (!mgItemNumbers || !!mgItemNumbers)
      ? 'noNumbersFound'
      : undefined;

  const status: ImportStatus = [nobbNumbers, finfoNumbers, gtinNumbers, mgItemNumbers].some(
    (numbers) => numbers?.length
  )
    ? 'success'
    : 'error';
  return {
    status,
    columnsState,
    nobbNumbers,
    finfoNumbers,
    gtinNumbers,
    mgItemNumbers,
  };
};
