import { Fragment, useCallback, useMemo } from 'react';
import { ButtonBase, Divider, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useContexts } from '@retail/app/stores/selected-context';
import { Context, TYPE_BRAND } from '@retail/auth/types';
import { usePopupElement } from '@shared/hooks';

export function ContextSelect() {
  const { palette } = useTheme();
  const { brandContexts, selectedContext, switchContext } = useContexts();

  const brands = useMemo(
    () => brandContexts.filter((context) => context.type === TYPE_BRAND),
    [brandContexts]
  );

  const locations = useMemo(
    () => brandContexts.filter((context) => context.type !== TYPE_BRAND),
    [brandContexts]
  );

  const { menuElementProps, triggerElementProps } = usePopupElement();

  const changeContext = useCallback(
    (context: Context) => {
      const newContext = brandContexts.find((c) => c.value === context.value);
      if (newContext) {
        switchContext(newContext);
      }
    },
    [brandContexts, switchContext]
  );

  const handleSelect = (context: Context) => {
    changeContext(context);
    menuElementProps.onClose();
  };

  const enableMenu = useMemo(() => brandContexts.length > 1, [brandContexts]);

  return (
    <Fragment>
      <Stack
        component={ButtonBase}
        {...triggerElementProps}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={palette.common.white}
        color={palette.text.primary}
        width="212px"
        borderRadius={3}
        px={2}
        py={1}
        sx={{
          cursor: enableMenu ? 'pointer' : 'default',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Typography variant="body1" fontWeight="500">
            {selectedContext.name}
          </Typography>
        </Stack>
        {enableMenu && (
          <ChevronLeft
            sx={{
              transform: menuElementProps.open ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        )}
      </Stack>
      {enableMenu && (
        <Menu {...menuElementProps} elevation={0}>
          {brands.map((context) => (
            <MenuItem
              key={context.value}
              value={context.id}
              onClick={() => {
                handleSelect(context);
              }}
              selected={context === selectedContext}
            >
              {context.name}
            </MenuItem>
          ))}

          {brands.length > 0 && <Divider />}

          {locations.map((context) => (
            <MenuItem
              key={context.value}
              value={context.id}
              onClick={() => {
                handleSelect(context);
              }}
              selected={context === selectedContext}
            >
              {context.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
}
