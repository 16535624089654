import { AssortmentProductDTO } from '@retail/assortment/types';
import { Root } from '@retail/products/types';
import { NobbOvergruppeDTO } from '@retail/products/types';

// group 1 = overgruppe
// group 2 = hovedgruppe
// group 3 = varegruppe not sure about digits
export const nobbGroupRegex = /(\d{2})(\d{2})(\d{3,5})/;

export const UNKNOWN_OVERGRUPPE = 'UNKNOWN_OVERGRUPPE';
export const UNKNOWN_HOVEDGRUPPE = 'UNKNOWN_HOVEDGRUPPE';
export const UNKNOWN_VAREGRUPPE = 'UNKNOWN_VAREGRUPPE';

/**
 *
 * @param nobbGroupId - Group looking for
 * @param groups - Iterate through varegruppe first, then hovedgruppe, then varegruppe
 */
export function findProductGroupName(nobbGroupId: string, groups: NobbOvergruppeDTO[]): string {
  const nobbGroupIdLength = nobbGroupId.length;
  const overgruppe = groups.find((g) => g.overgruppeNr.startsWith(nobbGroupId.substring(0, 2)));

  if (overgruppe === undefined) {
    return UNKNOWN_OVERGRUPPE;
  }

  if (nobbGroupIdLength === 2) {
    return overgruppe.beskrivelse;
  } else if (nobbGroupIdLength === 4) {
    const h = overgruppe.hovedgrupper.find((h) => h.hovedgruppeNr === nobbGroupId);
    return h?.beskrivelse || UNKNOWN_HOVEDGRUPPE;
  } else {
    const h = overgruppe.hovedgrupper.find((h) => h.hovedgruppeNr === nobbGroupId.substring(0, 4));
    if (h !== undefined) {
      const varegruppe = h.varegrupper.find((v) => v.varegruppeNr === nobbGroupId);
      return varegruppe?.beskrivelse || UNKNOWN_VAREGRUPPE;
    }
    return UNKNOWN_VAREGRUPPE;
  }
}

export function calculateProductGroups(
  products: AssortmentProductDTO[],
  productGroups: NobbOvergruppeDTO[]
): Root {
  return products
    .filter((p) => !!p.nobbModul && p.requested)
    .map((p) => p.nobbModul?.nobbVaregruppe)
    .reduce((prev, curr) => {
      if (curr === undefined) {
        // skip it if undefined
        return prev;
      }
      const group = nobbGroupRegex.exec(curr.varegruppeNr);
      if (group === null) {
        return prev;
      }
      const hovedgruppeNr = group[1] + group[2];
      const overgruppeNr = group[1];

      if (prev[overgruppeNr]) {
        prev[overgruppeNr].count++;
      } else {
        prev[overgruppeNr] = {
          id: overgruppeNr,
          count: 1,
          level: 1,
          name: findProductGroupName(overgruppeNr, productGroups),
          parent: undefined,
          rootChildren: {},
        };
      }

      const overgruppe = prev[overgruppeNr];
      if (overgruppe.rootChildren[hovedgruppeNr]) {
        overgruppe.rootChildren[hovedgruppeNr].count++;
      } else {
        overgruppe.rootChildren[hovedgruppeNr] = {
          id: hovedgruppeNr,
          count: 1,
          level: 2,
          parent: overgruppeNr,
          name: findProductGroupName(hovedgruppeNr, productGroups),
          rootChildren: {},
        };
      }
      const varegruppe = overgruppe.rootChildren[hovedgruppeNr].rootChildren;
      if (varegruppe[curr.varegruppeNr]) {
        varegruppe[curr.varegruppeNr].count++;
      } else {
        varegruppe[curr.varegruppeNr] = {
          id: curr.varegruppeNr,
          count: 1,
          level: 3,
          name: curr.beskrivelse,
          parent: hovedgruppeNr,
          rootChildren: {},
        };
      }
      return prev;
    }, {} as Root);
}

// function transformGroupToNode(group:);

// finds the deepest selected nodes.
// e.g [22, 2224, 2224123] => [2224123]
export function deepestSearch(checkedGroups: string[]): Set<string> {
  const b = new Set<string>();
  checkedGroups.forEach((gr) => {
    if (gr.length === 2) {
      b.add(gr);
      // Overgruppe
    } else if (gr.length === 4) {
      const overgruppe = gr.substring(0, 2);
      if (b.has(overgruppe)) {
        b.delete(overgruppe);
      }
      b.add(gr);
      // Hovedgruppe
    } else {
      // varegruppe
      const group = nobbGroupRegex.exec(gr);
      if (group !== null) {
        const [varegruppe, overgruppe, hoved] = group;
        const hovedgruppe = `${overgruppe}${hoved}`;
        if (b.has(overgruppe)) {
          b.delete(overgruppe);
        } else if (b.has(hovedgruppe)) {
          b.delete(hovedgruppe);
        }
        b.add(varegruppe);
      }
    }
  });
  return b;
}
