import { numberWithOptionalDecimalRegex, percentageRegex } from '@retail/form/validation';
import { FreightMarkupTFunction } from '@retail/freight-markup/i18n';
import * as yup from 'yup';

export const percentFreightUnit = '%';

export const createFreightMarkupSchema = (t: FreightMarkupTFunction) =>
  yup.object({
    level: yup.string().default('').required(t('freightMarkup.errors.chooseLevel')),
    productGroup: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'productGroup',
        then: yup.object().nullable().required(t('freightMarkup.errors.chooseProductGroup')),
      }),
    mgSupplierItem: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'mgSupplierItem',
        then: yup.object().nullable().required(t('freightMarkup.errors.chooseMgSupplierItem')),
      }),
    freightUnit: yup
      .string()
      .default('')
      .when('level', {
        is: 'mgSupplierItem',
        then: yup.string().required(t('freightMarkup.errors.chooseSurchargeType')),
      }),
    freightFactor: yup
      .string()
      .when(['level'], (level, field) =>
        level === 'supplier' || level === 'productGroup'
          ? field
              .matches(percentageRegex, t('freightMarkup.errors.mustBeNumberBetween'))
              .required(t('freightMarkup.errors.mustAddFreightSurcharge'))
          : field
      )
      .when('freightUnit', {
        is: percentFreightUnit,
        then: yup
          .string()
          .matches(percentageRegex, t('freightMarkup.errors.mustBeNumberBetween'))
          .required(t('freightMarkup.errors.mustAddFreightSurcharge')),
      })

      .default(''),
    freightSurcharge: yup
      .string()
      .when('freightUnit', (freightUnit, field) =>
        freightUnit !== percentFreightUnit && freightUnit !== ''
          ? field
              .matches(numberWithOptionalDecimalRegex, t('freightMarkup.errors.mustBeNumber'))
              .required(t('freightMarkup.errors.mustAddFreightSurcharge'))
          : field
      )
      .default(''),
    fromDate: yup.date().default(null).nullable().required(t('freightMarkup.errors.addFromDate')),
    toDate: yup.date().default(null).nullable().optional(),
  });

export const editFreightMarkupSchema = (t: FreightMarkupTFunction) =>
  yup.object({
    freightUnit: yup.string().default('').required(),
    freightFactor: yup
      .string()
      .when('freightUnit', {
        is: percentFreightUnit,
        then: yup
          .string()
          .matches(percentageRegex, t('freightMarkup.errors.mustBeNumberBetween'))
          .required(t('freightMarkup.errors.mustAddFreightSurcharge')),
      })

      .default(''),
    freightSurcharge: yup
      .string()
      .when('freightUnit', (freightUnit, field) =>
        freightUnit !== percentFreightUnit
          ? field
              .matches(numberWithOptionalDecimalRegex, t('freightMarkup.errors.mustBeNumber'))
              .required(t('freightMarkup.errors.mustAddFreightSurcharge'))
          : field
      )
      .default(''),
    fromDate: yup.date().default(null).nullable().required(t('freightMarkup.errors.addFromDate')),
    toDate: yup.date().default(null).nullable().optional(),
  });

export type CreateFreightMarkupSchema = yup.InferType<ReturnType<typeof createFreightMarkupSchema>>;
export type EditFreightMarkupSchema = yup.InferType<ReturnType<typeof editFreightMarkupSchema>>;
