import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useContexts } from '@retail/app/stores/selected-context';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { contextPath } from './freightMarkupProductGroupsForMgParticipantApi';
import { freightMarkupsPath } from './freightMarkupApi';
import { MgSupplierItemsPageDTO } from '@retail/purchase-agreements/types';

export const itemsPath = 'items';

type QueryParams = {
  mgSupplierId: number;
  pageSize: number;
  page: number;
  onlyAffected?: boolean;
};

export function useFetchFreightMarkupItems(
  markupId: string,
  { mgSupplierId, pageSize, page, onlyAffected = true }: QueryParams,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<MgSupplierItemsPageDTO>({
    method: 'GET',
    queryKey: [
      'freight-markup-items',
      selectedContext,
      markupId,
      mgSupplierId,
      pageSize,
      page,
      onlyAffected,
    ],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${freightMarkupsPath}/${markupId}/${itemsPath}`,
      searchParams: {
        mgSupplierId,
        pageSize,
        page,
        onlyAffected,
      },
    }),
    ...queryOptions,
  });
}
