import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { useCallback } from 'react';
import { PurchaseAgreementDTO } from '@retail/purchase-agreements/types';

export const purchaseAgreementApiRoute = '/purchase-agreements';

interface FetchAllPurchaseAgreementsProps {
  url: string;
  token: string;
}

export async function fetchAllPurchaseAgreements({ url, token }: FetchAllPurchaseAgreementsProps) {
  return fetcher<PurchaseAgreementDTO[]>({ url, token, method: 'GET' });
}

export interface PurchaseAgreementParams {
  mgParticipantAccountId: number;
}

export function useFetchAllPurchaseAgreements(
  { mgParticipantAccountId }: PurchaseAgreementParams,
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const fetcher = useCallback(
    () =>
      fetchAllPurchaseAgreements({
        url: `${pps.url}${purchaseAgreementApiRoute}/${mgParticipantAccountId}`,
        token,
      }),
    [pps.url, token, mgParticipantAccountId]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['purchaseAgreements'],
    ...queryOptions,
  });
}
