import { prisinnsiktConfig } from '@retail/app/config';
import { fetcher } from '@shared/fetch-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Context, ContextUser, SearchUser } from '@retail/auth/types';
import { useSelectedContext } from '../../stores/userContexts';
import { useAuthContext } from '..';
import { useCallback } from 'react';

const url = prisinnsiktConfig.pps.url;

export async function fetchContextUsers(token: string, context: Context): Promise<SearchUser[]> {
  return fetcher<SearchUser[]>({
    url: `${url}/v1/contexts/users`,
    token,
    context: { key: context.type, value: context.value },
  });
}

export async function promoteContextUser(
  token: string,
  context: Context,
  userId: string
): Promise<SearchUser> {
  return fetcher<SearchUser>({
    url: `${url}/v1/users/${userId}/adminContext`,
    method: 'POST',
    token,
    context: { key: context.type, value: context.value },
  });
}

export async function demoteContextUser(
  token: string,
  context: Context,
  userId: string
): Promise<ContextUser> {
  return fetcher<ContextUser>({
    url: `${url}/v1/users/${userId}/adminContext`,
    method: 'DELETE',
    token,
    context: { key: context.type, value: context.value },
  });
}

// TODO move to common folder
type Options = {
  refetchOnWindowFocus?: boolean;
};

function useContextUsers({ refetchOnWindowFocus = true }: Options = {}) {
  const queryClient = useQueryClient();
  const context = useSelectedContext();
  const { token } = useAuthContext();
  const fetcher = useCallback(() => fetchContextUsers(token, context), [token, context]);

  // const context = useRecoilValue(SelectedContextSelector);

  const { data: contextUsers } = useQuery(['context-users', context], fetcher, {
    refetchOnWindowFocus,
  });

  const promoteUserMutation = useMutation(
    async (userId: string) => {
      return promoteContextUser(token, context, userId);
    },
    {
      onSuccess: (data) => {
        const updated = contextUsers?.map((u) =>
          u.userId === data.userId ? { ...u, isAdmin: true } : u
        );
        queryClient.setQueryData(['context-users', context], updated);
      },
    }
  );
  const demoteUserMutation = useMutation(
    async (userId: string) => {
      return demoteContextUser(token, context, userId);
    },
    {
      onSuccess: (data) => {
        const updated = contextUsers?.map((u) =>
          u.userId === data.userId ? { ...u, isAdmin: false } : u
        );
        queryClient.setQueryData(['context-users', context], updated);
      },
    }
  );
  return { contextUsers, promoteUserMutation, demoteUserMutation };
}

export default useContextUsers;
