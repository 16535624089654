import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher, PPSFetchError, RequestContext, RequestMethods } from './fetcher';

export interface UseMutateFetchOptions<URLVariables> {
  url: string | ((variables: URLVariables | undefined) => string);
  method: RequestMethods;
  token?: string;
  context?: RequestContext;
}

interface VariablesT<TBody, TURLVariables> {
  body?: TBody;
  urlVariables?: TURLVariables;
}

export function useMutateFetch<ResultT, BodyT = unknown, URLVariables = any>({
  url,
  method,
  context,
  token,
  ...mutationOptions
}: (
  | Omit<
      UseMutationOptions<ResultT, PPSFetchError, VariablesT<BodyT, URLVariables> | undefined>,
      'mutationFn' | 'context'
    >
  | undefined
) &
  UseMutateFetchOptions<URLVariables>) {
  const fetch = async (fetchVariables: VariablesT<BodyT, URLVariables> = {}) =>
    fetcher<ResultT>({
      url: typeof url === 'function' ? url(fetchVariables?.urlVariables) : url,
      method,
      token,
      body: fetchVariables?.body ? JSON.stringify(fetchVariables?.body) : undefined,
      context,
    });

  return useMutation<ResultT, PPSFetchError, VariablesT<BodyT, URLVariables>>(
    fetch,
    mutationOptions
  );
}
