import { Coordinates } from '@retail/retailer/types';
import { getDistance } from 'geolib';

export function calculateDistanceFromCompetitorInKm(
  lat: number,
  long: number,
  coordinates: Coordinates
): number {
  const distance = getDistance(
    { latitude: coordinates.lat, longitude: coordinates.long },
    { latitude: lat, longitude: long }
  );
  return distance * 0.001;
}