import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '@retail/components';
import { useAppTranslation } from '@retail/app/i18n';
import { useFetchAllMgParticipants } from '@retail/products/data-access';
import { MgParticipant } from '@retail/products/types';
import { SelectSupplierContainer } from '@retail/freight-markup/containers';
import { mgPrisRoutes } from '@retail/mgpris/config';

export function FreightMarkupPage() {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { data: participantDtos } = useFetchAllMgParticipants({ suspense: true });
  const suppliers = useMemo(
    () => participantDtos?.map(MgParticipant.fromMinimalDto) || [],
    [participantDtos]
  );

  const selectSupplier = useCallback(
    (selectedSupplier: MgParticipant | null) => {
      navigate(mgPrisRoutes.freightMarkup.supplier.getFullLinkPath(`${selectedSupplier?.id}`));
    },
    [navigate]
  );

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <SelectSupplierContainer
        label={t('freightMarkup.supplier.choose')}
        selectSupplier={selectSupplier}
        mgParticipants={suppliers}
        breadcrumb={t('app.routes.freightMarkup')}
      />
    </PageLayout>
  );
}
