import {
  getMgAssortmentsQueryKey,
  useCreateMgAssortment,
  useFetchMgAssortments,
} from '@retail/assortment/data-access';
import { useCallback, useMemo, useState } from 'react';
import { MgAssortmentBase, MgAssortmentDTO } from '@retail/assortment/types';
import {
  AddItemToAssortmentDialog,
  AddMgItemToMgAssortmentMenu,
  CompetitorViewForMgItem,
  MgItemView,
  SuccessAddingItemsDialog,
} from '@retail/products/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { ContainedButton, TagHeading } from '@shared/components';
import { Stack, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { useContexts } from '@retail/app/stores/selected-context';
import { CreateAssortmentManuallyDialog, NewAssortment } from '@retail/assortment/components';
import { FetchError } from '@shared/fetch-utils';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';
import { useDisclosure, usePopupElement } from '@shared/hooks';
import { useMgAssortmentActions } from '@retail/assortment/context';
import { Visibility } from '@mui/icons-material';
import { MgItemDetailed } from '@retail/products/types';
import { Competitor } from '@retail/retailer/types';

interface Props {
  mgItem: MgItemDetailed;
  competitors: Competitor[];
}

export function MgItemContainer({ mgItem, competitors }: Props) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const t = useProductsTFunction();
  const { selectedContext } = useContexts();

  const { data: allAssortmentsDtos = [] } = useFetchMgAssortments();
  const allAssortments = useMemo(
    () => allAssortmentsDtos.map(MgAssortmentBase.fromDto),
    [allAssortmentsDtos]
  );

  const { data: assortmentsItemExistsInDtos = [] } = useFetchMgAssortments([mgItem.id]);
  const assortmentsItemExistsIn = useMemo(
    () => assortmentsItemExistsInDtos.map(MgAssortmentBase.fromDto),
    [assortmentsItemExistsInDtos]
  );
  const availableAssortments = useMemo(() => {
    const idsToExclude = assortmentsItemExistsIn.map(({ id }) => id);

    return allAssortments.filter(
      (assortment: MgAssortmentBase) => !idsToExclude.includes(assortment.id)
    );
  }, [allAssortments, assortmentsItemExistsIn]);

  const firstSupplierItem = mgItem.getPrimarySupplierItem();

  const { menuElementProps, triggerElementProps } = usePopupElement();
  const [selectedAssortment, setSelectedAssortment] = useState<null | MgAssortmentDTO>(null);
  const [successModal, setSuccessModal] = useState(false);

  const {
    isOpen: isCreateManuallyModalOpen,
    onClose: closeCreateManuallyModal,
    onOpen: openCreateManuallyModal,
  } = useDisclosure(false);

  const {
    isOpen: isAddToExistingModalOpen,
    onClose: closeAddToExistingModal,
    onOpen: openAddToExistingModal,
  } = useDisclosure(false);

  const { handleAddItemsToMgAssortment, handleDeleteItemsFromMgAssortment } =
    useMgAssortmentActions();
  const { mutateAsync: createMgAssortmentAsync, isLoading: isLoadingCreate } =
    useCreateMgAssortment(selectedContext);

  const submitNewAssortmentForm = ({ title, assortmentType }: NewAssortment) => {
    menuElementProps.onClose();
    createMgAssortmentAsync({
      body: {
        title,
        assortmentType,
        itemCodes: {
          mgItemNumbers: [mgItem.id],
        },
      },
    })
      .then((assortment) => {
        closeCreateManuallyModal();
        queryClient.invalidateQueries(getMgAssortmentsQueryKey(selectedContext));
        setSelectedAssortment(assortment);
        setSuccessModal(true);
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('products.mgItem.search.error.addingItemToAssortment'));
      });
  };

  const navigate = useNavigate();
  const navigateToAssortment = useCallback(
    (assortmentId: number) => {
      navigate(
        mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(assortmentId + '')
      );
    },
    [navigate]
  );

  const addItemToAssortment = useCallback(
    (assortment: MgAssortmentBase) => {
      menuElementProps.onClose();
      handleAddItemsToMgAssortment({
        assortmentId: assortment.id,
        mgItemNumbers: [mgItem.id],
      })
        .then(() => {
          queryClient.invalidateQueries(getMgAssortmentsQueryKey(selectedContext, [mgItem.id]));
        })
        .catch(() => {
          toast.error(t('products.mgItem.addItemsToAssortment.error.addItemToAssortment'));
        });
    },
    [
      handleAddItemsToMgAssortment,
      menuElementProps,
      mgItem.id,
      queryClient,
      selectedContext,
      t,
      toast,
    ]
  );

  const deleteItemFromAssortment = useCallback(
    (assortment: MgAssortmentBase) => {
      menuElementProps.onClose();
      handleDeleteItemsFromMgAssortment({
        assortmentId: assortment.id,
        mgItemNumbers: [mgItem.id],
      })
        .then(() => {
          queryClient.invalidateQueries(getMgAssortmentsQueryKey(selectedContext, [mgItem.id]));
        })
        .catch(() => {
          toast.error(t('products.mgItem.addItemsToAssortment.error.deleteItemFromAssortment'));
        });
    },
    [
      handleDeleteItemsFromMgAssortment,
      menuElementProps,
      mgItem.id,
      queryClient,
      selectedContext,
      t,
      toast,
    ]
  );

  return (
    <Stack gap={3}>
      <Stack flexDirection="row" py={2} alignItems="flex-start" justifyContent="space-between">
        <MgItemView mgItem={mgItem} />

        <ContainedButton {...triggerElementProps} endIcon={<Visibility />} size="small">
          {t('products.mgItem.supplierItem.monitor')}
        </ContainedButton>

        <AddMgItemToMgAssortmentMenu
          {...menuElementProps}
          mgAssortments={availableAssortments}
          addItemsToNewAssortment={openCreateManuallyModal}
          openAddToExistingModal={openAddToExistingModal}
        />
      </Stack>

      <Stack>
        <Stack mb={1}>
          <TagHeading color="primary">
            <Typography variant="h6">{t('products.prices.competitors.title')}</Typography>
          </TagHeading>
        </Stack>
        <CompetitorViewForMgItem
          mgItem={mgItem}
          contextCompetitors={competitors}
          context={selectedContext}
        />
      </Stack>

      {isCreateManuallyModalOpen && (
        <CreateAssortmentManuallyDialog
          onClose={closeCreateManuallyModal}
          onSubmit={submitNewAssortmentForm}
          isLoading={isLoadingCreate}
          hideAddItemsLater
          hideAssortmentType
        />
      )}

      <AddItemToAssortmentDialog
        open={isAddToExistingModalOpen}
        onClose={closeAddToExistingModal}
        assortmentsItemExistsIn={assortmentsItemExistsIn}
        availableAssortments={availableAssortments}
        addItemToAssortment={addItemToAssortment}
        deleteItemFromAssortment={deleteItemFromAssortment}
      />

      {successModal && selectedAssortment && (
        <SuccessAddingItemsDialog
          isOpen={successModal}
          closeDialog={() => setSuccessModal(false)}
          successText={t('products.mgItem.addItemsToAssortment.added', {
            itemName: firstSupplierItem?.primaryText || mgItem.id,
          })}
          assortmentId={selectedAssortment.id}
          assortmentTitle={selectedAssortment.title}
          navigate={navigateToAssortment}
        />
      )}
    </Stack>
  );
}
