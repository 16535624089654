import { Box, alpha } from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { SpreadsheetStructure } from '@retail/assortment/types';

const highlightedClassName = 'hightlight-cell';

interface Props extends SpreadsheetStructure {
  shallHighlightColumn: (header: string) => boolean;
}

export const ImportedSpreadsheetTable: React.FC<Props> = ({
  headerRow,
  dataRows,
  shallHighlightColumn,
}) => {
  const columns: GridColDef[] = useMemo(
    () =>
      headerRow.map((header) => {
        const highlightColumn = shallHighlightColumn(`${header}`);
        if (highlightColumn) {
          return {
            field: `${header}`,
            width: 130,
            cellClassName: ({ value }: GridCellParams<string | undefined>) => {
              return value ? highlightedClassName : '';
            },
            sortable: false,
          };
        }

        return {
          field: `${header}`,
          sortable: false,
        };
      }),
    [headerRow, shallHighlightColumn]
  );

  const rows: GridRowsProp = useMemo(() => {
    return dataRows.map((dataRow) => {
      const rowId = dataRow.join('-');
      return headerRow.reduce(
        (tmpRow, headerName, index) => ({
          ...tmpRow,
          [headerName]: dataRow[index],
        }),
        {
          id: rowId,
        }
      );
    });
  }, [headerRow, dataRows]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        height: '500px',
        [`& [role=cell].${highlightedClassName}`]: {
          bgcolor: ({ palette }) => alpha(palette.info.main, 0.2),
          border: ({ palette }) => `1px solid ${palette.common.white}`,
        },
      }}
    >
      <DataGridPro
        columns={columns}
        rows={rows}
        rowHeight={50}
        disableColumnMenu
        disableColumnResize
        disableSelectionOnClick
        pagination
        initialState={{
          pagination: { pageSize: 100 },
        }}
      />
    </Box>
  );
};
