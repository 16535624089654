import { useContext } from 'react';
import { AppConfigContext } from './AppConfigContext';

export const useAppConfig = () => {
  const appContext = useContext(AppConfigContext);
  if (!appContext) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return appContext;
};
