import { IconButton, TableCell, TableRow } from '@mui/material';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { TrashIcon } from '@sanity/icons';
import dayjs from 'dayjs';
import { ChangeInValue } from './ChangeInValue';

interface Props {
  mgItemNr: number;
  itemName: string;
  prevActiveAgreement: ItemAgreement;
  newActiveAgreement: ItemAgreement;
  removeAgreement: (id: number) => void;
}

export function SummaryDrawerTableItemRow({
  mgItemNr,
  itemName,
  prevActiveAgreement,
  newActiveAgreement,
  removeAgreement,
}: Props) {
  return (
    <TableRow key={mgItemNr} sx={{ borderTop: '1px solid black' }}>
      <TableCell>{itemName}</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell>{ChangeInValue(prevActiveAgreement.name, newActiveAgreement.name)}</TableCell>
      <TableCell>
        {ChangeInValue(
          dayjs(prevActiveAgreement.fromDate).format('ll'),
          dayjs(newActiveAgreement.fromDate).format('ll')
        )}
      </TableCell>
      <TableCell>
        {ChangeInValue(
          dayjs(prevActiveAgreement.toDate).format('ll'),
          dayjs(newActiveAgreement.toDate).format('ll')
        )}
      </TableCell>
      <TableCell>
        {ChangeInValue(newActiveAgreement.priceType, prevActiveAgreement.priceType)}
      </TableCell>
      <TableCell>{ChangeInValue(newActiveAgreement.price, prevActiveAgreement.price)}</TableCell>
      <TableCell>
        {ChangeInValue(newActiveAgreement.basePriceUnit, prevActiveAgreement.basePriceUnit)}
      </TableCell>
      <TableCell>
        <IconButton onClick={() => removeAgreement(mgItemNr)}>
          <TrashIcon fontSize="25px" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
