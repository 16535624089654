import { AssortmentType } from '@retail/assortment/types';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { NewAssortmentForm } from './newAssortmentForm';
import { SelectInput, TextInput } from '@retail/components';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { Stack } from '@mui/material';

interface Props extends Pick<UseFormReturn<NewAssortmentForm>, 'control'> {
  hideAssortmentType?: boolean;
}

const NewAssortmentFields: React.FC<Props> = ({ control, hideAssortmentType }) => {
  const t = useAssortmentTFunction();

  return (
    <Stack py={2}>
      <TextInput
        control={control}
        name="title"
        label={t('assortment.admin.create.form.title.label')}
        fullWidth
        margin="normal"
        required
      />
      {!hideAssortmentType && (
        <SelectInput
          control={control}
          name="assortmentType"
          label={t('assortment.admin.create.form.assortmentType.label')}
          menuItems={[
            {
              value: AssortmentType.monitoring,
              name: t('assortment.assortmentType.MONITORING.singular'),
            },
            {
              value: AssortmentType.inclusion,
              name: t('assortment.assortmentType.INCLUSION.singular'),
            },
            {
              value: AssortmentType.exclusion,
              name: t('assortment.assortmentType.EXCLUSION.singular'),
            },
          ]}
          fullWidth
          margin="normal"
        />
      )}
    </Stack>
  );
};

export default NewAssortmentFields;
