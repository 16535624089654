import { ReactQueryContainer } from '@retail/app/prisinnsikt-app';
import { AuthContextProvider, AuthProvider } from '@retail/auth/data-access';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import {
  AppLayout,
  AuthContainerPage,
  ByggmaterialCompetitorsPage,
  FreightMarkupForSupplierPage,
  FreightMarkupPage,
  MarketInsightPage,
  MarkupForSupplierPage,
  MarkupPage,
  MgAssortmentPage,
  MgItemPage,
  MgSupplierItemSearchPage,
  NotFoundPage,
  PurchaseAgreementsForSupplierPage,
  PurchaseAgreementsPage,
  SuperUsersPage,
  UsersPage,
} from '@retail/app/pages';
import { mgPrisRoutes, mgPrisConfig } from '@retail/mgpris/config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrandsProvider, ContextsProvider } from '@retail/app/stores/selected-context';
import { BodyLoader, ErrorFallbackPage, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useMgPrisConfig } from "@retail/app/stores/app-config";
import { mgPrisTheme } from '@shared/styles';
import { SuperUserRouteGuard } from './SuperUserRouteGuard';
import { ContextAdminRouteGuard } from './ContextAdminRouteGuard';
import { useMemo } from 'react';

const globalStyles = (
  <GlobalStyles
    styles={{
      code: {
        color: mgPrisTheme.palette.error.main,
        backgroundColor: mgPrisTheme.palette.grey['100'],
        padding: '4px 6px',
        borderRadius: '7px',
      },
    }}
  />
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  const { env, features } = useMgPrisConfig();

  const activatePurchaseAgreements = useMemo(
    () => features?.purchaseAgreements.toggle === 'on',
    [features]
  );
  const activateFreightMarkup = useMemo(() => features?.freightMarkup.toggle === 'on', [features]);
  const activateMarkup = useMemo(() => features?.markup.toggle === 'on', [features]);

  <Helmet>
    {mgPrisConfig.clarity?.id && (
      <script type="text/javascript">
        {`(function(c, l, a, r, i, t, y) {
       c[a] = c[a] || function() {(c[a].q = c[a].q || []).push(arguments)};
       t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
       y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
     })(window, document, "clarity", "script", ${mgPrisConfig.clarity.id});`}
      </script>
    )}
  </Helmet>;
  return (
    <ThemeProvider theme={mgPrisTheme}>
      {globalStyles}
      <CssBaseline />
      <ReactQueryContainer showDevtools={env === 'local'}>
        <RecoilRoot>
          <AuthProvider>
            <AuthContextProvider>
              <SentryRoutes>
                <Route
                  path={mgPrisRoutes.root.fullRoutePath}
                  element={
                    <AuthContainerPage>
                      <SuspenseWithSentryErrorBoundary
                        errorFallback={(props) => <ErrorFallbackPage {...props} />}
                        suspenseFallback={<BodyLoader />}
                      >
                        <BrandsProvider>
                          <ContextsProvider>
                            <AppLayout>
                              <Outlet />
                            </AppLayout>
                          </ContextsProvider>
                        </BrandsProvider>
                      </SuspenseWithSentryErrorBoundary>
                    </AuthContainerPage>
                  }
                >
                  {/* All following routes are only shown to authorized users, due to AuthContainerPage */}
                  <Route
                    path=""
                    element={<Navigate to={mgPrisRoutes.marketInsight.fullRoutePath} replace />}
                  />
                  <Route
                    path={mgPrisRoutes.marketInsight.fullRoutePath}
                    element={<MarketInsightPage />}
                  />
                  <Route
                    path={mgPrisRoutes.marketInsight.assortments.fullRoutePath}
                    element={<Navigate to={mgPrisRoutes.marketInsight.fullRoutePath} replace />}
                  />
                  <Route
                    path={mgPrisRoutes.marketInsight.assortments.assortment.fullRoutePath}
                    element={<MgAssortmentPage />}
                  />
                  <Route
                    path={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
                    element={<ByggmaterialCompetitorsPage />}
                  />
                  <Route
                    path={mgPrisRoutes.users.fullRoutePath}
                    element={
                      <ContextAdminRouteGuard>
                        <UsersPage />
                      </ContextAdminRouteGuard>
                    }
                  />
                  <Route
                    path={mgPrisRoutes.superusers.fullRoutePath}
                    element={
                      <SuperUserRouteGuard>
                        <SuperUsersPage />
                      </SuperUserRouteGuard>
                    }
                  />
                  <Route
                    path={mgPrisRoutes.items.fullRoutePath}
                    element={<MgSupplierItemSearchPage />}
                  />
                  <Route path={mgPrisRoutes.items.item.fullRoutePath} element={<MgItemPage />} />
                  {activatePurchaseAgreements && (
                    <>
                      <Route
                        path={mgPrisRoutes.purchaseAgreements.fullRoutePath}
                        element={<PurchaseAgreementsPage />}
                      />
                      <Route
                        path={mgPrisRoutes.purchaseAgreements.supplier.fullRoutePath}
                        element={<PurchaseAgreementsForSupplierPage />}
                      />
                    </>
                  )}
                  {activateFreightMarkup && (
                    <>
                      <Route
                        path={mgPrisRoutes.freightMarkup.fullRoutePath}
                        element={<FreightMarkupPage />}
                      />
                      <Route
                        path={mgPrisRoutes.freightMarkup.supplier.fullRoutePath}
                        element={<FreightMarkupForSupplierPage />}
                      />
                    </>
                  )}
                  {activateMarkup && (
                    <>
                      <Route path={mgPrisRoutes.markup.fullRoutePath} element={<MarkupPage />} />
                      <Route
                        path={mgPrisRoutes.markup.supplier.fullRoutePath}
                        element={<MarkupForSupplierPage />}
                      />
                    </>
                  )}
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </SentryRoutes>
              <ToastContainer />
            </AuthContextProvider>
          </AuthProvider>
        </RecoilRoot>
      </ReactQueryContainer>
    </ThemeProvider>
  );
}

export default App;
