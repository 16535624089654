import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import {
  EditFreightMarkupSchema,
  FreightMarkupFormBase,
  FreightMarkupFormMgSupplierItemBase,
  FreightSurchargeOptionsSelect,
  percentFreightUnit,
} from '@retail/freight-markup/components';
import { FreightMarkup } from '@retail/freight-markup/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useToast } from '@retail/hooks';
import { useMutateEditFreightMarkup } from '@retail/freight-markup/data-access';
import { MgParticipant } from '@retail/products/types';

interface Props {
  supplier: MgParticipant;
  onClose: () => void;
  open: boolean;
  freightMarkup: FreightMarkup;
  freightSurchargeOptions: FreightSurchargeOptionsSelect[];
}

export function EditFreightMarkupDialog({
  supplier,
  onClose,
  freightMarkup,
  freightSurchargeOptions,
}: Props) {
  const t = useAppTFunction();
  const toast = useToast();
  const { handleSubmit, control } = useFormContext<EditFreightMarkupSchema>();
  const toDateController = useController({ name: 'toDate', control });
  const toDateIsToday = toDateController.field.value?.toDateString() === new Date().toDateString();

  const { mutateAsync: postFreightMarkup, isLoading } = useMutateEditFreightMarkup();
  const onSubmit = useCallback(
    (data: EditFreightMarkupSchema) => {
      postFreightMarkup({
        urlVariables: freightMarkup.id,
        body: {
          id: freightMarkup.id,
          mgSupplierId: supplier.id,
          mgSupplierFreightFactor: freightMarkup.supplierFreight
            ? Number(data.freightFactor)
            : undefined,
          productGroupFreight: freightMarkup.productGroupFreight
            ? {
                productGroupId: freightMarkup.productGroupFreight?.productGroup?.id + '',
                freightFactor: Number(data.freightFactor),
              }
            : undefined,
          mgSupplierItemFreight: freightMarkup.mgSupplierItemFreight
            ? {
                mgSupplierItemId: Number(
                  freightMarkup.mgSupplierItemFreight.mgSupplierItem?.mgSupplierItemId
                ),
                freightFactor: Number(data.freightFactor) || undefined,
                frightSurcharge:
                  data.freightUnit !== percentFreightUnit
                    ? {
                        unit: data.freightUnit,
                        value: Number(data.freightSurcharge),
                      }
                    : undefined,
              }
            : undefined,
          fromDate: `${data.fromDate.getFullYear()}-${data.fromDate.getMonth()}-${data.fromDate.getDay()}`,
          toDate: data.toDate
            ? `${data.toDate.getFullYear()}-${data.toDate.getMonth()}-${data.toDate.getDay()}`
            : undefined,
        },
      })
        .then(() => {
          onClose();
          toast.success(t('freightMarkup.actions.success'));
        })
        .catch(() => toast.error(t('freightMarkup.errors.generic')));
    },
    [postFreightMarkup, freightMarkup, supplier, onClose, toast, t]
  );

  const agreementToEditName = useMemo(() => {
    if (freightMarkup.mgSupplierItemFreight) {
      return freightMarkup.mgSupplierItemFreight.mgSupplierItem.primaryText;
    } else if (freightMarkup.productGroupFreight) {
      return (
        freightMarkup.productGroupFreight.productGroup.name ||
        freightMarkup.productGroupFreight.productGroup.groupNumber
      );
    }
    return supplier.name;
  }, [freightMarkup, supplier]);

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle justifyContent="space-between" alignItems="center">
        {t('freightMarkup.form.editFreigthMarkupFor')}
        {agreementToEditName}
        <ContainedButton
          sx={{ whiteSpace: 'nowrap' }}
          disabled={toDateIsToday}
          size="small"
          color="warning"
          onClick={() => {
            toDateController.field.onChange(new Date());
          }}
        >
          {t('freightMarkup.actions.endFreightMarkup')}
        </ContainedButton>
      </DialogTitle>
      <DialogContent>
        {freightMarkup.mgSupplierItemFreight ? (
          <FreightMarkupFormMgSupplierItemBase freightSurchargeOptions={freightSurchargeOptions} />
        ) : (
          <FreightMarkupFormBase />
        )}
      </DialogContent>
      <DialogActions>
        <TextButton
          sx={{ textDecoration: 'none', color: ({ palette }) => palette.common.black }}
          size="small"
          onClick={onClose}
        >
          {t('common.close')}
        </TextButton>
        <ContainedButton
          disabled={isLoading}
          size="small"
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
