import { AppConfig } from '@retail/app/config';
import React from 'react';

export const AppConfigContext = React.createContext<AppConfig | null>(null);

export const AppContextProvider = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config: AppConfig;
}) => {
  return <AppConfigContext.Provider value={config}>{children}</AppConfigContext.Provider>;
};
