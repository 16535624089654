import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { LogoutOptions, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';

import { hasExpired } from '@shared/utils';

interface AuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  refreshToken: () => void;
  logout: (options?: LogoutOptions) => void;
  login: (options?: RedirectLoginOptions) => Promise<void>;
  token: string;
  error: Error | undefined;
}

const AuthContext = React.createContext<AuthContextProps | null>(null);

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const {
    isLoading: isAuth0Loading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    error,
  } = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>('');
  const [initialPath] = useState(window.location.pathname);

  const refreshToken = useCallback(() => {
    const fetchToken = async () => {
      return await getAccessTokenSilently()
        .then(setToken)
        .catch((error) => {
          console.error(error);
          // Something went wrong. Relog user
          loginWithRedirect();
        });
    };

    fetchToken();
  }, [getAccessTokenSilently, setToken, loginWithRedirect]);

  useEffect(() => {
    const checkEveryHour = 60 * 60;
    const checkToken = () => {
      if (isAuthenticated && token) {
        if (hasExpired(token, checkEveryHour)) {
          refreshToken();
        }
      }
    };
    const checkTokenInterval = setInterval(checkToken, checkEveryHour * 1000);
    return () => {
      clearInterval(checkTokenInterval);
    };
  }, [token, refreshToken, isAuthenticated]);

  useEffect(() => {
    const initAuth = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      }
      setIsLoading(false);
    };
    if (isAuth0Loading) {
      return;
    }
    initAuth();
  }, [isAuth0Loading, setToken, getAccessTokenSilently, loginWithRedirect, isAuthenticated]);

  const login = useCallback(
    (options?: RedirectLoginOptions) =>
      loginWithRedirect({
        appState: { returnTo: initialPath },
        ...options,
      }),
    [initialPath, loginWithRedirect]
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        refreshToken,
        logout,
        login,
        token,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error(`useAuthContext must be used within a AuthContextProvider`);
  }
  return context;
};

export { AuthContext, AuthContextProvider, useAuthContext };
