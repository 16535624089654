import { Box, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { EquivalentInfoDTO } from '@retail/products/types';
import React, { useMemo } from 'react';
import { sortEquivalentInfo } from '@retail/products/utils';
import { muiGridWrapperStyles } from '@retail/products/components';
import { FollowMasterProductCell, ProductNameCell } from './TableCells';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  alternativeProducts: EquivalentInfoDTO[];
  groupTitle: string | undefined;
  imageUrl: string | undefined;
  updated: string | undefined;
}

const rowHeight = 70;

const EquivalentGroup: React.FC<Props> = ({ alternativeProducts, groupTitle = '-' }) => {
  const t = useAppTFunction();

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'Retail produkt-ID', flex: 1, sortable: false },
      { field: 'nobbNr', headerName: 'NOBB-nummer', flex: 1, sortable: false },
      {
        field: 'followMasterProduct',
        headerName: 'Følger masterprodukt',
        flex: 1.3,
        sortable: false,
        renderCell: ({ value }) => <FollowMasterProductCell followMasterProduct={value} />,
      },
      {
        field: 'retailProductName',
        headerName: 'Varenavn',
        flex: 1.2,
        sortable: false,
        renderCell: ({ value: { retailProductName, retailProductUrl } }) => (
          <ProductNameCell
            retailProductName={retailProductName}
            retailProductUrl={retailProductUrl}
          />
        ),
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      alternativeProducts
        .sort(sortEquivalentInfo)
        .map(
          ({
            retailProductId,
            followMasterProduct,
            nobbNr,
            retailProductName,
            retailProductUrl,
          }) => ({
            id: retailProductId,
            nobbNr,
            followMasterProduct,
            retailProductName: { retailProductName, retailProductUrl },
          })
        ),
    [alternativeProducts]
  );

  // Adjust the grid height with the number of variants, but no more than 500px
  const tableHeight = useMemo(() => `min(500px, ${58 + rows.length * rowHeight}px)`, [rows]);

  return (
    <Stack spacing={2}>
      <Typography variant="h3" fontSize="16px">
        {t('admin.equivalents.result.groupName', { groupTitle })}
      </Typography>
      <Box sx={{ ...muiGridWrapperStyles, height: tableHeight }}>
        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={rowHeight}
          disableColumnMenu
          disableColumnResize
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    </Stack>
  );
};

export default EquivalentGroup;
