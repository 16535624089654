import PublicHeader from '../Header/PublicHeader';
import { ReactComponent as LogoNo } from '../../images/mg-prisinnsikt.svg';
import { ReactComponent as LogoSe } from '../../images/mgpris.svg';
import { Layout } from '../Layout';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

const PublicPage: React.FC<PropsWithChildren> = ({ children }) => {
  const language = useTranslation().i18n.language;
  return (
    <React.Fragment>
      <PublicHeader>{language === 'se' ? <LogoSe /> : <LogoNo />}</PublicHeader>
      <Layout component="main">{children}</Layout>
    </React.Fragment>
  );
};

export default PublicPage;
