import React, { useId, useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props<T> {
  suppliers: T[];
  selectedSupplier: T | null;
  selectSupplier: (supplier: T) => void;
  getSupplierName: (supplier: T) => string | undefined;
  getSupplierId: (supplier: T) => string;
  size?: 'small' | 'medium';
}

export const SupplierSelect = <T,>({
  suppliers,
  selectedSupplier,
  selectSupplier,
  getSupplierName,
  getSupplierId,
  size,
}: Props<T>) => {
  const t = useProductsTFunction();
  const labelId = useId();
  const onChange = (event: SelectChangeEvent) => {
    selectSupplier(
      suppliers.find((supplier) => getSupplierId(supplier) === event.target.value) as T
    );
  };

  const supplierLabel = useMemo(
    () => `${t('products.supplier.name')} (${suppliers.length})`,
    [suppliers, t]
  );

  return (
    <FormControl>
      <InputLabel id={labelId}>{supplierLabel}</InputLabel>
      <Select
        size={size}
        labelId={labelId}
        value={selectedSupplier ? getSupplierId(selectedSupplier) : ''}
        label={<Typography variant="body2">{supplierLabel}</Typography>}
        onChange={onChange}
        sx={{ minWidth: '130px' }}
      >
        {suppliers.map((supplier) => {
          const id = getSupplierId(supplier);
          return (
            <MenuItem value={id} key={id}>
              {getSupplierName(supplier) || t('products.supplier.unknown')}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
