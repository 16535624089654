import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useContexts } from '@retail/app/stores/selected-context';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions, useFetch, useMutateFetch } from '@shared/fetch-utils';
import { contextPath } from './freightMarkupProductGroupsForMgParticipantApi';
import { FreightMarkupDTO, FreightMarkupLevel } from '@retail/freight-markup/types';
import { buildUrl } from '@shared/utils';

export const freightMarkupsPath = 'freight-markups';

export function useMutateCreateNewFreightMarkup(queryOptions: QueryOptions = {}) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  interface PostProps {
    mgSupplierId: number;
    fromDate: string;
    toDate?: string;
    mgSupplierFreightFactor?: number;
    productGroupFreight?: {
      productGroupId: string;
      freightFactor: number;
    };
    mgSupplierItemFreight?: {
      mgSupplierItemId: number;
      freightFactor?: number;
      frightSurcharge?: {
        unit: string;
        value: number;
      };
    };
  }

  return useMutateFetch<void, PostProps>({
    method: 'POST',
    url: `${pps.url}${contextPath}/${selectedContext.id}/${freightMarkupsPath}`,
    token,
    ...queryOptions,
  });
}

interface PostProps {
  id: string;
  mgSupplierId: number;
  fromDate: string;
  toDate?: string;
  mgSupplierFreightFactor?: number;
  productGroupFreight?: {
    productGroupId: string;
    freightFactor: number;
  };
  mgSupplierItemFreight?: {
    mgSupplierItemId: number;
    freightFactor?: number;
    frightSurcharge?: {
      unit: string;
      value: number;
    };
  };
}

export function useMutateEditFreightMarkup(queryOptions: QueryOptions = {}) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateFetch<void, PostProps>({
    method: 'POST',
    url: (id: number) =>
      `${pps.url}${contextPath}/${selectedContext.id}/${freightMarkupsPath}/${id}`,
    token,
    ...queryOptions,
  });
}

type QueryParams = {
  level?: FreightMarkupLevel;
  productGroupId?: string;
  mgItemId?: string;
};

export function useFetchFreightMarkupsForMgParticipant(
  mgParticipantAccountId: number,
  { level, productGroupId, mgItemId }: QueryParams,
  queryOptions: QueryOptions = {}
) {
  const { selectedContext } = useContexts();
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<FreightMarkupDTO[]>({
    method: 'GET',
    queryKey: ['freight-markups', selectedContext, level, productGroupId, mgItemId],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${freightMarkupsPath}`,
      searchParams: {
        mgParticipantAccountId,
        level,
        productGroupId,
        mgItemId,
      },
    }),
    ...queryOptions,
  });
}
