import React from 'react';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ITextInput } from './formTypes';
import { useGetErrorMessage } from '@retail/form/validation';

export const TextInput = <TFieldValues extends FieldValues>({
  name,
  label,
  required = false,
  disabled = false,
  readOnly = false,
  placeholder,
  margin = 'none',
  defaultValue = '' as PathValue<TFieldValues, Path<TFieldValues>>,
  size = 'medium',
  variant,
  startIcon,
  endIcon,
  control,
  ...rest
}: ITextInput<TFieldValues>) => {
  const { breakpoints } = useTheme();
  const isLessThanMedium = useMediaQuery(breakpoints.down('md'));
  const getErrorMessage = useGetErrorMessage();

  return (
    <Controller
      render={function ({ field, fieldState: { error } }) {
        return (
          <TextField
            {...field}
            fullWidth={isLessThanMedium || rest.fullWidth}
            margin={margin}
            type="text"
            id={name}
            disabled={disabled}
            required={required}
            label={label}
            placeholder={placeholder}
            variant={variant}
            error={!!error}
            helperText={!!error && getErrorMessage(error['message'])}
            inputProps={{ readOnly }}
            autoComplete="off"
            size={size}
            InputProps={{
              startAdornment: startIcon && (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ),
              endAdornment: endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
              'aria-label': rest['aria-label'],
            }}
          />
        );
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
};
