import { PageLayout } from '../Layout';
import { Paper } from '@shared/components';
import { FC } from 'react';
import { ErrorFallback, SentryErrorProps } from './ErrorFallback';

export const ErrorFallbackPage: FC<SentryErrorProps> = (props) => {
  return (
    <PageLayout maxWidth="full">
      <Paper sx={{ flexGrow: 1, display: 'flex' }}>
        <ErrorFallback {...props} />
      </Paper>
    </PageLayout>
  );
};
