import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { TextInput } from '@retail/components';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';

interface TitleForm {
  title: string;
}

const assortmentNameMinChars = 2;
const assortmentNameMaxChars = 40;

const titleSchema = yup.object().shape({
  title: yup
    .string()
    .min(assortmentNameMinChars, {
      message: {
        key: 'assortment.admin.create.form.title.validation.minChars',
        values: { amount: assortmentNameMinChars },
      },
    })
    .max(assortmentNameMaxChars, {
      message: {
        key: 'assortment.admin.create.form.title.validation.maxChars',
        values: { amount: assortmentNameMaxChars },
      },
    })
    .matches(/^[a-zA-Z0-9 _\-æøåäö]+$/i, {
      message: {
        key: 'assortment.admin.create.form.title.validation.illegalChars',
      },
    }),
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmTitle: (title: string) => void;
  existingTitle: string;
  isLoading: boolean;
}

export const EditAssortmentTitleDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  existingTitle,
  handleConfirmTitle,
  isLoading,
}) => {
  const t = useAssortmentTFunction();
  const { control, handleSubmit, reset } = useForm<TitleForm>({
    resolver: yupResolver(titleSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = handleSubmit(({ title }) => {
    handleConfirmTitle(title);
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('assortment.admin.edit.options.editTitle')}</DialogTitle>
      <DialogContent>
        <Stack py={1}>
          <TextInput
            control={control}
            defaultValue={existingTitle}
            name="title"
            label={t('assortment.admin.edit.titleForm.title')}
            size="medium"
            fullWidth
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <OutlinedButton size="small" onClick={onClose}>
          {t('assortment.common.cancel')}
        </OutlinedButton>
        <ContainedButton size="small" onClick={onSubmit} loading={isLoading}>
          {t('assortment.common.confirm')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssortmentTitleDialog;
