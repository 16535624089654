import { ChangeEvent, useCallback, useState } from 'react';
import { Box, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { AddIcon, TrashIcon } from '@sanity/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';
import { useAppTranslation } from '@retail/app/i18n';
import {
  BodyLoader,
  BreadcrumbsToolbar,
  ErrorFallbackPage,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { ContainedButton, Paper } from '@shared/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import {
  useFetchSuperUsers,
  useRemoveUserAsSuperUser,
  useSetUserAsSuperUser,
} from '@retail/users/data-access';
import { SuperUsersTable } from '@retail/users/components';

export function SuperUsersPage() {
  const { t } = useAppTranslation();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: superUsers = [] } = useFetchSuperUsers();

  const { mutateAsync: setUserAsSuperUser, isLoading: isSetSuperUserLoading } =
    useSetUserAsSuperUser();
  const { mutateAsync: removeUserAsSuperUser, isLoading: isRemoveSuperUserLoading } =
    useRemoveUserAsSuperUser();

  const [userId, setUserId] = useState<string>('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setUserId(e.target.value);

  const clear = () => setUserId('');

  const addSuperUser = useCallback(async () => {
    setUserAsSuperUser(userId)
      .then(({ email }) => {
        queryClient.invalidateQueries({ queryKey: ['super-users'] });
        toast.success(t('admin.superusers.actions.add.success', { email }));
      })
      .catch((err) => {
        toast.error(t('admin.superusers.actions.add.error'));
        console.error(err);
      })
      .finally(() => setUserId(''));
  }, [setUserAsSuperUser, userId, queryClient, toast, t]);

  const removeSuperUser = useCallback(async () => {
    removeUserAsSuperUser(userId)
      .then(({ email }) => {
        queryClient.invalidateQueries({ queryKey: ['super-users'] });
        toast.success(t('admin.superusers.actions.remove.success', { email }));
      })
      .catch((err) => {
        toast.error(t('admin.superusers.actions.remove.error'));
        console.error(err);
      })
      .finally(() => setUserId(''));
  }, [removeUserAsSuperUser, userId, queryClient, toast, t]);

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Paper paddingY="dense">
        <BreadcrumbsToolbar
          items={[
            { label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() },
            { label: t('admin.superusers.title') },
          ]}
          endContent={
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack display="flex" flexDirection="row" justifyContent="space-between" pr={2}>
                <TextField
                  placeholder={t('admin.superusers.actions.user.label')}
                  onChange={onChange}
                  value={userId}
                  sx={{ width: '500px' }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={clear}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <ContainedButton
                  onClick={addSuperUser}
                  disabled={!userId}
                  sx={{ whiteSpace: 'nowrap', marginRight: '0.5rem' }}
                  size="small"
                  startIcon={<AddIcon />}
                  loading={isSetSuperUserLoading}
                >
                  {t('admin.superusers.actions.add.submit')}
                </ContainedButton>
                <ContainedButton
                  onClick={removeSuperUser}
                  disabled={!userId}
                  sx={{ whiteSpace: 'nowrap' }}
                  size="small"
                  color="warning"
                  startIcon={<TrashIcon />}
                  loading={isRemoveSuperUserLoading}
                >
                  {t('admin.superusers.actions.remove.submit')}
                </ContainedButton>
              </Box>
            </Box>
          }
        />
        <SuspenseWithSentryErrorBoundary
          errorFallback={(props) => <ErrorFallbackPage {...props} />}
          suspenseFallback={<BodyLoader />}
        >
          <SuperUsersTable superUsers={superUsers} />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
