import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { ProductResources } from '../types/ProductResources';

export const useProductsTranslation = () => useMessagesTranslation<ProductResources>();

export type ProductsMessageKey = MessagePaths<ProductResources>;

export const useProductsTFunction = () => useProductsTranslation().t;

export type ProductsTFunction = ReturnType<typeof useProductsTFunction>;
