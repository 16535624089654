import { IconButton, Link, TableCell, TableRow } from '@mui/material';
import { ProductGroupAgreement } from '@retail/purchase-agreements/types';
import { TrashIcon } from '@sanity/icons';
import dayjs from 'dayjs';
import { ChangeInValue } from './ChangeInValue';

interface Props {
  id: string;
  name: string;
  nrOfAffectedItems?: number;
  prevActiveAgreement: ProductGroupAgreement;
  newActiveAgreement: ProductGroupAgreement;
  viewAffectedItems: () => void;
  removeAgreement: (id: string) => void;
}

export function SummaryDrawerTableRow({
  id,
  name,
  nrOfAffectedItems,
  prevActiveAgreement,
  newActiveAgreement,
  viewAffectedItems,
  removeAgreement,
}: Props) {
  return (
    <TableRow key={id} sx={{ borderTop: '1px solid black' }}>
      <TableCell>{name}</TableCell>
      <TableCell align="center">
        {nrOfAffectedItems ? (
          <Link component="button" onClick={() => viewAffectedItems()}>
            {nrOfAffectedItems}
          </Link>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{ChangeInValue(prevActiveAgreement.name, newActiveAgreement.name)}</TableCell>
      <TableCell>
        {ChangeInValue(
          dayjs(prevActiveAgreement.fromDate).format('ll'),
          dayjs(newActiveAgreement.fromDate).format('ll')
        )}
      </TableCell>
      <TableCell>
        {ChangeInValue(
          dayjs(prevActiveAgreement.toDate).format('ll'),
          dayjs(newActiveAgreement.toDate).format('ll')
        )}
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>
        <IconButton onClick={() => removeAgreement(id)}>
          <TrashIcon fontSize="25px" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
