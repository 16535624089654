import { AssortmentType } from './AssortmentType';
import { AssortmentDTO, MgAssortmentDTO } from '../dto';

export class AssortmentBase {
  readonly title: string;
  readonly id: number;
  readonly type?: AssortmentType;

  constructor(title: string, id: number, type?: AssortmentType) {
    this.title = title;
    this.id = id;
    this.type = type;
  }
}

export class NobbAssortmentBase extends AssortmentBase {
  static fromDto(assortment: AssortmentDTO): NobbAssortmentBase {
    return new NobbAssortmentBase(
      assortment.title,
      assortment.id,
      assortment.type as AssortmentType | undefined
    );
  }
}

interface MgAssortmentBaseConstructorProps {
  title: string;
  id: number;
  readOnly: boolean;
  type?: AssortmentType;
  description?: string;
}

export class MgAssortmentBase extends AssortmentBase {
  readonly readOnly: boolean;
  readonly description?: string;

  constructor({ title, id, readOnly, type, description }: MgAssortmentBaseConstructorProps) {
    super(title, id, type);
    this.description = description;
    this.readOnly = readOnly;
  }

  static fromDto(assortment: MgAssortmentDTO): MgAssortmentBase {
    return new MgAssortmentBase({
      title: assortment.title,
      id: assortment.id,
      readOnly: assortment.readOnly,
      type: assortment.type as AssortmentType | undefined,
    });
  }
}
