import { OpenInNew } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import React from 'react';

interface Props {
  retailProductName: string;
  retailProductUrl: string | undefined;
}

const ProductNameCell: React.FC<Props> = ({ retailProductName, retailProductUrl }) => {
  if (retailProductUrl) {
    return (
      <Link
        href={retailProductUrl}
        target="_blank"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          textDecorationColor: 'black',
        }}
      >
        <Box component="span" pr={1}>
          {retailProductName}
        </Box>
        <OpenInNew fontSize="small" />
      </Link>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{retailProductName}</>;
};

export default ProductNameCell;
