import { AgreementsForMgItemDTO, PriceUnit, ItemAgreementDTO, PriceType } from '../dto';
import { AgreementStatus, InheritedLevel } from './ProductGroupAgreement';

export interface ItemAgreementProps {
  id: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  status: AgreementStatus;
  inheritedLevel?: InheritedLevel;
  priceType: PriceType;
  price: number;
  priceUnit: PriceUnit;
  basePriceUnit: PriceUnit;
}

interface AgreementsForItemProps {
  mgItemId: number;
  primaryText: string;
  awaitingConfirmationAgreementId: string;
  agreements: ItemAgreement[];
}

export class ItemAgreement {
  id: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  status: AgreementStatus;
  inheritedLevel?: InheritedLevel;
  priceType: PriceType;
  price: number;
  priceUnit: PriceUnit;
  basePriceUnit: PriceUnit;

  constructor({
    id,
    name,
    fromDate,
    toDate,
    status,
    inheritedLevel,
    priceType,
    price,
    priceUnit,
    basePriceUnit,
  }: ItemAgreementProps) {
    this.id = id;
    this.name = name;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.status = status;
    this.inheritedLevel = inheritedLevel;
    this.priceType = priceType;
    this.price = price;
    this.priceUnit = priceUnit;
    this.basePriceUnit = basePriceUnit;
  }

  static fromAgreementDto = (dto: ItemAgreementDTO): ItemAgreement =>
    new ItemAgreement({
      id: dto.id,
      name: dto.name,
      fromDate: dto.fromDate,
      toDate: dto.toDate,
      status: dto.status as AgreementStatus,
      inheritedLevel: dto.inheritedLevel as InheritedLevel,
      priceType: dto.priceType,
      price: dto.price,
      priceUnit: dto.priceUnit,
      basePriceUnit: dto.basePriceUnit,
    });
}

export class MgItemAgreements {
  mgItemId: number;
  primaryText: string;
  awaitingConfirmationAgreementId: string;
  agreements: ItemAgreement[];
  constructor({
    mgItemId,
    primaryText,
    awaitingConfirmationAgreementId,
    agreements,
  }: AgreementsForItemProps) {
    this.mgItemId = mgItemId;
    this.primaryText = primaryText;
    this.awaitingConfirmationAgreementId = awaitingConfirmationAgreementId;
    this.agreements = agreements;
  }

  static fromItemAgreementsDto = (
    dto: AgreementsForMgItemDTO,
    awaitingConfirmationAgreementId?: string
  ): MgItemAgreements =>
    new MgItemAgreements({
      mgItemId: dto.mgItemNumber,
      primaryText: dto.primaryText,
      awaitingConfirmationAgreementId: awaitingConfirmationAgreementId!,
      agreements: dto.agreements.map(ItemAgreement.fromAgreementDto),
    });
}
