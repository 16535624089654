import { Box, Toolbar, Typography, Button, ButtonGroup } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { alpha } from '@mui/material/styles';
import { ContextUser } from '@retail/auth/types';
import { UseMutationResult } from '@tanstack/react-query';
import { useAppTFunction, useAppTranslation } from '@retail/app/i18n';

interface TableToolbarProps {
  title: string;
  selected?: string;
  makeAdmin: () => void;
  removeAdmin: () => void;
}

const TableToolbar = ({ selected, title, makeAdmin, removeAdmin }: TableToolbarProps) => {
  const t = useAppTFunction();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {selected ? (
        <>
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {t('admin.users.actions.selected', { selected })}
          </Typography>
          <ButtonGroup>
            <Button onClick={makeAdmin}>{t('admin.users.actions.promoteToAdmin')}</Button>
            <Button onClick={removeAdmin}>{t('admin.users.actions.removeAdmin')}</Button>
          </ButtonGroup>
        </>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

interface Props {
  contextUsers: ContextUser[];
  demoteUser: UseMutationResult<ContextUser, unknown, string>;
  promoteUser: UseMutationResult<ContextUser, unknown, string>;
}

function ContextUsers({ contextUsers, demoteUser, promoteUser }: Props) {
  const [selectedUserIds, setSelectedUserIds] = useState<GridSelectionModel>([]);
  const { t } = useAppTranslation();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderHeader: () => <div />,
      },
      { field: 'givenName', headerName: t('admin.users.firstName'), width: 200 },
      { field: 'familyName', headerName: t('admin.users.lastName'), width: 200 },
      { field: 'email', headerName: t('admin.users.email'), width: 300 },
      { field: 'isAdmin', headerName: t('admin.users.admin'), width: 100 },
      { field: 'id', headerName: t('admin.users.id'), width: 500 },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      contextUsers?.map((user) => ({
        givenName: user.givenName,
        familyName: user.familyName,
        email: user.email,
        isAdmin: user.isAdmin ? t('common.yes') : t('common.no'),
        id: user.userId,
      })),
    [contextUsers, t]
  );

  const makeAdmin = () => {
    selectedUserIds.forEach((id) => promoteUser.mutateAsync(id.toString()));
    setSelectedUserIds([]);
  };

  const removeAdmin = () => {
    selectedUserIds.forEach((id) => demoteUser.mutateAsync(id.toString()));
    setSelectedUserIds([]);
  };

  const onSelectionModelChange = useCallback(
    (selection: GridSelectionModel) => {
      if (selection.length > 1) {
        const selectionSet = new Set(selectedUserIds);
        const result = selection.filter((s) => !selectionSet.has(s));

        setSelectedUserIds(result);
      } else {
        setSelectedUserIds(selection);
      }
    },
    [selectedUserIds]
  );

  const selectedUser = useMemo(
    () => contextUsers?.find((user) => user.userId === selectedUserIds[0]),
    [contextUsers, selectedUserIds]
  );

  return rows ? (
    <Box
      sx={{
        height: '700px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableToolbar
        selected={selectedUser && `${selectedUser.givenName} ${selectedUser.familyName}`}
        title={t('admin.users.title')}
        makeAdmin={makeAdmin}
        removeAdmin={removeAdmin}
      />
      <DataGridPro
        columns={columns}
        rows={rows}
        pageSize={30}
        rowsPerPageOptions={[10, 30, 50, 100]}
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectedUserIds}
      />
    </Box>
  ) : null;
}

export default memo(ContextUsers);
