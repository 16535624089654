import { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Typography } from '@mui/material';
import { AssortmentBase } from '@retail/assortment/types';
import { groupAndSortAssortments } from '@retail/assortment/utils';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { ExpandMore } from '@mui/icons-material';

interface Props {
  assortments: AssortmentBase[];
  selectedAssortment: AssortmentBase | null;
  selectAssortment: (assortment: AssortmentBase | null) => void;
  alternativeLabel?: string;
  width?: string | number | (string | number)[];
  groupByType: boolean;
}

export function AssortmentPicker({
  assortments,
  selectedAssortment,
  selectAssortment,
  alternativeLabel,
  width,
  groupByType,
}: Props) {
  const t = useAssortmentTFunction();
  const anyAssortmentHasType = useMemo(() => assortments.some(({ type }) => !!type), [assortments]);
  const groupedAndAlfabeticallySortedAssortments: AssortmentBase[] = useMemo(
    () => groupAndSortAssortments(assortments),
    [assortments]
  );

  if (groupedAndAlfabeticallySortedAssortments.length === 0) {
    return <Typography>{t('assortment.noAssortmentExists')}</Typography>;
  }

  return (
    <Autocomplete
      disablePortal
      popupIcon={<ExpandMore />}
      options={groupedAndAlfabeticallySortedAssortments}
      size="small"
      groupBy={
        groupByType && anyAssortmentHasType
          ? (assortment) => t(`assortment.assortmentType.${assortment.type || 'undefined'}.plural`)
          : undefined
      }
      getOptionLabel={(option) => option.title}
      onChange={(_, newValue) => selectAssortment(newValue)}
      value={selectedAssortment}
      sx={{ width: width || 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={alternativeLabel || t('assortment.assortment.singular')}
        />
      )}
      renderOption={(props, { id, title }) => (
        <Box component="li" {...props} key={id}>
          {title}
        </Box>
      )}
      noOptionsText={t('assortment.picker.noOptions')}
    />
  );
}
