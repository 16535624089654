import { Typography, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { OutlinedButton } from '@shared/components';
import { CheckIcon } from '@shared/custom-icons';

export const ActivatedButton = () => {
  const { palette } = useTheme();
  const t = useAppTFunction();
  return (
    <OutlinedButton
      sx={{
        bgcolor: `${palette.common.white} !important`,
        border: `1px solid #5E964A !important`,
      }}
      size="small"
      disabled
      startIcon={<CheckIcon color="#5E964A" />}
    >
      <Typography variant="body2" color="#5E964A">
        {t('common.actions.activated')}
      </Typography>
    </OutlinedButton>
  );
};

