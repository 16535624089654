import { byggtorgetConfig, xlbyggConfig } from './brands';
import { AppConfig, BaseConfig, Envs, envType } from '../PrisinnsiktAppConfig';

const getConfig = (): AppConfig => {
  const env = (process.env['NX_REACT_APP_ENV'] || 'local') as envType;
  const version = process.env['NX_REACT_APP_VERSION'] || 'local';

  const baseConfig: BaseConfig = {
    env,
    version,
    appInfo: {
      name: 'Prisinnsikt',
      key: 'prisinnsikt',
    },
    country: 'NO',
    brandConfig: {
      byggtorget: byggtorgetConfig,
      xlbygg: xlbyggConfig,
    },
    sentryDsn: 'https://49ec183c72734a1d92932c0281b29593@o344472.ingest.sentry.io/5439688',
    muiLicenseKey:
      '37d24028aa82caa294effbc034e42797T1JERVI6MzY5NTEsRVhQSVJZPTE2NzUyNTIzMDgwMDAsS0VZVkVSU0lPTj0x',
    activeAdminFeatures: [
      'manageUsers',
      'manageAssortments',
      'manageCompetitors',
      'manageSuperUsers',
      'manageEquivalents',
      'manageUnits',
    ],
    retailerLogoBaseUrl: 'https://www.plankepriser.no/static/images/logos',
  };

  const c: Envs = {
    dev: {
      ...baseConfig,
      pps: { url: 'https://pps.datahub-dev.mestergruppen.cloud' },
      auth0: {
        clientId: 'jl10knzSskOzMPumGQD3i0hv44oHHPo0',
        domain: 'dev-mestergruppen.eu.auth0.com',
        audience: 'mg.pps',
      },
      gaMeasurementId: 'G-FWBZ9FPR1T',
    },
    stage: {
      ...baseConfig,
      pps: { url: 'https://pps.datahub-stage.mestergruppen.cloud' },
      auth0: {
        clientId: 'g8gJu9cdU9ZvjA1oNbvTO8WJZMDo4Xih',
        domain: 'stage-mestergruppen.eu.auth0.com',
        audience: 'mg.pps',
      },
      gaMeasurementId: 'G-RN2K86FGZ2',
    },
    prod: {
      ...baseConfig,
      pps: { url: 'https://pps.datahub-prod.mestergruppen.cloud' },
      auth0: {
        clientId: 'xHArQ8CaF2qfK0PwZSYhgHlKWVrgtSUM',
        domain: 'mestergruppen.eu.auth0.com',
        audience: 'mg.pps',
      },
      gaMeasurementId: 'G-DZN52R7KYY',
      hotjar: {
        id: 3464494,
        version: 6,
      },
    },
  };
  return env === 'local' ? { ...c.dev, env } : c[env];
};

export const prisinnsiktConfig: AppConfig = getConfig();
