import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ReactNode } from 'react';

interface Props extends CheckboxProps {
  label?: string | ReactNode;
  labelplacement?: 'top' | 'bottom';
}

export const Checkbox: React.FC<Props> = (props) => (
  <FormControlLabel
    label={props.label}
    labelPlacement={props.labelplacement}
    sx={{
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    }}
    control={<MuiCheckbox size="small" sx={{ padding: '6px' }} {...props} />}
  />
);
