import ContentLoader from 'react-content-loader';
import { useProductsTFunction } from '@retail/products/i18n';
import { useTheme } from '@mui/material';

const PriceLoader = () => {
  const t = useProductsTFunction();
  const { palette } = useTheme();

  return (
    <ContentLoader
      title={t('products.productList.loading')}
      speed={1.5}
      width="100%"
      height="12px"
      backgroundColor={palette.grey[200]}
      foregroundColor={palette.grey[300]}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
    </ContentLoader>
  );
};

export default PriceLoader;
