import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useContexts } from '@retail/app/stores/selected-context';
import { useFetchMe } from '@retail/auth/data-access';

type Props = {
  children: JSX.Element;
};

export const ContextAdminRouteGuard = ({ children }: Props) => {
  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });
  const { selectedContext } = useContexts();

  const isContextAdmin = useMemo(
    () => !!user?.isSuperUser || selectedContext.isAdmin,
    [user, selectedContext]
  );

  return isContextAdmin ? children : <Navigate to={mgPrisRoutes.root.fullRoutePath} replace />;
};
