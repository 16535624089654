import { useEffect } from 'react';

export function useKeypress(eventCallback: (event: KeyboardEvent) => void) {
  useEffect(() => {
    document.addEventListener('keydown', eventCallback);

    return () => {
      document.removeEventListener('keydown', eventCallback);
    };
  }, [eventCallback]);
}
