import { useEffect, useMemo, useState } from 'react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { AgreementTabs, ProductGroupWithAgreements } from '@retail/purchase-agreements/types';
import { ItemAgreementsForProductGroup } from './ItemAgreementsForProductGroup';
import { BodyLoader, ErrorFallbackPage, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { ProductGroupAgreements } from './ProductGroupAgreements';
import { useFetchProductGroupAgreements } from '@retail/purchase-agreements/data-access';
import { MgParticipant } from '@retail/products/types';
import {
  usePurchaseAgreementsToActivate,
  useSelectedAgreementsForSupplier,
} from '@retail/purchase-agreements/stores';
import { usePurchaseAgreementsSearchParams } from '@retail/purchase-agreements/hooks';
import { useAffectedItems } from '@retail/purchase-agreements/context';
import { useDisclosure } from '@shared/hooks';
import MgSupplierItemsDialogContainer from './MgSupplierItemsDialogContainer';
import {
  ActiveProductGroupOrSupplierAgreement,
  AgreementStatusIcon,
} from '@retail/purchase-agreements/components';

interface Props {
  supplier: MgParticipant;
  productGroupId?: string;
}

export function SelectedProductGroupOrSupplier({ supplier, productGroupId }: Props) {
  const { t } = usePurchaseAgreementsTranslation();

  const { activatedPurchaseAgreements } = usePurchaseAgreementsToActivate();
  const { selectedAgreements } = useSelectedAgreementsForSupplier(supplier.id);

  const queryParams = useMemo(() => {
    return { agreementIds: selectedAgreements, productGroupId };
  }, [selectedAgreements, productGroupId]);

  const agreementIdAwaitingConfirmation = useMemo(
    () =>
      productGroupId
        ? activatedPurchaseAgreements.productGroupsAgreementsToActivate.find(
            (acivatedAgreement) => acivatedAgreement.productGroupNr === productGroupId
          )?.newActiveAgreement.id
        : activatedPurchaseAgreements.supplierAgreementsToActivate?.newActiveAgreement.id,
    [activatedPurchaseAgreements, productGroupId]
  );

  const { data: agreementDtos } = useFetchProductGroupAgreements(supplier.id, queryParams, {
    suspense: true,
  });
  const productGroupOrMgSupplierAgreements = useMemo(
    () =>
      ProductGroupWithAgreements.fromProductGroupAgreementsDTO(
        agreementDtos!,
        agreementIdAwaitingConfirmation
      ),
    [agreementDtos, agreementIdAwaitingConfirmation]
  );

  const productGroupOrSupplierName = useMemo(
    () => productGroupOrMgSupplierAgreements.productGroupName || supplier.name,
    [productGroupOrMgSupplierAgreements.productGroupName, supplier.name]
  );

  const activeAgreement = productGroupOrMgSupplierAgreements.agreements.find(
    (agreement) => agreement.status !== 'inactive'
  );

  const {
    isOpen: isMgSupplierItemsModalOpen,
    onClose: closeMgSupplierItemModal,
    onOpen: openMgSupplierItemModal,
  } = useDisclosure(false);

  const {
    result: { totalItems },
  } = useAffectedItems({ mgSupplierId: supplier.id, productGroupId, initPageSize: 0 });

  const tabs = [
    { label: productGroupOrSupplierName, value: AgreementTabs.AGREEMENTS },
    {
      label: t('purchaseAgreements.itemsIn') + productGroupOrSupplierName,
      value: AgreementTabs.ITEM_AGREEMENTS,
    },
  ];

  const { selectedTab, selectTab } = usePurchaseAgreementsSearchParams();
  const [currentTab, setCurrentTab] = useState(selectedTab || AgreementTabs.AGREEMENTS);

  useEffect(() => {
    if (selectedTab) {
      setCurrentTab(selectedTab);
    }
  }, [selectedTab]);

  return (
    <Stack width="100%" flexGrow={1} gap={2}>
      <MgSupplierItemsDialogContainer
        open={isMgSupplierItemsModalOpen}
        onClose={closeMgSupplierItemModal}
        supplierId={supplier.id}
        productGroupId={productGroupId}
      />
      {activeAgreement && (
        <Stack gap={2}>
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography variant="h5">
              {t('purchaseAgreements.agreementsFor')} {productGroupOrSupplierName}
            </Typography>
            <AgreementStatusIcon
              status={activeAgreement.status}
              inheritedLevel={activeAgreement.inheritedLevel}
            />
          </Stack>
          <ActiveProductGroupOrSupplierAgreement
            activeAgreement={activeAgreement}
            openModal={openMgSupplierItemModal}
            name={productGroupOrSupplierName}
            totalItems={totalItems}
          />
        </Stack>
      )}

      <Stack flexGrow={1}>
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => selectTab(newValue)}
          sx={{ mb: '-1px' }}
        >
          {tabs.map(({ label, value }) => (
            <Tab key={value} label={label} value={value} />
          ))}
        </Tabs>

        {currentTab === AgreementTabs.AGREEMENTS && (
          <SuspenseWithSentryErrorBoundary
            errorFallback={(props) => <ErrorFallbackPage {...props} />}
            suspenseFallback={<BodyLoader />}
          >
            <ProductGroupAgreements
              agreements={productGroupOrMgSupplierAgreements}
              mgSupplier={supplier}
              productGroupId={productGroupId}
              totalItems={totalItems}
            />
          </SuspenseWithSentryErrorBoundary>
        )}

        {currentTab === AgreementTabs.ITEM_AGREEMENTS && (
          <SuspenseWithSentryErrorBoundary
            errorFallback={(props) => <ErrorFallbackPage {...props} />}
            suspenseFallback={<BodyLoader />}
          >
            <ItemAgreementsForProductGroup
              selectedAgreements={selectedAgreements}
              supplierId={supplier.id}
              productGroupId={productGroupId}
            />
          </SuspenseWithSentryErrorBoundary>
        )}
      </Stack>
    </Stack>
  );
}
