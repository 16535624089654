import { prisinnsiktConfig } from '@retail/app/config';
import { QueryOptions, RequestContext, useFetch, useMutateFetch } from '@shared/fetch-utils';
import { useAuthContext } from './AuthContext';
import { useMemo } from 'react';
import { AssortmentDTO, AssortmentType, NobbAssortmentBase } from '@retail/assortment/types';

const url = prisinnsiktConfig.pps.url + '/v1/assortments';

export const useAllAssortmentsQueryKey = (context: RequestContext) => {
  return ['assortments', context];
};

export interface CreateAssortmentProps {
  title: string;
  assortmentType: AssortmentType;
  nobbNrs?: number[];
}

export const useCreateAssortment = (context: RequestContext) => {
  const { token } = useAuthContext();

  return useMutateFetch<AssortmentDTO, CreateAssortmentProps>({
    url,
    method: 'POST',
    context,
    token,
  });
};

interface FetchAssortmentProps extends QueryOptions {
  context: RequestContext;
}

export function useFetchAssortments({
  refetchOnWindowFocus = false,
  context,
  ...rest
}: FetchAssortmentProps) {
  const { token } = useAuthContext();
  const queryKey = useAllAssortmentsQueryKey(context);

  const {
    data: assortmentDtos,
    isLoading,
    error,
  } = useFetch<AssortmentDTO[]>({
    url,
    method: 'GET',
    queryKey,
    refetchOnWindowFocus,
    context,
    token,
    ...rest,
  });

  const assortments = useMemo(
    () => assortmentDtos?.map((dto) => NobbAssortmentBase.fromDto(dto)),
    [assortmentDtos]
  );

  return { assortments, isLoading, error };
}
