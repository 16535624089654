import { useAppTFunction } from '@retail/app/i18n';
import { NotFoundError, PageLayout } from '@retail/components';
import { Paper } from '@shared/components';

export function NotFoundPage() {
  const t = useAppTFunction();

  return (
    <PageLayout maxWidth="full">
      <Paper sx={{ height: '100%', display: 'flex', justifyContent: 'center', pb: 14 }}>
        <NotFoundError
          title={t('common.notFoundPage.title')}
          subtitle={t('common.notFoundPage.subtitle')}
          buttonText={t('common.notFoundPage.goHome')}
        />
      </Paper>
    </PageLayout>
  );
}
