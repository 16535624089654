import {
  ProductGroupAgreementDTO,
  ProductGroupAgreementsDTO,
} from '@retail/purchase-agreements/types';
import { purchaseAgreements } from './purchaseAgreementTestData';

const agreements1: ProductGroupAgreementDTO[] = [
  {
    status: 'active',
    ...purchaseAgreements[0],
  },
  {
    status: 'inactive',
    ...purchaseAgreements[1],
  },
  {
    status: 'inactive',
    ...purchaseAgreements[2],
  },
];
const agreements2: ProductGroupAgreementDTO[] = [
  {
    status: 'inactive',
    ...purchaseAgreements[0],
  },
  {
    status: 'activeInherited',
    inheritedLevel: {
      id: '300203',
      level: 'supplier',
      name: 'Ljungberg Fritzoe AB',
    },
    ...purchaseAgreements[1],
  },
  {
    status: 'inactive',
    ...purchaseAgreements[2],
  },
];
const agreements3: ProductGroupAgreementDTO[] = [
  {
    status: 'inactive',
    ...purchaseAgreements[0],
  },
  {
    status: 'inactive',
    ...purchaseAgreements[1],
  },
  {
    status: 'active',
    ...purchaseAgreements[2],
  },
];

export const supplierAgreementsTestData: ProductGroupAgreementsDTO = {
  agreements: agreements3,
};

export const productGroupWithAgreementsTestData: ProductGroupAgreementsDTO[] = [
  {
    productGroupId: '1',
    productGroupName: 'Byggmaterial',
    agreements: agreements1,
  },
  {
    productGroupId: '2',
    productGroupName: 'Träprodukter',
    agreements: agreements2,
  },
  {
    productGroupId: '100',
    productGroupName: 'Byggnadsblock och ballast',
    agreements: agreements3,
  },
  {
    productGroupId: '101',
    productGroupName: 'Tätkiktssystem, tejp och tätningslist',
    agreements: agreements1,
  },
  {
    productGroupId: '1000',
    productGroupName: 'Krossat bergmaterial',
    agreements: agreements2,
  },
  {
    productGroupId: '1001',
    productGroupName: 'Byggnadsblock ock ballast övrigt',
    agreements: agreements3,
  },
  {
    productGroupId: '1002',
    productGroupName: 'Glasblock',
    agreements: agreements1,
  },
];
