import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { Retailer } from '@retail/retailer/types';
import React, { useState } from 'react';
import { StoreCompetitorsSelector } from '@retail/retailer/components';
import { ContainedButton, Paper } from '@shared/components';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAppTFunction } from '@retail/app/i18n';
import { useContexts } from '@retail/app/stores/selected-context';
import { EmptyCircularFallbackImage, Image } from '@retail/products/components';
import { useScrollWithShadow } from '@retail/hooks';

interface Props {
  retailers: Retailer[];
  addStores: (storeIds: number[]) => void;
  loading: boolean;
  setSelectedStores: (storeId: number[]) => void;
  updateSelectedStores: (storeId: number) => void;
  selectedStores: number[];
}

function EditByggmaterialCompetitors({
  retailers,
  addStores,
  updateSelectedStores,
  setSelectedStores,
  selectedStores,
  loading,
}: Props) {
  const t = useAppTFunction();
  const {
    selectedContext: { coordinates },
  } = useContexts();
  const { retailerLogoBaseUrl } = useMgPrisConfig();
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer | null>(null);
  const updatedRetailer = retailers.find((retailer) => retailer.id === selectedRetailer?.id);
  const { onScrollHandler, boxShadow, overflowRef } = useScrollWithShadow([selectedRetailer]);

  return (
    <Paper
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
    >
      <Typography variant="h6" component="h2" pb={3}>
        {t('products.marketInsight.competitors.chooseCompetitors')}
      </Typography>
      <Autocomplete
        options={retailers}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          return (
            <Stack component={'li'} my={0.5} flexDirection="row" gap={2} {...props}>
              <Image
                height="30px"
                src={Retailer.getCircularLogoUrl(option.urlName, retailerLogoBaseUrl)}
                fallback={() => <EmptyCircularFallbackImage size="30px" />}
              />
              {option.name}
            </Stack>
          );
        }}
        onChange={(_, newValue) => {
          setSelectedRetailer(newValue);
          setSelectedStores([]);
        }}
        value={selectedRetailer}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedRetailer && (
                <Image
                  src={Retailer.getLogoUrl(selectedRetailer?.urlName, retailerLogoBaseUrl)}
                  height="30px"
                  fallback={() => null}
                />
              ),
            }}
            placeholder={t('products.marketInsight.competitors.chooseRetailer')}
          />
        )}
      />
      <Stack overflow="auto" ref={overflowRef} onScroll={onScrollHandler} sx={{ boxShadow }} my={2}>
        {updatedRetailer &&
          (updatedRetailer.stores.filter((stores) => !stores.isSelected).length ? (
            <StoreCompetitorsSelector
              stores={updatedRetailer.stores.filter((stores) => !stores.isSelected)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateSelectedStores(Number(event.target.value))
              }
              selectedStores={selectedStores}
              myCoordinates={coordinates}
              logoUrl={Retailer.getCircularLogoUrl(updatedRetailer.urlName, retailerLogoBaseUrl)}
            />
          ) : (
            <Typography mt={3}>{t('products.marketInsight.competitors.allCompetitors')}</Typography>
          ))}
      </Stack>
      <Stack alignItems="end">
        <ContainedButton
          loading={loading}
          onClick={() => addStores(selectedStores)}
          disabled={!selectedStores.length}
        >
          {t('common.save')}
        </ContainedButton>
      </Stack>
    </Paper>
  );
}

export default EditByggmaterialCompetitors;
