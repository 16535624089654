import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  color?: 'default' | 'warning' | 'success' | 'primary';
  marginBottom?: number;
  alignItems?: string;
}

const Header = styled('div', {
  shouldForwardProp: (prop) => prop !== 'marginBottom' && prop !== 'alignItems',
})<Props>(({ theme, marginBottom = 0, alignItems }) => ({
  display: 'grid',
  width: '100%',
  alignItems,
  gridTemplateColumns: `${theme.spacing(2)} 1fr auto`,
  gridGap: theme.spacing(2),
  marginBottom: theme.spacing(marginBottom),
}));

const Tag = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color',
})<Pick<Props, 'color'>>(({ theme, color }) => ({
  width: theme.spacing(2),
  height: theme.spacing(4),
  background:
    color === 'warning'
      ? theme.palette.gradient.warning
      : color === 'success'
      ? theme.palette.success.main
      : color === 'primary'
      ? theme.palette.primary[400]
      : theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
}));

export default function TagHeading({
  color = 'default',
  marginBottom = 0,
  children,
  alignItems = 'center',
}: PropsWithChildren<Props>) {
  return (
    <Header marginBottom={marginBottom} alignItems={alignItems}>
      <Tag color={color} />
      {children}
    </Header>
  );
}
