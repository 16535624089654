import { useCallback, useMemo, useState } from 'react';
import {
  EditFreightMarkupSchema,
  FreightSurchargeOptionsSelect,
  editFreightMarkupSchema,
  percentFreightUnit,
} from '@retail/freight-markup/components';
import { useFetchFreightMarkupsForMgParticipant } from '@retail/freight-markup/data-access';
import { MgParticipant } from '@retail/products/types';
import { FreightMarkup, FreightMarkupLevel } from '@retail/freight-markup/types';
import { UniversalFormProvider } from '@shared/components';
import { useFreightMarkupTFunction } from '@retail/freight-markup/i18n';
import { EditFreightMarkupDialog } from './EditFreightMarkupDialog';
import { useTriggerFetchMgSupplierItem } from '@retail/products/data-access';
import { FreightMarkupsTable } from './FreightMarkupsTable';
import ItemsAffectedDialog from './ItemsAffected/ItemsAffectedDialogContainer';
import { useDisclosure } from '@shared/hooks';

type Props = {
  supplier: MgParticipant;
  productGroupId?: string;
  mgItemId?: string;
  level: FreightMarkupLevel;
};

export const FreightMarkups = ({ supplier, productGroupId, mgItemId, level }: Props) => {
  const [selectedMarkupId, setSelectedMarkupId] = useState<string>('');
  const t = useFreightMarkupTFunction();
  const {
    isOpen: isItemsAffectedModalOpen,
    onClose: closeItemsAffectedModal,
    onOpen,
  } = useDisclosure(false);

  const openItemsAffectedModal = (markupId: string) => {
    setSelectedMarkupId(markupId);
    onOpen();
  };

  const queryParams = useMemo(() => {
    return {
      productGroupId,
      mgItemId,
      level,
    };
  }, [productGroupId, mgItemId, level]);

  const { data: freightMarkupDtos = [] } = useFetchFreightMarkupsForMgParticipant(
    supplier.id,
    queryParams,
    { suspense: true }
  );

  const freightMarkups = useMemo(
    () => freightMarkupDtos.map(FreightMarkup.fromDto),
    [freightMarkupDtos]
  );

  const [freightMarkupToEdit, setFreightMarkupToEdit] = useState<FreightMarkup | undefined>(
    undefined
  );
  const [freightSurchargeOptions, setfreightSurchargeOptions] = useState<
    FreightSurchargeOptionsSelect[]
  >([]);

  const { mutateAsync: fetchMgSupplierItem } = useTriggerFetchMgSupplierItem();
  const getFreightMarkup = useCallback(
    (freightMarkup: FreightMarkup) => {
      setFreightMarkupToEdit(freightMarkup);
      setfreightSurchargeOptions([]);
      if (freightMarkup.mgSupplierItemFreight) {
        fetchMgSupplierItem({ urlVariables: freightMarkup.id }).then((item) => {
          const packages = item.mgSupplierItemPackages.map(
            (mgSupplierItemPackage) =>
              ({
                value: mgSupplierItemPackage.vilmaUnit.unit,
                name: `${t('freightMarkup.form.surcharge')} ${
                  mgSupplierItemPackage.vilmaUnit.unit
                }`,
              } as FreightSurchargeOptionsSelect)
          );
          setfreightSurchargeOptions([
            { value: percentFreightUnit, name: t('freightMarkup.form.percent') },
            ...packages,
          ]);
        });
      } else {
        setfreightSurchargeOptions([
          { value: percentFreightUnit, name: t('freightMarkup.form.percent') },
        ]);
      }
    },
    [fetchMgSupplierItem, t]
  );

  const defaultValues: EditFreightMarkupSchema = useMemo(() => {
    if (freightMarkupToEdit && freightSurchargeOptions.length > 0) {
      return {
        freightUnit:
          freightMarkupToEdit.mgSupplierItemFreight?.freightSurcharge?.unit || percentFreightUnit,
        freightFactor:
          freightMarkupToEdit.supplierFreight?.freightFactor ||
          freightMarkupToEdit.productGroupFreight?.freightFactor ||
          freightMarkupToEdit.mgSupplierItemFreight?.freightFactor
            ? (freightMarkupToEdit.supplierFreight?.freightFactor ||
                freightMarkupToEdit.productGroupFreight?.freightFactor ||
                freightMarkupToEdit.mgSupplierItemFreight?.freightFactor) + ''
            : '',
        freightSurcharge: freightMarkupToEdit.mgSupplierItemFreight?.freightSurcharge
          ? freightMarkupToEdit.mgSupplierItemFreight.freightSurcharge.value + ''
          : '',
        fromDate: new Date(freightMarkupToEdit.fromDate),
        toDate: new Date(freightMarkupToEdit.toDate),
      };
    }
    return { ...editFreightMarkupSchema(t).getDefault(), fromDate: new Date() };
  }, [freightMarkupToEdit, freightSurchargeOptions.length, t]);

  return (
    <>
      <ItemsAffectedDialog
        open={isItemsAffectedModalOpen}
        onClose={closeItemsAffectedModal}
        mgSupplierId={supplier.id}
        markupId={selectedMarkupId}
      />
      <FreightMarkupsTable
        supplier={supplier}
        freightMarkups={freightMarkups}
        setFreightMarkupToEdit={getFreightMarkup}
        openItemsAffectedModal={openItemsAffectedModal}
      />
      {freightMarkupToEdit && freightSurchargeOptions.length > 0 && (
        <UniversalFormProvider schema={editFreightMarkupSchema(t)} defaultValues={defaultValues}>
          <EditFreightMarkupDialog
            supplier={supplier}
            onClose={() => setFreightMarkupToEdit(undefined)}
            open={true}
            freightMarkup={freightMarkupToEdit}
            freightSurchargeOptions={freightSurchargeOptions}
          />
        </UniversalFormProvider>
      )}
    </>
  );
};
