import { Close } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgParticipant } from '@retail/products/types';
import { Chip } from '@shared/components';
import { Dispatch, SetStateAction } from 'react';

interface GenericProductGroup {
  name?: string;
  id: number;
  commonGroupId: string;
}

interface Props {
  selectedMgParticipants: MgParticipant[] | null;
  selectMgParticipants: (mgParticipant: MgParticipant[]) => void;
  selectedProductGroups: GenericProductGroup[] | null;
  selectProductGroups: (productGroup: GenericProductGroup[]) => void;
  addedFreeSearch: string[] | null;
  addFreeSearch: Dispatch<SetStateAction<string[]>>;
  totalMatchesInSearch?: number;
}

export function SearchTags({
  selectedMgParticipants,
  selectMgParticipants,
  selectedProductGroups,
  selectProductGroups,
  addedFreeSearch,
  addFreeSearch,
  totalMatchesInSearch,
}: Props) {
  const t = useProductsTFunction();
  const { palette } = useTheme();

  return (
    <Box
      border={`2px solid ${palette.grey.A100}`}
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      gap={1}
      width="fit-content"
      alignItems="center"
      padding="10px 20px"
      borderRadius={4}
    >
      {totalMatchesInSearch && (
        <Typography variant="body1" fontWeight="bold" mr="24px">
          {t('products.search.results.label', {
            count: totalMatchesInSearch,
          })}
        </Typography>
      )}
      {selectedMgParticipants?.map((mgParticipant) => {
        return (
          <Chip
            key={mgParticipant.id}
            deleteIcon={<Close />}
            onDelete={() => {
              selectMgParticipants(
                selectedMgParticipants.filter(({ id }) => id !== mgParticipant.id)
              );
            }}
            label={
              <Typography color={palette.grey.A400} variant="body2" fontWeight="bold">
                {mgParticipant.name}
              </Typography>
            }
            backgroundColor={palette.primary.A100}
          ></Chip>
        );
      })}
      {selectedProductGroups?.map((productGroup) => {
        return (
          <Chip
            key={productGroup.id}
            deleteIcon={<Close />}
            onDelete={() => {
              selectProductGroups(selectedProductGroups.filter(({ id }) => id !== productGroup.id));
            }}
            label={
              <Typography color={palette.grey.A400} variant="body2" fontWeight="bold">
                {productGroup.name}
              </Typography>
            }
            backgroundColor={palette.primary.A100}
          ></Chip>
        );
      })}
      {addedFreeSearch?.map((freeSearch) => {
        return (
          <Chip
            key={freeSearch}
            deleteIcon={<Close />}
            onDelete={() => {
              addFreeSearch((prev) => prev.filter((prevSearch) => prevSearch !== freeSearch));
            }}
            label={
              <Typography color={palette.grey.A400} variant="body2" fontWeight="bold">
                {freeSearch}
              </Typography>
            }
            backgroundColor={palette.primary.A100}
          ></Chip>
        );
      })}
    </Box>
  );
}
