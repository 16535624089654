import { useProductsTFunction } from '@retail/products/i18n';
import { Button, Link, Stack, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CopyToClipboard } from '@retail/components';
import { AlternativeIcon } from '@shared/custom-icons';

interface Props {
  onClick?: () => void;
  linkTo?: string;
  mgItemNumber: number;
}

export function EquivalentItemTooltip({ onClick, linkTo, mgItemNumber }: Props) {
  const t = useProductsTFunction();

  return (
    <Tooltip
      arrow
      title={
        <Stack spacing={1} alignItems="start">
          <Typography>{t('products.prices.equivalent.tooltip.title')}</Typography>
          <CopyToClipboard
            value={mgItemNumber}
            alignItems="center"
            spacing={1}
            CopyIconProps={{ color: 'secondary' }}
          >
            <Typography variant="body1" fontWeight="bold">{`${t(
              'products.mgItem.number.label'
            )}: `}</Typography>
            <Typography variant="body1">{mgItemNumber}</Typography>
          </CopyToClipboard>
          {onClick && (
            <Link
              component={Button}
              onClick={() => onClick()}
              sx={{ color: 'white', textDecorationColor: 'white', pl: 1 }}
            >
              <Typography variant="body1">
                {t('products.prices.equivalent.tooltip.goToProduct')}
              </Typography>
            </Link>
          )}
          {linkTo && (
            <Link
              component={RouterLink}
              to={linkTo}
              sx={{ color: 'white', textDecorationColor: 'white', pl: 1 }}
            >
              <Typography variant="body1">
                {t('products.prices.equivalent.tooltip.goToProduct')}
              </Typography>
            </Link>
          )}
        </Stack>
      }
    >
      <AlternativeIcon fontSize="small" color="primary" />
    </Tooltip>
  );
}
