import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { FreightMarkupResources } from '../types/FreightMarkupResources';

export const useFreightMarkupTranslation = () => useMessagesTranslation<FreightMarkupResources>();

export type FreightMarkupMessageKey = MessagePaths<FreightMarkupResources>;

export const useFreightMarkupTFunction = () => useFreightMarkupTranslation().t;

export type FreightMarkupTFunction = ReturnType<typeof useFreightMarkupTFunction>;
