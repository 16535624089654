import { prisinnsiktConfig } from '@retail/app/config';
import { useToken } from '../../hooks';
import { useMutation } from '@tanstack/react-query';
import { useSelectedContext } from '../../stores';
import { downloadResponseFile, fetchFile } from '@shared/fetch-utils';

const url = prisinnsiktConfig.pps.url + '/v1/export/xlsx';

interface ExportXLSXOptions {
  assortmentId: number;
  includeVAT: boolean;
  includeVariantPrices: boolean;
  nobbNrs?: number[];
}

export function useExportXLSXApi() {
  const getAccessTokenWithErrorHandling = useToken();
  const context = useSelectedContext();

  return useMutation(
    async ({ assortmentId, ...options }: ExportXLSXOptions) => {
      const token = await getAccessTokenWithErrorHandling();
      return fetchFile({
        token,
        context: { key: context.type, value: context.value },
        body: options,
        url: `${url}/${assortmentId}`,
        method: 'POST',
      });
    },
    {
      onSuccess: downloadResponseFile({ filenameFallback: 'prisinnsikt-export.xlsx' }),
    }
  );
}
