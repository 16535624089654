import { MgItemMinimal } from '@retail/products/types';
import { MgAssortmentDetailsDTO } from '../dto';
import { AssortmentType } from './AssortmentType';

interface ConstructorProps {
  id: number;
  title: string;
  description?: string;
  type: AssortmentType;
  items: MgItemMinimal[];
  readOnly: boolean;
  contextId?: number;
}

export class MgAssortment {
  id: number;
  title: string;
  description?: string;
  type: AssortmentType;
  items: MgItemMinimal[];
  readOnly: boolean;
  contextId?: number;

  constructor({ id, contextId, title, type, items, readOnly, description }: ConstructorProps) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.items = items;
    this.readOnly = readOnly;
    this.description = description;
    this.contextId = contextId;
  }

  static fromDto(assortmentDto: MgAssortmentDetailsDTO): MgAssortment {
    return new MgAssortment({
      contextId: assortmentDto.contextId,
      description: assortmentDto.description,
      items: assortmentDto.mgItems.map(MgItemMinimal.fromDto),
      readOnly: assortmentDto.readOnly,
      title: assortmentDto.title,
      id: assortmentDto.id,
      type: assortmentDto.type as AssortmentType,
    });
  }
}
