import React, { useCallback } from 'react';
import { useAppConfig } from '@retail/app/stores/app-config';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

interface Props {
  children: React.ReactNode;
}

function AuthProvider({ children }: Props) {
  const navigate = useNavigate();

  const { domain, clientId, audience } = useAppConfig().auth0;

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(appState?.returnTo || window.location.pathname, {
        replace: true,
      });
    },
    [navigate]
  );

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
}

export default AuthProvider;
