import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { buildUrl } from '@shared/utils';
import { MgSupplierItemsPageDTO } from '@retail/purchase-agreements/types';
import { useContexts } from '@retail/app/stores/selected-context';
import { contextPath, purchaseAgreementsPath } from './purchaseAgreementsForMgParticipantApi';

interface SearchParams {
  mgSupplierId: number;
  productGroupId?: string;
  page?: number;
  pageSize?: number;
}

export const affectedItemsPath = 'affected-items';

export function useFetchAffectedItems(
  { mgSupplierId, productGroupId, page, pageSize }: SearchParams,
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const { selectedContext } = useContexts();

  return useFetch<MgSupplierItemsPageDTO>({
    method: 'GET',
    queryKey: ['affectedItems', mgSupplierId, productGroupId, page, pageSize],
    token,
    url: buildUrl({
      base: pps.url,
      route: `${contextPath}/${selectedContext.id}/${purchaseAgreementsPath}/${mgSupplierId}/${affectedItemsPath}`,
      searchParams: { productGroupId, page, pageSize },
    }),
    ...queryOptions,
  });
}
