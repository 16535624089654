import { prisinnsiktConfig } from '@retail/app/config';
import { useToken } from '../../hooks/useToken';
import { useQuery } from '@tanstack/react-query';
import { Context } from '@retail/auth/types';
import { CompetitorPriceResponseDTO, NobbNr } from '@retail/products/types';
import { useSelectedContext } from '../../stores/userContexts';

import { format, sub } from 'date-fns';
import { fetcher } from '@shared/fetch-utils';

const url = prisinnsiktConfig.pps.url + '/v1/price/competitorPricesByDate';

export async function fetchHistoricCompetitorPrices(
  token: string,
  context: Context,
  nobbNumbers: NobbNr[]
) {
  const query =
    '?' +
    'from=' +
    format(sub(new Date(), { years: 3 }), 'yyyy-MM-dd') +
    '&to=' +
    format(new Date(), 'yyyy-MM-dd');

  return fetcher<CompetitorPriceResponseDTO[]>({
    url: `${url}${query}`,
    token,
    method: 'POST',
    context: { key: context.type, value: context.value },
    body: JSON.stringify(nobbNumbers),
  });
}

// TODO move to common folder
type Options = {
  refetchOnWindowFocus?: boolean;
  shouldFetch?: boolean;
  useSuspense?: boolean;
  nobbNumbers: NobbNr[];
};

// React friendly version
export function useHistoricCompetitorPrices({
  refetchOnWindowFocus = true,
  shouldFetch = false,
  useSuspense = false,
  nobbNumbers,
}: Options) {
  const getAccessTokenWithErrorHandling = useToken();
  const context = useSelectedContext();
  // TODO create generic fetcher?
  // @ts-ignore
  const fetcher = async () => {
    const t = await getAccessTokenWithErrorHandling();

    try {
      // id is not undefined here. TODO find something more beauty?
      return await fetchHistoricCompetitorPrices(t, context, nobbNumbers);
    } catch (e) {
      // if err === 401
      // try one more time with new token.
      // else logout/relog
    }
  };
  // handle nobbnumbers is []?
  return useQuery(['competitor-historic-prices', context, nobbNumbers], fetcher, {
    refetchOnWindowFocus,
    enabled: shouldFetch,
    suspense: useSuspense,
  });
}
