import React, { memo } from 'react';
import { BodyLoader } from '@retail/components';
import { PageLayoutOld } from '@retail/components';
import ContextUsers from './ContextUsers';
import guard, { isContextAdmin } from '../../decorators/guard';
import useContextUsers from '../../api/pps/context-users';

function AdminUsersPage() {
  const { contextUsers, demoteUserMutation, promoteUserMutation } = useContextUsers();

  return (
    <React.Suspense fallback={<BodyLoader />}>
      <PageLayoutOld>
        <ContextUsers
          contextUsers={contextUsers || []}
          demoteUser={demoteUserMutation}
          promoteUser={promoteUserMutation}
        />
      </PageLayoutOld>
    </React.Suspense>
  );
}

export default memo(guard({ guards: [isContextAdmin] })(AdminUsersPage));
