import React from 'react';
import { PublicPage } from '../../components/PublicPage';
import { useAppTFunction } from '@retail/app/i18n';
import { Button, Stack, Typography } from '@mui/material';
import { useAuthContext } from '../../api';

export function PublicErrorPage() {
  const t = useAppTFunction();
  const { logout } = useAuthContext();

  return (
    <PublicPage>
      <Stack alignItems="center" spacing={4}>
        <Typography>{t('app.login.error.generic')}</Typography>
        <Button onClick={() => logout()}>{t('common.logOut')}</Button>
      </Stack>
    </PublicPage>
  );
}
