import React from 'react';
import { Container, CircularProgress, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

export function LoadingProduct() {
  const t = useAppTFunction();
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <CircularProgress />
      <Typography>{t('products.loadingProduct')}</Typography>
    </Container>
  );
}
