import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  BodyLoader,
  BreadcrumbsToolbar,
  ErrorFallbackPage,
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { useFetchAllMgParticipants } from '@retail/products/data-access';
import { MgParticipant } from '@retail/products/types';
import { BreadcrumbsProps, ContainedButton, Paper } from '@shared/components';
import { SupplierSelect } from '@retail/products/components';
import { CreateFreightMarkupContainer } from '@retail/freight-markup/containers';
import { ProductGroupsTreeViewContainer } from '@retail/freight-markup/containers';
import { useDisclosure } from '@shared/hooks';
import { FreightMarkups } from '@retail/freight-markup/containers';
import { useFreightMarkupSearchParams } from '@retail/freight-markup/hooks';

export function FreightMarkupForSupplierPage() {
  const t = useAppTFunction();
  const navigate = useNavigate();

  const { supplierId } = useParams<{ supplierId: string }>();
  const { data: participantDtos = [] } = useFetchAllMgParticipants({ suspense: true });
  const suppliers = useMemo(
    () => participantDtos?.map(MgParticipant.fromMinimalDto),
    [participantDtos]
  );

  const selectedSupplier = useMemo(() => {
    return suppliers.find((supplier) => supplier.id === Number(supplierId));
  }, [supplierId, suppliers]);

  const {
    isOpen: isCreateDialogOpen,
    onClose: closeCreateDialog,
    onOpen: openCreateDialog,
  } = useDisclosure(false);

  const breadcrumbItems: BreadcrumbsProps['items'] = useMemo(() => {
    return [
      { label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() },
      {
        label: t('app.routes.freightMarkup'),
        href: mgPrisRoutes.freightMarkup.getFullLinkPath(),
      },
      { label: selectedSupplier?.name || `${supplierId}` },
    ];
  }, [supplierId, selectedSupplier?.name, t]);

  const { selectedProductGroupId, selectedMgItemId, selectedLevel } =
    useFreightMarkupSearchParams();

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper paddingY="dense" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <BreadcrumbsToolbar
          items={breadcrumbItems}
          endContent={
            selectedSupplier && (
              <Stack direction="row" gap={1}>
                <SupplierSelect
                  size="small"
                  suppliers={suppliers}
                  getSupplierId={(supplier) => `${supplier.id}`}
                  selectedSupplier={selectedSupplier}
                  selectSupplier={() =>
                    navigate(
                      mgPrisRoutes.freightMarkup.supplier.getFullLinkPath(`${selectedSupplier.id}`)
                    )
                  }
                  getSupplierName={(supplier) => supplier.name}
                />
                <ContainedButton
                  sx={{ whiteSpace: 'nowrap' }}
                  color="secondary"
                  endIcon={<Add />}
                  size="small"
                  onClick={openCreateDialog}
                >
                  {t('freightMarkup.newFreightMarkup')}
                </ContainedButton>

                {isCreateDialogOpen && (
                  <CreateFreightMarkupContainer
                    supplierId={selectedSupplier.id}
                    closeCreateDialog={closeCreateDialog}
                  />
                )}
              </Stack>
            )
          }
        />
        {selectedSupplier ? (
          <Stack direction="row" mt={2} flexGrow={1} overflow="hidden">
            <ProductGroupsTreeViewContainer selectedSupplier={selectedSupplier} />
            <Paper variant="outlined" sx={{ width: '100%', overflow: 'auto', padding: 0 }}>
              <SuspenseWithSentryErrorBoundary
                errorFallback={(props) => <ErrorFallbackPage {...props} />}
                suspenseFallback={<BodyLoader />}
              >
                <FreightMarkups
                  supplier={selectedSupplier}
                  productGroupId={selectedProductGroupId}
                  mgItemId={selectedMgItemId}
                  level={selectedLevel}
                />
              </SuspenseWithSentryErrorBoundary>
            </Paper>
          </Stack>
        ) : (
          <NotFoundError
            title={t('freightMarkup.supplier.notFound.title')}
            subtitle={t('freightMarkup.supplier.notFound.subtitle', { supplierId })}
            buttonText={t('freightMarkup.supplier.notFound.goBack')}
          />
        )}
      </Paper>
    </PageLayout>
  );
}
