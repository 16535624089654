import { captureMessage } from '@sentry/react';
import {
  CampaignPriceDTO,
  MgPriceDTO,
  NobbPriceDTO,
  OmniumPriceDTO,
  PakningDTO,
} from '@retail/products/types';

interface PricePakning {
  pakningsEnhet: string;
  bestarAvAntall: number;
  bestarAvEnhet: string;
  kalkulertInnhold: number;
  price: number;
  mgPrice?: number;
  omniumPrice?: number;
  campaignPrice?: number;
}

interface Props {
  productId: string | number;
  nobbPrice: NobbPriceDTO;
  pakninger: PakningDTO[];
  mgPrice?: MgPriceDTO;
  omniumPrice?: OmniumPriceDTO;
  campaignPrice?: CampaignPriceDTO;
}

export function calculatePricePerUnitMg({
  productId,
  nobbPrice,
  pakninger,
  mgPrice,
  omniumPrice,
  campaignPrice,
}: Props): PricePakning[] {
  const basisEnhet = pakninger.find((p) => p.erPrisEnhet);
  const mgEnhet = mgPrice?.salgsEnhet;

  if (basisEnhet !== undefined && mgEnhet !== undefined && mgEnhet !== basisEnhet.pakningsEnhet) {
    captureMessage(
      `NobbProduct: ${productId} has mismatch in baseUnit in NobbPrice and MGPrice`,
      'error'
    );
  }

  // IF nobbPrice basisenhet === mgPrice then we can do this...
  return pakninger.map((pakning) => {
    const pricePakning: PricePakning = {
      ...pakning,
      price: nobbPrice.pris * pakning.kalkulertInnhold,
      mgPrice: mgPrice && mgPrice.salgsPris * pakning.kalkulertInnhold,
      omniumPrice: omniumPrice && omniumPrice.unitPrice * pakning.kalkulertInnhold,
      campaignPrice: campaignPrice && campaignPrice.salgsPris * pakning.kalkulertInnhold,
    };
    return pricePakning;
  });
}
