import { prisinnsiktConfig } from '@retail/app/config';
import { NobbNr } from '@retail/products/types';
import { CompetitorPriceResponseDTO } from '@retail/products/types';
import { useMemo } from 'react';
import { QueryOptions, RequestContext, useFetch } from '@shared/fetch-utils';
import { useAuthContext } from './AuthContext';

const url = prisinnsiktConfig.pps.url + '/v1/price/competitorPrices';

interface Options extends QueryOptions {
  nobbNumbers: NobbNr[];
  context: RequestContext;
  selectedStores: number[];
  selectedRetailers: number[];
}

export function useCompetitorPrices({
  nobbNumbers,
  context,
  selectedStores,
  selectedRetailers,
  ...queryOptions
}: Options) {
  const { token } = useAuthContext();
  const retailersQuery = useMemo(() => {
    if (selectedRetailers.length > 0) {
      return `&retailerId=${selectedRetailers.join(',')}`;
    }
    return '';
  }, [selectedRetailers]);

  const storesQuery = useMemo(() => {
    if (selectedStores.length > 0) {
      return `&storeId=${selectedStores.join(',')}`;
    }
    return '';
  }, [selectedStores]);

  return useFetch<CompetitorPriceResponseDTO[]>({
    queryKey: ['competitor-prices', context, nobbNumbers],
    method: 'POST',
    context,
    token,
    body: nobbNumbers,
    url: `${url}?prices=1${retailersQuery}${storesQuery}`,
    ...queryOptions,
  });
}
