import { useDisclosure } from '@shared/hooks';
import { usePurchaseAgreementsToActivate } from '@retail/purchase-agreements/stores';
import { useCallback, useMemo, useState } from 'react';
import { BottomDrawer } from '@retail/components';
import { DrawerIcon } from '@shared/custom-icons';
import { ContainedButton, OutlinedButton, Paper, TagHeading } from '@shared/components';
import { useMutatePurchaseAgreements } from '@retail/purchase-agreements/data-access';
import { Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';
import { SummaryDrawerTable } from '@retail/purchase-agreements/components';
import MgSupplierItemsDialogContainer from './MgSupplierItemsDialogContainer';

interface Props {
  supplierId: number;
}

export function SummaryDrawerContainer({ supplierId }: Props) {
  const { isOpen, onToggle } = useDisclosure(false);
  const toast = useToast();
  const {
    activatedPurchaseAgreements,
    removeSupplierAgreement,
    removeProductGroupAgreement,
    removeItemAgreement,
    clearState,
  } = usePurchaseAgreementsToActivate();

  const t = useAppTFunction();
  const numberOfAgreementsToActivate = useMemo(
    () => activatedPurchaseAgreements.numberOfAgreementsToActivate(),
    [activatedPurchaseAgreements]
  );
  const { mutateAsync: postAgreements, isLoading } = useMutatePurchaseAgreements();

  const supplierAgreement = activatedPurchaseAgreements.supplierAgreementsToActivate;

  const productGroupAgreements = activatedPurchaseAgreements.productGroupsAgreementsToActivate.map(
    (agreement) => ({
      productGroupId: agreement.productGroupNr,
      agreementId: agreement.newActiveAgreement.id,
    })
  );
  const itemAgreements = activatedPurchaseAgreements.itemAgreementsToActivate.map((agreement) => {
    return {
      mgSupplierItemId: agreement.mgItemNr,
      agreementId: agreement.newActiveAgreement.id,
      priceType: agreement.newActiveAgreement.priceType,
    };
  });

  const submitAgreements = useCallback(() => {
    postAgreements({
      body: {
        itemAgreementsToActivate: itemAgreements,
        mgParticipantAccountAgreementsToActivate: supplierAgreement?.newActiveAgreement.id || null,
        productGroupsAgreementsToActivate: productGroupAgreements,
      },
      urlVariables: supplierId,
    })
      .then(() => {
        clearState();
        toast.success(t('purchaseAgreements.update.success'));
      })
      .catch(() => toast.error(t('purchaseAgreements.update.error')));
  }, [
    supplierAgreement,
    productGroupAgreements,
    itemAgreements,
    clearState,
    supplierId,
    postAgreements,
    t,
    toast,
  ]);
  const {
    isOpen: isMgSupplierItemsModalOpen,
    onClose: closeMgSupplierItemModal,
    onOpen: openMgSupplierItemModal,
  } = useDisclosure(false);
  const [productGroupId, setproductGroupId] = useState<string | undefined>(undefined);

  const viewAffectedItemsForSupplierOrProductGroup = useCallback(
    (productGroupNr?: string) => {
      openMgSupplierItemModal();
      productGroupNr && setproductGroupId(productGroupNr);
    },
    [openMgSupplierItemModal]
  );

  return numberOfAgreementsToActivate ? (
    <>
      <MgSupplierItemsDialogContainer
        open={isMgSupplierItemsModalOpen}
        onClose={() => {
          setproductGroupId(undefined);
          closeMgSupplierItemModal();
        }}
        supplierId={supplierId}
        productGroupId={productGroupId}
      />
      <BottomDrawer isOpen={isOpen} collapsedHeight={24}>
        <OutlinedButton
          sx={{ height: 28, alignSelf: 'center', my: '5px' }}
          size="small"
          onClick={onToggle}
          startIcon={
            <DrawerIcon
              orientation={isOpen ? 'down' : 'up'}
              sx={{ width: '16px', height: '16px' }}
            />
          }
        >
          {numberOfAgreementsToActivate === 1
            ? t('purchaseAgreements.summary.drawerTitle.singular')
            : t('purchaseAgreements.summary.drawerTitle.plural', {
                count: numberOfAgreementsToActivate,
              })}
        </OutlinedButton>
        {isOpen && (
          <Paper sx={{ m: 4, mt: 0, height: '100%', overflow: 'scroll', p: 2.5, pt: 0 }}>
            <Stack
              position="sticky"
              top={0}
              zIndex={1}
              bgcolor={({ palette }) => palette.common.white}
              pt={2.5}
            >
              <TagHeading>
                <Typography variant="h6" component="h2">
                  {t('purchaseAgreements.summary.title')}
                </Typography>
              </TagHeading>
            </Stack>
            <Stack>
              <SummaryDrawerTable
                supplierAgreement={supplierAgreement}
                productGroupAgreement={
                  activatedPurchaseAgreements.productGroupsAgreementsToActivate
                }
                mgItemAgreement={activatedPurchaseAgreements.itemAgreementsToActivate}
                viewAffectedItems={viewAffectedItemsForSupplierOrProductGroup}
                removeSupplierAgreement={removeSupplierAgreement}
                removeProductGroupAgreement={removeProductGroupAgreement}
                removeItemAgreement={removeItemAgreement}
              />

              <ContainedButton
                sx={{ alignSelf: 'flex-end' }}
                size="small"
                onClick={submitAgreements}
                loading={isLoading}
              >
                {t('common.save')}
              </ContainedButton>
            </Stack>
          </Paper>
        )}
      </BottomDrawer>
    </>
  ) : null;
}
