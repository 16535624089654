import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

import { useIncludeVat } from '../../stores';
import { addVAT } from '../../utils/vat';
import { renderPrice } from '@retail/products/utils';
import { NobbPriceDTO, NobbProductDTO, PriceProvider } from '@retail/products/types';
import { calculatePricePerUnitMg } from '../../utils/priceUnitCalculator';
import { SupplierView } from './ProductViewTypes';
import { SupplierSelect } from '@retail/products/components';
import { mapToSupplierView } from './productPageUtils';
import { withSuspense } from '../../decorators';
import LoadingFallback from './components/LoadingFallback';
import ContainerLoadingError from './components/ContainerLoadingError';
import { useFetchPriceForProvider } from '../../api';
import { useAppTFunction } from '@retail/app/i18n';
import { useSelectedContext } from '../../stores/userContexts';

interface ContentProps {
  productId: number;
  supplier: SupplierView;
}

function NobbPriceTableContent({ productId, supplier }: ContentProps) {
  const t = useAppTFunction();
  const { includeVat } = useIncludeVat();
  const nobbPrice: NobbPriceDTO | undefined = useMemo(() => {
    const now = Date.now();

    return supplier.nobbPrices?.filter((price) => {
      if (price.tom) {
        return isAfter(now, new Date(price.fom)) && isBefore(now, new Date(price.tom));
      } else {
        return isAfter(now, new Date(price.fom));
      }
    })[0];
  }, [supplier]);

  const tableRows = useMemo(() => {
    if (nobbPrice !== undefined) {
      return calculatePricePerUnitMg({
        productId,
        nobbPrice,
        pakninger: supplier.packages,
        mgPrice: supplier.mgPrice,
        omniumPrice: supplier.omniumPrice,
        campaignPrice: supplier.campaignPrice,
      }).sort((a, b) => a.bestarAvAntall - b.bestarAvAntall);
    }
    return [];
  }, [supplier, nobbPrice, productId]);

  const moreNobbPrices = useMemo(() => {
    return supplier.nobbPrices.length > 1;
  }, [supplier]);

  if (tableRows.length === 0) {
    return (
      <Box height="200px">
        <Typography>{t('products.prices.own.noPrices')}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('products.prices.own.baseUnit')}</TableCell>
            <TableCell>{t('products.prices.own.consistsOf')}</TableCell>
            <TableCell>{t('products.prices.own.mgPrice')}</TableCell>
            <TableCell>{t('products.prices.own.webPrice')}</TableCell>
            <TableCell>{`${t('products.prices.own.nobbPrice')}${
              moreNobbPrices ? ' **' : ''
            }`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map(
            ({
              pakningsEnhet,
              bestarAvEnhet,
              bestarAvAntall,
              omniumPrice,
              campaignPrice,
              mgPrice,
              price,
            }) => {
              const currentStorePrice = campaignPrice || omniumPrice;
              return (
                <TableRow
                  key={pakningsEnhet}
                  sx={{
                    '&:nth-of-type(odd)': {
                      bgcolor: ({ palette }) => palette.grey[100],
                    },
                  }}
                >
                  <TableCell>1 {pakningsEnhet}</TableCell>
                  <TableCell>
                    {bestarAvAntall} {bestarAvEnhet}
                  </TableCell>
                  <TableCell>
                    {mgPrice
                      ? includeVat
                        ? renderPrice(addVAT(mgPrice))
                        : renderPrice(mgPrice)
                      : '-'}{' '}
                    {t('products.prices.currency.NOK.short')}
                  </TableCell>
                  <TableCell>
                    {currentStorePrice
                      ? includeVat
                        ? renderPrice(addVAT(currentStorePrice))
                        : renderPrice(currentStorePrice)
                      : '-'}{' '}
                    {t('products.prices.currency.NOK.short')}
                  </TableCell>
                  <TableCell>
                    {`${includeVat ? renderPrice(addVAT(price)) : renderPrice(price)} ${t(
                      'products.prices.currency.NOK.short'
                    )}`}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
      {supplier.mgPrice && (
        <Typography fontSize={12}>
          <i>Vår salgsenhet: {supplier.mgPrice.salgsEnhet}</i>
        </Typography>
      )}
      {moreNobbPrices && (
        <Typography fontSize={10}>
          <i>{`** = ${t('products.prices.own.moreNobbPrices')}`}</i>
        </Typography>
      )}
    </Box>
  );
}

interface Props {
  product: NobbProductDTO;
}

const NobbPriceTable: React.FC<Props> = ({ product }) => {
  const selectedContext = useSelectedContext();
  const requestContext = { key: selectedContext.type, value: selectedContext.value };
  const t = useAppTFunction();
  const { data: nobbProductPrices } = useFetchPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.nobb,
    suspense: true,
    context: requestContext,
  });

  const { data: mgProductPrices } = useFetchPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.mg,
    suspense: true,
    context: requestContext,
  });

  const { data: omniumProductPrices } = useFetchPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.omnium,
    suspense: true,
    context: requestContext,
  });

  const { data: campaignProductPrices } = useFetchPriceForProvider({
    nobbNumbers: [product.nobbNr],
    priceProvider: PriceProvider.campaign,
    suspense: true,
    context: requestContext,
  });

  const suppliers = useMemo(
    () =>
      mapToSupplierView({
        nobbProductPrice: nobbProductPrices?.[0],
        mgProductPrice: mgProductPrices?.[0],
        omniumProductPrice: omniumProductPrices?.[0],
        campaignProductPrice: campaignProductPrices?.[0],
        productSuppliers: product.leverandorer,
      }),
    [
      nobbProductPrices,
      mgProductPrices,
      omniumProductPrices,
      campaignProductPrices,
      product.leverandorer,
    ]
  );

  const [selectedSupplier, setSelectedSupplier] = useState<SupplierView | undefined>(suppliers[0]);

  useEffect(() => {
    setSelectedSupplier(suppliers[0]);
  }, [suppliers]);

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center" mb={2} justifyContent="space-between">
        <Typography variant="h2">{t('products.prices.own.title')}</Typography>
        {selectedSupplier && (
          <SupplierSelect
            suppliers={suppliers}
            selectSupplier={setSelectedSupplier}
            selectedSupplier={selectedSupplier}
            getSupplierId={(supplier) => supplier.supplierInfo.id}
            getSupplierName={(supplier) => supplier.supplierInfo.name}
          />
        )}
      </Stack>
      {selectedSupplier ? (
        <NobbPriceTableContent supplier={selectedSupplier} productId={product.nobbNr} />
      ) : (
        <Typography sx={{ marginY: 2 }}>{t('products.supplier.noExisting')}</Typography>
      )}
    </Box>
  );
};

export default withSuspense({
  Component: NobbPriceTable,
  fallback: ({ t }) => <LoadingFallback heading={t('products.prices.own.title')} />,
  errorFallback: ({ t }) => (
    <ContainerLoadingError containerHeading={t('products.prices.own.title')} />
  ),
});
