import { SvgIcon, SvgIconProps } from '@mui/material';

export const RedCrossIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29299 9.70714C7.90246 9.31661 7.90246 8.68345 8.29299 8.29292C8.68351 7.9024 9.31668 7.9024 9.7072 8.29292L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7072 14.2929C16.0977 14.6835 16.0977 15.3166 15.7072 15.7071C15.3167 16.0977 14.6835 16.0977 14.293 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071Z"
          fill="#BF6E6E"
        />
      </svg>
    </SvgIcon>
  );
};
