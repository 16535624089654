import { useMountEffect } from '@shared/hooks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const availableLanguageCodes = ['no', 'se'] as const;
type Language = (typeof availableLanguageCodes)[number];

const dayjsLanguageCodes: Record<Language, string> = {
  no: 'nb',
  se: 'sv',
};

export function useLanguageSelector() {
  const { i18n } = useTranslation();
  const { changeLanguage, language } = i18n;

  useMountEffect(() => {
    changeLanguage(localStorage.getItem('selectedLanguage') || language);
  });

  const switchLanguage = (languageCode: Language) => {
    document.querySelector('html')?.setAttribute('lang', languageCode);
    changeLanguage(languageCode);
    dayjs.locale(dayjsLanguageCodes[languageCode]);
    localStorage.setItem('selectedLanguage', languageCode);
  };

  return {
    switchLanguage,
    language,
  };
}
