import { Typography } from '@mui/material';
import { useHistoricCompetitorPrices } from '../../api/pps/competitor-historic-price';
import { NobbProductDTO } from '@retail/products/types';
import { withSuspense } from '../../decorators';
import React, { useMemo } from 'react';
import { ProductCompetitorInfo } from '@retail/products/types';
import ContainerLoadingError from './components/ContainerLoadingError';
import HistoricProductCompetitorPricesChart from './components/HistoricProductCompetitorPricesChart';
import HistoricVariantPricesCharts from './components/HistoricVariantPricesCharts';
import LoadingFallback from './components/LoadingFallback';
import ProductPagePaper from './components/ProductPagePaper';
import { createCompetitorPriceMap } from './productPageUtils';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  product: NobbProductDTO;
}

const HistoricCompetitorPricesContainer: React.FC<Props> = ({ product }) => {
  const t = useAppTFunction();
  const { data: historicCompetitorPrices } = useHistoricCompetitorPrices({
    nobbNumbers: [product.nobbNr],
    shouldFetch: true,
    useSuspense: true,
  });

  const historicCompetitorPriceMap = useMemo(() => {
    return createCompetitorPriceMap(historicCompetitorPrices || []);
  }, [historicCompetitorPrices]);

  const historicCompetitorProductPrices: ProductCompetitorInfo = useMemo(() => {
    return {
      nobbNr: product.nobbNr,
      productName: product.varetekst1,
      retailerPrices: historicCompetitorPriceMap[product.nobbNr]?.retailers || [],
    };
  }, [product, historicCompetitorPriceMap]);

  const historicalVariants: ProductCompetitorInfo[] = useMemo(
    () =>
      product.equivalents?.nobbProducts
        .filter(({ nobbNr }) => nobbNr !== product.nobbNr)
        .map((variant) => ({
          nobbNr: variant.nobbNr,
          productName: variant.varetekst1,
          retailerPrices: historicCompetitorPriceMap[variant.nobbNr]?.retailers || [],
        })) || [],
    [product, historicCompetitorPriceMap]
  );

  return (
    <>
      <ProductPagePaper>
        <HistoricProductCompetitorPricesChart
          productData={historicCompetitorProductPrices}
          heading={
            <Typography variant="h2" sx={{ mb: 2 }}>
              {t('products.prices.competitors.historic.title')}
            </Typography>
          }
        />
      </ProductPagePaper>
      <ProductPagePaper>
        <HistoricVariantPricesCharts variants={historicalVariants} />
      </ProductPagePaper>
    </>
  );
};

export default withSuspense({
  Component: HistoricCompetitorPricesContainer,
  fallback: ({ t }) => (
    <ProductPagePaper>
      <LoadingFallback heading={t('products.prices.competitors.historic.title')} />
    </ProductPagePaper>
  ),
  errorFallback: ({ t }) => (
    <ContainerLoadingError containerHeading={t('products.prices.competitors.historic.title')} />
  ),
});
