import { ItemForProductGroupDTO } from '../dto';

export interface ItemForProductGroupProps {
  productGroupId: string;
  mgItemNumber: number;
  primaryText: string;
  gtinCode: number;
  finfoNumber: number;
}

export class ItemForProductGroup {
  productGroupId: string;
  mgItemNumber: number;
  primaryText: string;
  gtinCode: number;
  finfoNumber: number;

  constructor({
    productGroupId,
    mgItemNumber,
    primaryText,
    gtinCode,
    finfoNumber,
  }: ItemForProductGroupProps) {
    this.productGroupId = productGroupId;
    this.mgItemNumber = mgItemNumber;
    this.primaryText = primaryText;
    this.gtinCode = gtinCode;
    this.finfoNumber = finfoNumber;
  }

  static fromItemDto = (dto: ItemForProductGroupDTO): ItemForProductGroup =>
    new ItemForProductGroup({
      productGroupId: dto.productGroupId,
      mgItemNumber: dto.mgItemNumber,
      primaryText: dto.primaryText,
      gtinCode: dto.gtinCode,
      finfoNumber: dto.finfoNumber,
    });
}
