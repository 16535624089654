import { SxProps } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { RetailerColumnHeader } from '../ProductCell';
import { CompetitorCellsMap } from '@retail/products/types';
import { Competitor } from '@retail/retailer/types';

export type CompetitorColDef<TCompetitorPrice> = GridColDef<
  CompetitorCellsMap<TCompetitorPrice>,
  TCompetitorPrice
>;

export const getCompetitorColumn = ({
  type,
  key,
  urlName,
  name,
  retailer,
  circularLogoUrl,
}: Competitor): GridColDef => {
  return {
    field: key(),
    headerName: name,
    minWidth: 170,
    renderHeader: () => (
      <RetailerColumnHeader
        retailerName={retailer().name}
        storeName={type() === 'store' ? name : undefined}
        retailerUrlName={urlName()}
        logoUrl={circularLogoUrl()}
      />
    ),
  };
};

export const muiGridWrapperStyles: SxProps = {
  '& .MuiDataGrid-cell[role=cell]': {
    whiteSpace: 'normal !important',
    fontSize: '12px',
  },
  '& .MuiDataGrid-pinnedColumnHeaders--left': {
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
};
