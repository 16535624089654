import { createTheme } from '@mui/material';
import './prisinnsikt.css';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    '3xl': true;
  }
}

export const prisinnsiktTheme = createTheme({
  // @ts-ignore
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: 28,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
      '3xl': 2500,
    },
  },
});
