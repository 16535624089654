import {
  bolistCircularLogoUri,
  bolistIconImgUri,
  bolistLogoImgUri,
  xlbyggCircularLogoUri,
  xlByggIconImgUri,
  xlByggImgUri,
} from '@assets/images';
import { BrandConfig } from '@retail/mgpris/config-types';

export const bolistConfig: BrandConfig = {
  colors: {
    primary: 'rgb(255, 94, 10)',
    light: 'rgba(255, 94, 10, 0.2)',
    contrast: '#000000',
  },
  logo: bolistLogoImgUri,
  icon: bolistIconImgUri,
  circularLogo: bolistCircularLogoUri,
  key: 'bolist',
  name: 'Bolist',
};

export const xlbyggConfig: BrandConfig = {
  colors: {
    primary: '#161616',
    contrast: '#FFFFFF',
  },
  logo: xlByggImgUri,
  icon: xlByggIconImgUri,
  circularLogo: xlbyggCircularLogoUri,
  key: 'xlbyggsverige',
  name: 'XL-BYGG',
};
