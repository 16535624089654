import React, { useCallback } from 'react';
import { NativeSelect } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelectedContextState, useUserContexts } from '../../stores/userContexts';
//TODO: Don't think there is any need for NativeSelect. Think Select is quite smart when it comes to responsive behaviour. Check it out.
const Select = styled(NativeSelect)({
  color: 'black',
  borderRadius: '5px',
  paddingLeft: '1rem',
  background: 'white',
  minWidth: '300px',
});

function UserContextSelect() {
  const { data: userContexts = [] } = useUserContexts({ suspense: true });
  const { selectedContext, setSelectedContext } = useSelectedContextState();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sc = userContexts.find((c) => c.value === e.target.value);
      if (sc !== undefined) {
        setSelectedContext(sc);
      }
    },
    [setSelectedContext, userContexts]
  );

  return (
    <Select value={selectedContext?.value} onChange={handleChange} variant="outlined">
      {userContexts.map((context) => (
        <option key={context.value} value={context.value}>
          {context.name}
        </option>
      ))}
    </Select>
  );
}

export default React.memo(UserContextSelect);
