import { Container, ContainerProps, Theme } from '@mui/material';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SystemCssProperties } from '@mui/system/styleFunctionSx/styleFunctionSx';

interface Props extends Omit<ContainerProps, 'sx'> {
  sx?: SystemCssProperties<Theme>;
}

export function PageLayoutOld({ maxWidth = 'xl', sx, ...props }: Props) {
  return <Container maxWidth={maxWidth} sx={{ ...sx, py: sx?.py || 2 }} {...props} />;
}
