import { VilmaUnit } from '../MgItem';
import { FinfoPriceDTO } from '../../dto';

interface ConstructorProps {
  basePrice?: number;
  priceDate: string;
  priceId: string;
  recommendedPrice?: number;
  updatedAt: string;
  vilmaUnit: VilmaUnit;
}

export class FinfoPrice {
  basePrice?: number;
  priceDate: string;
  priceId: string;
  recommendedPrice?: number;
  updatedAt: string;
  vilmaUnit: VilmaUnit;

  constructor({
    basePrice,
    priceDate,
    priceId,
    recommendedPrice,
    updatedAt,
    vilmaUnit,
  }: ConstructorProps) {
    this.basePrice = basePrice;
    this.priceDate = priceDate;
    this.priceId = priceId;
    this.recommendedPrice = recommendedPrice;
    this.updatedAt = updatedAt;
    this.vilmaUnit = vilmaUnit;
  }

  static fromDto = (dto: FinfoPriceDTO): FinfoPrice => {
    return new FinfoPrice({
      basePrice: dto.basePrice,
      priceDate: dto.priceDate,
      priceId: dto.priceId,
      recommendedPrice: dto.recommendedPrice,
      updatedAt: dto.updatedAt,
      vilmaUnit: {
        description: dto.vilmaUnit.description,
        unit: dto.vilmaUnit.unit,
      },
    });
  };
}
