import { NobbProductRow } from '@retail/products/types';

export const productHasMgPrice = ({ mgPrice }: Pick<NobbProductRow, 'mgPrice'>) =>
  mgPrice.unitPrice || mgPrice.variantPrice;

export const productHasOmniumPrice = ({ omniumPrice }: Pick<NobbProductRow, 'omniumPrice'>) =>
  omniumPrice.unitPrice || omniumPrice.variantPrice?.price;

export const producthasPriceForRetailer = (product: NobbProductRow, retailerKey: string) => {
  const retailerPrice = product[retailerKey];
  return retailerPrice?.price?.primaryPrice || retailerPrice?.lowestVariantPrice?.price;
};
