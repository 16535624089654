import { useContexts, useUserBrands } from '@retail/app/stores/selected-context';
import {
  getMgAssortmentsQueryKey,
  useCreateMgAssortment,
  useFetchMgAssortments,
} from '@retail/assortment/data-access';
import { Stack, Tooltip, Typography } from '@mui/material';
import { ContainedButton, TagHeading } from '@shared/components';
import {
  AssortmentsList,
  CreateAssortmentManuallyDialog,
  CreateAssortmentMenu,
  IdentifiableColumn,
  ImportAssortmentDialog,
  NewAssortment,
  SuccessCreatingAssortment,
} from '@retail/assortment/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';
import { useDisclosure, usePopupElement } from '@shared/hooks';
import { FetchError } from '@shared/fetch-utils';
import { useAssortmentTFunction } from '@retail/assortment/i18n';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMgAssortmentActions } from '@retail/assortment/context';

const columnsToRetrieve = new Set<IdentifiableColumn>(['finfo', 'gtin', 'mgItem']);

export function AllMgAssortmentsContainer() {
  const { selectedContext } = useContexts();
  const { data: mgAssortmentDtos = [] } = useFetchMgAssortments();
  const t = useAssortmentTFunction();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const [successModal, setSuccessModal] = useState(false);
  const { mutateAsync: createAssortmentAsync, isLoading: isLoadingCreate } =
    useCreateMgAssortment(selectedContext);
  const navigateToItemSearch = useCallback(() => {
    navigate(mgPrisRoutes.items.getFullLinkPath());
  }, [navigate]);

  const { handleValidateItemsOnImportedAssortment } = useMgAssortmentActions();

  const submitNewAssortmentForm = ({
    title,
    assortmentType,
    gtins,
    finfoNrs,
    mgItemNrs,
  }: NewAssortment) => {
    createAssortmentAsync({
      body: {
        title,
        assortmentType,
        itemCodes: {
          gtinCodes: gtins,
          finfoNumbers: finfoNrs,
          mgItemNumbers: mgItemNrs,
        },
        description: '',
      },
    })
      .then(() => {
        queryClient.invalidateQueries(getMgAssortmentsQueryKey(selectedContext));

        if (isImportModalOpen) {
          toast.success(t('assortment.admin.create.success', { title }));
          closeImportModal();
        } else {
          closeCreateManuallyModal();
          setSuccessModal(true);
        }
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('assortment.admin.create.error.generic'));
      });
  };
  const {
    isOpen: isImportModalOpen,
    onClose: closeImportModal,
    onOpen: openImportModal,
  } = useDisclosure(false);

  const {
    isOpen: isCreateManuallyModalOpen,
    onClose: closeCreateManuallyModal,
    onOpen: openCreateManuallyModal,
  } = useDisclosure(false);
  const { selectedBrand } = useUserBrands();

  const { menuElementProps, triggerElementProps } = usePopupElement();

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <TagHeading color="primary">
            <Typography variant="h6" component="h1">
              {t('assortment.monitoringAssortment')}
            </Typography>
          </TagHeading>
        </Stack>

        {selectedContext.isAdmin ? (
          <ContainedButton {...triggerElementProps} color="secondary" size="small">
            {t('assortment.admin.create.options.title')}
          </ContainedButton>
        ) : (
          <Tooltip title={t('assortment.assortmentsOverview.onlyAdminAccess')} placement="top">
            <span>
              <ContainedButton disabled size="small" sx={{ whiteSpace: 'nowrap' }}>
                {t('assortment.admin.create.options.title')}
              </ContainedButton>
            </span>
          </Tooltip>
        )}
      </Stack>
      <AssortmentsList
        assortments={mgAssortmentDtos}
        linkTo={(asssortmentId: number) =>
          mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(`${asssortmentId}`)
        }
        contextId={selectedContext.id}
        brandLogoUri={selectedBrand.circularLogo}
      />
      {isImportModalOpen && (
        <ImportAssortmentDialog
          onClose={closeImportModal}
          onSubmit={submitNewAssortmentForm}
          getInvalidItemCodes={handleValidateItemsOnImportedAssortment}
          columnsToRetrieve={columnsToRetrieve}
          isLoading={isLoadingCreate}
          hideAssortmentType
        />
      )}
      {isCreateManuallyModalOpen && (
        <CreateAssortmentManuallyDialog
          onClose={closeCreateManuallyModal}
          onSubmit={submitNewAssortmentForm}
          isLoading={isLoadingCreate}
          hideAssortmentType
        />
      )}
      <SuccessCreatingAssortment
        isOpen={successModal}
        closeDialog={() => setSuccessModal(false)}
        navigateToItemSearch={navigateToItemSearch}
      />
      <CreateAssortmentMenu
        {...menuElementProps}
        openImportModal={openImportModal}
        openCreateManuallyModal={openCreateManuallyModal}
      />
    </Stack>
  );
}
