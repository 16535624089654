import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { appLanguageResources } from '@retail/app/i18n';
import { sharedLanguageResources } from '@shared/i18n';

export const setupi18n = (options: InitOptions = {}) => {
  const resources = {
    no: {
      ...appLanguageResources.no,
      ...sharedLanguageResources.no
    },
    se: {
      ...appLanguageResources.se,
      ...sharedLanguageResources.se
    }
  }

  i18n.use(initReactI18next).init({
    resources: resources,
    lng: 'se',
    fallbackLng: 'se',
    ns: Object.keys(resources.se),
    defaultNS: 'common',
    ...options,
  });
};