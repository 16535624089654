import { Box, Typography } from '@mui/material';
import { ProductCompetitorInfo } from '@retail/products/types';
import HistoricProductCompetitorPricesChart from './HistoricProductCompetitorPricesChart';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  variants: ProductCompetitorInfo[];
}

const HistoricVariantPricesCharts: React.FC<Props> = ({ variants }) => {
  const t = useAppTFunction();
  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 2 }}>
        {t('products.prices.competitors.variants.historic.title')}
      </Typography>
      {variants.length > 0 ? (
        variants.map((variant, index) => (
          <div key={variant.nobbNr}>
            <Typography>
              {`${variant.productName} (${t('products.nobb.number')} ${variant.nobbNr})`}
            </Typography>
            <HistoricProductCompetitorPricesChart productData={variant} />
          </div>
        ))
      ) : (
        <Typography>{t('products.prices.competitors.variants.historic.noVariants')}</Typography>
      )}
    </Box>
  );
};

export default HistoricVariantPricesCharts;
