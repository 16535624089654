import { Typography } from '@mui/material';

export const ChangeInValue = (prevValue: string | number, newValue: string | number) => {
  if (!newValue || !prevValue) {
    return '-';
  } else if (prevValue !== newValue) {
    return (
      <>
        <Typography fontSize={14} sx={{ textDecoration: 'line-through' }}>
          {prevValue}
        </Typography>
        {newValue}
      </>
    );
  }

  return newValue;
};
