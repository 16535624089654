import { ByggmaterialpriserStoreDetailedDTO, StoreCompetitorDTO } from '../dto';

export class Store {
  id: number;
  name: string;
  isSelected: boolean;
  city?: string;
  latitude?: number;
  longitude?: number;

  constructor(
    id: number,
    name: string,
    isSelected: boolean,
    city?: string,
    latitude?: number,
    longitude?: number
  ) {
    this.id = id;
    this.name = name;
    this.isSelected = isSelected;
    this.city = city;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  static fromByggmaterialpriserStoreDto =
    (competitors: StoreCompetitorDTO[]) =>
    ({ id, name, city, latitude, longitude }: ByggmaterialpriserStoreDetailedDTO): Store => ({
      id,
      city,
      name,
      latitude,
      longitude,
      isSelected: competitors.some((storeComp) => storeComp.id === id),
    });
}
