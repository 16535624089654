import { Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

export function ConfettiCircle() {
  const t = useAppTFunction();

  return (
    <Stack
      height="128px"
      width="128px"
      borderRadius="50%"
      bgcolor="#E1F4EA"
      alignItems="center"
      justifyContent="center"
      fontSize="68px"
    >
      <span role="img" aria-label={t('products.mgItem.addItemsToAssortment.confettiEmoji')}>
        🎉
      </span>
    </Stack>
  );
}
