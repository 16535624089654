import { PriceUnitAlias } from '../../types/PriceUnits';
import { prisinnsiktConfig } from '@retail/app/config';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToken } from '../../hooks/useToken';
import { fetcher } from '@shared/fetch-utils';

const url = `${prisinnsiktConfig.pps.url}/v1/priceunits`;

export async function fetchPriceUnitsAliases(token: string): Promise<PriceUnitAlias[]> {
  return fetcher<PriceUnitAlias[]>({
    url: `${url}/aliases`,
    token,
  });
}

export async function fetchUpdatePriceUnitAlias(
  token: string,
  aliasId: number,
  unit: string
): Promise<PriceUnitAlias> {
  return fetcher<PriceUnitAlias>({
    url: `${url}/aliases/${aliasId}`,
    method: 'PUT',
    body: JSON.stringify({ unit }),
    token,
  });
}

export function useAliasesApi() {
  const queryClient = useQueryClient();
  const getAccessTokenWithErrorHandling = useToken();
  const aliases = useQuery(['aliases'], async () => {
    const token = await getAccessTokenWithErrorHandling();
    return fetchPriceUnitsAliases(token);
  });

  const updatePriceUnitAlias = useMutation(
    async ({ aliasId, unitValue }: { aliasId: number; unitValue: string }) => {
      const token = await getAccessTokenWithErrorHandling();
      return fetchUpdatePriceUnitAlias(token, aliasId, unitValue);
    },
    {
      onSuccess: (data) => {
        const updated = aliases.data?.map((a) => (a.id === data.id ? { ...a, ...data } : a));
        queryClient.setQueryData(['aliases'], updated);
      },
    }
  );

  return { aliases, updatePriceUnitAlias };
}
