import themeV2 from '../dap';
import { mgPrisBlue } from './colors';
import { Theme, createTheme } from '@mui/material';
import { componentsStyles } from './compontentsStyles';

const mgPrisTheme: Theme = createTheme({
  ...themeV2,
  palette: {
    ...themeV2.palette,
    primary: {
      ...mgPrisBlue,
      main: mgPrisBlue[600],
      dark: mgPrisBlue[900],
      light: mgPrisBlue[400],
      contrastText: themeV2.palette.common.white,
    },

    error: {
      light: themeV2.palette.warning[900],
      main: themeV2.palette.error.main,
      dark: themeV2.palette.error.dark,
      contrastText: themeV2.palette.common.black,
    },
    gradient: {
      ...themeV2.palette.gradient,
      primary: `linear-gradient(292deg, ${mgPrisBlue[800]} 15.01%, ${mgPrisBlue[700]} 32.03%, ${mgPrisBlue[600]} 50.5%, ${mgPrisBlue[500]} 98.63%)`,
      tertiary: `linear-gradient(292deg, #6E1FBC 15.01%, #8A13B4 32.03%, #991AC6 50.5%, #CA2CCD 98.63%)`,
    },
    background: {
      ...themeV2.palette.background,
      default: mgPrisBlue.A200,
      secondary: mgPrisBlue.A100,
    },
    divider: themeV2.palette.grey[50],
  },
  components: componentsStyles,
});

export default mgPrisTheme;
