import styled from '@emotion/styled';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { HeaderText } from '../../components/Header';
import { ProductCard } from '../../components';
import { Fragment, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { BaseNobbProductType } from '@retail/products/types';
import LoadingButton from '../../components/LoadingButton';
import { useAppTFunction } from '@retail/app/i18n';
import { StrictModeDroppable } from '@shared/components';
import { dropZoneSelected } from '../../config';

const DroppableZone = styled.div<{ isDraggingOver: boolean }>`
  background-color: ${(props) => (props.isDraggingOver ? 'white' : 'none')};
  border: ${(props) => (props.isDraggingOver ? '3px dashed #c7cace' : 'none')};
  border-radius: ${(props) => (props.isDraggingOver ? '8px' : '0px')};
  flex: 1;
  height: 100%;
`;

const DeleteIconButton = styled(DeleteIcon)`
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  selectedProducts: BaseNobbProductType[];
  onRemoveProduct: (nobbNr: number) => void;
  onSave: (nobbNrs: number[]) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export function AssortmentZone({
  selectedProducts,
  onRemoveProduct,
  onSave,
  onCancel,
  isLoading,
}: Props) {
  const t = useAppTFunction();
  const totalProducts = useMemo(() => {
    return selectedProducts.length;
  }, [selectedProducts]);

  const handleSave = () => {
    onSave(selectedProducts.map((p) => p.nobbNr));
  };

  return (
    <Fragment>
      <HeaderText>{t('assortment.admin.edit.title')}</HeaderText>
      <Box
        display="flex"
        flexDirection="column"
        height={'100%'}
        bgcolor={'white'}
        borderRadius={'10px'}
        py={1}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography>{t('assortment.admin.edit.numProducts', { totalProducts })}</Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={onCancel}>
              {t('common.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              isLoading={!!isLoading}
            >
              {t('common.save')}
            </LoadingButton>
          </Stack>
        </Box>
        <Typography variant="subtitle2">{t('assortment.admin.edit.saveReminder')}</Typography>
        <Divider />
        <Box bgcolor={'white'} height={'100%'} flexGrow={1}>
          <StrictModeDroppable droppableId={dropZoneSelected}>
            {(provided, snapshot) => (
              <DroppableZone ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                {selectedProducts.length === 0 && (
                  <Box>{t('assortment.admin.edit.noProducts')}</Box>
                )}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                    gridGap: '5px 5px',
                  }}
                >
                  {selectedProducts.map((p, idx) => (
                    <Draggable key={p.nobbNr} draggableId={`${p.nobbNr}`} index={idx}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ProductCard
                            key={p.nobbNr}
                            product={p}
                            icon={<DeleteIconButton onClick={() => onRemoveProduct(p.nobbNr)} />}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Box>
                {provided.placeholder}
              </DroppableZone>
            )}
          </StrictModeDroppable>
        </Box>
      </Box>
    </Fragment>
  );
}
