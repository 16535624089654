import { FinfoPrice, MgSupplierItemPackage, VilmaUnit } from '@retail/products/types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CopyToClipboard } from '@retail/components';
import { Box, Stack } from '@mui/material';
import { UnitTooltip } from '../Units/UnitTooltip';
import Tooltip from '@mui/material/Tooltip';
import { ArmScaleIcon } from '@shared/custom-icons';
import { formatPrice, toFixedIfNecessary } from '@retail/products/utils';
import * as React from 'react';
import { useMemo } from 'react';
import { useProductsTFunction } from '@retail/products/i18n';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '@sanity/icons';

interface Props extends MgSupplierItemPackage {
  finfoPrice?: FinfoPrice;
  basePackageUnit?: VilmaUnit;
}

export function MgSupplierItemPackagesTableRow({
  mgSupplierItemPackageId,
  mgPackage,
  vilmaUnit,
  basePriceUnit,
  priceComparisonUnit,
  calculatedCount,
  finfoPrice,
  basePackageUnit,
  type,
}: Props) {
  const t = useProductsTFunction();
  const { i18n } = useTranslation();
  const packageType = useMemo(() => {
    switch (type) {
      case 'PRIMARY':
        return t('products.mgItem.supplierItem.packageType.primary');
      case 'SECONDARY':
        return t('products.mgItem.supplierItem.packageType.secondary');
      case 'TERTIARY':
        return t('products.mgItem.supplierItem.packageType.tertiary');
      case 'PRICE_COMPARISON':
        return t('products.mgItem.supplierItem.packageType.priceComparison');
      case 'BASE_PRICE':
        return t('products.mgItem.supplierItem.packageType.basePrice');
      default:
        return type;
    }
  }, [t, type]);

  return (
    <TableRow
      key={mgSupplierItemPackageId}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        {mgPackage?.gtinCode ? (
          <CopyToClipboard value={mgPackage.gtinCode}>{mgPackage.gtinCode}</CopyToClipboard>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <UnitTooltip {...vilmaUnit} />
          {!!priceComparisonUnit && (
            <Tooltip title={t('products.mgItem.supplierItem.priceComparisonUnit')}>
              <div>
                <ArmScaleIcon fontSize="small" />
              </div>
            </Tooltip>
          )}
          {!!basePriceUnit && (
            <Tooltip title={t('products.mgItem.supplierItem.basePriceUnit')}>
              <StarIcon fontSize="20px" />
            </Tooltip>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        {mgPackage?.vilmaUnit ? (
          <Box display="flex">
            <UnitTooltip {...mgPackage?.vilmaUnit} />
          </Box>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center">
          {toFixedIfNecessary(calculatedCount, 2)}

          {basePackageUnit && (
            <UnitTooltip {...basePackageUnit} typographyProps={{ sx: { ml: 1 } }} />
          )}
        </Stack>
      </TableCell>
      <TableCell>{packageType}</TableCell>
      <TableCell align="right">
        {finfoPrice?.basePrice
          ? `${formatPrice(finfoPrice.basePrice * calculatedCount, i18n.language)}`
          : '-'}
      </TableCell>
      <TableCell align="right">
        {finfoPrice?.recommendedPrice
          ? `${formatPrice(finfoPrice.recommendedPrice * calculatedCount, i18n.language)}`
          : '-'}
      </TableCell>
    </TableRow>
  );
}
